import React, { useState, useEffect } from "react";
import Footer from "../components/Footer";
import FrontendHeader from "../../dashboard/components/DashboardHeader";
import { useNavigate } from "react-router-dom";
import { PlanBronzeLink, PlanGoldLink, PlanSilverLink, 
    AddOn8Link, AddOn29Link, AddOn44Link, AddOn89Link, AddOn179Link, AddOn269Link } from "../../utilities/enviroment";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";

const PricePlan = () => {
    const [activeTabani, setActiveTabani] = useState(1);
    const user = useSelector(state => state.user)
    const [active, setActive] = useState("monthly");
    const navigate = useNavigate();
    console.log(user)

    function handleSubscribe(e) {
        Swal.fire({
            icon: 'warning',
            title: 'Attention!!!',
            html: `Make sure add your eromentic account's email (${user?.user?.email}) while payment, otherwise your account will not update with the plan.`,
            cancelButtonAriaLabel: false,
            confirmButtonText: "Subscribe now!",
            confirmButtonColor: "#ff3232",
            showCloseButton: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                const btnValue = e.target.value;
                if (btnValue === 'bronze') {
                    window.location.href = PlanBronzeLink;
                } else if (btnValue === 'silver') {
                    window.location.href = PlanSilverLink;
                } else if (btnValue === 'gold') {
                    window.location.href = PlanGoldLink;
                } else {
                    navigate('/');
                }
            }
        });
    }
    const renderContent = () => {
        switch (active) {
            case "monthly":
                return (
                    <div className="flex flex-wrap justify-center gap-10">
                        {/* {/ Monthly Plan /} */}
                        <div className="relative bg-gradient-to-b from-[#3A3A3A] to-[#606060] border-4 border-[#CD7F32] text-white p-8 rounded-3xl shadow-lg w-full sm:w-1/2 lg:w-1/3 xl:w-1/4 flex flex-col items-center hover:scale-105 transition-transform">
                            <p
                                className="absolute top-2 right-2 text-sm sm:text-base font-extrabold inline-block px-4 py-1
                                    bg-gradient-to-r from-red-500 to-gray-500 text-white rounded-3xl
                                    border-2 border-transparent animate-border overflow-hidden"
                            >
                                <span
                                    className="absolute inset-0 bg-gradient-to-r from-pink-500 via-yellow-500 to-red-500
                                        blur-md opacity-75 animate-gradient"
                                ></span>
                                <span className="relative z-10">Offer 40% OFF</span>
                            </p>
                            <h2 className="text-2xl sm:text-3xl font-bold text-[#CD7F32] mt-5">Bronze Plan</h2>
                            <p className="text-lg sm:text-xl mt-2">Pay Monthly</p>
                            <p className="text-sm sm:text-md font-extrabold mt-4 text-gray-400 line-through">$19.99/ month</p>

                            <p className="flex text-3xl sm:text-4xl font-extrabold mt-4">$11.99</p>
                            <p className="text-sm sm:text-base text-gray-300 mt-2">40% off for the first month, then $19.99 per month</p>
                            <ul className="mt-6 text-left text-gray-200 space-y-2 text-sm sm:text-base">
                                {/* <li>✔ Messages: 1 Tokens per message sent/received</li>
        <li>✔ Girlfriend/Boyfriend Creation: 5 Tokens</li>
        <li>✔ Image Generation: 1 Tokens</li>
        <li>✔ Video Generation: 5 Tokens</li>
        <li>✔ Upscale Image: 1 Token</li>
        <li>✔ Voice Call: 2 Tokens/Minute</li>
        <li>✔ Voice Message: 0.5 Tokens</li> */}

                                <li>✔100 tokens per month for FREE </li>
                                <li>✔Create your perfect AI girlfriend/boyfriend </li>
                                <li>✔Chat with your virtual lover </li>
                                <li>✔Generate the spiciest images you can dream up </li>
                                <li>✔Bring your AI companion to life with personalized videos </li>
                                <li>✔Give your images more fidelity with our Upscale feature </li>
                                <li>✔Call your AI and discover the endless possibilities of conversation </li>
                                <li>✔Hear their voice come alive as you chat </li>
                            </ul>
                            {/* Flex grow for button alignment */}
                            <div className="flex-grow"></div>
                            <button
                                type="button"
                                value={"bronze"}
                                onClick={handleSubscribe}
                                className="py-4 px-8 bg-[#FF4C4C] rounded-full text-white hover:bg-[#FF3232] transition-all duration-200"
                            >
                                Subscribe $11.99/month
                            </button>
                        </div>


                        {/* {/ 3 Month Plan /} */}
                        <div className="relative bg-gradient-to-b from-[#3A3A3A] to-[#606060] border-4 border-[#C0C0C0] text-white p-8 rounded-3xl shadow-lg w-full sm:w-1/2 lg:w-1/3 xl:w-1/4 flex flex-col items-center hover:scale-105 transition-transform">
                            <p
                                className="absolute top-2 right-2 text-sm sm:text-base font-extrabold inline-block px-4 py-1
            bg-gradient-to-r from-red-500 to-gray-500 text-white rounded-3xl
            border-2 border-transparent animate-border overflow-hidden"
                            >
                                <span
                                    className="absolute inset-0 bg-gradient-to-r from-pink-500 via-yellow-500 to-red-500
                blur-md opacity-75 animate-gradient"
                                ></span>
                                <span className="relative z-10">Offer 55% OFF</span>
                            </p>
                            <h2 className="text-2xl sm:text-3xl font-bold text-[#C0C0C0] mt-5">Silver Plan</h2>
                            <p className="text-lg sm:text-xl mt-2">Billed Every 3 Months</p>
                            <p className="text-sm sm:text-md font-extrabold mt-4 text-gray-400 line-through">$54.99 / 3 month</p>
                            <p className="text-3xl sm:text-4xl font-extrabold mt-4">$7.99/month</p>
                            <p className="text-sm sm:text-base text-gray-300 mt-2">55% off per month, billed as $23.99, then $54.99 per 3-month period</p>
                            <ul className="mt-6 text-left text-gray-200 space-y-2 text-sm sm:text-base">
                                {/* <li>✔ Messages: 1 Tokens per message sent/received</li>
        <li>✔ Girlfriend/Boyfriend Creation: 5 Tokens</li>
        <li>✔ Image Generation: 1 Tokens</li>
        <li>✔ Video Generation: 5 Tokens</li>
        <li>✔ Upscale Image: 1 Token</li>
        <li>✔ Voice Call: 2 Tokens/Minute</li>
        <li>✔ Voice Message: 0.5 Tokens</li>
        <li>✔ Chat Customization: 0.2 Tokens</li> */}

                                <li>✔ 150 tokens per month for FREE</li>
                                <li>✔ Create your perfect AI girlfriend/boyfriend</li>
                                <li>✔ Chat with your virtual lover</li>
                                <li>✔ Generate the spiciest images you can dream up</li>
                                <li>✔ Bring your AI companion to life with personalized videos</li>
                                <li>✔ Give your images more fidelity with our Upscale feature</li>
                                <li>✔ Call your AI and discover the endless possibilities of conversation</li>
                                <li>✔ Hear their voice come alive as you chat</li>
                                <li>✔ Tailor your conversations to create a truly unique connection</li>
                            </ul>
                            <button
                                type="button"
                                value={"silver"}
                                onClick={handleSubscribe}
                                className="py-4 px-7 bg-[#FF4C4C] rounded-full text-white hover:bg-[#FF3232] transition-all duration-200 mt-auto"
                            >
                                Subscribe $23.99/3 months
                            </button>
                        </div>


                        {/* {/ Yearly Plan /} */}
                        <div className="relative bg-gradient-to-b from-[#3A3A3A] to-[#606060] border-4 border-[#FFD700] text-white p-8 rounded-3xl shadow-lg w-full sm:w-1/2 lg:w-1/3 xl:w-1/4 flex flex-col items-center hover:scale-105 transition-transform">
                            <p
                                className="absolute top-2 right-2 text-sm sm:text-base font-extrabold inline-block px-4 py-1
            bg-gradient-to-r from-red-500 to-gray-500 text-white rounded-3xl
            border-2 border-transparent animate-border overflow-hidden"
                            >
                                <span
                                    className="absolute inset-0 bg-gradient-to-r from-pink-500 via-yellow-500 to-red-500
                blur-md opacity-75 animate-gradient"
                                ></span>
                                <span className="relative z-10">Offer 70% OFF</span>
                            </p>
                            <h2 className="text-2xl sm:text-3xl font-bold text-[#FFD700] mt-5">Gold Plan</h2>
                            <p className="text-lg sm:text-xl mt-2">Pay Annually</p>
                            <p className="text-sm sm:text-md font-extrabold mt-4 text-gray-400 line-through">$199.99 / Annual</p>
                            <p className="text-3xl sm:text-4xl font-extrabold mt-4">$4.99/month</p>
                            <p className="text-sm sm:text-base text-gray-300 mt-2">70% off for the first year, billed as $59.99, then $199.99 annually</p>
                            <ul className="mt-6 text-left text-gray-200 space-y-2 text-sm sm:text-base">
                                {/* <li>✔ Messages: Unlimited</li>
        <li>✔ Girlfriend/Boyfriend Creation: 5 Tokens</li>
        <li>✔ Image Generation: 1 Tokens</li>
        <li>✔ Video Generation: 5 Tokens</li>
        <li>✔ Upscale Image: 1 Token</li>
        <li>✔ Voice Call: 2 Tokens/Minute</li>
        <li>✔ Voice Message: 0.5 Tokens</li>
        <li>✔ Chat Customization: 0.2 Tokens</li>
        <li>✔ Message Edit: 0.1 Tokens</li> */}

                                <li>✔ 200 tokens per month for FREE</li>
                                <li>✔ Create your perfect AI girlfriend/boyfriend</li>
                                <li>✔ Chat with your virtual lover</li>
                                <li>✔ Generate the spiciest images you can dream up</li>
                                <li>✔ Bring your AI companion to life with personalized videos</li>
                                <li>✔ Give your images more fidelity with our Upscale feature</li>
                                <li>✔ Call your AI and discover the endless possibilities of conversation</li>
                                <li>✔ Hear their voice come alive as you chat</li>
                                <li>✔ Tailor your conversations to create a truly unique connection</li>
                                <li>✔ Edit your messages to say exactly what's on your mind and guide your AI companion to respond in ways you'll love</li>
                            </ul>
                            <button
                                type="button"
                                value={"gold"}
                                onClick={handleSubscribe}
                                className="mt-auto py-4 px-7 bg-[#FF4C4C] rounded-full text-white hover:bg-[#FF3232] transition-all duration-200"
                            >
                                Subscribe $59.99/year
                            </button>
                        </div>

                    </div>
                );

            default:
                return null;
        }
    };

    // Handle tab click and update the URL
    const handleTabClick = (tab) => {
        setActiveTabani(tab);

        if (tab === 1) {
            // Remove 'token' from the URL when 'Subscriptions' is clicked
            const newUrl = window.location.href.replace(/([?&])token=[^&]*/, '');
            window.history.pushState({}, '', newUrl);
        } else if (tab === 2) {
            // Add 'token' to the URL when 'Token' is clicked
            const newUrl = window.location.href.includes('?')
                ? window.location.href + '&token=true'
                : window.location.href + '?token=true';
            window.history.pushState({}, '', newUrl);
        }
    };

    useEffect(() => {
        // On initial load, check if 'token' is in the URL to set the active tab
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get('token')) {
            setActiveTabani(2);
        }
    }, []);

    const tokenOptions = [
        { tokens: 100, price: 8.99, link: AddOn8Link },
        { tokens: 350, price: 29.99, link: AddOn29Link },
        { tokens: 550, price: 44.99, link: AddOn44Link },
        { tokens: 1200, price: 89.99, link: AddOn89Link },
        { tokens: 2500, price: 179.99, link: AddOn179Link },
        { tokens: 5200, price: 269.99, link: AddOn269Link },
    ];

    const handleSelect = (option) => {
        Swal.fire({
            icon: "info",
            title: "Proceed to Payment",
            html: `You have selected <b>${option.tokens} tokens</b> for <b>$${option.price}</b>. Do you want to proceed?`,
            showCancelButton: true,
            confirmButtonText: "Yes, Proceed!",
            confirmButtonColor: "#ff3232",
            cancelButtonText: "Cancel",
        }).then((result) => {
            if (result.isConfirmed) {
                window.location.href = option.link
                // navigate(option.link); // Redirect to payment page
            }
        });
    };

    return (
        <div className="flex flex-col min-h-screen">
            <FrontendHeader />
            <div className="flex justify-center space-x-4">
                <button
                    onClick={() => handleTabClick(1)}
                    className={`px-4 py-2 w-[25%] font-semibold text-lg rounded-tl-lg rounded-bl-lg transition-colors duration-300 ${activeTabani === 1 ? 'bg-[#991b1b] text-white' : 'bg-gray-200 text-gray-600'
                        }`}
                >
                    Subscriptions
                </button>
                <button
                    onClick={() => handleTabClick(2)}
                    style={{ margin: "0" }}
                    className={`px-4 py-2 w-[25%] font-semibold text-lg rounded-tr-lg rounded-br-lg transition-colors duration-300 ${activeTabani === 2 ? 'bg-[#991b1b] text-white' : 'bg-gray-200 text-gray-600'
                        }`}
                >
                    Token
                </button>
            </div>
            {activeTabani === 1 && (
                <div className="flex-1 text-white text-center mt-[75px] py-10 font-josefin-sans px-5 lg:px-20">
                    <p className="text-2xl sm:text-3xl lg:text-4xl font-extrabold">Choose Your Plan</p>
                    <p className="text-base sm:text-lg lg:text-xl text-gray-300">Find a plan that works best for you</p>

                    <div className="mt-10">
                        {renderContent()}
                        {/* <p className="px-3 lg:px-7 xl:px-40 2xl:px-48 text-start text-sm sm:text-base lg:text-lg mt-10 text-gray-700">
                            <span className="font-semibold">* Important Note:</span>The 'Replicate Face or Pose from an Uploaded Image' feature is currently in its experimental stage. Access to this feature is available to users who complete purchases via Epoch or Cryptocurrency, as CCBill prohibits image uploads on the platform.
                            If you wish to generate an image of a real person, please note that we <span className="font-bold">require</span> the person's ID document and consent for verification, in compliance with relevant laws and regulations.
                            Thank you for your understanding.
                        </p> */}
                    </div>
                </div>
            )}
            {activeTabani === 2 && (
                <>
                    <div className="flex flex-col min-h-screen">
                        <FrontendHeader />
                        <div className="flex-1 text-white text-center mt-[75px] py-10 font-josefin-sans px-5">
                            <p className="text-2xl sm:text-3xl lg:text-4xl font-extrabold">Add-On Tokens</p>
                            <p className="text-base sm:text-lg lg:text-xl text-gray-300">
                                Choose the add-on token package that suits your needs
                            </p>

                            <div className="mt-10 mx-[20%]">
                                {/* {/ Centered container with 20% left & right margin /} */}
                                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
                                    {tokenOptions.map((option, index) => (
                                        <div
                                            key={index}
                                            className="relative group p-6 rounded-3xl shadow-lg overflow-hidden bg-gradient-to-br from-[#292929] via-[#3a3a3a] to-[#606060] transition-transform transform hover:scale-105 hover:shadow-2xl hover:bg-gradient-to-tr hover:from-[#FF4C4C] hover:via-[#FF3232] hover:to-[#FF0000] duration-500"
                                        >
                                            {/* {/ Glow Effect /} */}
                                            <div className="absolute inset-0 bg-gradient-to-r from-pink-500 to-yellow-500 opacity-0 group-hover:opacity-25 blur-xl transition-all duration-500"></div>

                                            {/* {/ Inner Content /} */}
                                            <div className="relative z-10 flex flex-col items-center">
                                                <h3 className="text-2xl sm:text-3xl font-bold text-white">
                                                    {option.tokens} Tokens
                                                </h3>
                                                <p className="text-lg sm:text-xl text-gray-300 mt-2">${option.price}</p>
                                                <p className="text-sm sm:text-base text-gray-400 mt-1">
                                                    Perfect for {option.tokens / 100}x interactions
                                                </p>
                                                <button
                                                    className="mt-5 py-3 px-6 bg-white text-[#FF4C4C] rounded-full font-semibold hover:bg-[#FF3232] hover:text-white transition-all duration-300 shadow-md hover:shadow-lg"
                                                    onClick={() => handleSelect(option)}
                                                >
                                                    Buy Now
                                                </button>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <p className="px-3 lg:px-7 xl:px-40 2xl:px-48 text-start text-sm sm:text-base lg:text-lg mt-10 text-gray-700">
                                <span className="font-semibold">* Note:</span> Add-on tokens do not expire and can be used for any services or
                                features.
                            </p>
                        </div>
                    </div>
                </>
            )}
            <Footer />
        </div>
    );
};

export default PricePlan;
