import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Togal from '../../components/Togal';
import { IoClose } from 'react-icons/io5';
import { DeleteImg, eye } from '../../../assets';
import PagenameSearchBox from '../../components/PagenameSearchBox';
import UserApiClient from '../../../utilities/apiClients';

const Faqs = () => {
    const navigate = useNavigate();
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [faqsList, setfaqsList] = useState([]);
    const [filteredFaqs, setFilteredFaqs] = useState([]);
    const [popupData, setpopupData] = useState({});

    const handleBlog = () => {
        navigate('/admin/addFaqs');
    };

    const togglePopup = (data) => {
        setIsPopupOpen(!isPopupOpen);
        setpopupData(data);
    };

    const handleClose = () => {
        setIsPopupOpen(false);
        setpopupData({});
    };

    const handleDelete = async (faqsId) => {
        await UserApiClient.delete(`api/v1/admin/faq/${faqsId}/`, {}, true).then(async res => {
            if (res.success) {
                await fetchfaqsList();
            }
        })
    };

    const toggleStatus = async (faqsId, currentStatus) => {
        await UserApiClient.patch(`api/v1/admin/faq/${faqsId}/`, { "status": !currentStatus}, true).then(async res => {
            if (res.success) {
                await fetchfaqsList()
            }
        })
    };

    // Fetch the FAQ list when the component is mounted
    const fetchfaqsList = async () => {
        await UserApiClient.get(`api/v1/admin/faq/`, {}, true).then(async res => {
            if (res.success) {
                setfaqsList(res.data);
                setFilteredFaqs(res.data);
            }
        })
    };

    useEffect(() => {
        fetchfaqsList();
    }, []);

    const handleSearchChange = (e) => {
        const searchTerm = e.target.value.toLowerCase();
        const filtered = faqsList.filter(faq =>
            faq.question.toLowerCase().includes(searchTerm) ||
            faq.answer.toLowerCase().includes(searchTerm)
        );
        setFilteredFaqs(filtered);
    };

    return (
        <>
            <div className="h-full bg-white px-7 pb-20 font-josefin-sans">
                <PagenameSearchBox
                    title="FAQs List"
                    placename="Search FAQ"
                    btnname="Add FAQ"
                    btnfunction={handleBlog}
                    onSearchChange={handleSearchChange} // Pass the search change handler
                />
                <div className="relative overflow-x-auto sm:rounded-lg mt-10">
                    <table className="w-full text-left text-gray-500 border border-stone-300 table-fixed">
                        <thead className="text-xl text-black bg-stone-300">
                            <tr>
                                <th scope="col" className="px-2 2xl:px-6 py-3">Sr.</th>
                                <th scope="col" className="px-2 2xl:px-6 py-3">Questions</th>
                                <th scope="col" className="px-2 2xl:px-6 py-3">Answers</th>
                                <th scope="col" className="px-2 2xl:px-6 py-3">Pages</th>
                                <th scope="col" className="px-2 2xl:px-6 py-3">Status</th>
                                <th scope="col" className="px-2 2xl:px-6 py-3 text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredFaqs.map((faqs, index) => (
                                <tr key={faqs.id} className="bg-white text-xl font-normal text-black border-b border-stone-300">
                                    <td className="px-2 2xl:px-6 py-4 whitespace-normal break-words">{index + 1}.</td>
                                    <td className="px-2 2xl:px-6 py-4 whitespace-normal break-words">{faqs.question}</td>
                                    <td className="px-2 2xl:px-6 py-4 whitespace-normal break-words">{faqs.answer}</td>
                                    <td className="px-2 2xl:px-6 py-4 whitespace-nowrap">{faqs.page}</td>
                                    <td className="px-2 2xl:px-6 py-4">
                                        <Togal isChecked={faqs.status} value={faqs.status} handleChange={() => toggleStatus(faqs.id, faqs.status)} />
                                    </td>
                                    <td className="px-2 2xl:px-6 py-4 flex-wrap flex gap-5 justify-center items-center">
                                        <img
                                            src={eye}
                                            alt="eye"
                                            className="hover:cursor-pointer"
                                            onClick={() => togglePopup(faqs)}
                                        />
                                        <img
                                            src={DeleteImg}
                                            alt="delete"
                                            className="hover:cursor-pointer"
                                            onClick={() => handleDelete(faqs.id)}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

            {isPopupOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60" style={{zIndex: '999999999'}}>
                    <div className="bg-white pt-5 pb-10 px-10 rounded-lg text-left shadow-lg w-[1100px]">
                        <div className="relative left-[100%]">
                            <IoClose
                                size={29}
                                onClick={handleClose}
                                className="hover:cursor-pointer"
                            />
                        </div>
                        <p className="font-semibold text-2xl">Question</p>
                        <p className="mb-4 font-font-light text-md mx-2">{popupData.question}</p>
                        <p className="font-semibold text-2xl">Answer</p>
                        <p className="mb-4 font-font-light text-md mx-2">{popupData.answer}</p>
                        <p className="font-semibold text-2xl">Page</p>
                        <p className="mb-4 font-font-light text-md mx-2">{popupData.page}</p>
                        <p className="font-semibold text-2xl">Status</p>
                        <p className="mb-4 font-font-light text-md mx-2">{popupData.status? 'Active': 'Inactive'}</p>
                    </div>
                </div>
            )}
        </>
    );
};

export default Faqs;
