import React, { useEffect, useState } from 'react';
import UserApiClient from '../../utilities/apiClients';

const AdminNotificationPage = () => {
  const [notifications, setNotifications] = useState([
    // { id: 1, message: 'Server maintenance scheduled for 11 PM tonight.' },
    // { id: 2, message: 'New feature update: dark mode is now available.' },
  ]);

  const [newNotification, setNewNotification] = useState('');


  const fetchNotification = async () => {
    await UserApiClient.get('api/v1/notification/', false).then(async (res) => {
      if (res.success) {
        setNotifications(res.data);
      }
    })

  }

  const handleAddNotification = async () => {
    if (newNotification.trim() !== '') {
      await UserApiClient.post('api/v1/notification/', { 'text': newNotification }, false).then(async (res) => {
        if (res.success) {
          await fetchNotification();
          setNewNotification("");
        }
      })
    }
  };
  const handleRemove = async (id) => {
    await UserApiClient.delete(`api/v1/notification/${id}/`, {}, false).then(async (res) => {
      if (res.success) {
        await fetchNotification();
        setNewNotification("");
      }
    })
  };

  useEffect(() => {
    fetchNotification();
  }, [])

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col items-center py-8 px-4">
      <h1 className="text-2xl md:text-3xl lg:text-4xl font-bold text-gray-800 mb-6">Notifications</h1>

      {/* Add New Notification */}
      <div className="w-full max-w-2xl mb-8 w-100">
        <h2 className=" text-lg md:text-xl font-semibold text-gray-700 mb-4">Add New Notification</h2>
        <div className="bg-white p-6 rounded-lg shadow-md">
          <textarea
            className="w-full p-4 mb-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Enter notification message..."
            rows="4"
            value={newNotification}
            onChange={(e) => setNewNotification(e.target.value)}
          ></textarea>
          <button
            onClick={handleAddNotification}
            className=" w-full bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            Add Notification
          </button>
        </div>
      </div>

      {/* Display Current Notifications */}
      <div className="w-full max-w-2xl mb-8">
        <h2 className="text-lg md:text-xl font-semibold text-gray-700 mb-4">Current Notifications</h2>
        <div className="bg-white p-6 rounded-lg shadow-md">
          <ul className="space-y-4">
            {notifications.map((notif) => (
              <li key={notif.id} className="flex justify-between items-center p-4 bg-gray-100 rounded-lg">
                <span className="text-gray-800">{notif.text}</span>
                <i class="fa-solid fa-trash-can" onClick={() => handleRemove(notif.id)}></i>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AdminNotificationPage;
