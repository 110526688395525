import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { DeleteImg, eye, rename, tableUser1, tableUser2 } from '../../../../assets'
import PagenameSearchBox from '../../../components/PagenameSearchBox'
import UserApiClient from '../../../../utilities/apiClients'
import Swal from 'sweetalert2'

const AllUser = () => {

    const [userList, setUserList] = useState([]);
    const [filteredUser, setFilteredUser] = useState([]);

    const [activeTab, setActiveTab] = useState(1);
    const [showEditModal, setShowEditModal] = useState(false)
    const [showViewModal, setShowViewModal] = useState(false)
    const [selectedUser, setSelectedUser] = useState(null)

    const navigate = useNavigate()

    const handleStatusChange = async (e, id) => {
        await Swal.fire({
            title: "Are you sure?",
            text: `You won't be able to revert this!`,
            icon: "question",
            showCancelButton: true,
            cancelButtonColor: "#d33",
            confirmButtonText: `Yes!`,
        }).then(async (result) => {
            if (result.isConfirmed) {
                await UserApiClient.patch(`api/v1/admin/user/${id}/`, { 'is_active': !e.target.checked }, true).then(async (res) => {
                    if (res.success) {
                        await handleTabClick(1, true);
                        Swal.fire(`User ${e.target.checked ? 'Inactivate' : 'Activated'}.`, "", "success", 3000);
                    }
                });
            } else {
                e.target.checked = e.target.checked;
            }
        });
    }

    const openEditModal = (user) => {
        setSelectedUser(user)
        setShowEditModal(true)
    }

    const openViewModal = (user) => {
        setSelectedUser(user)
        setShowViewModal(true)
    }

    const closeModals = () => {
        setShowEditModal(false)
        setShowViewModal(false)
    }

    const handleSearchChange = (e) => {
        const searchTerm = e.target.value.toLowerCase();
        const filtered = userList.filter(faq =>
            faq.email.toLowerCase().includes(searchTerm) ||
            faq.affiliate_id?.toLowerCase().includes(searchTerm)
        );
        setFilteredUser(filtered);
    };

    // Handle tab click to change the active tab
    const handleTabClick = async (tabNumber, change = false) => {
        if (activeTab != tabNumber || change) {
            await fetchData(tabNumber == "1" ? undefined : tabNumber == "2" ? "1" : "0")
            setActiveTab(tabNumber);
        }
    };

    const fetchData = async (status) => {
        await UserApiClient.get(`api/v1/admin/users/${status ? '?status=' + status : ''}`, {}, true).then(async res => {
            if (res.success) {
                setUserList(res.data);
                setFilteredUser(res.data);
            }
        })
    }

    useEffect(() => {
        fetchData();
    }, [])

    return (
        <>
            <div className='h-full bg-white px-7 pb-20 font-josefin-sans'>
                {/* Search Bar and Heading */}
                <PagenameSearchBox
                    title='User List'
                    placename='Search User'
                    // btnname='Add User'
                    // btnfunction={() => navigate('/admin/addUser')}
                    onSearchChange={handleSearchChange}
                />

                {/* User Table */}
                <div className="relative overflow-x-auto sm:rounded-lg mt-10 font-josefin-sans">
                    <div className="flex space-x-4">
                        <button
                            onClick={() => handleTabClick(1)}
                            className={`px-4 py-2 m-2 w-[20%] font-semibold text-lg rounded-lg transition-colors duration-300 ${activeTab === 1 ? 'bg-[#000] text-white' : 'bg-gray-200 text-gray-600'
                                }`}
                        >
                            All
                        </button>
                        <button
                            onClick={() => handleTabClick(2)}
                            className={`px-4 py-2 m-2 w-[20%] font-semibold text-lg rounded-lg transition-colors duration-300 ${activeTab === 2 ? 'bg-[#000] text-white' : 'bg-gray-200 text-gray-600'
                                }`}
                        >
                            Active
                        </button>
                        <button
                            onClick={() => handleTabClick(3)}
                            className={`px-4 py-2 m-2 w-[20%] font-semibold text-lg rounded-lg transition-colors duration-300 ${activeTab === 3 ? 'bg-[#000] text-white' : 'bg-gray-200 text-gray-600'
                                }`}
                        >
                            Inactive
                        </button>
                    </div>
                    <table className="w-full text-left text-gray-500 border border-stone-300 table-fixed sm:table-auto">
                        <thead className="text-xl text-black bg-stone-300">
                            <tr>
                                <th scope="col" className=" px-6 py-3 w-1/5">ID</th>
                                <th scope="col" className=" px-6 py-3 w-1/5">User</th>
                                {/* <th scope="col" className=" px-6 py-3 w-1/5">Name</th> */}
                                <th scope="col" className=" px-6 py-3 w-1/5">Affiliate Id</th>
                                <th scope="col" className=" px-6 py-3 w-1/5">Sub. Commission</th>
                                <th scope="col" className=" px-6 py-3 w-1/5">Token Commission</th>
                                <th scope="col" className=" px-6 py-3 w-1/5">Plan</th>
                                <th scope="col" className=" px-6 py-3 w-1/5">Status</th>
                                <th scope="col" className=" px-6 py-3 text-center w-1/5">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredUser.map((item, index) => (
                                <tr className="bg-white font-normal text-black text-xl border-b border-stone-300" key={item.id}>
                                    <td className="px-6 py-4 whitespace-nowrap">#{item.id}</td>
                                    <td onClick={() => navigate(`/admin/userdetail/${item.id}`)} className="px-6 flex items-center py-4 text-gray-900 whitespace-nowrap">
                                        {/* <img src={tableUser2} alt="user2" onClick={() => openViewModal({ name: 'Gagandeep Singh', email: 'gagan.pyt@gmail.com' })} className='hover:cursor-pointer w-9 h-9 rounded-full' /> */}
                                        <span className="ml-2">{item.email}</span>
                                    </td>
                                    {/* <td onClick={() => navigate('/admin/userProfile')} className="px-6 py-4 whitespace-nowrap truncate w-1/5">gagan.pyt@gmail.com</td> */}
                                    <td className="px-6 py-4 whitespace-nowrap">{item.affiliate_id || 'Not created yet.'}</td>
                                    <td className="px-6 py-4 whitespace-nowrap">{item.ref_commission}/{item.ref_commission_raise}</td>
                                    <td className="px-6 py-4 whitespace-nowrap">{item.token_commission}/{item.token_commission_raise}</td>
                                    <td className="px-6 py-4 whitespace-nowrap capitalize">
                                        {/* <div className='rounded-full border text-base w-20 py-1 border-[#FFD700] bg-[#FFD7004A] text-[#FFD700]'> */}
                                        <p className='text-center'>{item.subscription_type}</p>
                                        {/* </div> */}
                                    </td>
                                    <td className="px-6 py-4">
                                        <label className="inline-flex items-center cursor-pointer ">
                                            <input type="checkbox" className="sr-only peer" onChange={(e) => handleStatusChange(e, item.id)} checked={item.is_active} />
                                            <div className="relative w-11 h-6 bg-red-500 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-500" />
                                        </label>
                                    </td>
                                    <td className="px-6 py-4">
                                        <div className='flex gap-5 justify-center items-center'>
                                            <img src={eye} alt="view" className='hover:cursor-pointer' onClick={() => openViewModal(item)} />
                                            {/* <img src={rename} alt="rename" className='hover:cursor-pointer' onClick={() => openEditModal({ name: 'Gagandeep Singh', email: 'gagan.pyt@gmail.com' })} /> */}
                                            {/* <img src={DeleteImg} alt="delete" className='hover:cursor-pointer' onClick={() => { }} /> */}
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

            {/* Edit Modal */}
            {showEditModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                    <div className="bg-white p-5 rounded-md w-96">
                        <h2 className="text-xl font-bold">Edit User</h2>
                        <input
                            type="text"
                            placeholder="Enter name"
                            className="w-full mt-4 p-2 border border-gray-300 rounded"
                            defaultValue={selectedUser?.name}
                        />
                        <input
                            type="email"
                            placeholder="Enter email"
                            className="w-full mt-4 p-2 border border-gray-300 rounded"
                            defaultValue={selectedUser?.email}
                        />
                        <div className="mt-4 flex justify-end gap-4">
                            <button className="bg-gray-500 text-white p-2 rounded" onClick={closeModals}>Cancel</button>
                            <button className="bg-blue-500 text-white p-2 rounded" onClick={closeModals}>Save</button>
                        </div>
                    </div>
                </div>
            )}

            {/* View Modal */}
            {showViewModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                    <div className="bg-white p-5 rounded-md w-96">
                        <h2 className="text-xl font-bold">View User</h2>
                        <div className='m-2'>

                            <p className='p-2 m-1 flex flex-wrap justify-between border rounded-lg'>
                                <strong className='mb-1'>ID:</strong> {selectedUser?.id}
                            </p>
                            <p className='p-2 m-1 flex flex-wrap justify-between border rounded-lg'>
                                <strong className='mb-1'>Affiliate Id:</strong> {selectedUser?.affiliate_id || 'Not created yet.'}
                            </p>
                            <p className='p-2 m-1 flex flex-wrap justify-between border rounded-lg'>
                                <strong className='mb-1'>Email:</strong> {selectedUser?.email}
                            </p>
                            <p className='p-2 m-1 flex flex-wrap justify-between border rounded-lg'>
                                <strong className='mb-1'>Date Joined:</strong> {selectedUser?.date_joined}
                            </p>
                            <p className='p-2 m-1 flex flex-wrap justify-between border rounded-lg'>
                                <strong className='mb-1'>Referred by:</strong> {selectedUser?.ref_by || 'Unreferred'}
                            </p>
                            <p className='p-2 m-1 flex flex-wrap justify-between border rounded-lg'>
                                <strong className='mb-1'>Referral Commission:</strong> {selectedUser?.ref_commission}/{selectedUser?.ref_commission_raise}
                            </p>
                            <p className='p-2 m-1 flex flex-wrap justify-between border rounded-lg'>
                                <strong className='mb-1'>Token Commission:</strong> {selectedUser?.token_commission}/{selectedUser?.token_commission_raise}
                            </p>
                            <p className='p-2 m-1 flex flex-wrap justify-between border rounded-lg'>
                                <strong className='mb-1'>Balance:</strong> {selectedUser?.balance}
                            </p>
                            <p className='p-2 m-1 flex flex-wrap justify-between border rounded-lg'>
                                <strong className='mb-1'>Subscription Type:</strong> {selectedUser?.subscription_type}
                            </p>
                            <div className="mt-4 flex justify-end">
                                <button className="bg-gray-500 text-white p-2 rounded" onClick={closeModals}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

        </>
    )
}

export default AllUser
