import React, { useState, useRef } from "react";
import {
  Rectangle73,
  img1,
  img3,
  img4,
  img7,
  gifstep1,
  gifstep2,
  gifstep3,
  gifstep5,
  gifstep6,
  newstep1,
  newstep2,
  newstep3,
  newstep4,
  newstep5,
} from "../../../assets";
import { buttonsData, imageData } from "../../../assets/data";
import HoverButton from "../../../dashboard/components/HoverButton";

const Characters = () => {
  const [openIndex, setOpenIndex] = useState(0);
  const contentRefs = useRef([]);

  // const contentImages = [gifstep1, gifstep2, gifstep3, gifstep5, gifstep6];
  const contentImages = [newstep1, newstep2, newstep3, newstep4, newstep5];

  const questions = [
    { step: "Step 1", text: "Create Account", disc: (
        <ol className="px-1">
          <li style={{ marginTop: '10px' }}><>1. Click the Free Trial button to get started through the registration process.</><br />
          </li>
          <li style={{ marginTop: '10px' }}><>2. Sign up directly using your Google account. This will automatically create an account for you on Eromantic-AI.</><br />
          </li>
          <li style={{ marginTop: '10px' }}><>3. Register with email & password. Use the same to log in and access all features of Eromantic-AI.</><br />
          </li>
        </ol>
      )},
    { step: "Step 2", text: "Design Personality and Looks ",disc:(
        <ol className="px-1">
           <li style={{ marginTop: '10px' }}><> You can personalize your AI girlfriend or boyfriend by adjusting her/his voice, hobbies, demeanor, and physical traits. This allows you to engage in meaningful conversations, offer companionship, advice, and share life experiences.  </><br />
          </li>
        </ol>) },
    // { step: 'step 3', text: "Choose Personality" },
    { step: "Step 3", text: "Build Profile of Your GF/BF", disc: (
        <ol className="px-">
          <li style={{ marginTop: '10px' }}>
          Build your ideal GF/BF profile by selecting unique features like voice, personality, hobbies, and appearance. Personalize every detail to create the perfect virtual companion.
          </li>
          </ol>
      )},
    { step: "Step 4", text: "Chat with Your GF/BF",disc: (
        <ol className="px-">
          <li style={{ marginTop: '10px' }}>Eromantic-AI offers interactive chats with your AI girlfriend, including photo requests, making conversations more engaging and immersive through its unique AI chat system.
          </li>
          </ol>
      ) },
    { step: "Step 5", text: "Upgrade for More Features",disc: (
        <ol className="px-">
          <li style={{ marginTop: '10px' }}>    Upgrade to unlock enhanced image generation, advanced customization options, exclusive features, and more, offering a richer, personalized virtual companion experience with Eromantic-AI.
          </li>
          </ol>
      ) },
  ];

  const handleToggle = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const getMaxHeight = (index) => {
    if (contentRefs.current[index]) {
      return openIndex === index
        ? `${contentRefs.current[index].scrollHeight}px`
        : "0px";
    }
    return "0px";
  };

  return (
    <div className="text-white pb-24 px-5 md:px-10 lg:px-20">
      {/* <div className="text-center py-12">
                <p className="text-black text-[16px] lg:text-[18px] xl:text-[22px] leading-none font-light xl:font-normal py-3 mx-auto">
                    How to make your own
                </p>
                <p className="text-black text-[18px] lg:text-[24px] xl:text-[36px]">
                    <span className="text-[#99000b]">AI Girlfriends</span> with Eromantic.AI?
                </p>
            </div> */}

      <div className="flex flex-col lg:flex-row">
        <div className="w-full lg:w-1/4 rounded-lg mt-5 lg:mt-0">
          <div id="accordion-collapse" data-accordion="collapse">
            {questions.map((question, index) => (
              <div key={index}>
                <h2 id={`accordion-collapse-heading-${index}`}>
                  <button
                    type="button"
                    className={`flex items-center justify-between w-full py-2 px-5 font-medium text-gray-500 hover:text-red-500 transition-colors duration-300 ease-in-out gap-3 ${
                      openIndex === index ? "text-red-500" : ""
                    }`}
                    onClick={() => handleToggle(index)}
                    aria-expanded={openIndex === index}
                    aria-controls={`accordion-collapse-body-${index}`}
                  >
                    <div className="flex flex-col items-baseline hover:scale-105 animate-fadeInUp">
                      <span className="text-[#990013] text-base sm:text-lg">
                        {question.step}:{" "}
                      </span>
                      <span className="text-black text-md sm:text-lg hover:text-red-500 text-left ">
                        {question.text}
                      </span>
                    </div>
                    <svg
                      className={`w-3 h-3 transform ${
                        openIndex === index ? "" : "rotate-180"
                      }`}
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 10 6"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M9 5 5 1 1 5"
                      />
                    </svg>
                  </button>
                </h2>
                <div
                  id={`accordion-collapse-body-${index}`}
                  className="overflow-hidden transition-all duration-500 ease-in-out"
                  style={{ maxHeight: getMaxHeight(index) }}
                  ref={(el) => (contentRefs.current[index] = el)}
                  aria-labelledby={`accordion-collapse-heading-${index}`}
                >
                  <p className="px-5 text-sm sm:text-sm text-[#5e5f5f]">
                  {question.disc}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="w-full lg:w-3/4 rounded-lg mt-5 lg:mt-0">
          <img
            className="w-full h-[10rem] lg:h-[30rem] object-contain rounded-lg"
            src={contentImages[openIndex ?? 0]}
            alt="steps"
          />
        </div>
      </div>
    </div>
  );
};

export default Characters;

{
  /* <div className=" text-white pb-24">
                <div className="text-center py-12">
                <p className="text-black text-[16px] lg:text-[18px] xl:text-[22px] leading-none font-light xl:font-normal py-3 2xl:mx-[599px] xl:mx-[300px] md:mx-[150px] mx-5 ">
                        How to make your own
                    </p>
                    <p className="text-black text-[18px] lg:text-[24px] xl:text-[36px]">AI Girlfriends with Eromantic.AI ?</p>
                    <p className="text-[18px] lg:text-[24px] xl:text-[36px]">AI Girls and Characters Revisited</p>
                    <p className="text-[16px] lg:text-[18px] xl:text-[22px] leading-none font-light xl:font-normal py-3 2xl:mx-[599px] xl:mx-[300px] md:mx-[150px] mx-5 ">
                        With EROMANTIC<span className="text-red-600">AI</span>, you can save
                        and reuse previously generated girls or characters, enabling them to
                        appear in endless scenarios across generations.
                    </p>
                </div>
                <div className="border-2 border-[#FF0013] grid grid-cols-10 xl:mx-[100px] rounded-2xl">

                    <div className="col-span-2 hidden md:block">
                        {imageData.map((image, index) => (
                            <div key={index} className="relative w-full p-0 m-0 group">
                                <div className="image-wrapper cursor-pointer">
                                    <img
                                        src={image.src}
                                        alt={image.alt}
                                        className="block w-full h-auto p-0 m-0 image"
                                    />
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="col-span-10 md:col-span-8">
                        <div>
                            <div className="flex justify-center md:justify-start flex-wrap gap-5 m-10">
                                {buttonsData.map((button, index) => (
                                    <div key={index}>
                                        <HoverButton placeholder={button.placeholder} width={button.width} />
                                    </div>
                                ))}
                            </div>
                            <div className="mx-12">
                                <img src={Rectangle73} alt="Rectangle73" className="w-full h-full hidden md:block" />
                            </div>
                        </div>
                    </div>
                </div>
            </div> */
}
