import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {Relationshipicon, Location, age, brest, country, eyes, hair, occupation, manbody, personality,Bodyicon,Bodyback} from '../../assets';
import { imageData } from '../../assets/data';
import loaders from '../../frontend/components/loaders';

function MyCreation() {
    const navigate = useNavigate();
    const [imgdata, setImgdata] = useState([]);  // Default to empty array
    const [loading, setLoading] = useState(false);  // Loading state
    const [error, setError] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);  // Modal visibility state
    const [modalData, setModalData] = useState(null);  // Data for the modal
    const [modalImg, setModalImg] = useState(null);  // Image for modal
    const [liked, setLiked] = useState(false);  // Likes count for modal
    const [likesCount, setLikesCount] = useState(0);
    const [botdetail, setBotdetail] = useState(null);  // Likes count for modal

    const BASE_URL = "https://api2.eromantic.ai/assistant_api/";

    const NewGenerate = async () => {
        { loaders(true, "heart") }
        setError(null);
        try {
            const authToken = localStorage.getItem('token');
            const response = await axios.get(`${BASE_URL}bot_fetch/`, {
                headers: {
                    'Authorization': `Bearer ${authToken}`
                }
            });
            if (response.status === 200) {
                setImgdata(response.data.data);
            } else {
                setError('Unexpected response from the server.');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setError('No creations available.');
        } finally {
            { loaders(false, "heart") }
        }
    };

    useEffect(() => {
        NewGenerate();
    }, []);

    const handleAddProfileClick = () => {
        navigate('/CreateDreamGF');  // Replace '/add-profile' with your desired route
    };

    const openModal = async (assistant_id, item) => {
        console.log("assistant_id is required",item);
        setBotdetail(item);
        if (!assistant_id) {
            console.error("assistant_id is required");
            return;
        }

        let selectedImage = null;
        const imgdataList = [imgdata]; // Add more lists here if necessary, like imgdataanime, imgboydata

        for (let list of imgdataList) {
            selectedImage = list.find(item => item.assistant_id === assistant_id);
            if (selectedImage) break;
        }

        if (selectedImage) {
            setModalImg(selectedImage.Image);  // Set the image for the modal
        }

        const payload = { assistant_id };
        try {
            const authToken = localStorage.getItem('token');
            const response = await axios.get(`https://api2.eromantic.ai/assistant_api/fetch_static_data/`, {
                params: payload,
                headers: {
                    'Authorization': `Bearer ${authToken}`
                }
            });
            if (response.status !== 200) {
                throw new Error('Network response was not ok');
            }
            const data = response.data;
            setModalData(data);
            setLikesCount(data.likes);  // Set the likes count
            setIsModalOpen(true);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setModalData(null);
    };

    const toggleLike = async (assistant_id) => {
        const u_id = localStorage.getItem('u_id');
        const payload = { assistant_id: assistant_id, u_id: u_id };

        try {
            const authToken = localStorage.getItem('token');
            const response = await axios.post(`${BASE_URL}like_static/`, payload, {
                headers: {
                    'Authorization': `Bearer ${authToken}`
                }
            });

            if (response.status === 200) {
                setLiked(!liked);
                console.log(response.data, "asasddasasd")

                setLikesCount(response.data.likes);

            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    console.log(botdetail)

    const itemsToDisplay = botdetail ? (
        (botdetail.Butt_Size === '' || botdetail.Butt_Size === 'NA') &&
        (botdetail.Breast_Size === '' || botdetail.Breast_Size === 'NA')
        ? [
            { label: 'Relationship:', value: botdetail.Relationship, src: Relationshipicon },
            { label: 'Age:', value: botdetail.age, src: age },
            { label: 'Occupation:', value: botdetail.Occupation, src: occupation },
            { label: 'Personality:', value: botdetail.Personality, src: personality },
            { label: 'Hair Style and Color:', value: `${botdetail.hair_Style} ${botdetail.hair_color}`, src: hair },
            { label: 'Eyes:', value: botdetail.Eyes, src: eyes },
            { label: 'Ethnicity:', value: botdetail.Ethnicity, src: Location },
            { label: 'Region:', value: botdetail.region, src: country },
            { label: 'Body Type:', value: botdetail.Body_Type, src: manbody }
        ]
        : [
            { label: 'Relationship:', value: botdetail.Relationship, src: Relationshipicon },
            { label: 'Age:', value: botdetail.age, src: age },
            { label: 'Occupation:', value: botdetail.Occupation, src: occupation },
            { label: 'Personality:', value: botdetail.Personality, src: personality },
            { label: 'Hair Style and Color:', value: `${botdetail.hair_Style} ${botdetail.hair_color}`, src: hair },
            { label: 'Eyes:', value: botdetail.Eyes, src: eyes },
            { label: 'Ethnicity:', value: botdetail.Ethnicity, src: Location },
            { label: 'Region:', value: botdetail.region, src: country },
            { label: 'Body Type:', value: botdetail.Body_Type, src: Bodyicon },
            { label: 'Breast Size:', value: botdetail.Breast_Size, src: brest },
            { label: 'Butt Size:', value: botdetail.Butt_Size, src: Bodyback }
        ]
    ) : null; //

    const generatepage = (id) => {
        navigate(`/dashboard/generate/${id}`);
    };

    return (
        <div className="min-h-screen p-8">
            <h1 className="text-4xl font-bold text-center mb-12">My Creations</h1>

            {/* Loading and Error Handling */}
            {loading && <p className="text-center text-xl">Loading...</p>}
            {error && <p className="text-center text-red-500">{error}</p>}

            {/* Rendering Image Data */}
            <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
                <div className="relative group">
                    <div onClick={handleAddProfileClick} className="h-80 w-full xl:h-[500px] rounded-xl border-2 border-dashed border-gray-300 flex justify-center items-center bg-[#FD818A] transition-all duration-300 ease-in-out">
                        <button className="!text-[50px] xss:text-[100px] font-bold text-white hover:text-[#99000B]">
                            +
                        </button>
                        <div className="absolute bottom-20 xl:bottom-40 left-0 right-0 text-white p-2 rounded-b-lg">
                            <div className="flex justify-center py-1">
                                <span className="text-lg lg:text-lg font-medium">Add New Profile</span>
                            </div>
                        </div>
                    </div>
                </div>
                {imgdata.length > 0 ? (
                    imgdata.map((item, index) => (
                        <div key={index} className="relative group">
                            {/* Image with hover effects */}
                            <div className='overflow-hidden'>
                            <img
                                src={item.Image}
                                alt={`Image of ${item.name}`}
                                className="h-80 w-full xl:h-[500px] rounded-xl object-cover transition-transform duration-700 ease-in-out group-hover:scale-105 group-hover:shadow-lg"
                                onClick={() => openModal(item.assistant_id, item)}  // Open modal on image click
                            />
                            </div>

                            {/* Overlay with text */}
                            <div onClick={() => openModal(item.assistant_id, item)} className="absolute inset-0 flex flex-col justify-between opacity-100 transition-opacity duration-300 group-hover:opacity-100">
                                {/* Button in top-right corner */}
                                <div className="grid justify-end">
                                    <button onClick={() => navigate(`/AidreamGFchat`)} className="text-white font-medium rounded-2xl px-3 py-2 hover:animate-shake">
                                        <img src="/assets/img/Group-icon.png" alt="Icon" className="w-10 h-10" />
                                    </button>
                                    <button onClick={()=>generatepage(item.assistant_id)} className="text-white font-medium rounded-2xl px-3 pb-2 hover:animate-shake">
                                        <img src="/assets/img/Group-icon-msg.png" alt="Icon" className="w-10 h-10" />
                                    </button>
                                </div>

                                {/* Bottom text container */}
                                <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-70 text-white p-2 rounded-b-lg">
                                    <div className="flex justify-between py-1">
                                        <span className="text-lg lg:text-lg font-medium">{item.name}</span>
                                        <span className="text-lg lg:text-lg font-medium">{item.age}</span>
                                    </div>
                                    <p className="text-sm lg:text-base">{(item.Summary || "").slice(0, 60)}...</p>
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    !loading && <p className="text-center text-xl"></p>
                )}
            </div>

            {/* Modal */}
            {isModalOpen && modalData && (
                <div className="fixed inset-0 flex items-center justify-center">
                    <div
                        className="bg-white p-8 rounded w-[80%] mt-10 h-[80%] flex flex-col items-center justify-center"
                        style={{
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                        }}
                    >
                        <div className="flex flex-col sm:flex-row overflow-scroll" style={{ scrollbarWidth: "none" }}>
                            <div>
                                <img
                                    src={modalImg}  // Modal image
                                    alt="Generated Image"
                                    className="w-[350px] h-[400px] lg:w-[400px] xl:w-[500px] lg:h-[500px] xl:h-[600px] 2xl:w-[570px] 2xl:h-[621px] rounded-xl"
                                    style={{ objectFit: 'cover' }}
                                />
                            </div>

                            <div className='grid ml-4'>
                                <div className='flex justify-between'>
                                    <h2 className='text-[#b30016d9] sm:text-xl font-bold md:text-2xl lg:text-4xl whitespace-nowrap'>
                                        {modalData.name}<span onClick={() => toggleLike(modalData.assistant_id)} className='ms-5'>
                                            {liked ? (
                                                <>
                                                    <i className="fa-solid fa-sm fa-heart"></i>
                                                    <span className='text-lg'>{likesCount}  Liked</span>
                                                </>
                                            ) : (
                                                <>
                                                    <i className="fa-regular fa-sm fa-heart"></i>
                                                    <span className='text-lg'>{likesCount} Like</span>
                                                </>
                                            )}
                                        </span>
                                    </h2>
                                    <div>
                                        <button onClick={closeModal} className="text-black border-none px-6 py-2 rounded"> <i class="fa-regular fa-2xl fa-circle-xmark"></i> </button>
                                    </div>
                                </div>
                                <div className="grid grid-cols-2 sm:grid-cols-3 xl:grid-cols-4 gap-2 mb-3">


                                    {itemsToDisplay.map(({ label, value, src }, index) => (
                                        <div key={index} className="flex items-start">
                                            <span className='icon w-10 mt-1 mr-2'>
                                                <img src={src} alt='icon' />
                                            </span>
                                            <div className="flex flex-col">
                                                <span className="text-muted font-semibold text-sm">{label}</span>
                                                <span className="text-md">{value}</span>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                                <div class="flex items-start ">
                                    <span class="icon mt-1 mr-2 icon-summary"></span>
                                    <div class="flex flex-col">
                                        <span class=" font-semibold text-muted text-sm">About:</span>
                                        <span class="text-md">{modalData.Summary}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='flex justify-center w-full mt-7'>
                        <button
                            className="bg-red-800 text-white font-medium rounded-2xl mr-2 p-2 sm:p-4 lg:p-4 w-[35%] lg:w-[25%]"
                            onClick={() => navigate(`/AidreamGFchat`)}
                        >
                            Chat Now
                        </button>
                        <button
                            className="bg-[#db3c3c] text-white font-medium rounded-2xl p-2 sm:p-4 lg:p-4 w-[35%] lg:w-[25%]"
                            onClick={()=>generatepage(modalData.assistant_id)}
                        >
                            Generate Image
                        </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default MyCreation;
