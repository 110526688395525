import React, { useState, useEffect } from 'react';
import { IoSearchOutline } from 'react-icons/io5'
import "react-multi-carousel/lib/styles.css";
import Carousel from 'react-multi-carousel';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {
    aiModal1, aiModal2, aiModal3, aiModal_futanari, aiModal4, aiModal11, aiModal12, aiModal13, aiModal5, aiModal6, aiModal7, aiModal8, bigtits, mediumtits, GiantTits, BJ, smalltits, goldE, info, boy, girls,
    Ass_show, Bondage, From_behind, Futanari, Reverse_cowgirl, bg1, breast_massage, boobjob, cum, dildo, footjob, front_pussy, futanari_V2,
    legs_up_pussy, missionary, missionary_anal, pants_down, pregnant, pregnant_front_pussy, Anime_Male_bondage, Anime_Male_muscular_body, Anime_Male_penis,
    Anime_bondage, Anime_boobjob, Anime_breast_grab, Anime_cum_bath, Anime_gigantic_boobs, Anime_imminent_penetration, Anime_masturbation, Anime_missionary_pov,
    Anime_pants_down, Anime_pussy_from_behind, Anime_reverse_suspended_anal, Anime_sideways_ass, Male_penis, Male_bondage, Male_muscular_body,
} from '../../assets'
import Footer from '../../frontend/components/Footer';

const Replicate = () => {
    const [selectedFeature, setSelectedFeature] = useState('Hair color');
    const [selectedCharacter, setSelectedCharacter] = useState('1 Girl');
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [selectedIndextwo, setSelectedIndextwo] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const [currentFeatureIndex, setCurrentFeatureIndex] = useState(0);
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [selectedIndexAi, setSelectedIndexAi] = useState(0);
    const [isChecked, setIsChecked] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [isHoveredone, setIsHoveredone] = useState(false);
    const [isCheckedPrivate, setIsCheckedPrivate] = useState(false);
    const [isCheckedPrivatensf, setIsCheckedPrivatensf] = useState(false);

    const [isAdvanced, setIsAdvanced] = useState(false);
    const [value, setValue] = useState("");
    const [error, setError] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [text, setText] = useState("");
    const [selectedSuggestions, setSelectedSuggestions] = useState([]); // To store selected suggestions
    const { assistant_id } = useParams(); // Assuming the route has assistant_id as a parameter
    const [girlData, setGirlData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const payload = { assistant_id };
            try {
                const authToken = localStorage.getItem('token');
                const response = await axios.get('https://api2.eromantic.ai/assistant_api/fetch_static_data/', {
                    params: payload,
                    headers: {
                        'Authorization': `Bearer ${authToken}`
                    }
                });

                if (response.status !== 200) {
                    throw new Error('Network response was not ok');
                }

                const data = response.data;
                if (data.Breast_Size != "") {
                    setSelectedCharacter("1 Girl")
                } else {
                    setSelectedCharacter('1 Boy')
                }
                setGirlData(data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        if (assistant_id) {
            fetchData();  // Only fetch if assistant_id is available
        }
    }, [assistant_id]);

    // Suggestions data
    const suggestions = [
        { id: 1, line: "1girl, brown hair, (large breasts), shag hair, wearing bra, in a desert, Bokeh" },
        { id: 2, line: "1girl, brown hair, (large breasts), shag hair, wearing bikini, in a casino, motion blur" },
        { id: 3, line: "1 girl, 30 years old, brown hair, (medium breasts), pixie cut, wearing pink dress, in a strip club, bokeh" },
        { id: 4, line: "1 girl, 22 years old, Blonde hair, (large breasts), curly hair, wearing dress, in a cruise ship, bokeh" },
    ];

    // const handleChange = (e) => {
    //     const inputValue = e.target.value;
    //     if (inputValue === '9') {
    //         setError('You cannot enter 9');
    //         setValue('');
    //     } else if (inputValue <= 8 && inputValue >= 0) {
    //         setError('');
    //         setValue(inputValue);
    //     } else {
    //         setError('Please enter a valid number between 0 and 8');
    //     }
    // };
    const handleSelect = (num) => {
        // When a predefined number is selected, update the value state
        setValue(num);
        setError(''); // Clear any existing error when a valid number is selected
    };

    const handleChange = (e) => {
        const inputValue = e.target.value;

        if (inputValue === '5' || '6' || '7' || '8') {
            setError('You cannot enter above 4');
            setValue('');
        } else if (inputValue <= 4 && inputValue >= 0) {
            setError('');
            setValue(inputValue);
        } else {
            setError('Please enter a valid number between 0 and 8');
        }
    };
    const handleCheckboxChangePrivate = () => {
        setIsCheckedPrivate(!isCheckedPrivate);
    };
    const handleCheckboxChangePrivateNSF = () => {
        setIsCheckedPrivatensf(!isCheckedPrivate);
    };

    {/*&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
    */}
    const [selectedTitleAi, setSelectedTitleAi] = useState('Realistic');

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1280 },
            items: 4,
            partialVisibilityGutter: 40
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            partialVisibilityGutter: 30
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            partialVisibilityGutter: 30
        }
    };

    const data = [
        // { img: aiModal8, title: 'Super Realistic Model', model: 'realisticinstav6.safetensors [ecb478bd5e]' },
        { img: aiModal5, title: 'Realistic', model: 'influncerv2_p1.safetensors [b6a4e767b6]', description: "This model generates hyper-realistic human images, capturing subtle facial features, natural lighting, and detailed textures that mirror real-world photography. Ideal for lifelike portraits and high-detail renderings" },
        { img: aiModal6, title: 'Hentai', model: 'metrixhentaip.safetensors [184d9775e3]', description: "Specialized for creating anime-inspired content with traditional Hentai aesthetics. It maintains bold outlines, vivid colors, and highly stylized character designs suitable for fans of classic and contemporary Hentai art styles." },
        { img: aiModal4, title: 'Enchant', model: 'hyperrealisticv6.safetensors [fe7578cb5e]', description: "This model produces images with a hyper-realistic yet magical quality, blending realism with a touch of fantasy. The resulting artwork captures mystical atmospheres, perfect for enchanting portraits or dreamlike scenes with high attention to detail." },
        { img: aiModal11, title: 'Anime HD', model: 'animeCARTOON_v10.ckpt [c5321a0b10]', description: "A high-definition anime model optimized for crisp, vibrant, and detailed animation-style images. This model is suited for both fan art and original characters, capturing the essence of Japanese animation with clear lines and expressive colors." },
        { img: aiModal12, title: 'Surreal', model: 'realisticinstav6.safetensors [ecb478bd5e]', description: "Focusing on surrealism, this model generates lifelike images with an imaginative twist, merging real-world elements with abstract concepts. Perfect for creative compositions that challenge reality while maintaining a high level of detail." },
        { img: aiModal_futanari, title: 'Futanari', model: 'monstercockv15.Y2ws.safetensors [6d862828a0]', description: "A anime model optimized to generate futanari images that consists hermaphrodite characters, generally with female bodies plus a male phallus. Extensions is not available on this modal." },
        { img: aiModal13, title: 'Anime 3D', model: 'bestanimface.safetensors [d65f84e1d0]', description: "This model generates anime-style 3D renderings, providing depth and a unique stylized realism. Ideal for creating anime characters with a modern 3D aesthetic, bringing additional dimension and detail into traditional anime styles." },
        { img: aiModal3, title: 'Photoreal', model: 'realisticinstav6.safetensors [ecb478bd5e]', description: "Aiming for photorealism, this model generates highly detailed, lifelike images that closely resemble actual photography. With a focus on capturing fine textures, natural lighting, and realistic expressions, it's ideal for users looking for a true-to-life rendering style." },
        // { img: aiModal1, title: 'Surreal Anime Model', model: 'bestanimface.safetensors [d65f84e1d0]' },
        // { img: aiModal2, title: 'Realistic Anime Model', model: 'anniepornbestwithhuman.safetensors [dfc5cbba6c]' },
        // { img: aiModal7, title: 'Dark Anime Model', model: 'dksAnimeMerge_dksAnimeV10.safetensors [a378378a06]' },
    ];

    // Function to set the selected index based on the default model from the API
    const setDefaultModel = async () => {
        try {
            const response = await axios.get('https://api2.eromantic.ai/api/v1/GetDefaultModel/');
            const defaultModel = response.data.sd_model_checkpoint;

            // Find the index of the model in the data array
            const defaultIndex = data.findIndex(item => item.model === defaultModel);

            // If the model is found, set it as the selected index
            if (defaultIndex !== -1) {
                setSelectedIndexAi(defaultIndex);
            }
        } catch (error) {
            console.log("Error fetching default model:", error);
        }
    };

    useEffect(() => {
        // Call the function to fetch the default model when the component is mounted
        setDefaultModel();
    }, []);

    const features = [
        { name: 'Hair color' },
        { name: 'Haircut' },
        { name: 'Wearing' },
        // { name: 'Body type' },
        // { name: 'Nationality' },
        { name: 'Environment' },
        // { name: 'Style' },
        { name: 'Effects' },
    ];

    const options = {
        'Characters': [
            { name: '1 Girl', src: girls, },
            { name: '1 Boy', src: boy, },
        ],
        '1 Girl': {
            'Hair color': [
                { 'name': 'Blonde' },
                { 'name': 'Black' },
                { 'name': 'Brown' },
                { 'name': 'Redhead' },
                { 'name': 'Pink' },
                { 'name': 'White' },
                { 'name': 'Ginger' },
                { 'name': 'Golden' },
                { 'name': 'blue' },
                { 'name': 'green' },

            ],
            'Haircut': [
                { 'name': 'Pixie Cut' },
                { 'name': 'Shag Hair' },
                { 'name': 'Bob Cut' },
                { 'name': 'Curly Hair' },
                { 'name': 'Braids' },
                { 'name': 'Bangs' },
                { 'name': 'Bald' },
                { 'name': 'Dreadlocks hair' },
                { 'name': 'Short curly hair' },
                { 'name': 'Long straight hair' },
                { 'name': 'Afro hair' },

            ],
            'Wearing': [
                { 'name': 'Lingerie' },
                { 'name': 'Fishnets' },
                { 'name': 'Swimwear' },
                { 'name': 'Bodysuits' },
                { 'name': 'Corset' },
                { 'name': 'Bikini' },
                { 'name': 'Chemise' },
                { 'name': 'French Maid' },
                { 'name': 'Thong' },
                { 'name': 'Yoga Pants' },
                { 'name': 'Nightgown' },
                { 'name': 'Transparent Clothing' },
                { 'name': 'Latex Clothing' },
            ],
            'Environment': [
                { 'name': 'Street' },
                { 'name': 'Swimming Pool' },
                { 'name': 'Bathroom' },
                { 'name': 'Apartment' },
                { 'name': 'Beach' },
                { 'name': 'Shopping Mall' },
                { 'name': 'Luxurious Castle' },
                { 'name': 'Cafe' },
                { 'name': 'University' },
                { 'name': 'Strip Club' },
                { 'name': 'Restaurant' },
                { 'name': 'Park' },
                // { 'name': 'Spaceship' },
                { 'name': 'Mars' },
                { 'name': 'Forest' },
                { 'name': 'Mountain' },
                { 'name': 'Lake' },
                { 'name': 'Library' },
                // { 'name': 'Concert Venue' },
                { 'name': 'Sports Stadium' },
                { 'name': 'Cruise Ship' },
                { 'name': 'Desert' },
                { 'name': 'Farm' },
                { 'name': 'Art Gallery' },
                { 'name': 'Amusement Park' },
                { 'name': 'Ski Resort' },
                { 'name': 'Rainforest' },
                // { 'name': 'Space Station' },
                { 'name': 'Aquarium' },
                { 'name': 'Vineyard' },
                // { 'name': 'Movie Theater' },
                { 'name': 'Fitness Center' },
                { 'name': 'Historical Monument' },
                { 'name': 'Airport' },
                // { 'name': 'Submarine' },
                // { 'name': 'Circus Tent' },
                { 'name': 'Casino' },
                { 'name': 'Underground Cave' },
                // { 'name': 'Space Observatory' },
                { 'name': 'Abandoned Mansion' },
                { 'name': 'Ski Lodge' },
                { 'name': 'Botanical Garden' },
                { 'name': 'Wildlife Sanctuary' },
                { 'name': 'Secret Garden' },
                { 'name': 'Floating Island' },
                { 'name': 'Whimsical Wonderland' },
                // { 'name': 'Mystical Labyrinth' },
                // { 'name': 'Neon Utopia' }    
            ],
            'Effects': [
                { 'name': 'Bokeh' },
                // { 'name': 'Hardcore' },
                { 'name': 'Sepia' },
                { 'name': 'Vintage Film' },
                { 'name': 'Lens Flare' },
                { 'name': 'Reflection' },
                { 'name': 'Bloom' },
                { 'name': 'Depth Of Field' },
                { 'name': 'Dream Blur' },
                // { 'name': 'Monochrome' }    
            ],
        },
        '1 Boy': {
            'Hair color': [
                { 'name': 'Blonde' },
                { 'name': 'Black' },
                { 'name': 'Brown' },
                { 'name': 'Redhead' },
                { 'name': 'Pink' },
                { 'name': 'White' },
                { 'name': 'Ginger' },
                { 'name': 'Golden' },
                { 'name': 'blue' },
                { 'name': 'green' },

            ],
            'Haircut': [
                { 'name': 'Buzz Cut' },
                { 'name': 'Crew Cut' },
                { 'name': 'Man Bun Hair' },
                { 'name': 'Curly Hair' },
                { 'name': 'Quiff Hair Cut' },
                { 'name': 'Side Part' },
                { 'name': 'Dread locks Hair' },
                { 'name': 'Loose Wave Hair' },
                { 'name': 'Short Crop' },
                { 'name': 'Spiky' },
                { 'name': 'Mullet' },

            ],
            'Wearing': [
                { 'name': 'T-shirt' },
                { 'name': 'Jeans' },
                { 'name': 'Hoodie' },
                { 'name': 'Jacket' },
                { 'name': 'Shorts' },
                { 'name': 'Suit' },
                { 'name': 'Sweater' },
                { 'name': 'Blazer' },
                { 'name': 'Shirt' },
                { 'name': 'Tank Top' },
                { 'name': 'Polo Shirt' },
                { 'name': 'Cargo Pants' },
                { 'name': 'Tracksuit' },
                { 'name': 'Leather Jacket' },
                { 'name': 'V-Neck' },
            ],
            'Environment': [
                { 'name': 'Street' },
                { 'name': 'Swimming Pool' },
                { 'name': 'Bathroom' },
                { 'name': 'Apartment' },
                { 'name': 'Beach' },
                { 'name': 'Shopping Mall' },
                { 'name': 'Luxurious Castle' },
                { 'name': 'Cafe' },
                { 'name': 'University' },
                { 'name': 'Strip Club' },
                { 'name': 'Restaurant' },
                { 'name': 'Park' },
                { 'name': 'Mars' },
                { 'name': 'Forest' },
                { 'name': 'Mountain' },
                { 'name': 'Lake' },
                { 'name': 'Library' },
                { 'name': 'Sports Stadium' },
                { 'name': 'Cruise Ship' },
                { 'name': 'Desert' },
                { 'name': 'Farm' },
                { 'name': 'Art Gallery' },
                { 'name': 'Amusement Park' },
                { 'name': 'Ski Resort' },
                { 'name': 'Rainforest' },
                { 'name': 'Aquarium' },
                { 'name': 'Vineyard' },
                { 'name': 'Fitness Center' },
                { 'name': 'Historical Monument' },
                { 'name': 'Airport' },
                { 'name': 'Casino' },
                { 'name': 'Underground Cave' },
                { 'name': 'Abandoned Mansion' },
                { 'name': 'Ski Lodge' },
                { 'name': 'Botanical Garden' },
                { 'name': 'Wildlife Sanctuary' },
                { 'name': 'Secret Garden' },
                { 'name': 'Floating Island' },
                { 'name': 'Whimsical Wonderland' },
            ],
            'Effects': [
                { 'name': 'Bokeh' },
                { 'name': 'Sepia' },
                { 'name': 'Vintage Film' },
                { 'name': 'Lens Flare' },
                { 'name': 'Reflection' },
                { 'name': 'Bloom' },
                { 'name': 'Depth Of Field' },
                { 'name': 'Dream Blur' },
            ],
        },
    };
    const [selectedFeatures, setSelectedFeatures] = useState(options);
    const [selected, setSelected] = useState('medium');
    const [aiselected, setaiSelected] = useState('creative');
    const [MagicSelected, setMagicSelected] = useState('none');
    const [svgselected, setsvgSelected] = useState('fourth');
    const [otherInput, setOtherInput] = useState(null); // Add state for input value
    const [FaceoptionalInput, setFaceoptionalInput] = useState(null); // Add state for input value

    const handleOtherInputChange = (e) => {
        setOtherInput(e.target.value); // Update state when input changes
    };

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    const navigate = useNavigate()
    const generate = () => {
        const selectedHairColor = typeof selectedFeatures[selectedCharacter]["Hair color"] === 'string' ? selectedFeatures[selectedCharacter]["Hair color"] : null;
        const selectedHaircut = typeof selectedFeatures[selectedCharacter]["Haircut"] === 'string' ? selectedFeatures[selectedCharacter]["Haircut"] : null;
        const selectedWearing = typeof selectedFeatures[selectedCharacter]["Wearing"] === 'string' ? selectedFeatures[selectedCharacter]["Wearing"] : null;
        const selectedBodyType = typeof selectedFeatures[selectedCharacter]["Body type"] === 'string' ? selectedFeatures[selectedCharacter]["Body type"] : null;
        const selectedNationality = typeof selectedFeatures[selectedCharacter]["Nationality"] === 'string' ? selectedFeatures[selectedCharacter]["Nationality"] : null;
        const selectedEnvironment = typeof selectedFeatures[selectedCharacter]["Environment"] === 'string' ? selectedFeatures[selectedCharacter]["Environment"] : null;
        const selectedStyle = typeof selectedFeatures[selectedCharacter]["Style"] === 'string' ? selectedFeatures[selectedCharacter]["Style"] : null;
        const selectedEffects = typeof selectedFeatures[selectedCharacter]["Effects"] === 'string' ? selectedFeatures[selectedCharacter]["Effects"] : null;
        const nationality = selectedNationality;
        const Upscale = isChecked;

        const promptString = Object.values({
            hair_color: selectedHairColor + " " + selectedHaircut ? selectedHairColor + " " + selectedHaircut + " hair" : null,

            wearing: selectedWearing ? "Wearing " + selectedWearing : null,
            body_type: selectedBodyType ? selectedBodyType + " body" : null,
            nationality: selectedNationality || null,
            environment: selectedEnvironment ? "in a " + selectedEnvironment : null,
            style: selectedStyle ? selectedStyle + " style" : null,
            effects: selectedEffects ? selectedEffects + " effect" : null,
            extensions: selectedIndex,
            // extensions: selectedIndex ? "(" + selectedIndex + ")" : null,
            other: otherInput || null,
            face_prompt: FaceoptionalInput || null
        })

            .filter(value => value !== null)
            .join(', ');
        setSelectedItem(promptString);


        console.log(promptString)

        // Other arguments
        var newDate = new Date();

        var datetime = newDate.getDate() + "_" + newDate.getMonth() + "_" + newDate.getFullYear() + "_" + newDate.getTime();
        const timestamp = new Date().getTime(); // Current timestamp
        const randomString = Math.random().toString(36).substring(2, 10); // Random string of 8 characters
        const requestPayload = {
            generation_prompt: promptString,
            steps: selected,
            imageupscale: Upscale,
            nationality: nationality,
            make_ai_listen: aiselected,
            magic: MagicSelected,
            aspect_ratio: svgselected,
            variations: null,
            make_private: false,
            user_name: localStorage.getItem('login'),
            generation_id: "ER_GenImg_" + datetime,
            generation_slug: "ER_" + selectedIndexAi + "_" + promptString.replaceAll(', ', '_').replaceAll(' ', '_'),
            Aimodel: selectedIndexAi,
            Aititle: selectedTitleAi,
            make_private: isCheckedPrivate,
            make_Nesf: isCheckedPrivatensf,
            extensions: selectedIndex,
            face_prompt: FaceoptionalInput,
            assistant_id: girlData.assistant_id,
            // image_file_name: `ER_image_${timestamp}_${randomString}.png`,
            other: otherInput,
            variations: value == "" ? "1" : value,
        };
        console.log(requestPayload);
        const id = girlData.assistant_id;
        const authToken = localStorage.getItem('token');
        axios.post('https://api2.eromantic.ai/assistant_api/bot/image_generate/', requestPayload, {
            headers: {
                'Authorization': `Bearer ${authToken}`
            }
        })
            // axios.post('https://api2.eromantic.ai/assistant_api/bot/image_generate/', requestPayload)
            .then(response => {
                navigate(`/dashboard/ImageView/ER_GenImg_${datetime}`);
                // navigate(`/dashboard/ImageView/${datetime}/?data=${encodeURIComponent(response.data.images)}`);
            })
            .catch(error => {
                console.log("Error generating image:", error);
            });
    };
    const choosefeature = (featureName, featureType) => {
        setSelectedFeatures((prev) => ({
            ...prev,
            [selectedCharacter]: {
                ...prev[selectedCharacter],
                [featureType]: featureName,
            },
        }));

        const nextIndex = currentFeatureIndex + 1;
        if (nextIndex < features.length) {
            setSelectedFeature(features[nextIndex].name);
            setCurrentFeatureIndex(nextIndex);
        } else {
            console.log('All features have been selected');
        }
    };


    console.log('girlDatagirlDatagirlData', girlData)

    return (
        <>

            <div className=''>
                <div className='flex justify-center gap-5 pt-5 md:pt-5'>
                    <p className='font-semibold text-xl xl:text-2xl pb-5'>Generated Images</p>
                    <img src={info} alt="infoBlack" className='h-6 bg-black rounded-full' />
                </div >

                <div className="flex flex-wrap justify-evenly p-6">
                    <div className='p-6'>
                        <div className='flex justify-between items-center'>
                            <p className='font-semibold text-xl py-2 text-grey-500'>Image</p>
                            {/* <img src={info} alt="infoBlack" className='h-8 bg-gray-600 p-2 rounded-full' /> */}
                        </div>
                        <div className='relative flex justify-center'>
                            <img src={girlData && girlData.Image ? girlData.Image : ""} alt="aiModal3" className='w-80 rounded-lg' />
                            <div className='absolute bottom-[1px] w-full px-4 bg-[#00000061] flex justify-between items-center rounded-lg'>
                                <p className='font-semibold text-xl py-2 text-white'>{girlData && girlData.name ? girlData.name : ''}</p>
                                {/* <p className='font-semibold text-md pb-2 text-gray-800'>Age: {girlData && girlData.name ? girlData.age : ''}</p> */}
                                {/* <img src={info} alt="infoBlack" className='h-8 bg-gray-600 p-2 rounded-full' /> */}
                            </div>
                        </div>
                    </div>
                    {selectedCharacter && (
                        <div className='w-full sm:w-[50%] bg-[#83808052] shadow-xl rounded-lg p-6'>
                            <div className='flex justify-between items-center mb-6'>
                                <p className='font-semibold text-2xl pb-5 text-gray-800'>Select Features</p>
                                <div className="w-full flex justify-center mt-2">
                    {options["Characters"].map((item, index) => (
                      <div
                        key={index}
                        className={`px-4 py-2 items-center flex flex-col justify-center rounded-lg mx-1 ${
                          selectedCharacter === item.name
                            ? "bg-black"
                            : "bg-[#d1d1d1]"
                        }`}
                        onClick={() => {
                          setSelectedCharacter(item.name);
                          setSelectedFeature("Hair Color");
                        }}
                      >
                        {/* {/ Character Name /} */}
                        <p
                          className={`hover:text-red-600 capitalize hover:cursor-pointer text-lg  ${
                            selectedCharacter === item.name
                              ? "text-white"
                              : "text-black"
                          }`}
                        >
                          {item.name == "1 Girl" ? "Girls" : "Boys"}
                        </p>
                      </div>
                    ))}
                  </div>
                            </div>

                            <div className='bg-[#bbaeaf] p-3 rounded-lg shadow-sm flex flex-wrap justify-center mo:justify-normal gap-1 sm:gap-2'>
                                {features.map((item, index) => (
                                    <p
                                        key={index}
                                        className={`font-semibold text-md py-1 px-2 sm:px-3 rounded-lg cursor-pointer transition-transform duration-200 transform ${selectedFeature === item.name ? 'bg-gray-800 text-white' : 'hover:bg-gray-300'}`}
                                        onClick={() => {
                                            setSelectedFeature(item.name);
                                            setCurrentFeatureIndex(index); // Set the current feature index on click
                                        }}
                                    >
                                        {item.name}
                                    </p>
                                ))}
                            </div>

                            {selectedFeature && (
                                <div className='mt-3 bg-[#b9b9b97a] p-3 rounded-lg shadow-sm flex flex-wrap justify-center mo:justify-normal gap-2 overflow-scroll' style={{ scrollbarWidth: "none" }}>
                                    {options[selectedCharacter][selectedFeature].map((item, index) => (
                                        // <div
                                        //     key={index}
                                        //     className={`flex justify-center items-center border-2 bg-white border-gray-400 hover:border-gray-600 hover:scale-105 rounded-full px-4 py-1 cursor-pointer transition-all duration-300 ease-in-out transform ${selectedFeatures[selectedCharacter]?.[selectedFeature] === item.name ? 'bg-gray-600' : ''}`}
                                        //     onClick={() => choosefeature(item.name, selectedFeature)}
                                        // >
                                        <div
                                            key={index}
                                            className={`flex justify-center items-center border-2  border-gray-400 hover:border-gray-600 hover:scale-105 rounded-full px-4 py-1 cursor-pointer transition-all duration-300 ease-in-out transform ${selectedFeatures[selectedCharacter]?.[selectedFeature] === item.name ? 'bg-[#202936] text-white' : 'bg-white'}`}
                                            onClick={() => choosefeature(item.name, selectedFeature)}
                                        >
                                            <p className='font-semibold text-md text-center'>
                                                {item.name}
                                            </p>
                                        </div>
                                    ))}
                                </div>
                            )}

                            {selectedCharacter && Object.keys(selectedFeatures[selectedCharacter] || {}).length > 0 && (
                                <div className="bg-[#b9b9b97a] p-4 rounded-lg shadow-sm mt-6">
                                    <h3 className="font-semibold text-xl text-center text-gray-800">Selected Features</h3>
                                    <div className="flex flex-wrap justify-center gap-2">
                                        {Object.entries(selectedFeatures[selectedCharacter] || {}).map(([feature, value], index) => (
                                            <div
                                                key={index}
                                                className="flex flex-col bg-white rounded-lg shadow-md px-4 py-1 border-l-4 border-gray-400 transition-transform duration-300 ease-in-out transform hover:scale-105"
                                            >
                                                <span className="font-semibold text-md text-gray-700">{feature}</span>
                                                <span className="text-sm text-gray-500">
                                                    {typeof value === 'object' ? value.name : value}
                                                </span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                            <div>
                                <div className='flex relative items-center gap-5 mt-6'>
                                    <p className='text-base md:text-[15px] lg:text-[20px] xl:text-[25px] font-semibold text-gray-800'>
                                        Other <span className='text-lg text-gray-600'>(use prompt words with commas)</span>
                                    </p>
                                    <div
                                        className=""
                                        onMouseEnter={() => setIsHoveredone(true)}
                                        onMouseLeave={() => setIsHoveredone(false)}
                                    >
                                        <img src={info} alt="info" className="h-4 md:h-6 cursor-pointer transition-transform duration-200 hover:scale-110" />
                                        {isHoveredone && (
                                            <span className="absolute text-white bg-[#8a8a8a] text-sm bottom-100 right-0 p-2 shadow-lg rounded max-w-xs w-auto">
                                                Specify art styles, features, colors, mood, or setting to unlock the full creative potential of the model, generating images that match your unique vision and aesthetic.
                                            </span>
                                        )}
                                    </div>
                                </div>
                                <div className='bg-zinc-300 rounded-xl mt-2 px-5 py-2 text-black text-2xl font-light w-full h-38 shadow-lg hover:shadow-xl transition-all'>
                                    <textarea
                                        type="text"
                                        rows={2}
                                        className='focus:outline-none bg-zinc-300 w-full text-black p-2 placeholder-gray-600 rounded-lg'
                                        placeholder='e.g. smiling, sunny weather'
                                        value={otherInput}
                                        onChange={handleOtherInputChange}
                                    />
                                </div>
                                <div className='flex justify-between mt-5'>
                                    <div className='flex items-center gap-5 md:mb-3 lg:mb-0'>
                                        <input
                                            type="checkbox"
                                            className='w-7 h-7 rounded-full'
                                            onClick={handleCheckboxChangePrivateNSF}
                                        />
                                        <p className='text-base md:text-md lg:text-lg xl:text-lg'>Allow NSFW</p>
                                    </div>
                                    <div className='flex items-center gap-5 md:mb-3 lg:mb-0'>
                                        <p className='text-base md:text-md lg:text-lg xl:text-lg'>Make Private</p>
                                        <button
                                            onClick={handleCheckboxChangePrivate}
                                            className={`relative inline-flex items-center h-[2.42rem] w-[4.4rem] rounded-full transition-all duration-300 ${isCheckedPrivate ? 'bg-[#ab1e1eb5]' : 'bg-gray-300'}`}
                                        >
                                            {/* OFF text */}
                                            <span
                                                className={`absolute z-10 left-2 text-black transition-all duration-300 ${isCheckedPrivate ? 'opacity-0' : 'opacity-100'}`}
                                                style={{ top: '50%', transform: 'translateY(-50%)' }}
                                            >
                                            </span>

                                            {/* ON text */}
                                            <span
                                                className={`absolute z-10 right-2 text-black font-bold transition-all duration-300 ${isCheckedPrivate ? 'opacity-100' : 'opacity-0'}`}
                                                style={{ top: '50%', transform: 'translateY(-50%)' }}
                                            >
                                                ON
                                            </span>

                                            <span
                                                className={`absolute left-1 top-1 transition-all duration-300 transform ${isCheckedPrivate ? 'translate-x-8 bg-white' : 'translate-x-0 bg-white'}`}
                                                style={{ height: '30px', width: '30px', borderRadius: '50%' }}
                                            ></span>
                                        </button>

                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <div className='py-10 px-6'>  {/* Reduced the padding to px-6 */}
                <div className='flex flex-col gap-5 lg:gap-12 text-black font-josefin-sans'>
                    <div className='flex relative items-center gap-5 md:mb-3 lg:mb-0'>
                        <p className='text-base md:text-[15px] lg:text-[20px] xl:text-[25px] font-semibold text-gray-800'>
                            Do you want to Upscale Image
                            <p className='flex md:text-[8px] lg:text-[12px] xl:text-[16px]'>(Addon 1- Token  <img className='w-6' src={goldE} alt="goldE" />)</p>
                        </p>

                        {/* Stylish toggle button */}
                        <button
                            onClick={handleCheckboxChange}
                            className={`relative inline-flex items-center h-[2.42rem] w-[4.4rem] rounded-full transition-all duration-300 ${isChecked ? 'bg-[#ab1e1eb5]' : 'bg-gray-300'}`}
                        >
                            <span
                                className={`absolute left-1 top-1 transition-all duration-300 transform ${isChecked ? 'translate-x-8 bg-white' : 'translate-x-0 bg-white'}`}
                                style={{ height: '30px', width: '30px', borderRadius: '50%' }}
                            ></span>
                        </button>

                        {/* Info Tooltip */}
                        <div
                            className=""
                            onMouseEnter={() => setIsHovered(true)}
                            onMouseLeave={() => setIsHovered(false)}
                        >
                            <img src={info} alt="info" className="h-4 md:h-6 cursor-pointer transition-transform duration-200 hover:scale-110" />
                            {isHovered && (
                                <span className="absolute bg-[#8a8a8a] text-white text-sm top-7 right-0 p-2 shadow-lg rounded max-w-xs w-auto z-10">
                                    Elevate your images with AI-powered upscaling. Increase resolution for sharper, more detailed visuals, perfect for larger displays.
                                </span>
                            )}
                        </div>
                    </div>

                    <div className="flex flex-col items-start">
                        <label htmlFor="variations" className="text-base sm:text-md md:text-lg lg:text-xl xl:text-2xl font-semibold text-gray-800">
                            Number of variations you want (Max 4)
                        </label>
                        <p className="mt-0 text-sm sm:text-base text-gray-600">Enter a number to specify the number of image variations you want.</p>

                        <div className="flex space-x-2 my-4">
                            <button
                                className="bg-[#d4d4d8] border-2 border-gray-300 rounded-lg p-2 w-20 sm:w-24 md:w-28 text-center text-sm focus:ring-2 focus:ring-blue-500"
                                onClick={() => handleSelect(1)}
                            >
                                1
                            </button>
                            <button
                                className="bg-[#d4d4d8] border-2 border-gray-300 rounded-lg p-2 w-20 sm:w-24 md:w-28 text-center text-sm focus:ring-2 focus:ring-blue-500"
                                onClick={() => handleSelect(2)}
                            >
                                2
                            </button>
                            <button
                                className="bg-[#d4d4d8] border-2 border-gray-300 rounded-lg p-2 w-20 sm:w-24 md:w-28 text-center text-sm focus:ring-2 focus:ring-blue-500"
                                onClick={() => handleSelect(3)}
                            >
                                3
                            </button>
                            <button
                                className="bg-[#d4d4d8] border-2 border-gray-300 rounded-lg p-2 w-20 sm:w-24 md:w-28 text-center text-sm focus:ring-2 focus:ring-blue-500"
                                onClick={() => handleSelect(4)}
                            >
                                4
                            </button>
                        </div>
                        {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
                    </div>

                </div>
                <button
                    onClick={generate}
                    className="w-full h-[80px] mt-5 rounded-xl text-xl lg:text-3xl bg-gradient-to-r from-[#99111f] via-[#d43e44] to-[#ff5c6c] py-4 text-center flex items-center gap-5 justify-center relative overflow-hidden group shadow-xl hover:shadow-2xl transition-all duration-500 ease-out"
                >
                    <span className="relative z-10 text-white font-bold">Generate for 1.00</span>
                    <span className="absolute inset-0 bg-gradient-to-r from-[#e63d3d] to-[#ff5c6c] opacity-0 group-hover:opacity-80 animate-glow-background"></span>
                    <img
                        className="w-12 transition-transform duration-300 group-hover:scale-150 group-hover:rotate-0 group-hover:glow-icon"
                        src={goldE}
                        alt="goldE"
                    />
                    <span className="absolute inset-0 rounded-xl border-4 border-transparent group-hover:border-[#99111f] opacity-0 group-hover:opacity-100 animate-glow-border"></span>
                </button>
            </div>

        </>
    );
}

export default Replicate;
