import React, { useState, useEffect } from "react";
import Recreateimage from "../../components/Recreateimage";
import SelectAIModel from "../../components/SelectAIModel";
import SearchExtension from "../../components/SearchExtension";
import UseShortWordsInputBox from "../../components/UseShortWordsInputBox";
import FooterMakeAI from "../../components/FooterMakeAI";
import { IoSearchOutline } from "react-icons/io5";
import "react-multi-carousel/lib/styles.css";
import Carousel from "react-multi-carousel";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  aiModal1,
  aiModal2,
  aiModal3,
  aiModal_futanari,
  aiModal4,
  aiModal11,
  aiModal12,
  aiModal13,
  aiModal5,
  aiModal6,
  aiModal7,
  aiModal8,
  bigtits,
  mediumtits,
  GiantTits,
  BJ,
  smalltits,
  goldE,
  info,
  boy,
  girls,
  Ass_show,
  Bondage,
  From_behind,
  Futanari,
  Reverse_cowgirl,
  bg1,
  breast_massage,
  boobjob,
  cum,
  dildo,
  footjob,
  front_pussy,
  futanari_V2,
  legs_up_pussy,
  missionary,
  missionary_anal,
  pants_down,
  pregnant,
  pregnant_front_pussy,
  Anime_Male_bondage,
  Anime_Male_muscular_body,
  Anime_Male_penis,
  Anime_bondage,
  Anime_boobjob,
  Anime_breast_grab,
  Anime_cum_bath,
  Anime_gigantic_boobs,
  Anime_imminent_penetration,
  Anime_masturbation,
  Anime_missionary_pov,
  Anime_pants_down,
  Anime_pussy_from_behind,
  Anime_reverse_suspended_anal,
  Anime_sideways_ass,
  Male_penis,
  Male_bondage,
  Male_muscular_body,
} from "../../../assets";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { EffectCoverflow, Pagination, Navigation } from "swiper/modules";
import "../../../../src/swipper.css";
import Footer from "../../../frontend/components/Footer";

const Simplified = () => {
  const [selectedFeature, setSelectedFeature] = useState("Hair Color");
  const [selectedCharacter, setSelectedCharacter] = useState("1 Girl");
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [selectedIndextwo, setSelectedIndextwo] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [currentFeatureIndex, setCurrentFeatureIndex] = useState(0);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [selectedIndexAi, setSelectedIndexAi] = useState(0);
  const [isChecked, setIsChecked] = useState(false);
  const [isCheckedPrivate, setIsCheckedPrivate] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isHoveredone, setIsHoveredone] = useState(false);

  const [hover, setHover] = useState({
    feature: false,
    extension: false,
    character: false,
    model: false,
  });

  const [isAdvanced, setIsAdvanced] = useState(false);
  const [value, setValue] = useState("");
  const [error, setError] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [text, setText] = useState("");
  const [selectedSuggestions, setSelectedSuggestions] = useState([]); // To store selected suggestions

  // Suggestions data
  const suggestions = [
    {
      id: 1,
      line: "1girl, brown hair, (large breasts), shag hair, wearing bra, in a desert, Bokeh",
    },
    {
      id: 2,
      line: "1girl, brown hair, (large breasts), shag hair, wearing bikini, in a casino, motion blur",
    },
    {
      id: 3,
      line: "1 girl, 30 years old, brown hair, (medium breasts), pixie cut, wearing pink dress, in a strip club, bokeh",
    },
    {
      id: 4,
      line: "1 girl, 22 years old, Blonde hair, (large breasts), curly hair, wearing dress, in a cruise ship, bokeh",
    },
  ];

  const handleChangeadvance = (e) => {
    setText(e.target.value);
  };
  const handleSuggestionClick = (line) => {
    // If the suggestion is already selected, remove it
    if (selectedSuggestions.includes(line)) {
      setSelectedSuggestions(
        selectedSuggestions.filter((item) => item !== line)
      );
      setText(text.replace(line, "")); // Remove from textarea
    } else {
      // Add the new suggestion to the list and textarea
      setSelectedSuggestions([...selectedSuggestions, line]);
      setText(text + " " + line); // Append to textarea
    }
  };

  const handleSelect = (num) => {
    setValue(num);
    setError(""); // Clear any existing error when a valid number is selected
  };

  const handleChange = (e) => {
    const inputValue = e.target.value;
    if (inputValue === "9") {
      setError("You cannot enter 9");
      setValue("");
    } else if (inputValue <= 8 && inputValue >= 0) {
      setError("");
      setValue(inputValue);
    } else {
      setError("Please enter a valid number between 0 and 8");
    }
  };

  {
    /*&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
     */
  }
  const [selectedTitleAi, setSelectedTitleAi] = useState("Realistic");

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1280 },
      items: 4,
      partialVisibilityGutter: 40,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      partialVisibilityGutter: 30,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      partialVisibilityGutter: 30,
    },
  };

  const data = [
    // { img: aiModal8, title: 'Super Realistic Model', model: 'realisticinstav6.safetensors [ecb478bd5e]' },
    {
      img: aiModal5,
      title: "Realistic",
      model: "influncerv2_p1.safetensors [b6a4e767b6]",
      description:
        "This model generates hyper-realistic human images, capturing subtle facial features, natural lighting, and detailed textures that mirror real-world photography. Ideal for lifelike portraits and high-detail renderings",
    },
    {
      img: aiModal6,
      title: "Hentai",
      model: "metrixhentaip.safetensors [184d9775e3]",
      description:
        "Specialized for creating anime-inspired content with traditional Hentai aesthetics. It maintains bold outlines, vivid colors, and highly stylized character designs suitable for fans of classic and contemporary Hentai art styles.",
    },
    {
      img: aiModal4,
      title: "Enchant",
      model: "hyperrealisticv6.safetensors [fe7578cb5e]",
      description:
        "This model produces images with a hyper-realistic yet magical quality, blending realism with a touch of fantasy. The resulting artwork captures mystical atmospheres, perfect for enchanting portraits or dreamlike scenes with high attention to detail.",
    },
    {
      img: aiModal11,
      title: "Anime HD",
      model: "animeCARTOON_v10.ckpt [c5321a0b10]",
      description:
        "A high-definition anime model optimized for crisp, vibrant, and detailed animation-style images. This model is suited for both fan art and original characters, capturing the essence of Japanese animation with clear lines and expressive colors.",
    },
    {
      img: aiModal12,
      title: "Surreal",
      model: "realisticinstav6.safetensors [ecb478bd5e]",
      description:
        "Focusing on surrealism, this model generates lifelike images with an imaginative twist, merging real-world elements with abstract concepts. Perfect for creative compositions that challenge reality while maintaining a high level of detail.",
    },
    {
      img: aiModal_futanari,
      title: "Futanari",
      model: "metrixhentaip.safetensors [184d9775e3]",
      description:
        "A anime model optimized to generate futanari images that consists hermaphrodite characters, generally with female bodies plus a male phallus. Extensions is not available on this modal.",
    },
    {
      img: aiModal13,
      title: "Anime 3D",
      model: "bestanimface.safetensors [d65f84e1d0]",
      description:
        "This model generates anime-style 3D renderings, providing depth and a unique stylized realism. Ideal for creating anime characters with a modern 3D aesthetic, bringing additional dimension and detail into traditional anime styles.",
    },
    {
      img: aiModal3,
      title: "Photoreal",
      model: "realisticinstav6.safetensors [ecb478bd5e]",
      description:
        "Aiming for photorealism, this model generates highly detailed, lifelike images that closely resemble actual photography. With a focus on capturing fine textures, natural lighting, and realistic expressions, it's ideal for users looking for a true-to-life rendering style.",
    },
    // { img: aiModal1, title: 'Surreal Anime Model', model: 'bestanimface.safetensors [d65f84e1d0]' },
    // { img: aiModal2, title: 'Realistic Anime Model', model: 'anniepornbestwithhuman.safetensors [dfc5cbba6c]' },
    // { img: aiModal7, title: 'Dark Anime Model', model: 'dksAnimeMerge_dksAnimeV10.safetensors [a378378a06]' },
  ];

  // Function to set the selected index based on the default model from the API
  const setDefaultModel = async () => {
    try {
      const response = await axios.get(
        "https://api2.eromantic.ai/api/v1/GetDefaultModel/"
      );
      const defaultModel = response.data.sd_model_checkpoint;

      // Find the index of the model in the data array
      const defaultIndex = data.findIndex(
        (item) => item.model === defaultModel
      );

      // If the model is found, set it as the selected index
      if (defaultIndex !== -1) {
        setSelectedIndexAi(defaultIndex);
      }
    } catch (error) {
      console.log("Error fetching default model:", error);
    }
  };

  useEffect(() => {
    // Call the function to fetch the default model when the component is mounted
    setDefaultModel();
  }, []);

  const handleModelSelection = (index) => {
    setSelectedIndexAi(index);

    const selectedtitle = data[index].title;
    setSelectedTitleAi(selectedtitle);
    const selectedModel = data[index].model;

    let requestData = {
      sd_model_checkpoint: selectedModel,
    };

    axios
      .post("https://api2.eromantic.ai/api/v1/sd_model_setup/", requestData)
      .then((response) => {
        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  {
    /*&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
     */
  }

  // Sample data for images and their titles
  const images = [
    { src: smalltits, title: "Small", trigerWord: "Small Tits", Lora: "" },
    {
      src: mediumtits,
      title: "Medium",
      trigerWord: "Medium Saggy Tits",
      Lora: "",
    },
    { src: bigtits, title: "Big", trigerWord: "Big Tits", Lora: "" },
    { src: GiantTits, title: "Huge", trigerWord: "Huge Tits", Lora: "" },
    {
      src: breast_massage,
      title: "Breast Massage",
      trigerWord: "Breast massage",
      Lora: "",
    },
    { src: boobjob, title: "Boobjob", trigerWord: "Boobjob", Lora: "" },
    {
      src: Anime_breast_grab,
      title: "Anime Breast Grab",
      trigerWord: "Anime breast grab",
      Lora: "",
    },
    {
      src: Anime_gigantic_boobs,
      title: "Anime Gigantic Boobs",
      trigerWord: "Anime gigantic boobs",
      Lora: "",
    },
    {
      src: Anime_boobjob,
      title: "Anime Boobjob",
      trigerWord: "Anime boobjob",
      Lora: "",
      ad_prompt:
        "((blowjob face:0.8)), ((hyper realistic face:0.5)), blowjobTopofheadPovquiron, penis",
    },
  ];
  const imagestwo = [
    { src: Ass_show, title: "Ass Show", trigerWord: "Ass show", Lora: "" },
    {
      src: pants_down,
      title: "Pants Down",
      trigerWord: "Pants down",
      Lora: "",
    },
    {
      src: Anime_pants_down,
      title: "Anime Pants Down",
      trigerWord: "Anime pants down",
      Lora: "",
    },
    {
      src: Anime_sideways_ass,
      title: "Anime Sideways Ass",
      trigerWord: "Anime sideways ass",
      Lora: "",
    },
    // { src: Futanari, title: 'Futanari', trigerWord: 'Futanari', Lora: '' },
  ];
  const imagesthree = [
    { src: cum, title: "Cum", trigerWord: "Cum", Lora: "" },
    {
      src: Anime_cum_bath,
      title: "Anime Cum Bath",
      trigerWord: "Anime cum bath",
      Lora: "",
    },
  ];
  const imagesfour = [
    {
      src: front_pussy,
      title: "Front Pussy",
      trigerWord: "Front pussy",
      Lora: "",
    },
    {
      src: legs_up_pussy,
      title: "Legs Up Pussy",
      trigerWord: "Legs up pussy",
      Lora: "",
    },
    {
      src: Anime_masturbation,
      title: "Anime Masturbation",
      trigerWord: "Anime masturbation",
      Lora: "",
    },
    {
      src: Anime_pussy_from_behind,
      title: "Anime Pussy From Behind",
      trigerWord: "Anime pussy from behind",
      Lora: "",
    },
  ];
  const imagesfive = [
    { src: footjob, title: "Footjob", trigerWord: "Footjob", Lora: "" },
  ];
  const imagessix = [
    { src: pregnant, title: "Pregnant", trigerWord: "Pregnant", Lora: "" },
    {
      src: pregnant_front_pussy,
      title: "Pregnant Front Pussy",
      trigerWord: "Pregnant front pussy",
      Lora: "",
    },
  ];
  const imagesseven = [
    { src: Bondage, title: "Bondage", trigerWord: "Bondage", Lora: "" },
    {
      src: Anime_bondage,
      title: "Anime Bondage",
      trigerWord: "Anime bondage",
      Lora: "",
    },
  ];
  const imageseight = [
    { src: dildo, title: "Dildo", trigerWord: "Dildo", Lora: "" },
    {
      src: missionary,
      title: "Missionary",
      trigerWord: "Missionary",
      Lora: "",
    },
    {
      src: missionary_anal,
      title: "Missionary Anal",
      trigerWord: "Missionary anal",
      Lora: "",
    },
    {
      src: Reverse_cowgirl,
      title: "Reverse Cowgirl",
      trigerWord: "Reverse cowgirl",
      Lora: "",
    },
    {
      src: From_behind,
      title: "From Behind",
      trigerWord: "From behind",
      Lora: "",
    },
    {
      src: Anime_imminent_penetration,
      title: "Anime Imminent Penetration",
      trigerWord: "Anime imminent penetration",
      Lora: "",
    },
    {
      src: Anime_missionary_pov,
      title: "Anime Missionary Pov",
      trigerWord: "Anime missionary pov",
      Lora: "",
    },
    {
      src: Anime_reverse_suspended_anal,
      title: "Anime Reverse Suspended Anal",
      trigerWord: "Anime reverse suspended anal",
      Lora: "",
    },
  ];
  const imagesnine = [
    { src: futanari_V2, title: "Futanari", trigerWord: "Futanari", Lora: "" },
  ];
  const imagesten = [
    {
      src: BJ,
      title: "BJ",
      trigerWord: "Blowjob",
      Lora: "",
      ad_prompt:
        "((blowjob face:0.8)), ((hyper realistic face:0.5)), blowjobTopofheadPovquiron, penis",
    },
  ];

  const imagesboyone = [
    {
      src: Male_muscular_body,
      title: "Muscular Body",
      trigerWord: "Male muscular body",
    },
    {
      src: Anime_Male_muscular_body,
      title: "Anime Muscular Body",
      trigerWord: "Anime Male muscular body",
    },
  ];
  const imagesboytwo = [
    { src: Male_penis, title: "Penis", trigerWord: "Male penis" },
    {
      src: Anime_Male_penis,
      title: "Anime Penis",
      trigerWord: "Anime Male penis",
    },
  ];
  const imagesboythree = [
    { src: Male_bondage, title: "Bondage", trigerWord: "Male bondage" },
    {
      src: Anime_Male_bondage,
      title: "Bondage",
      trigerWord: "Anime Male bondage",
    },
  ];

  const filteredBoyone = imagesboyone.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const filteredBoyTwo = imagesboytwo.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const filteredBoyThree = imagesboythree.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const filteredImages = images.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const filteredImagesTwo = imagestwo.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const filteredImagesThree = imagesthree.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const filteredImagesFour = imagesfour.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const filteredImagesFive = imagesfive.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const filteredImagesSix = imagessix.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const filteredImagesSeven = imagesseven.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const filteredImagesEight = imageseight.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const filteredImagesNine = imagesnine.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const filteredImagesTen = imagesten.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const features = [
    { name: "Hair Color" },
    { name: "Hair Cut" },
    { name: "Wearing" },
    { name: "Body Type" },
    { name: "Nationality" },
    { name: "Environment" },
    // { name: 'Style' },
    { name: "Effects" },
  ];

  const options = {
    Characters: [
      { name: "1 Girl", src: girls },
      { name: "1 Boy", src: boy },
    ],
    "1 Girl": {
      "Hair Color": [
        { name: "Blonde" },
        { name: "Black" },
        { name: "Brown" },
        { name: "Redhead" },
        { name: "Pink" },
        { name: "White" },
        { name: "Ginger" },
        { name: "Golden" },
        { name: "blue" },
        { name: "green" },
      ],
      "Hair Cut": [
        { name: "Pixie Cut" },
        { name: "Straight Hair" },
        { name: "Bob Cut" },
        { name: "Curly Hair" },
        { name: "Braids" },
        { name: "Bangs" },
        // { 'name': 'Bald' },
        { name: "Dreadlocks Hair" },
        { name: "Short Curly Hair" },
        { name: "Long Straight Hair" },
        { name: "Afro Hair" },
      ],
      Wearing: [
        { name: "Lingerie" },
        { name: "Fishnets" },
        { name: "Swimwear" },
        { name: "Bodysuits" },
        { name: "Corset" },
        { name: "Bikini" },
        { name: "Chemise" },
        { name: "French Maid" },
        { name: "Thong" },
        { name: "Yoga Pants" },
        { name: "Nightgown" },
        { name: "Transparent Clothing" },
        { name: "Latex Clothing" },
      ],
      "Body Type": [
        { name: "Slim" },
        { name: "Athletic" },
        { name: "Curvy" },
        // { 'name': 'Petite' },
        { name: "Hourglass" },
        // { 'name': 'Pear Shaped' },
        // { 'name': 'Tall' },
        // { 'name': 'Plus Size' },
      ],
      Nationality: [
        { name: "American" },
        { name: "Russian" },
        { name: "Polish" },
        { name: "German" },
        { name: "Italian" },
        { name: "French" },
        { name: "Indian" },
        { name: "Asian" },
        { name: "Brazilian" },
        // { 'name': 'Korean' },
        { name: "Japanese" },
        { name: "Mexican" },
        { name: "African" },
      ],
      Environment: [
        { name: "Street" },
        { name: "Swimming Pool" },
        { name: "Bathroom" },
        { name: "Apartment" },
        { name: "Beach" },
        { name: "Shopping Mall" },
        { name: "Luxurious Castle" },
        { name: "Cafe" },
        { name: "University" },
        { name: "Strip Club" },
        { name: "Restaurant" },
        { name: "Park" },
        // { 'name': 'Spaceship' },
        { name: "Mars" },
        { name: "Forest" },
        { name: "Mountain" },
        { name: "Lake" },
        { name: "Library" },
        // { 'name': 'Concert Venue' },
        { name: "Sports Stadium" },
        { name: "Cruise Ship" },
        { name: "Desert" },
        { name: "Farm" },
        { name: "Art Gallery" },
        { name: "Amusement Park" },
        { name: "Ski Resort" },
        { name: "Rainforest" },
        // { 'name': 'Space Station' },
        { name: "Aquarium" },
        { name: "Vineyard" },
        // { 'name': 'Movie Theater' },
        { name: "Fitness Center" },
        { name: "Historical Monument" },
        { name: "Airport" },
        // { 'name': 'Submarine' },
        // { 'name': 'Circus Tent' },
        { name: "Casino" },
        { name: "Underground Cave" },
        // { 'name': 'Space Observatory' },
        { name: "Abandoned Mansion" },
        { name: "Ski Lodge" },
        { name: "Botanical Garden" },
        { name: "Wildlife Sanctuary" },
        { name: "Secret Garden" },
        { name: "Floating Island" },
        { name: "Whimsical Wonderland" },
        // { 'name': 'Mystical Labyrinth' },
        // { 'name': 'Neon Utopia' }
      ],
      // 'Style': [
      //   { 'name': 'Realistic' },
      //   { 'name': 'Fantasy' },
      //   { 'name': 'Anime' },
      //   { 'name': 'Digital Art' },
      //   { 'name': 'Photorealism' },
      //   { 'name': 'Pop Art' },
      //   { 'name': 'Vintage' },
      //   { 'name': 'Surreal' },
      //   { 'name': 'Minimalist' },
      //   { 'name': 'Expressionist' },
      //   { 'name': 'Cubist' },
      //   { 'name': 'Gothic' },
      //   { 'name': 'Renaissance' },
      // ],
      Effects: [
        { name: "Bokeh" },
        // { 'name': 'Hardcore' },
        { name: "Sepia" },
        { name: "Vintage Film" },
        { name: "Lens Flare" },
        { name: "Reflection" },
        { name: "Bloom" },
        { name: "Depth Of Field" },
        { name: "Dream Blur" },
        // { 'name': 'Monochrome' }
      ],
    },
    "1 Boy": {
      "Hair Color": [
        { name: "Blonde" },
        { name: "Black" },
        { name: "Brown" },
        { name: "Redhead" },
        { name: "Pink" },
        { name: "White" },
        { name: "Ginger" },
        { name: "Golden" },
        { name: "blue" },
        { name: "green" },
      ],
      "Hair Cut": [
        { name: "Buzz Cut" },
        { name: "Crew Cut" },
        { name: "Man Bun Hair" },
        { name: "Curly Hair" },
        { name: "Quiff Hair Cut" },
        { name: "Side Part" },
        { name: "Dread locks Hair" },
        { name: "Loose Wave Hair" },
        { name: "Afro Hair" },
        { name: "Spiky" },
        { name: "Mullet" },
      ],
      Wearing: [
        { name: "T-Shirt" },
        { name: "Jeans" },
        { name: "Hoodie" },
        { name: "Jacket" },
        { name: "Shorts" },
        { name: "Suit" },
        { name: "Sweater" },
        { name: "Blazer" },
        { name: "Shirt" },
        { name: "Tank Top" },
        { name: "Polo Shirt" },
        { name: "Cargo Pants" },
        { name: "Tracksuit" },
        { name: "Leather Jacket" },
        { name: "V-Neck" },
      ],
      "Body Type": [
        { name: "Slim" },
        { name: "Athletic" },
        { name: "Muscular" },
        { name: "Average" },
        { name: "Broad Shouldered" },
        { name: "Body Builder" },
        // { 'name': 'Tall' },
        // { 'name': 'Short' },
        // { 'name': 'Stocky' },
      ],
      Nationality: [
        { name: "American" },
        { name: "Russian" },
        { name: "Polish" },
        { name: "German" },
        { name: "Italian" },
        { name: "French" },
        { name: "Indian" },
        { name: "Asian" },
        { name: "Brazilian" },
        // { 'name': 'Korean' },
        { name: "Japanese" },
        { name: "Mexican" },
        { name: "African" },
      ],
      Environment: [
        { name: "Street" },
        { name: "Swimming Pool" },
        { name: "Bathroom" },
        { name: "Apartment" },
        { name: "Beach" },
        { name: "Shopping Mall" },
        { name: "Luxurious Castle" },
        { name: "Cafe" },
        { name: "University" },
        { name: "Strip Club" },
        { name: "Restaurant" },
        { name: "Park" },
        { name: "Mars" },
        { name: "Forest" },
        { name: "Mountain" },
        { name: "Lake" },
        { name: "Library" },
        { name: "Sports Stadium" },
        { name: "Cruise Ship" },
        { name: "Desert" },
        { name: "Farm" },
        { name: "Art Gallery" },
        { name: "Amusement Park" },
        { name: "Ski Resort" },
        { name: "Rainforest" },
        { name: "Aquarium" },
        { name: "Vineyard" },
        { name: "Fitness Center" },
        { name: "Historical Monument" },
        { name: "Airport" },
        { name: "Casino" },
        { name: "Underground Cave" },
        { name: "Abandoned Mansion" },
        { name: "Ski Lodge" },
        { name: "Botanical Garden" },
        { name: "Wildlife Sanctuary" },
        { name: "Secret Garden" },
        { name: "Floating Island" },
        { name: "Whimsical Wonderland" },
      ],
      // 'Style': [
      //   { 'name': 'Realistic' },
      //   { 'name': 'Cartoon' },
      //   { 'name': 'Abstract' },
      //   { 'name': 'Minimalist' },
      //   { 'name': 'Anime' },
      //   { 'name': 'Digital Art' },
      //   { 'name': 'Sci-Fi' },
      //   { 'name': 'Steampunk' },
      //   { 'name': 'Noir' },
      //   { 'name': 'Futuristic' },
      //   { 'name': 'Graffiti' },
      //   { 'name': '3D Rendering' },
      // ],
      Effects: [
        { name: "Bokeh" },
        { name: "Sepia" },
        { name: "Vintage Film" },
        { name: "Lens Flare" },
        { name: "Reflection" },
        { name: "Bloom" },
        { name: "Depth Of Field" },
        { name: "Dream Blur" },
      ],
    },
  };
  const [selectedFeatures, setSelectedFeatures] = useState(options);
  {
    /*&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
     */
  }
  const [selected, setSelected] = useState("medium");
  const [aiselected, setaiSelected] = useState("creative");
  const [MagicSelected, setMagicSelected] = useState("none");
  const [svgselected, setsvgSelected] = useState("fourth");
  const [otherInput, setOtherInput] = useState(null); // Add state for input value
  const [FaceoptionalInput, setFaceoptionalInput] = useState(null); // Add state for input value

  const MagicClick = (value) => {
    setMagicSelected(value);
  };
  const handleClick = (value) => {
    setSelected(value);
  };
  const handleaiClick = (value) => {
    setaiSelected(value);
  };

  const handlesvgClick = (value) => {
    setsvgSelected(value);
  };

  const handleOtherInputChange = (e) => {
    setOtherInput(e.target.value); // Update state when input changes
  };
  // const handleCheckboxChange = (event) => {
  //   setIsChecked(event.target.checked);
  // };
  const handleFaceoptionalInputChange = (e) => {
    setFaceoptionalInput(e.target.value); // Update state when input changes
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  const handleCheckboxChangePrivate = () => {
    setIsCheckedPrivate(!isCheckedPrivate);
  };

  const navigate = useNavigate();
  //  const generate = () => {
  //   navigate('/dashboard/ImageView')
  //  }

  {
    /*&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
     */
  }
  const generate = () => {
    const selectedHairColor =
      typeof selectedFeatures[selectedCharacter]["Hair Color"] === "string"
        ? selectedFeatures[selectedCharacter]["Hair Color"]
        : null;
    const selectedHaircut =
      typeof selectedFeatures[selectedCharacter]["Hair Cut"] === "string"
        ? selectedFeatures[selectedCharacter]["Hair Cut"]
        : null;
    const selectedWearing =
      typeof selectedFeatures[selectedCharacter]["Wearing"] === "string"
        ? selectedFeatures[selectedCharacter]["Wearing"]
        : null;
    const selectedBodyType =
      typeof selectedFeatures[selectedCharacter]["Body Type"] === "string"
        ? selectedFeatures[selectedCharacter]["Body Type"]
        : null;
    const selectedNationality =
      typeof selectedFeatures[selectedCharacter]["Nationality"] === "string"
        ? selectedFeatures[selectedCharacter]["Nationality"]
        : null;
    const selectedEnvironment =
      typeof selectedFeatures[selectedCharacter]["Environment"] === "string"
        ? selectedFeatures[selectedCharacter]["Environment"]
        : null;
    const selectedStyle =
      typeof selectedFeatures[selectedCharacter]["Style"] === "string"
        ? selectedFeatures[selectedCharacter]["Style"]
        : null;
    const selectedEffects =
      typeof selectedFeatures[selectedCharacter]["Effects"] === "string"
        ? selectedFeatures[selectedCharacter]["Effects"]
        : null;
    const nationality = selectedNationality;
    const Upscale = isChecked;
    const promptString = isAdvanced
      ? text
      : Object.values({
          character: selectedCharacter || null,
          hair_color: selectedHairColor ? selectedHairColor + " hair" : null,
          haircut: selectedHaircut ? selectedHaircut + " hair" : null,
          wearing: selectedWearing ? "Wearing " + selectedWearing : null,
          body_type: selectedBodyType ? selectedBodyType + " body" : null,
          nationality: selectedNationality || null,
          environment: selectedEnvironment
            ? "in a " + selectedEnvironment
            : null,
          style: selectedStyle ? selectedStyle + " style" : null,
          effects: selectedEffects ? selectedEffects + " effect" : null,
          extensions: selectedIndex,
          // extensions: selectedIndex ? "(" + selectedIndex + ")" : null,
          other: otherInput || null,
          face_prompt: FaceoptionalInput || null,
        })
          .filter((value) => value !== null)
          .join(", ");
    setSelectedItem(promptString);
    setSelectedItem(promptString);

    console.log(promptString);

    // Other arguments
    var newDate = new Date();

    var datetime =
      newDate.getDate() +
      "_" +
      newDate.getMonth() +
      "_" +
      newDate.getFullYear() +
      "_" +
      newDate.getTime();
    const timestamp = new Date().getTime(); // Current timestamp
    const randomString = Math.random().toString(36).substring(2, 10); // Random string of 8 characters
    const requestPayload = {
      // ...(isAdvanced ? {
      //   advanceprompt: true,
      //   advanceprompt_text: text,
      // } : {
      generation_prompt: promptString,
      steps: selected,
      nationality: nationality,
      make_ai_listen: aiselected,
      magic: MagicSelected,
      aspect_ratio: svgselected,
      variations: null,
      upscale: Upscale,
      make_private: isCheckedPrivate,
      user_name: localStorage.getItem("login"),
      generation_id: "ER_GenImg_" + datetime,
      generation_slug:
        "ER_" +
        selectedIndexAi +
        "_" +
        promptString.replaceAll(", ", "_").replaceAll(" ", "_"),
      Aimodel: selectedIndexAi,
      Aititle: selectedTitleAi,
      extensions: selectedIndex,
      face_prompt: FaceoptionalInput,
      other: otherInput,
      variations: value === "" ? "1" : value, // Corrected the ternary here
    };
    console.log(requestPayload);

    axios
      .post("https://api2.eromantic.ai/api/v1/generate_image/", requestPayload)
      .then((response) => {
        window.location.href = "/dashboard/ImageView/ER_GenImg_" + datetime;
        // navigate('/dashboard/ImageView/ER_GenImg_' + datetime);
      })
      .catch((error) => {
        console.log("Error generating image:", error);
      });
  };

  const choosefeature = (featureName, featureType) => {
    setSelectedFeatures((prev) => ({
      ...prev,
      [selectedCharacter]: {
        ...prev[selectedCharacter],
        [featureType]: featureName,
      },
    }));

    // Move to the next feature
    const nextIndex = currentFeatureIndex + 1;
    if (nextIndex < features.length) {
      setSelectedFeature(features[nextIndex].name); // Set the next feature as selected
      setCurrentFeatureIndex(nextIndex); // Update current feature index
    } else {
      // Optionally, handle when all features have been selected
      console.log("All features have been selected");
    }
  };

  return (
    <>
      {/* <p className='pt-5 text-xl xl:text-[25px] font-medium'>This image generation Model provide Optimized AI Images and High-Quality Results.
      Use Simplified Mode: Stick to simplified mode unless you need specific control over word weights or intricate details, as it typically yields good results with minimal effort.

Avoid Long Prompts: Lengthy prompts dilute the power of selected extensions. Use concise words and avoid overloading the 'other' section to maintain focus and image quality.

Avoid Specific Body Part Terms: Using terms like "legs" or "hands" may lead to errors such as extra limbs.

Use Short Keywords + Commas: Simple words separated by commas work better than natural language for most models. Exceptions may apply, like the "semireal" model, which can handle natural phrases.

Face Prompt and Higher Resolution: A "face prompt" can enhance facial quality at high resolution, but it's not advisable when specific character attributes or face replication are needed.

2X Upscaling for Quality: Upscaling by 2X generally produces better quality than 3X, enhancing detail, realism, and facial features.

HD AI for Explicit Content: For explicit images, HD models provide better quality and fewer malformations than Base models.

Avoid Conflicting Extensions: Combining conflicting extensions (e.g., opposing poses) often results in image malformations.</p> */}
      {/* <Recreateimage /> */}

      <div className="pt-10 md:pt-20">
        {/* <div className='flex justify-center gap-5 pb-2'>
          <p className='font-semibold text-3xl xl:text-4xl pb-5'>Select AI Model</p>
          <img src={info} alt="infoBlack" className='h-6' />
        </div> */}

        <div className="container">
          <div className="flex justify-center gap-5 pt-5 md:pt-5">
            <p className="text-center  font-semibold text-2xl xl:text-3xl pb-5">
              Select AI Model
            </p>
            <div className="relative">
              {hover.model && (
                <span className="absolute w-72 -left-32 mo:left-44  mo:bottom-4 -translate-x-1/2  bg-[#8a8a8a] text-white text-sm p-2 rounded shadow-lg z-10">
                  Unlock the power of diverse models, from hyper-realistic human
                  portraits to vibrant Hentai and enchanting dreamscapes.
                  Whether you're seeking detailed anime HD, photorealism, or
                  surreal creativity, each model offers unique features to bring
                  your visions to life with stunning detail and style.
                </span>
              )}
              <img
                src={info}
                alt="infoBlack"
                className="h-4 md:h-6 cursor-pointer transition-transform duration-200 hover:scale-110"
                onMouseEnter={() => setHover({ ...hover, model: true })}
                onMouseLeave={() => setHover({ ...hover, model: false })}
              />
            </div>
          </div>
          <Swiper
            effect={"coverflow"}
            grabCursor={true}
            centeredSlides={true}
            loop={true}
            slidesPerView={"auto"}
            coverflowEffect={{
              rotate: 0,
              stretch: 0,
              depth: 100,
              modifier: 2.5,
            }}
            pagination={{ el: ".swiper-pagination", clickable: true }}
            navigation={{
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
              clickable: true,
            }}
            modules={[EffectCoverflow, Pagination, Navigation]}
            className="swiper_container"
          >
            {data.map((item, index) => (
              <SwiperSlide key={index}>
                <div
                  className="relative"
                  onMouseEnter={() => setHoveredIndex(index)}
                  onMouseLeave={() => setHoveredIndex(null)}
                  onClick={() => handleModelSelection(index)}
                >
                  <img src={item.img} alt={item.title} />
                  <div className="absolute bg-[#d3b7b79c] border-1 rounded-full p-3 sm:text-sm md:text-lg lg:text-xl xxl:text-2xl bottom-1 left-1">
                    {item.title}
                  </div>
                  <div
                    className={`absolute text-white border-1 rounded-2xl p-3 sm:text-sm md:text-lg lg:text-xl xxl:text-2xl bottom-1 right-1 ${
                      selectedIndexAi === index
                        ? "bg-[#0e7a36bf]"
                        : "bg-[#9d2d2de0]"
                    }`}
                  >
                    {selectedIndexAi === index ? "Selected" : "Select Model"}
                  </div>
                  {hoveredIndex === index && (
                    <div className="absolute mb-[6rem] ml-[6rem] text-center bottom-0 left-0 w-60 bg-black bg-opacity-70 p-2 text-white text-sm rounded-lg">
                      {item.description}
                    </div>
                  )}
                </div>
              </SwiperSlide>
            ))}

            <div className="slider-controler">
              <div className="swiper-button-prev slider-arrow xss:-ml-5 lg:-ml-3">
                <span className="hover-text prev-text">Prev</span>
              </div>
              <div className="swiper-button-next slider-arrow xss:ml-5 lg:ml-3">
                <span className="hover-text next-text">Next</span>
              </div>
              <div className="swiper-pagination"></div>
            </div>
          </Swiper>

          {selectedIndexAi !== null && (
            <div className="mt-4 p-4 bg-[#75787e6e] border rounded-lg text-lg text-gray-800">
              <h3 className="font-semibold mb-2">
                Selected {data[selectedIndexAi].title} Model
              </h3>
              <p>{data[selectedIndexAi].description}</p>
            </div>
          )}
        </div>
      </div>

      <div className="bg-[#afa4a4] rounded-lg">
        <div className="p-6">
          {/* Buttons to toggle content */}
          {isAdvanced ? (
            <button
              onClick={() => setIsAdvanced(false)}
              className={`px-6 py-2 rounded-lg focus:outline-none transition duration-300 ${
                isAdvanced ? "bg-white text-black" : "bg-black text-[#ffb5b5]"
              }`}
            >
              Back to Normal Mode
            </button>
          ) : (
            <>
              <span className="text-md text-gray-700">
                Advanced mode is designed for users skilled in crafting
                effective prompts. To make the most of this feature, we
                recommend browsing through the showcased section to find
                examples that resonate with you and replicating those prompts.
                Ensure you retain all other settings, including extensions, to
                achieve your desired outcomes seamlessly.
              </span>
              <div className="flex mt-2 items-center space-x-4">
                <button
                  onClick={() => setIsAdvanced(true)}
                  className={`px-6 py-2 rounded-lg focus:outline-none transition duration-300 ${
                    isAdvanced
                      ? "bg-black text-white"
                      : "bg-[#a90d0d73] text-black"
                  }`}
                >
                  Try Advanced Prompting{" "}
                  <i class="ml-2 fa-solid fa-arrow-right"></i>
                </button>
              </div>
            </>
          )}
        </div>

        {!isAdvanced ? (
          <>
            <div className="flex justify-center gap-5 pt-5 md:pt-5">
              <p className="font-semibold text-2xl xl:text-3xl pb-5">
                Select Character
              </p>
              <div className="relative">
                {hover.character && (
                  <span className="absolute w-60 -left-32 -translate-x-1/2  bg-[#8a8a8a] text-white text-sm p-2 rounded shadow-lg z-10">
                    Choose your character and bring their story to life. Whether
                    it's a bold boy or a graceful girl, the possibilities are
                    endless!
                  </span>
                )}
                <img
                  src={info}
                  alt="infoBlack"
                  className="h-4 md:h-6 cursor-pointer transition-transform duration-200 hover:scale-110"
                  onMouseEnter={() => setHover({ ...hover, character: true })}
                  onMouseLeave={() => setHover({ ...hover, character: false })}
                />
              </div>
            </div>
            <div className="flex justify-center space-x-6">
              {options["Characters"].map((item, index) => (
                <div
                  key={index}
                  className={`p-2 rounded-xl items-center flex flex-col justify-center space-y-4 m-4 ${
                    selectedCharacter === item.name
                      ? "bg-black"
                      : "bg-[#d1d1d1]"
                  }`}
                  onClick={() => {
                    setSelectedCharacter(item.name);
                    setSelectedFeature("Hair Color");
                  }}
                >
                  {/* Image of the character */}
                  <img
                    src={item.src}
                    alt={item.name}
                    className="w-60 h-60 object-cover rounded-lg"
                  />

                  {/* Character Name */}
                  <p
                    className={`font-semibold hover:text-red-600 capitalize hover:cursor-pointer text-2xl ${
                      selectedCharacter === item.name
                        ? "text-white"
                        : "text-black"
                    }`}
                  >
                    {item.name}
                  </p>
                </div>
              ))}
            </div>
          </>
        ) : (
          ""
        )}

        <div className="flex flex-wrap p-6">
          {/* Left Section: Select Features */}
          {!isAdvanced ? (
            <div className="w-full md:w-2/4 md:pr-4">
              {selectedCharacter && (
                <div className="bg-[#83808052] shadow-xl rounded-lg p-6">
                  <div className="flex justify-between items-center mb-6">
                    <p className="font-semibold text-2xl pb-5 text-gray-800">
                      Select Features
                    </p>
                    <div className="relative">
                      {hover.feature && (
                        <span className="absolute w-60 -left-32 -translate-x-1/2  bg-[#8a8a8a] text-white text-sm p-2 rounded shadow-lg z-10">
                          Unleash your creativity! Customize your character with
                          features like Hair Color, Body Type, and Effects to
                          bring your vision to life.
                        </span>
                      )}
                      <img
                        src={info}
                        alt="infoBlack"
                        className="h-4 md:h-6 cursor-pointer transition-transform duration-200 hover:scale-110"
                        onMouseEnter={() =>
                          setHover({ ...hover, feature: true })
                        }
                        onMouseLeave={() =>
                          setHover({ ...hover, feature: false })
                        }
                      />
                    </div>
                  </div>

                  <div className="bg-[#bbaeaf] p-4 rounded-lg shadow-sm flex flex-wrap gap-1 sm:gap-4">
                    {features.map((item, index) => (
                      <p
                        key={index}
                        className={`font-semibold text-md py-2 px-3 sm:px-6 rounded-lg cursor-pointer transition-transform duration-200 transform ${
                          selectedFeature === item.name
                            ? "bg-gray-800 text-white"
                            : "hover:bg-gray-300"
                        }`}
                        onClick={() => {
                          setSelectedFeature(item.name);
                          setCurrentFeatureIndex(index); // Set the current feature index on click
                        }}
                      >
                        {item.name}
                      </p>
                    ))}
                  </div>

                  {selectedFeature && (
                    <div
                      className="mt-6 bg-[#b9b9b97a] p-6 rounded-lg shadow-sm flex justify-center flex-wrap gap-4 overflow-scroll"
                      style={{ scrollbarWidth: "none" }}
                    >
                      {options[selectedCharacter][selectedFeature].map(
                        (item, index) => (
                          <div
                            key={index}
                            className={`flex justify-center items-center w-[100px] sm:w-[140px] border-2  border-gray-400 hover:border-gray-600 hover:scale-105 rounded-full p-2 cursor-pointer transition-all duration-300 ease-in-out transform ${
                              selectedFeatures[selectedCharacter]?.[
                                selectedFeature
                              ] === item.name
                                ? "bg-[#202936] text-white"
                                : "bg-white"
                            }`}
                            onClick={() =>
                              choosefeature(item.name, selectedFeature)
                            }
                          >
                            <p
                              className="font-semibold text-base mo:text-lg text-center"
                              style={{ textTransform: "capitalize" }}
                            >
                              {item.name}
                            </p>
                          </div>
                        )
                      )}
                    </div>
                  )}

                  {selectedCharacter &&
                    Object.keys(selectedFeatures[selectedCharacter] || {})
                      .length > 0 && (
                      <div className="bg-[#b9b9b97a] p-4 rounded-lg shadow-sm mt-6">
                        <h3 className="font-semibold text-xl text-center text-gray-800">
                          Selected Features
                        </h3>
                        <div className="flex flex-wrap justify-center gap-4">
                          {Object.entries(
                            selectedFeatures[selectedCharacter] || {}
                          ).map(([feature, value], index) => (
                            <div
                              key={index}
                              className="flex flex-col w-[120px] sm:w-[150px] bg-white rounded-lg shadow-md p-3 border-l-4 border-gray-400 transition-transform duration-300 ease-in-out transform hover:scale-105"
                            >
                              <span className="font-semibold text-md text-gray-700">
                                {feature}
                              </span>
                              <span className="text-sm text-gray-500">
                                {typeof value === "object" ? value.name : value}
                              </span>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                </div>
              )}
            </div>
          ) : (
            <div className="w-full md:w-2/4 md:pr-4">
              <div className="bg-[#83808052] shadow-xl rounded-lg p-6">
                <p className="font-semibold text-3xl pb-5 text-gray-800">
                  Write Your Custom Prompt Here
                </p>
                {/* Textarea */}
                <textarea
                  value={text}
                  onChange={handleChangeadvance}
                  rows="4"
                  className="w-full bg-[#b9b9b97a] text-black p-4 border-2 border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-red-500 placeholder-[#5e5e5e]"
                  placeholder="Write something..."
                ></textarea>

                {/* Suggestions Box */}
                <div className="mt-4 p-4 bg-[#b9b9b97a] rounded-lg shadow-md">
                  <h3 className="font-semibold text-gray-700">Suggestions:</h3>
                  <ul className="list-disc pl-5 space-y-2">
                    {suggestions.map((suggestion) => (
                      <li
                        key={suggestion.id}
                        className={`cursor-pointer p-2 rounded-lg transition-colors ${
                          selectedSuggestions.includes(suggestion.line)
                            ? "bg-red-200 text-red-800"
                            : "hover:bg-gray-200"
                        }`}
                        onClick={() => handleSuggestionClick(suggestion.line)}
                      >
                        {suggestion.line}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          )}

          {/* Right Section: Extensions */}
          <div className="w-full md:w-2/4 md:pl-4">
            <div className="bg-[#83808052] shadow-xl rounded-lg p-6">
              <div className="flex justify-between items-center mb-6">
                <p className="font-semibold text-3xl pb-5 text-gray-800">
                  Extensions{" "}
                  <span className="mt-0 text-base text-gray-600">
                    (Addon 0.5 Token)
                  </span>
                </p>
                <div className="relative">
                  {hover.extension && (
                    <span className="absolute w-60 -left-32 -translate-x-1/2  bg-[#8a8a8a] text-white text-sm p-2 rounded shadow-lg z-10">
                      Transform your creations with a diverse range of features,
                      from elegant poses to dynamic expressions. Highlight
                      captivating details, explore imaginative themes, and bring
                      your vision to life with stunning realism and creativity.
                    </span>
                  )}
                  <img
                    src={info}
                    alt="infoBlack"
                    className="h-4 md:h-6 cursor-pointer transition-transform duration-200 hover:scale-110"
                    onMouseEnter={() => setHover({ ...hover, extension: true })}
                    onMouseLeave={() =>
                      setHover({ ...hover, extension: false })
                    }
                  />
                </div>
              </div>
              <div className="px-5 flex items-center border rounded-full bg-gray-100">
                <IoSearchOutline color="black" size={23} />
                <input
                  type="search"
                  placeholder="Search Extension"
                  className="focus:outline-none w-full bg-transparent text-lg text-black p-2 rounded-full ml-2"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)} // Update search term on input change
                />
              </div>

              {isAdvanced ? (
                <>
                  <div className="w-full flex justify-center mt-2">
                    {options["Characters"].map((item, index) => (
                      <div
                        key={index}
                        className={`px-4 py-2 items-center flex flex-col justify-center rounded-lg mx-1 ${
                          selectedCharacter === item.name
                            ? "bg-black"
                            : "bg-[#d1d1d1]"
                        }`}
                        onClick={() => {
                          setSelectedCharacter(item.name);
                          setSelectedFeature("Hair Color");
                        }}
                      >
                        {/* Character Name */}
                        <p
                          className={`hover:text-red-600 capitalize hover:cursor-pointer text-lg  ${
                            selectedCharacter === item.name
                              ? "text-white"
                              : "text-black"
                          }`}
                        >
                          {item.name == "1 Girl" ? "Girls" : "Boys"}
                        </p>
                      </div>
                    ))}
                  </div>
                </>
              ) : (
                <></>
              )}

              <div
                style={{
                  height: "32rem",
                  overflowY: "scroll",
                  scrollbarWidth: "thin",
                  scrollbarColor: "#c0c0c0 #f0f0f0",
                  borderRadius: "0.75rem",
                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                  padding: "1rem",
                }}
                className="scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100 mt-6"
              >
                {selectedCharacter == "1 Girl" ? (
                  <>
                    {/* Boots section */}
                    <div className="text-center mb-6">
                      <div className="bg-gray-200 rounded-full w-32 my-4 py-2 font-semibold text-lg text-gray-600">
                        Boobs
                      </div>
                    </div>
                    <div className="flex flex-wrap justify-center gap-6">
                      {filteredImages.map((item, index) => (
                        <div
                          key={index}
                          className={`relative w-[180px] h-[210px] xl:w-[170px] xl:h-[210px] cursor-pointer rounded-lg overflow-hidden transition-transform duration-300 ease-in-out transform ${
                            selectedIndex === item.trigerWord
                              ? "border-4 border-red-600"
                              : ""
                          }`}
                          onClick={() => setSelectedIndex(item.trigerWord)}
                        >
                          <img
                            src={item.src}
                            alt={item.title}
                            className="w-full h-full object-cover cursor-pointer rounded-lg"
                          />
                          <div
                            className={`absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white text-center py-2 rounded-b-lg ${
                              selectedIndex === item.trigerWord
                                ? "bg-red-600"
                                : ""
                            }`}
                          >
                            {item.title}
                          </div>
                        </div>
                      ))}
                    </div>

                    {/* Assa section */}
                    <div className="text-center my-6">
                      <div className="bg-gray-200 rounded-full w-32 my-4 py-2 font-semibold text-lg text-gray-600">
                        Ass
                      </div>
                    </div>
                    <div className="flex flex-wrap justify-center gap-6">
                      {filteredImagesTwo.map((item, index) => (
                        <div
                          key={index}
                          className={`relative w-[180px] h-[210px] xl:w-[170px] xl:h-[210px] cursor-pointer rounded-lg overflow-hidden transition-transform duration-300 ease-in-out transform ${
                            selectedIndex === item.trigerWord
                              ? "border-4 border-red-600"
                              : ""
                          }`}
                          onClick={() => setSelectedIndex(item.trigerWord)}
                        >
                          <img
                            src={item.src}
                            alt={item.title}
                            className="w-full h-full object-cover cursor-pointer rounded-lg"
                          />
                          <div
                            className={`absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white text-center py-2 rounded-b-lg ${
                              selectedIndex === item.trigerWord
                                ? "bg-red-600"
                                : ""
                            }`}
                          >
                            {item.title}
                          </div>
                        </div>
                      ))}
                    </div>

                    {/* Cum section */}
                    <div className="text-center my-6">
                      <div className="bg-gray-200 rounded-full w-32 my-4 py-2 font-semibold text-lg text-gray-600">
                        Cum
                      </div>
                    </div>
                    <div className="flex flex-wrap justify-center gap-6">
                      {filteredImagesThree.map((item, index) => (
                        <div
                          key={index}
                          className={`relative w-[180px] h-[210px] xl:w-[170px] xl:h-[210px] cursor-pointer rounded-lg overflow-hidden transition-transform duration-300 ease-in-out transform ${
                            selectedIndex === item.trigerWord
                              ? "border-4 border-red-600"
                              : ""
                          }`}
                          onClick={() => setSelectedIndex(item.trigerWord)}
                        >
                          <img
                            src={item.src}
                            alt={item.title}
                            className="w-full h-full object-cover cursor-pointer rounded-lg"
                          />
                          <div
                            className={`absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white text-center py-2 rounded-b-lg ${
                              selectedIndex === item.trigerWord
                                ? "bg-red-600"
                                : ""
                            }`}
                          >
                            {item.title}
                          </div>
                        </div>
                      ))}
                    </div>

                    {/* Pussy section */}
                    <div className="text-center my-6">
                      <div className="bg-gray-200 rounded-full w-32 my-4 py-2 font-semibold text-lg text-gray-600">
                        Pussy
                      </div>
                    </div>
                    <div className="flex flex-wrap justify-center gap-6">
                      {filteredImagesFour.map((item, index) => (
                        <div
                          key={index}
                          className={`relative w-[180px] h-[210px] xl:w-[170px] xl:h-[210px] cursor-pointer rounded-lg overflow-hidden transition-transform duration-300 ease-in-out transform ${
                            selectedIndex === item.trigerWord
                              ? "border-4 border-red-600"
                              : ""
                          }`}
                          onClick={() => setSelectedIndex(item.trigerWord)}
                        >
                          <img
                            src={item.src}
                            alt={item.title}
                            className="w-full h-full object-cover cursor-pointer rounded-lg"
                          />
                          <div
                            className={`absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white text-center py-2 rounded-b-lg ${
                              selectedIndex === item.trigerWord
                                ? "bg-red-600"
                                : ""
                            }`}
                          >
                            {item.title}
                          </div>
                        </div>
                      ))}
                    </div>

                    {/* Feet section */}
                    <div className="text-center my-6">
                      <div className="bg-gray-200 rounded-full w-32 my-4 py-2 font-semibold text-lg text-gray-600">
                        Feet
                      </div>
                    </div>
                    <div className="flex flex-wrap justify-center gap-6">
                      {filteredImagesFive.map((item, index) => (
                        <div
                          key={index}
                          className={`relative w-[180px] h-[210px] xl:w-[170px] xl:h-[210px] cursor-pointer rounded-lg overflow-hidden transition-transform duration-300 ease-in-out transform ${
                            selectedIndex === item.trigerWord
                              ? "border-4 border-red-600"
                              : ""
                          }`}
                          onClick={() => setSelectedIndex(item.trigerWord)}
                        >
                          <img
                            src={item.src}
                            alt={item.title}
                            className="w-full h-full object-cover cursor-pointer rounded-lg"
                          />
                          <div
                            className={`absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white text-center py-2 rounded-b-lg ${
                              selectedIndex === item.trigerWord
                                ? "bg-red-600"
                                : ""
                            }`}
                          >
                            {item.title}
                          </div>
                        </div>
                      ))}
                    </div>

                    {/* Pregnant section */}
                    <div className="text-center my-6">
                      <div className="bg-gray-200 rounded-full w-32 my-4 py-2 font-semibold text-lg text-gray-600">
                        Pregnant
                      </div>
                    </div>
                    <div className="flex flex-wrap justify-center gap-6">
                      {filteredImagesSix.map((item, index) => (
                        <div
                          key={index}
                          className={`relative w-[180px] h-[210px] xl:w-[170px] xl:h-[210px] cursor-pointer rounded-lg overflow-hidden transition-transform duration-300 ease-in-out transform ${
                            selectedIndex === item.trigerWord
                              ? "border-4 border-red-600"
                              : ""
                          }`}
                          onClick={() => setSelectedIndex(item.trigerWord)}
                        >
                          <img
                            src={item.src}
                            alt={item.title}
                            className="w-full h-full object-cover cursor-pointer rounded-lg"
                          />
                          <div
                            className={`absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white text-center py-2 rounded-b-lg ${
                              selectedIndex === item.trigerWord
                                ? "bg-red-600"
                                : ""
                            }`}
                          >
                            {item.title}
                          </div>
                        </div>
                      ))}
                    </div>

                    {/* BDSM section */}
                    <div className="text-center my-6">
                      <div className="bg-gray-200 rounded-full w-32 my-4 py-2 font-semibold text-lg text-gray-600">
                        BDSM
                      </div>
                    </div>
                    <div className="flex flex-wrap justify-center gap-6">
                      {filteredImagesSeven.map((item, index) => (
                        <div
                          key={index}
                          className={`relative w-[180px] h-[210px] xl:w-[170px] xl:h-[210px] cursor-pointer rounded-lg overflow-hidden transition-transform duration-300 ease-in-out transform ${
                            selectedIndex === item.trigerWord
                              ? "border-4 border-red-600"
                              : ""
                          }`}
                          onClick={() => setSelectedIndex(item.trigerWord)}
                        >
                          <img
                            src={item.src}
                            alt={item.title}
                            className="w-full h-full object-cover cursor-pointer rounded-lg"
                          />
                          <div
                            className={`absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white text-center py-2 rounded-b-lg ${
                              selectedIndex === item.trigerWord
                                ? "bg-red-600"
                                : ""
                            }`}
                          >
                            {item.title}
                          </div>
                        </div>
                      ))}
                    </div>

                    {/* Penetration section */}
                    <div className="text-center my-6">
                      <div className="bg-gray-200 rounded-full w-32 my-4 py-2 font-semibold text-lg text-gray-600">
                        Penetration
                      </div>
                    </div>
                    <div className="flex flex-wrap justify-center gap-6">
                      {filteredImagesEight.map((item, index) => (
                        <div
                          key={index}
                          className={`relative w-[180px] h-[210px] xl:w-[170px] xl:h-[210px] cursor-pointer rounded-lg overflow-hidden transition-transform duration-300 ease-in-out transform ${
                            selectedIndex === item.trigerWord
                              ? "border-4 border-red-600"
                              : ""
                          }`}
                          onClick={() => setSelectedIndex(item.trigerWord)}
                        >
                          <img
                            src={item.src}
                            alt={item.title}
                            className="w-full h-full object-cover cursor-pointer rounded-lg"
                          />
                          <div
                            className={`absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white text-center py-2 rounded-b-lg ${
                              selectedIndex === item.trigerWord
                                ? "bg-red-600"
                                : ""
                            }`}
                          >
                            {item.title}
                          </div>
                        </div>
                      ))}
                    </div>

                    {/* Futanari section */}
                    <div className="text-center my-6">
                      <div className="bg-gray-200 rounded-full w-32 my-4 py-2 font-semibold text-lg text-gray-600">
                        Futanari
                      </div>
                    </div>
                    <div className="flex flex-wrap justify-center gap-6">
                      {filteredImagesNine.map((item, index) => (
                        <div
                          key={index}
                          className={`relative w-[180px] h-[210px] xl:w-[170px] xl:h-[210px] cursor-pointer rounded-lg overflow-hidden transition-transform duration-300 ease-in-out transform ${
                            selectedIndex === item.trigerWord
                              ? "border-4 border-red-600"
                              : ""
                          }`}
                          onClick={() => setSelectedIndex(item.trigerWord)}
                        >
                          <img
                            src={item.src}
                            alt={item.title}
                            className="w-full h-full object-cover cursor-pointer rounded-lg"
                          />
                          <div
                            className={`absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white text-center py-2 rounded-b-lg ${
                              selectedIndex === item.trigerWord
                                ? "bg-red-600"
                                : ""
                            }`}
                          >
                            {item.title}
                          </div>
                        </div>
                      ))}
                    </div>

                    {/* Blow section */}
                    <div className="text-center my-6">
                      <div className="bg-gray-200 rounded-full w-32 my-4 py-2 font-semibold text-lg text-gray-600">
                        Blowjob
                      </div>
                    </div>
                    <div className="flex flex-wrap justify-center gap-6">
                      {filteredImagesTen.map((item, index) => (
                        <div
                          key={index}
                          className={`relative w-[180px] h-[210px] xl:w-[170px] xl:h-[210px] cursor-pointer rounded-lg overflow-hidden transition-transform duration-300 ease-in-out transform ${
                            selectedIndex === item.trigerWord
                              ? "border-4 border-red-600"
                              : ""
                          }`}
                          onClick={() => setSelectedIndex(item.trigerWord)}
                        >
                          <img
                            src={item.src}
                            alt={item.title}
                            className="w-full h-full object-cover cursor-pointer rounded-lg"
                          />
                          <div
                            className={`absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white text-center py-2 rounded-b-lg ${
                              selectedIndex === item.trigerWord
                                ? "bg-red-600"
                                : ""
                            }`}
                          >
                            {item.title}
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                ) : (
                  <>
                    {/* BodyBuilder section */}
                    <div className="text-center mb-6">
                      <div className="bg-gray-200 rounded-full w-32 my-4 py-2 font-semibold text-lg text-gray-600">
                        Body Builder
                      </div>
                    </div>
                    <div className="flex flex-wrap justify-center gap-6">
                      {filteredBoyone.map((item, index) => (
                        <div
                          key={index}
                          className={`relative w-[180px] h-[210px] xl:w-[170px] xl:h-[210px] cursor-pointer rounded-lg overflow-hidden transition-transform duration-300 ease-in-out transform ${
                            selectedIndex === item.trigerWord
                              ? "border-4 border-red-600"
                              : ""
                          }`}
                          onClick={() => setSelectedIndex(item.trigerWord)}
                        >
                          <img
                            src={item.src}
                            alt={item.title}
                            className="w-full h-full object-cover cursor-pointer rounded-lg"
                          />
                          <div
                            className={`absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white text-center py-2 rounded-b-lg ${
                              selectedIndex === index ? "bg-red-600" : ""
                            }`}
                          >
                            {item.title}
                          </div>
                        </div>
                      ))}
                    </div>

                    {/* Penis section */}
                    <div className="text-center mb-6">
                      <div className="bg-gray-200 rounded-full w-32 my-4 py-2 font-semibold text-lg text-gray-600">
                        Penis
                      </div>
                    </div>
                    <div className="flex flex-wrap justify-center gap-6">
                      {filteredBoyTwo.map((item, index) => (
                        <div
                          key={index}
                          className={`relative w-[180px] h-[210px] xl:w-[170px] xl:h-[210px] cursor-pointer rounded-lg overflow-hidden transition-transform duration-300 ease-in-out transform ${
                            selectedIndex === item.trigerWord
                              ? "border-4 border-red-600"
                              : ""
                          }`}
                          onClick={() => setSelectedIndex(item.trigerWord)}
                        >
                          <img
                            src={item.src}
                            alt={item.title}
                            className="w-full h-full object-cover cursor-pointer rounded-lg"
                          />
                          <div
                            className={`absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white text-center py-2 rounded-b-lg ${
                              selectedIndex === index ? "bg-red-600" : ""
                            }`}
                          >
                            {item.title}
                          </div>
                        </div>
                      ))}
                    </div>

                    {/* Bondage section */}
                    <div className="text-center mb-6">
                      <div className="bg-gray-200 rounded-full w-32 my-4 py-2 font-semibold text-lg text-gray-600">
                        Bondage
                      </div>
                    </div>
                    <div className="flex flex-wrap justify-center gap-6">
                      {filteredBoyThree.map((item, index) => (
                        <div
                          key={index}
                          className={`relative w-[180px] h-[210px] xl:w-[170px] xl:h-[210px] cursor-pointer rounded-lg overflow-hidden transition-transform duration-300 ease-in-out transform ${
                            selectedIndex === item.trigerWord
                              ? "border-4 border-red-600"
                              : ""
                          }`}
                          onClick={() => setSelectedIndex(item.trigerWord)}
                        >
                          <img
                            src={item.src}
                            alt={item.title}
                            className="w-full h-full object-cover cursor-pointer rounded-lg"
                          />
                          <div
                            className={`absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white text-center py-2 rounded-b-lg ${
                              selectedIndex === index ? "bg-red-600" : ""
                            }`}
                          >
                            {item.title}
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="flex flex-wrap justify-between pt-20">
          <div className="flex flex-col gap-5 lg:gap-12 text-black font-josefin-sans">
            {/* <div className='flex relative items-center gap-5 md:mb-3 lg:mb-0'>
              <p className='text-base md:text-[15px] lg:text-[20px] xl:text-[25px] font-semibold text-gray-800'>
                Do you want to Upscale Image (2T)
              </p>

              <input
                type="checkbox"
                className="w-7 h-7 rounded-lg border-2 border-gray-300 transition-all duration-300 hover:border-blue-500"
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              <div
                className=""
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                <img src={info} alt="info" className="h-4 md:h-6 cursor-pointer transition-transform duration-200 hover:scale-110" />
                {isHovered && (
                  <span className="absolute bg-[#8a8a8a] text-white text-sm top-7 right-0 p-2 shadow-lg rounded max-w-xs w-auto z-10">
                    Elevate your images with AI-powered upscaling. Increase resolution for sharper, more detailed visuals, perfect for larger displays.
                  </span>
                )}
              </div>
            </div> */}

            <div className="flex relative  gap-5 md:mb-3 lg:mb-0">
              <p className="capitalize text-base md:text-xl lg:text-2xl xl:text-3xl font-semibold text-gray-800">
                Do you want to Upscale Image{" "}
                <p className="flex md:text-[8px] lg:text-[12px] xl:text-[16px]">
                  (Addon 1-Token {"  "}
                  <img className="w-6 h-6 mt-1 ms-1" src={goldE} alt="goldE" />)
                </p>
              </p>

              {/* Stylish toggle button */}
              <button
                onClick={handleCheckboxChange}
                className={`relative inline-flex items-center h-[2rem] w-[4rem] rounded-full transition-all duration-300 ${
                  isChecked ? "bg-[#ab1e1eb5]" : "bg-gray-300"
                }`}
              >
                <span
                  className={`absolute left-1 top-1 transition-all duration-300 transform ${
                    isChecked
                      ? "translate-x-8 bg-white"
                      : "translate-x-0 bg-white"
                  }`}
                  style={{ height: "25px", width: "25px", borderRadius: "50%" }}
                ></span>
              </button>

              {/* Info Tooltip */}
              <div
                className=""
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                <img
                  src={info}
                  alt="info"
                  className="h-4 md:h-6 cursor-pointer transition-transform duration-200 hover:scale-110"
                />
                {isHovered && (
                  <span className="absolute bg-[#8a8a8a] text-white text-sm top-7 right-0 p-2 shadow-lg rounded max-w-xs w-auto z-10">
                    Elevate your images with AI-powered upscaling. Increase
                    resolution for sharper, more detailed visuals, perfect for
                    larger displays.
                  </span>
                )}
              </div>
            </div>
            <div>
              <div className="flex relative items-center gap-5">
                <p className="text-base md:text-xl lg:text-2xl xl:text-3xl  font-semibold text-gray-800">
                  Other{" "}
                  <span className="text-md text-gray-600">
                    (use prompt words with commas)
                  </span>
                </p>
                <div
                  className=""
                  onMouseEnter={() => setIsHoveredone(true)}
                  onMouseLeave={() => setIsHoveredone(false)}
                >
                  <img
                    src={info}
                    alt="info"
                    className="h-4 md:h-6 cursor-pointer transition-transform duration-200 hover:scale-110"
                  />
                  {isHoveredone && (
                    <span className="absolute text-white bg-[#8a8a8a] text-sm bottom-100 right-0 p-2 shadow-lg rounded max-w-xs w-auto">
                      Specify art styles, features, colors, mood, or setting to
                      unlock the full creative potential of the model,
                      generating images that match your unique vision and
                      aesthetic.
                    </span>
                  )}
                </div>
              </div>
              <div className="bg-zinc-300 rounded-xl mt-2 px-5 py-2 text-black text-2xl font-light w-full h-38 shadow-lg hover:shadow-xl transition-all">
                <textarea
                  type="text"
                  rows={3}
                  className="focus:outline-none bg-zinc-300 w-full text-black p-2 placeholder-gray-600 rounded-lg"
                  placeholder="e.g. smiling, sunny weather"
                  value={otherInput}
                  onChange={handleOtherInputChange}
                />
              </div>
            </div>
            <div className="flex items-center gap-5 md:mb-3 lg:mb-0">
              {/* <input
                type="radio"
                className='w-7 h-7'
              /> */}
              <p className="text-base md:text-xl lg:text-2xl xl:text-3xl  font-semibold text-gray-800">
                Make Private
              </p>
              <button
                onClick={handleCheckboxChangePrivate}
                className={`relative inline-flex items-center h-[2rem] w-[4rem] rounded-full transition-all duration-300 ${
                  isCheckedPrivate ? "bg-[#ab1e1eb5]" : "bg-gray-300"
                }`}
              >
                {/* OFF text */}
                <span
                  className={`absolute z-10 left-2 text-black transition-all duration-300 ${
                    isCheckedPrivate ? "opacity-0" : "opacity-100"
                  }`}
                  style={{ top: "50%", transform: "translateY(-50%)" }}
                ></span>

                {/* ON text */}
                <span
                  className={`absolute z-10 right-1 text-black font-bold transition-all duration-300 ${
                    isCheckedPrivate ? "opacity-100" : "opacity-0"
                  }`}
                  style={{ top: "50%", transform: "translateY(-50%)" }}
                >
                  ON
                </span>

                <span
                  className={`absolute left-1 top-1 transition-all duration-300 transform ${
                    isCheckedPrivate
                      ? "translate-x-8 bg-white"
                      : "translate-x-0 bg-white"
                  }`}
                  style={{ height: "25px", width: "25px", borderRadius: "50%" }}
                ></span>
              </button>
            </div>
          </div>

          <div className="flex flex-col gap-5 lg:gap-12 text-black font-josefin-sans">
            {/* <div className='mt-3'>
      <div className='flex items-center gap-5'>
        <p className='text-base md:text-[25px] lg:text-[33px] xl:text-[35px]'>Make AI listen</p>
        <div>
          <img src={info} alt="info" className='h-4 md:h-6' />
        </div>
      </div>
      <div className='flex items-center gap-5 lg:pt-3'>
        <p
          onClick={() => handleaiClick('creative', 'Let it be creative')}
          className={`text-base md:text-lg lg:text-xl xl:text-2xl font-semibold cursor-pointer px-4 py-2 rounded border border-transparent transition-colors duration-300 ${aiselected === 'creative' ? 'text-red-500' : 'text-black'}`}
        >
          Let it be creative
        </p>
        <p
          onClick={() => handleaiClick('normal', 'Normal A lot')}
          className={`text-base md:text-lg lg:text-xl xl:text-2xl font-light cursor-pointer px-4 py-2 rounded border border-transparent transition-colors duration-300 ${aiselected === 'normal' ? 'text-red-500' : 'text-black'}`}
        >
          Normal A lot
        </p>
      </div>
    </div> */}
            <div>
              <div className="flex flex-col items-start">
                <label
                  htmlFor="variations"
                  className="text-base capitalize md:text-[15px] lg:text-[20px] xl:text-[25px] font-semibold text-gray-800"
                >
                  Number of variations you want (Max 4)
                </label>
                <p className="mt-0 text-sm capitalize text-gray-600">
                  Enter a number to specify the number of image variations you
                  want.
                </p>
                <div className="flex space-x-2 my-4">
                  <button
                    className="bg-[#d4d4d8] border-2 border-gray-300 rounded-lg p-2 w-14 sm:w-18 md:w-22 text-center text-sm focus:ring-2 focus:ring-red-500"
                    onClick={() => handleSelect(1)}
                  >
                    1
                  </button>
                  <button
                    className="bg-[#d4d4d8] border-2 border-gray-300 rounded-lg p-2 w-14 sm:w-18 md:w-22 text-center text-sm focus:ring-2 focus:ring-red-500"
                    onClick={() => handleSelect(2)}
                  >
                    2
                  </button>
                  <button
                    className="bg-[#d4d4d8] border-2 border-gray-300 rounded-lg p-2 w-14 sm:w-18 md:w-22 text-center text-sm focus:ring-2 focus:ring-red-500"
                    onClick={() => handleSelect(3)}
                  >
                    3
                  </button>
                  <button
                    className="bg-[#d4d4d8] border-2 border-gray-300 rounded-lg p-2 w-14 sm:w-18 md:w-22 text-center text-sm focus:ring-2 focus:ring-red-500"
                    onClick={() => handleSelect(4)}
                  >
                    4
                  </button>
                </div>
                {/* <input
                  type="text"
                  id="variations"
                  name="variations"
                  value={value}
                  onChange={handleChange}
                  maxLength="1"
                  className="my-4 bg-[#d4d4d8] border-2 border-gray-300 rounded-lg p-2 text-center w-48 focus:ring-2 focus:ring-blue-500"
                  placeholder="Enter number"
                /> */}
                {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
              </div>
              <div className="flex items-center gap-5">
                <p className="text-base md:text-[15px] lg:text-[20px] xl:text-[25px] font-semibold text-gray-800">
                  Aspect Ratio{" "}
                  <span className="text-md text-gray-600">
                    (square, horizontal, vertical)
                  </span>
                </p>
              </div>
              <div className="bg-[#d4d4d8] w-full sm:w-[75%] justify-center rounded-[20px] flex flex-wrap sm:flex-nowrap items-center gap-5 text-center mt-2 p-5">
                {/* First SVG */}
                <div className="relative group flex-1 sm:flex-none w-full sm:w-auto">
                  <svg
                    onClick={() => handlesvgClick("first")}
                    className={`cursor-pointer fill-none stroke-[#ef0808c9] mr-[1.5rem] transition-opacity duration-300 ${
                      svgselected === "first" ? "opacity-[1]" : "opacity-[0.4]"
                    } hover:scale-[2.4] transition-transform duration-300`}
                    width="42"
                    height="42"
                    viewBox="0 0 42 42"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="1.5"
                      y="1.5"
                      width="39"
                      height="39"
                      rx="6.5"
                      strokeWidth="2"
                    ></rect>
                  </svg>
                  {/* Text for First */}
                  <span className="absolute left-0 right-0 bottom-[-20px] text-[#ef0808c9] text-xs opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                    512 x 512
                  </span>
                </div>

                {/* Second SVG */}
                <div className="relative group flex-1 sm:flex-none w-full sm:w-auto">
                  <svg
                    onClick={() => handlesvgClick("second")}
                    className={`cursor-pointer fill-none stroke-[#ef0808c9] mr-[1.5rem] transition-opacity duration-300 ${
                      svgselected === "second" ? "opacity-[1]" : "opacity-[0.4]"
                    } hover:scale-[2.4] transition-transform duration-300`}
                    width="62"
                    height="42"
                    viewBox="0 0 62 42"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="1.5"
                      y="1.5"
                      width="59"
                      height="39"
                      rx="6.5"
                      strokeWidth="2"
                    ></rect>
                  </svg>
                  {/* Text for Second */}
                  <span className="absolute left-0 right-0 bottom-[-20px] text-[#ef0808c9] text-xs opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                    512 x 1024
                  </span>
                </div>

                {/* Third SVG */}
                <div className="relative group flex-1 sm:flex-none w-full sm:w-auto">
                  <svg
                    onClick={() => handlesvgClick("third")}
                    className={`cursor-pointer fill-none stroke-[#ef0808c9] mr-[1.5rem] transition-opacity duration-300 ${
                      svgselected === "third" ? "opacity-[1]" : "opacity-[0.4]"
                    } hover:scale-[2.4] transition-transform duration-300`}
                    width="29"
                    height="42"
                    viewBox="0 0 29 42"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="1.5"
                      y="1.5"
                      width="26"
                      height="39"
                      rx="6.5"
                      strokeWidth="2"
                    ></rect>
                  </svg>
                  {/* Text for Third */}
                  <span className="absolute left-0 right-0 bottom-[-20px] text-[#ef0808c9] text-xs opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                    480 X 680
                  </span>
                </div>

                {/* Fourth SVG */}
                <div className="relative group flex-1 sm:flex-none w-full sm:w-auto">
                  <svg
                    onClick={() => handlesvgClick("fourth")}
                    className={`cursor-pointer fill-none stroke-[#ef0808c9] mr-[1.5rem] transition-opacity duration-300 ${
                      svgselected === "fourth" ? "opacity-[1]" : "opacity-[0.4]"
                    } hover:scale-[2.4] transition-transform duration-300`}
                    width="29"
                    height="42"
                    viewBox="0 0 29 42"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="1.5"
                      y="1.5"
                      width="26"
                      height="39"
                      rx="6.5"
                      strokeWidth="2"
                    ></rect>
                    <path
                      d="M5.399 22.194V21.459L9.158 16.65H10.292L6.5645 21.459L6.029 21.291H11.8985V22.194H5.399ZM9.4835 24V22.194L9.515 21.291V19.695H10.502V24H9.4835ZM14.7627 19.821C14.5667 19.821 14.3987 19.7545 14.2587 19.6215C14.1187 19.4815 14.0487 19.3065 14.0487 19.0965C14.0487 18.8865 14.1187 18.715 14.2587 18.582C14.3987 18.449 14.5667 18.3825 14.7627 18.3825C14.9517 18.3825 15.1127 18.449 15.2457 18.582C15.3857 18.715 15.4557 18.8865 15.4557 19.0965C15.4557 19.3065 15.3857 19.4815 15.2457 19.6215C15.1127 19.7545 14.9517 19.821 14.7627 19.821ZM14.7627 24.063C14.5667 24.063 14.3987 23.9965 14.2587 23.8635C14.1187 23.7235 14.0487 23.5485 14.0487 23.3385C14.0487 23.1285 14.1187 22.957 14.2587 22.824C14.3987 22.691 14.5667 22.6245 14.7627 22.6245C14.9517 22.6245 15.1127 22.691 15.2457 22.824C15.3857 22.957 15.4557 23.1285 15.4557 23.3385C15.4557 23.5485 15.3857 23.7235 15.2457 23.8635C15.1127 23.9965 14.9517 24.063 14.7627 24.063ZM20.2916 24.084C19.7736 24.084 19.2696 24.0035 18.7796 23.8425C18.2966 23.6815 17.9011 23.4645 17.5931 23.1915L18.0761 22.362C18.3211 22.593 18.6396 22.782 19.0316 22.929C19.4236 23.076 19.8401 23.1495 20.2811 23.1495C20.8411 23.1495 21.2716 23.0305 21.5726 22.7925C21.8736 22.5545 22.0241 22.236 22.0241 21.837C22.0241 21.564 21.9576 21.326 21.8246 21.123C21.6916 20.92 21.4606 20.766 21.1316 20.661C20.8096 20.549 20.3651 20.493 19.7981 20.493H18.1286L18.5171 16.65H22.6646V17.5635H18.9056L19.4516 17.049L19.1471 20.0835L18.6011 19.5795H20.0186C20.7536 19.5795 21.3451 19.674 21.7931 19.863C22.2411 20.052 22.5666 20.3145 22.7696 20.6505C22.9726 20.9795 23.0741 21.361 23.0741 21.795C23.0741 22.215 22.9726 22.6 22.7696 22.95C22.5666 23.293 22.2586 23.5695 21.8456 23.7795C21.4396 23.9825 20.9216 24.084 20.2916 24.084Z"
                      fill="white"
                    ></path>
                  </svg>
                  {/* Text for Fourth */}
                  <span className="absolute left-0 right-0 bottom-[-20px] text-[#ef0808c9] text-xs opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                    512 X 768
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <button onClick={generate} className='w-full mt-5 rounded-xl text-xl lg:text-3xl bg-red-600 hover:bg-red-800 py-4 text-center flex items-center gap-5 justify-center'>
          Generate for 1.00
          <img className='w-10' src={goldE} alt="goldE" />
        </button> */}

        <button
          onClick={generate}
          className="w-full h-[80px] mt-5 rounded-xl text-xl lg:text-3xl bg-gradient-to-r from-[#99111f] via-[#d43e44] to-[#ff5c6c] py-4 text-center flex items-center gap-5 justify-center relative overflow-hidden group shadow-xl hover:shadow-2xl transition-all duration-500 ease-out"
        >
          <span className="relative z-10 text-white font-bold">
            Generate for{" "}
            {(1 + (isChecked ? 1 : 0)) * (value ? parseInt(value, 10) : 1) +
              (selectedIndex ? 0.5 : 0)}{" "}
            {selectedIndex ? "" : ".00"}
          </span>
          {/* Glowing Background Effect */}
          <span className="absolute inset-0 bg-gradient-to-r from-[#e63d3d] to-[#ff5c6c] opacity-0 group-hover:opacity-80 animate-glow-background"></span>

          {/* Glowing Icon Animation */}
          <img
            className="w-12 transition-transform duration-300 group-hover:scale-150 group-hover:rotate-0 group-hover:glow-icon"
            src={goldE}
            alt="goldE"
          />

          {/* Glowing Border and Pulse Effect */}
          <span className="absolute inset-0 rounded-xl border-4 border-transparent group-hover:border-[#99111f] opacity-0 group-hover:opacity-100 animate-glow-border"></span>
        </button>
      </div>
    </>
  );
};

export default Simplified;
