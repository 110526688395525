import { Link } from "react-router-dom";
import Header from "../components/Header";
import React, { useState } from 'react';

// Commission Structure Component (same as provided previously)
function CommissionStructure() {
  const [selectedOption, setSelectedOption] = useState('level');

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold mb-6 text-center">Commission Structure Options</h1>

      {/* Toggle Buttons to Select Commission Option */}
      <div className="flex justify-center gap-6 mb-10">
        <button
          onClick={() => setSelectedOption('level')}
          className={`px-4 py-2 rounded-md font-semibold ${
            selectedOption === 'level' ? 'bg-gray-600 text-white' : 'bg-gray-200 text-gray-800'
          }`}
        >
          Level-Based Commission
        </button>
        <button
          onClick={() => setSelectedOption('incremental')}
          className={`px-4 py-2 rounded-md font-semibold ${
            selectedOption === 'incremental' ? 'bg-gray-600 text-white' : 'bg-gray-200 text-gray-800'
          }`}
        >
          Incremental Commission by Referrals
        </button>
      </div>

      {/* Display Commission Structure Based on Selected Option */}
      <div className=" p-6 rounded-md shadow-lg">
        {selectedOption === 'level' ? (
          <LevelBasedCommission />
        ) : (
          <IncrementalCommission />
        )}
      </div>
    </div>
  );
}

function LevelBasedCommission() {
  return (
    <div>
      <h2 className="text-2xl font-semibold mb-4">Level-Based Commission Structure</h2>
      <ul className="space-y-6">
        <li className=" p-4 rounded-md shadow-sm">
          <h3 className="font-bold">Level 1:</h3>
          <p>1st person receives up to <strong>50%</strong> for bringing in the 2nd person.</p>
        </li>
        <li className=" p-4 rounded-md shadow-sm">
          <h3 className="font-bold">Level 2:</h3>
          <p>2nd person receives up to <strong>50%</strong> for bringing in the 3rd person, and 1st person receives up to <strong>10%</strong>.</p>
        </li>
        <li className=" p-4 rounded-md shadow-sm">
          <h3 className="font-bold">Level 3:</h3>
          <p>3rd person receives up to <strong>50%</strong> for bringing in the 4th person, 2nd person receives up to <strong>10%</strong>, and 1st person receives up to <strong>5%</strong>.</p>
        </li>
        <li className=" p-4 rounded-md shadow-sm">
          <h3 className="font-bold">Level 4:</h3>
          <p>4th person receives up to <strong>50%</strong> for bringing in the 5th person, 3rd person receives up to <strong>10%</strong>, 2nd person receives up to <strong>5%</strong>, and 1st person receives up to <strong>2.5%</strong>.</p>
        </li>
      </ul>
      <p className="mt-6 text-gray-600">Note: Commissions are adjustable in the admin panel.</p>
    </div>
  );
}

function IncrementalCommission() {
  return (
    <div>
      <h2 className="text-2xl font-semibold mb-4">Incremental Commission by Referrals</h2>
      <p className="mb-4">
        For each person you personally refer, you receive a <strong>fixed 50%</strong> commission.
      </p>
      <ul className="space-y-6">
        <li className=" p-4 rounded-md shadow-sm">
          <h3 className="font-bold">Initial Commission:</h3>
          <p>All referred individuals (except those brought by you) start at <strong>40%</strong> commission.</p>
        </li>
        <li className=" p-4 rounded-md shadow-sm">
          <h3 className="font-bold">Incremental Increase:</h3>
          <p>For every <strong>100 referrals</strong>, the commission increases by <strong>2%</strong>.</p>
        </li>
        <li className=" p-4 rounded-md shadow-sm">
          <h3 className="font-bold">Decrement on Referral Drop:</h3>
          <p>If referrals drop below <strong>100</strong>, the commission decreases by <strong>2%</strong> until they reach 100 again.</p>
        </li>
      </ul>
      <p className="mt-6 text-gray-600">Commission percentage also applies to token sales.</p>
    </div>
  );
}

function SubscriptionInfo() {
  return (
    <div className="container mx-auto p-10 bg-gray-900 text-white">
      
      {/* Message and AI Companion Availability Note */}
      <div className="mb-10 text-center">
        <p className="text-lg italic">
          "Message and AI companion availability varies based on your subscription plan."
        </p>
      </div>

      {/* AI Companion Retention Section */}
      <div className="bg-gray-800 p-6 rounded-lg mb-10">
        <h2 className="text-2xl font-semibold mb-4">AI Companion Retention</h2>
        <p className="mb-6">Your AI companions will remain active based on your recent interactions with them. The last interaction is considered the date of the last picture created or message sent.</p>
        <div className="space-y-4">
          <div className="bg-gray-700 p-4 rounded-md">
            <h3 className="font-bold">Yearly Plan</h3>
            <p>Your companions are <strong>never deleted</strong>.</p>
          </div>
          <div className="bg-gray-700 p-4 rounded-md">
            <h3 className="font-bold">Monthly Plan</h3>
            <p>Companions are kept for <strong>3 months</strong> after the last interaction.</p>
          </div>
          <div className="bg-gray-700 p-4 rounded-md">
            <h3 className="font-bold">Weekly Plan</h3>
            <p>Companions are kept for <strong>1 month</strong> after the last interaction.</p>
          </div>
        </div>
      </div>

      {/* Message History Retention Section */}
      <div className="bg-gray-800 p-6 rounded-lg">
        <h2 className="text-2xl font-semibold mb-4">Message History Retention</h2>
        <p className="mb-6">Enjoy different levels of chat history based on your subscription plan:</p>
        <div className="space-y-4">
          <div className="bg-gray-700 p-4 rounded-md">
            <h3 className="font-bold">Yearly Plan</h3>
            <p>Your chat history is <strong>always saved</strong>.</p>
          </div>
          <div className="bg-gray-700 p-4 rounded-md">
            <h3 className="font-bold">Monthly Plan</h3>
            <p>Messages are kept for <strong>3 months</strong>.</p>
          </div>
          <div className="bg-gray-700 p-4 rounded-md">
            <h3 className="font-bold">Weekly Plan</h3>
            <p>Messages are kept for <strong>1 month</strong>.</p>
          </div>
        </div>
      </div>
    </div>
  );
}


function PrivacyPolicy() {
  const [selectedPolicy, setSelectedPolicy] = useState(null);

  // List of policies with their identifiers
  const policies = [
    { id: 'terms', title: 'Terms & Conditions' },
    { id: 'underage', title: 'Underage Policy' },
    { id: 'antiSlavery', title: 'Anti-Slavery Policy' }, // New Anti-Slavery Policy
    { id: 'artificialContent', title: 'Content Removal Policy' }, // New Artificial Content Policy
    { id: 'contentManagement', title: 'Content Management and Complaints Policy' }, // New Content Management Policy
    { id: 'chargeback', title: 'Chargeback – Fraud Mitigation Policy' },
    { id: 'Subscription', title: 'Subscrition Plan Policy' },
    { id: 'pricing', title: 'Pricing & Subscrition Policy' },
    { id: 'Referral', title: 'Referral Policy' },
    { id: 'affiliate', title: 'Affiliate Program Policy' },
    { id: 'refund', title: 'Refund & Cancellation Policy' },
  ];

  // Placeholder content for each policy; replace with actual content
  const policyContent = {
    terms: (
      <div>

        <h3 className="text-xl font-semibold mt-6">1. Introduction</h3>
        <p>Welcome to Eromantic-AI. By accessing and using our platform, you agree to comply with and be bound by these Terms & Conditions. Please read them carefully before using our services.</p>

        <h3 className="text-xl font-semibold mt-6">2. Eligibility</h3>
        <p>Our services are intended for users who are 18 years of age or older. By using our platform, you confirm that you meet this age requirement.</p>

        <h3 className="text-xl font-semibold mt-6">3. Account Responsibilities</h3>
        <p>You are responsible for maintaining the confidentiality of your account information, including your password. Any activities under your account are your responsibility. Please notify us immediately of any unauthorized access or security breach.</p>

        <h3 className="text-xl font-semibold mt-6">4. Prohibited Conduct</h3>
        <p>While using our platform, you agree not to:</p>
        <ul className="list-disc ml-6 mt-2">
          <li>Engage in illegal activities or promote unlawful conduct.</li>
          <li>Upload, post, or transmit content that is harmful, offensive, or violates the rights of others.</li>
          <li>Impersonate others or misrepresent your affiliation with any person or entity.</li>
          <li>Attempt to disrupt or interfere with the functionality of our platform.</li>
        </ul>

        <h3 className="text-xl font-semibold mt-6">5. Intellectual Property</h3>
        <p>All content, including text, images, and software on our platform, is owned by Eromantic-AI or licensed to us. You may not reproduce, distribute, or create derivative works from our content without our permission.</p>

        <h3 className="text-xl font-semibold mt-6">6. Content Disclaimer</h3>
        <p>All content on our platform is created by artificial intelligence and does not represent real individuals. We strive for accuracy but do not guarantee that all content will be free from unintended resemblance to real individuals.</p>

        <h3 className="text-xl font-semibold mt-6">7. Termination</h3>
        <p>We reserve the right to terminate or suspend your account and access to our platform at our discretion, with or without notice, if you violate these Terms & Conditions or engage in prohibited conduct.</p>

        <h3 className="text-xl font-semibold mt-6">8. Limitation of Liability</h3>
        <p>To the maximum extent permitted by law, Eromantic-AI shall not be liable for any damages resulting from your use of our platform. This includes, without limitation, any direct, indirect, incidental, or consequential damages.</p>

        <h3 className="text-xl font-semibold mt-6">9. Privacy Policy</h3>
        <p>Your use of our platform is also governed by our Privacy Policy. Please review it to understand how we collect, use, and protect your information.</p>

        <h3 className="text-xl font-semibold mt-6">10. Changes to Terms & Conditions</h3>
        <p>We reserve the right to modify these Terms & Conditions at any time. Any changes will be posted on our website, and continued use of the platform constitutes acceptance of the revised terms.</p>

        <h3 className="text-xl font-semibold mt-6">11. Contact Us</h3>
        <p>If you have any questions about these Terms & Conditions, please contact us at <a href="https://support.eromantic.ai/hc/en-us" className="text-blue-400">support@eromantic.ai</a>.</p>
      </div>
    ),

    underage: (
      <div>

        <h3 className="text-xl font-semibold mt-6">1. Age Requirement</h3>
        <p>Eromantic-AI’s platform is intended for individuals who are 18 years of age or older. By accessing and using our platform, you confirm that you meet this age requirement. We do not knowingly permit individuals under 18 to access our services.</p>

        <h3 className="text-xl font-semibold mt-6">2. Parental Responsibility</h3>
        <p>We urge parents and guardians to be vigilant about online activity. Please monitor children’s internet usage and educate them on safe browsing practices, particularly regarding age-appropriate content.</p>

        <h3 className="text-xl font-semibold mt-6">3. Verification Measures</h3>
        <p>To help enforce our age policy, we may implement age verification processes during account creation or at any time while using the platform. These steps help ensure that only eligible users access our services.</p>

        <h3 className="text-xl font-semibold mt-6">4. Reporting Underage Accounts</h3>
        <p>If you suspect an underage individual is using our platform, please report it to our support team at <a href="https://support.eromantic.ai/hc/en-us" className="text-blue-400">support@eromantic.ai</a>. We will promptly review and take action as needed to comply with our age policy.</p>

        <h3 className="text-xl font-semibold mt-6">5. Account Termination for Underage Users</h3>
        <p>If we determine that an account belongs to an underage user, we reserve the right to suspend or terminate the account immediately. This action aligns with our commitment to maintaining a secure and compliant environment.</p>

        <h3 className="text-xl font-semibold mt-6">6. Policy Updates</h3>
        <p>We may update this Underage Policy as needed to ensure it remains current and effective. Any changes will be posted on our website and become effective immediately.</p>

        <h3 className="text-xl font-semibold mt-6">7. Contact Us</h3>
        <p>If you have any questions or require further clarification about our Underage Policy, please contact us at <a href="https://support.eromantic.ai/hc/en-us" className="text-blue-400">support@eromantic.ai</a>.</p>
      </div>
    ),
    
    blocked: 'Blocked Content Policy content goes here...',
    antiSlavery: (
      <div>

        <h3 className="text-xl font-semibold mt-6">Our Stance Against Modern Slavery</h3>
        <p>Eromantic-AI maintains a strict policy against any form of modern slavery, human trafficking, sex trafficking, or physical abuse. We are dedicated to upholding human rights, dignity, and equality, both within our company and throughout our business practices.</p>

        <h3 className="text-xl font-semibold mt-6">Supply Chain Responsibility</h3>
        <p>We carefully select our suppliers, partners, and third parties to ensure they share our commitment to eradicating modern slavery. We expect everyone in our supply chain to adhere to all relevant laws and regulations regarding slavery and human trafficking.</p>

        <h3 className="text-xl font-semibold mt-6">Fair Employment Practices</h3>
        <p>We provide fair and ethical working conditions for all our employees. We have zero tolerance for forced labor, child labor, or any exploitative practices. Our hiring and employment policies strictly adhere to legal standards, including minimum age requirements and fair wages.</p>

        <h3 className="text-xl font-semibold mt-6">Training and Awareness</h3>
        <p>We provide comprehensive training and awareness programs to educate our employees, suppliers, and partners about the risks and consequences of modern slavery. These programs foster a culture of vigilance and responsibility in identifying and reporting any signs of modern slavery or trafficking.</p>

        <h3 className="text-xl font-semibold mt-6">Confidential Reporting</h3>
        <p>We encourage all employees, suppliers, and stakeholders to report any concerns or suspicions related to modern slavery, human trafficking, or physical abuse. We have a confidential reporting mechanism in place to facilitate this process.</p>

        <h3 className="text-xl font-semibold mt-6">Compliance and Monitoring</h3>
        <p>Eromantic-AI is committed to regularly monitoring and evaluating our compliance with this Anti-Slavery Policy. We continuously review our practices, policies, and procedures to identify and address any potential risks and areas for improvement.</p>

        <h3 className="text-xl font-semibold mt-6">Remediation and Accountability</h3>
        <p>If any breach or non-compliance with this policy occurs, we will take immediate and appropriate corrective action. We hold ourselves accountable for upholding the principles outlined in this policy, and we expect the same level of accountability from all stakeholders associated with our operations.</p>

        <h3 className="text-xl font-semibold mt-6">Collaboration and Advocacy</h3>
        <p>We actively participate in collaborations and initiatives to combat modern slavery and human trafficking on a wider scale. We advocate for the eradication of these practices and support efforts to protect the rights and dignity of all individuals.</p>

        <h3 className="text-xl font-semibold mt-6">Policy Updates</h3>
        <p>We reserve the right to update or modify this Anti-Slavery Policy as needed. Any changes will be effective immediately upon posting the updated policy on our website.</p>

        <h3 className="text-xl font-semibold mt-6">Contact Us</h3>
        <p>If you have any questions or need further clarification about our Anti-Slavery Policy, please contact us at <a href="https://support.eromantic.ai/hc/en-us" className="text-blue-400">support@eromantic.ai</a>. We are dedicated to playing our part in the fight against modern slavery and promoting a safe and responsible business environment.</p>
      </div>),
    artificialContent: (
      <div>
        <h2 className="text-2xl font-semibold mb-4">Artificial Nature of Content and Content Removal Policy</h2>
        
        <h3 className="text-xl font-semibold mt-6">Artificial Nature of Content</h3>
        <p>All content on Eromantic-AI is created using artificial intelligence. Our platform lets you interact with AI-generated companions. We want to be clear: none of the content on our platform represents real people.</p>
        
        <h3 className="text-xl font-semibold mt-6">Unintentional Resemblance</h3>
        <p>While our AI-generated content isn't meant to look like anyone specific, we understand there might be accidental similarities. If you feel any content resembles you or someone you know, we're here to help.</p>
        
        <h3 className="text-xl font-semibold mt-6">Requesting Content Removal</h3>
        <p>If you think any content looks too much like a real person, contact our support team at <a href="https://support.eromantic.ai/hc/en-us" className="text-blue-400">support@eromantic.ai</a>. We'll review your request and take appropriate action in a reasonable timeframe.</p>

        <h3 className="text-xl font-semibold mt-6">Verification</h3>
        <p>To make sure content removal requests are genuine, we might ask you to prove your identity or your connection to the person you think the content resembles. This helps us handle requests responsibly and protect everyone's rights.</p>

        <h3 className="text-xl font-semibold mt-6">Prompt Removal</h3>
        <p>Once we verify your request, we'll quickly remove the content from our platform. We aim to do this efficiently while following all applicable laws.</p>

        <h3 className="text-xl font-semibold mt-6">Your Privacy Matters</h3>
        <p>We handle all requests with care and keep your information confidential. We won't share your personal details or request specifics with others unless you give us permission or the law requires it.</p>

        <h3 className="text-xl font-semibold mt-6">Policy Updates</h3>
        <p>We may update this policy from time to time. Any changes will be posted on our website and take effect immediately.</p>

        <h3 className="text-xl font-semibold mt-6">Contact Us</h3>
        <p>If you have any questions or need more information, please contact our support team at <a href="https://support.eromantic.ai/hc/en-us" className="text-blue-400">support@eromantic.ai</a>. We're committed to addressing your concerns and ensuring a positive experience on our platform.</p>
      </div>
    ),
    contentManagement: (
      <div>
        
        <h3 className="text-xl font-semibold mt-6">1. Content Creation Within Defined Boundaries</h3>
        <p>Eromantic-AI operates with a focus on user safety and content quality. All content generation occurs within predefined categories that have been meticulously reviewed by our team. Users cannot upload their own content, ensuring everything aligns with our guidelines.</p>
        
        <h3 className="text-xl font-semibold mt-6">2. Thorough Content Review</h3>
        <p>Our experts carefully examine each category before it's available, evaluating its suitability, legality, and compliance with our standards. We prioritize providing you with high-quality, relevant, and safe content.</p>
        
        <h3 className="text-xl font-semibold mt-6">3. User Responsibility & Reporting</h3>
        <p>We expect users to follow our guidelines when creating content. If you encounter any content that seems inappropriate, please report it to our support team for immediate action.</p>

        <h3 className="text-xl font-semibold mt-6">4. Policy Updates</h3>
        <p>We may update this policy as needed. Any changes take effect immediately upon posting on our website.</p>

        <h3 className="text-xl font-semibold mt-6">5. Addressing Your Concerns: Complaints Procedure</h3>
        <p>At Eromantic-AI, we value your experience. If you have any concerns about our services, we're here to help:</p>
        <ul className="list-disc ml-6 mt-2">
          <li><strong>Report It:</strong> Contact our support team at <a href="https://support.eromantic.ai/hc/en-us" className="text-blue-400">support@eromantic.ai</a> with details about your concern. We'll respond promptly.</li>
          <li><strong>We Investigate:</strong> Our team will review your complaint and keep you informed of the progress.</li>
          <li><strong>Resolution:</strong> We'll work to resolve your concern quickly and fairly.</li>
          <li><strong>Appeal:</strong> If you're not satisfied with the resolution, you can request an appeal. Your case will be reviewed independently.</li>
          <li><strong>Need More Help?</strong> Our support team is always ready to assist you.</li>
        </ul>

        <h3 className="text-xl font-semibold mt-6">6. Changes to the Complaints Procedure</h3>
        <p>We may update this procedure. Changes are effective upon posting on our website.</p>

        <h3 className="text-xl font-semibold mt-6">7. Get in Touch</h3>
        <p>If you have questions about our content management or complaints process, contact us at <a href="https://support.eromantic.ai/hc/en-us" className="text-blue-400">support@eromantic.ai</a>. We're dedicated to providing a secure and compliant environment for our users.</p>
      </div>
    ),
    chargeback: (
      <div>
        <p>At Eromantic-AI, we're dedicated to providing a safe and secure platform for all our users. Here's how we combat fraud and handle chargebacks:</p>
        
        <h3 className="text-xl font-semibold mt-6">Proactive Prevention</h3>
        <ul className="list-disc ml-6 mt-2">
          <li><strong>Strong Authentication:</strong> We use thorough identity checks during signup and payments to keep your account safe.</li>
          <li><strong>Top-Tier Security:</strong> Your data and payments are protected by cutting-edge security and encryption.</li>
          <li><strong>Active Monitoring:</strong> We constantly monitor for suspicious activity to stop fraud before it happens.</li>
        </ul>

        <h3 className="text-xl font-semibold mt-6">Chargebacks: How We Handle Them</h3>
        <p>If you initiate a chargeback:</p>
        <ul className="list-disc ml-6 mt-2">
          <li><strong>We Investigate:</strong> Our team will thoroughly examine the situation, looking at transaction records, your account activity, and any evidence provided.</li>
          <li><strong>We'll Reach Out:</strong> We'll contact you promptly to get more details and understand your reasons for the chargeback.</li>
          <li><strong>We Find a Solution:</strong> Based on our findings, we'll take the best course of action, which might include disputing the chargeback, providing evidence to your bank, or issuing a refund if necessary.</li>
          <li><strong>Fees for Fraudulent Chargebacks:</strong> If a chargeback is proven to be false or made in bad faith, you may be responsible for any fees we incur.</li>
        </ul>

        <h3 className="text-xl font-semibold mt-6">Zero Tolerance for Fraud</h3>
        <p>We have a strict policy against any fraudulent activity. If we detect actions like chargeback abuse, identity theft, or misuse of payment information, we'll take swift action. This could include suspending or closing your account, and we might also report the activity to authorities.</p>

        <h3 className="text-xl font-semibold mt-6">Your Part in Keeping Things Secure</h3>
        <ul className="list-disc ml-6 mt-2">
          <li><strong>Accurate Information:</strong> Please provide us with correct and up-to-date details.</li>
          <li><strong>Follow the Rules:</strong> Use our platform in a way that's consistent with our terms and the law.</li>
          <li><strong>Report Suspicious Activity:</strong> If you see anything that seems off, let our support team know right away.</li>
        </ul>

        <h3 className="text-xl font-semibold mt-6">Policy Updates</h3>
        <p>We may update this policy as needed. Any changes will take effect as soon as the updated policy is posted on our website.</p>

        <h3 className="text-xl font-semibold mt-6">Need Help?</h3>
        <p>If you have any questions or need more information about our chargeback and fraud prevention measures, our support team is here to help. Contact us at <a href="https://support.eromantic.ai/hc/en-us" className="text-blue-400">support@eromantic.ai</a>.</p>

        <p className="mt-6">We appreciate your trust in Eromantic-AI. We're dedicated to maintaining a safe and secure platform for everyone.</p>
      </div>
    ),
    Subscription: <SubscriptionInfo />,
    pricing: (
      <div>
        <ol className="list-decimal ml-6 space-y-4 text-lg">
          <li>
            <strong>Membership Plans and Pricing</strong>
            <p>Eromantic-AI offers the following base membership plans with a discounted price for the first billing cycle:</p>
            <ul className="list-disc ml-6 mt-2">
              <li>Monthly Plan: $11.99 (40%) for the first month, then $19.99 per month thereafter</li>
              <li>3 Month Plan: $7.99 (55%) per month billed as $23.99, then $54.99 per 3-month period</li>
              <li>Annual Plan: $4.99 (70%) per month for the first year, billed as $59.99, then $199.99 annually</li>
            </ul>
            <p className="mt-2">These memberships provide access to features such as AI companions, image generation, messaging, credits, and more, as outlined in our membership benefits documentation.</p>
          </li>
          <li>
            <strong>Token System</strong>
            <p>Users acquire Tokens to access various website features. Token costs for each feature are:</p>
            <ul className="list-disc ml-6 mt-2">
              <li>Messages: Unlimited</li>
              <li>Girlfriend/Boyfriend Creation: 5 Tokens</li>
              <li>Image Generation: 2 Tokens</li>
              <li>Upscale Image: 1 Token</li>
              <li>Voice Call: 2 Tokens/Minute</li>
              <li>Voice Message: 0.5 Tokens</li>
              <li>Chat Customization: 0.2 Tokens</li>
              <li>Message Edit: 0.1 Tokens</li>
            </ul>
          </li>
          <li>
            <strong>Membership Duration</strong>
            <p>Memberships are billed per period. Users may also purchase memberships for longer durations, such as our annual plan, at a discounted rate. Refunds are not available for the 12-month plan if utilized during the initial period.</p>
          </li>
          
          <li>
            <strong>Vouchers and Discounts</strong>
            <p>Vouchers can be applied to receive discounts on the initial subscription period. Note that these discounts are limited to the first period, with subsequent billing reverting to the standard plan price.</p>
          </li>
          <li>
            <strong>Changes to Plans</strong>
            <p>We reserve the right to modify membership plans, pricing, and features at any time. Any changes will be communicated before the next billing cycle.</p>
          </li>
          <li>
            <strong>Contact Information</strong>
            <p>For questions or assistance, contact our support team at <a href="https://support.eromantic.ai/hc/en-us" className="text-blue-400">support@eromantic.ai</a>.</p>
          </li>
        </ol>
        <p className="mt-6">By subscribing to our membership plans, you acknowledge that you have read, understood, and agreed to abide by this Membership/Subscription Policy.</p>
      </div>
    ),
    Referral: <CommissionStructure />,
    affiliate: (
      <div>
      
        <ol className="list-decimal ml-6 space-y-4 text-lg">
          <li>
            <strong>Joining the Team</strong>
            <p>Who's Eligible? To become an affiliate, you need an active online presence like a website, blog, or social media following.</p>
            <p>How to Apply: Contact us at <a href="https://support.eromantic.ai/hc/en-us" className="text-blue-400">support@eromantic.ai</a>. We’ll review your application and get back to you.</p>
          </li>
          <li>
            <strong>Earning Commissions</strong>
            <p>Earn up to 50% (starting at 30%) recurring commission on every sale made through your unique affiliate links.</p>
            <p>Additionally, earn up to 10% (starting at 5%) commission on sub-affiliate commissions by referring new affiliates.</p>
            <p>Minimum Payout: Commissions are paid once you've earned at least $150.</p>
            <p>Tracking: We use software to track sales and commissions. Ensure your links are set up correctly!</p>
          </li>
          <li>
            <strong>Promoting Responsibly</strong>
            <p>Be Honest: Represent our products accurately and ethically.</p>
            <p>Follow the Rules: Comply with all relevant laws and regulations (including marketing, advertising, and data privacy).</p>
            <p>Brand Guidelines: Stick to our brand guidelines when using our logos or other materials.</p>
            <p>Prohibited Activities:</p>
            <ul className="list-disc ml-6">
              <li>No spam or unwanted emails.</li>
              <li>No misleading or deceptive advertising.</li>
              <li>No false claims about our products.</li>
              <li>No unethical or shady practices.</li>
              <li>No pop-ups or unauthorized promotions.</li>
              <li>No bidding on our brand name or variations in search engine ads.</li>
            </ul>
          </li>
          <li>
            <strong>Ending the Partnership</strong>
            <p>We reserve the right to terminate your participation at any time, with or without notice.</p>
            <p>You may also leave the program at any time, after which you won’t earn further commissions.</p>
          </li>
          <li>
            <strong>Keeping Things Confidential</strong>
            <p>Keep all information we share (commission rates, customer data, etc.) confidential.</p>
          </li>
          <li>
            <strong>Program Changes</strong>
            <p>We may change the program, its terms, or commission rates at any time, with or without notice.</p>
          </li>
          <li>
            <strong>Your Responsibility</strong>
            <p>You participate in the program at your own risk. We are not responsible for any losses or damages you may experience.</p>
          </li>
          <li>
            <strong>Legal Stuff</strong>
            <p>This agreement is governed by the laws of Florida.</p>
          </li>
          <li>
            <strong>The Whole Story</strong>
            <p>This policy is the complete agreement between you and Eromantic-AI regarding the affiliate program, overriding any prior agreements.</p>
          </li>
          <li>
            <strong>Get in Touch</strong>
            <p>If you have questions, contact us at <a href="https://support.eromantic.ai/hc/en-us" className="text-blue-400">support@eromantic.ai</a></p>
          </li>
        </ol>
      </div>
    ),
    refund: (<div> <li>
      <strong>Cancellation</strong>
      <p>Users can cancel memberships at any time. After cancellation, the membership remains active until the current billing cycle ends, with no further charges applied.</p>
    </li>
    <li>
      <strong>Refund Policy</strong>
      <p>Refunds are not offered for any portion of the membership fee if any of the following services have been used during the membership:</p>
      <ul className="list-disc ml-6 mt-2">
        <li>AI companions</li>
        <li>Generated photos</li>
        <li>Sent messages</li>
        <li>Tokens or credits consumption</li>
      </ul>
    </li></div>),
  };




  

  return (
    <div className="min-h-screen text-white">
      <div className="container mx-auto p-10">
        <h1 className="text-center text-[30px] mb-10">Our Uses Policies for Legal Information  </h1>
        
        {/* Policy Links */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mb-10">
          {policies.map((policy) => (
            <button
              key={policy.id}
              className={`text-left w-full p-3 rounded-md bg-gray-800 hover:bg-gray-600 ${
                selectedPolicy === policy.id ? 'bg-red-600' : ''
              }`}
              onClick={() => setSelectedPolicy(policy.id)}
            >
              {policy.title}
            </button>
          ))}
        </div>

        {/* Display Selected Policy Content */}
        <div className="bg-gray-800 p-6 rounded-md">
          {selectedPolicy ? (
            <div>
              <h2 className="text-2xl font-semibold mb-4">{policies.find(p => p.id === selectedPolicy).title}</h2>
              <p className="text-[18px]">{policyContent[selectedPolicy]}</p>
            </div>
          ) : (
            <p className="text-[18px]">Select a policy to view its content.</p>
          )}
        </div>

        <div className="text-center text-black mt-5"><h1 className="text-[25px] font-bold">Contact US</h1>
       <strong>If you have any questions about these Terms, please contact us at  <Link className="text-blue">support@eromantic.ai</Link></strong></div>

        {/* Footer Links */}
        <div className="max-w-full mt-10 border-t border-white pt-5">
          <div className="flex justify-between gap-4 text-white pb-5">
            <Link to="/blogs" className="text-[14px] lg:text-lg">
              Blogs
            </Link>
            <Link to="/terms-of-service" className="text-[14px] lg:text-lg">
              Terms of Service
            </Link>
            <Link to="/privacy-policy" className="text-[14px] lg:text-lg">
              Privacy Policy
            </Link>
            <Link to="/cookie-policy" className="text-[14px] lg:text-lg">
              Cookie Policy
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;

