import React, { useState } from 'react';
import { support } from '../../../assets';
import { CgSearch } from 'react-icons/cg';
import { useNavigate } from 'react-router-dom';
import Footer from '../../../frontend/components/Footer';

const Support = () => {
    const navigate = useNavigate();
    const [activeButton, setActiveButton] = useState('');

    const buttonsData = [
        { name: 'GETTING STARTED', path: '/dashboard/SupportCategory' },
        { name: 'SUBSCRIPTION', path: '' },
        { name: 'CONTENT', path: '' },
        { name: 'ACCOUNT', path: '' },
        { name: 'BUGS', path: '' },
    ];

    const handleButtonClick = (path, name) => {
        if (path) {
            setActiveButton(name);
            navigate(path);
        }
    };

    return (
        <>
            <div className="mt-20 px-2 lg:px-20 pb-36 pt-10 text-black">
                <div className='bg-red-800 px-10 2xl:px-36 flex flex-wrap justify-center xl:justify-between items-end rounded-3xl'>
                    <div className='pt-10 md:pt-14 pb-5 xl:pb-24'>
                        <p className='font-semibold md:text-[23px]'>Help Center</p>
                        <p className='font-extrabold text-xl md:text-[33px] mt-2 lg:mt-4'>Find solutions fast.</p>
                        <p className='font-normal md:text-[26px] mt-2 lg:mt-4'>Search hundreds of articles on EROMANTIC.AI Help</p>
                        <div class='bg-white md:py-5 md:ps-14 md:pr-7 p-5 flex justify-between items-center md:w-[640px] rounded-full mt-3 lg:mt-7'>
                            <input type="text" placeholder='Search the Community' class='w-full focus:outline-none text-xl md:text-4xl        md:font-light font-normal text-black placeholder-red-800 font-josefin-sans' />
                            <CgSearch class='text-red-800' size={35} />
                        </div>
                    </div>
                    <div>
                        <img src={support} alt="support" />
                    </div>
                </div>
                {/* <div className='pt-14'>
                    <p className='font-bold text-4xl text-center'>Categories</p>
                    <div className='flex flex-wrap justify-center pt-5 md:pt-16'>
                        {buttonsData.map((item, index) => {
                            const isActive = activeButton === item.name;
                            return (
                                <button
                                    key={index}
                                    onClick={() => handleButtonClick(item.path, item.name)}
                                    className={` md:text-xl font-semibold rounded-full w-52 md:w-[300px] 2xl:w-[390px] h-12 md:h-20 m-2 lg:m-6 ${isActive ? 'bg-red-800 text-black' : 'bg-black text-white hover:bg-red-800 hover:text-black'}`}
                                >
                                    {item.name}
                                </button>
                            );
                        })}
                    </div>
                </div> */}

                <div className="container mx-auto px-6 py-12">
                    <div className='flex justify-center w-full mt-7'>
                        <button
                            className="bg-red-800 text-white font-medium rounded-2xl p-2 sm:p-4 lg:p-4 w-[35%] lg:w-[25%]"
                            onClick={() => window.open('https://support.eromantic.ai/hc/en-us', '_blank')}
                        >
                            Need Help
                        </button>
                    </div>
                    <h1 className="text-3xl font-semibold text-center my-8">Support Center</h1>
                    {/* <p className="text-center text-lg mb-12">
                For questions or assistance, contact our support team at 
                <a href="https://support.eromantic.ai/hc/en-us" className="text-blue-500 ml-1">
                    support@eromantic.ai
                </a>.
            </p> */}


                    {/* Subscription Support */}
                    <div className="mb-8">
                        <h2 className="text-2xl font-semibold mb-2">Subscription Support</h2>
                        <p className="text-lg mb-4">
                            For assistance with managing your subscription, upgrading or downgrading plans, or any other subscription-related inquiries, please review our Membership Policy. If you need further help, feel free to reach out.
                        </p>
                    </div>

                    {/* Account Support */}
                    <div className="mb-8">
                        <h2 className="text-2xl font-semibold mb-2">Account Support</h2>
                        <p className="text-lg mb-4">
                            If you are experiencing issues with account login, password recovery, or need help updating your account information, our support team is here to assist. Please ensure your account information is up to date for smoother assistance.
                        </p>
                    </div>

                    {/* Policy Information */}
                    <div className="mb-8">
                        <h2 className="text-2xl font-semibold mb-2">Policy Information</h2>
                        <p className="text-lg mb-4">
                            For information on our terms of service, privacy policy, cookies policy, and any other policies, please visit our Policies Page. Our policies are designed to provide a safe and transparent experience for all users.
                        </p>
                    </div>

                    {/* Refund Queries */}
                    <div className="mb-8">
                        <h2 className="text-2xl font-semibold mb-2">Refund Queries</h2>
                        <p className="text-lg mb-4">
                            If you are eligible for a refund or have questions about our refund policy, please review our Refund Policy. For specific questions, contact our support team, and we will be happy to assist you with the refund process.
                        </p>
                    </div>

                    {/* Offers and Promotions */}
                    <div className="mb-8">
                        <h2 className="text-2xl font-semibold mb-2">Offers and Promotions</h2>
                        <p className="text-lg mb-4">
                            If you have questions regarding available offers, promotional discounts, or any special deals, please reach out to our support team for more information on how to redeem or use them.
                        </p>
                    </div>

                    {/* Contact Support */}
                    <div className="text-center mt-12">
                        <p className="text-lg font-medium">
                            For questions or assistance, contact our support team at
                            <a href="https://support.eromantic.ai/hc/en-us" className="text-blue-500 ml-1">
                                support@eromantic.ai
                            </a>.
                        </p>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Support;
