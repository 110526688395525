import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { remaneUserProfile, tableUser1 } from '../../../assets'
import PagenameSearchBox from '../../components/PagenameSearchBox'
import { useSelector } from 'react-redux'
import { BaseApiUrl } from '../../../utilities/enviroment'

const UserProfile = () => {
    const navigate = useNavigate()
    const [activeTab, setActiveTab] = useState(1);
    const user = useSelector((state)=>state.user.user)

    // Handle tab click to change the active tab
    const handleTabClick = (tabNumber) => {
        setActiveTab(tabNumber);
    };

    // Static user details (replace with actual data or API data)
    const userDetails = useSelector(state => state.user.user);
    //  {
    //     id: 1,
    //     username: 'JohnDoe',
    //     age: 30,
    //     address: '123 Main St, City, Country',
    //     balance: 100.50,
    //     referrals: [
    //         { id: 101, name: 'JohnDoe', email: 'user1@example.com', affiliate_id: 'ABC123', ref_commission: 10, ref_commission_raise: 5, token_commission: 100, token_commission_raise: 50, withdraw: 100, balance: 300},
    //         { id: 102, name: 'JohnDoe', email: 'user2@example.com', affiliate_id: 'XYZ456', ref_commission: 20, ref_commission_raise: 10, token_commission: 200, token_commission_raise: 100, withdraw: 500, balance: 600},
    //         { id: 103, name: 'JaneDoe', email: 'user3@example.com', affiliate_id: 'LMN789', ref_commission: 15, ref_commission_raise: 8, token_commission: 150, token_commission_raise: 75, withdraw: 200, balance: 50},
    //     ]
    // };

    // Function to handle navigation back to the user list
    const handleBlog = () => {
        navigate('/admin/allUser')
    }

    return (
        <>
            <div className='h-full  px-7 pb-20 font-josefin-sans'>
                <PagenameSearchBox title='User Profile' />

                {/* User Profile Section */}
                <div className='rounded-lg bg-red-800 w-full pb-16 mt-12'>
                    <div className='flex justify-end pt-7 pr-7'>
                        <img src={remaneUserProfile} onClick={()=>navigate('/admin/generalPage')} alt="rename" className='w-8 h-8' />
                    </div>
                    <div className='flex items-center px-5 xl:px-16 pb-8 gap-14 border-b border-white'>
                        <div>
                            <img src={BaseApiUrl + user.profile_pic || ''} alt="user1" className='w-40 h-40 rounded-full' />
                        </div>
                        <div>
                            <p className='text-white text-4xl font-bold'>{userDetails.first_name||''} {userDetails.last_name||''}</p>
                            <p className='text-white text-3xl font-normal pt-[10px]'>@{userDetails.username}</p>
                        </div>
                    </div>
                </div>
                <div className='rounded-lg bg-[#838383] w-full pb-16 mt-12'>
                    {/* User Information Section */}
                    <div className="mt-10">
                        <div className="-100 p-6 rounded-lg">
                            <h3 className="text-lg font-semibold text-black">User Information</h3>
                            <div className="mt-4">
                                <p><strong>User ID:</strong> <span className="text-blue-600">{userDetails.id}</span></p>
                                <p><strong>Address:</strong> {userDetails.address || 'N/A'}</p>
                                <p><strong>Created At:</strong> {userDetails.date_joined || 'N/A'}</p>
                                <p><strong>Number:</strong> {userDetails.number || 'N/A'}</p>
                                <p><strong>Address:</strong> {userDetails.address || 'N/A'}</p>
                                <p><strong>Zip Code:</strong> {userDetails.zip_code || 'N/A'}</p>
                                <p><strong>State:</strong> {userDetails.state || 'N/A'}</p>
                                <p><strong>Country:</strong> {userDetails.country || 'N/A'}</p>
                                <p><strong>Timezone:</strong> {userDetails.timezone || 'N/A'}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UserProfile
