import React, { useEffect, useRef, useState } from 'react'
import { accountProfileImage } from '../../../assets'
import { useSelect } from '@material-tailwind/react'

import InputBox from '../../../admin/components/InputBox';
import { useCountries } from "use-react-countries";
import timezoneOptions from "timezones-list";
import "react-phone-input-2/lib/bootstrap.css";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { BaseApiUrl } from '../../../utilities/enviroment';
import UserApiClient from '../../../utilities/apiClients';
import { loginSuccess } from '../../../redux/actions';

const AccountProfile = () => {

  const [subData, setSubData] = useState({});
  const [InvoicData, setInvoicData] = useState([]);
  const [activeTab, setActiveTab] = useState(1);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    photo: null,
  });
  const user = useSelector(state => state.user.user)
  const dateString = user.date_joined;
  const date = new Date(dateString);
  const [userData, setuserData] = useState({
    'first_name': user.first_name,
    'last_name': user.last_name,
    'email': user.email,
    'number': user.number,
    'state': user.state,
    'address': user.address,
    'zip_code': user.zip_code
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { countries } = useCountries();
  const [imageSrc, setImageSrc] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);

  const dropdownData = [
    {
      label: 'Country',
      name: 'Select Your Country',
      options: countries
    }, {
      label: 'Timezone',
      name: 'Select Timezone',
      options: timezoneOptions
    },
  ]

  const [openIndex, setOpenIndex] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState({
    "country": user.country, "timezone": user.timezone
  });

  const toggleDropdown = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const handleOptionClick = (menuIndex, option) => {
    const menu = dropdownData[menuIndex]; // Get the current menu
    setSelectedOptions({
      ...selectedOptions,
      [menu.label.toLowerCase()]: option.name, // Use the label as the key (in lowercase)
    });
    setOpenIndex(null);
  };

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageSrc(reader.result);
      };
      reader.readAsDataURL(file);

      setuserData((prevState) => ({
        ...prevState,
        profile_pic: file,
      }));
      setSelectedFile(file);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setuserData({
      ...userData,
      [name]: value
    });
  };
  const handleReset = (e) => {
    setuserData(user);
    setSelectedOptions({
      "country": user.country, "timezone": user.timezone
    })
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!userData.first_name || !userData.last_name || !userData.number || !userData.state || !userData.address || !selectedOptions.country || !userData.zip_code || !selectedOptions.timezone) {
      Swal.fire({ title: '', text: 'Please fill all the fields', timer: 2000, icon: 'warning' })
      return;
    }
    const formData = new FormData();
    for (const key in userData) {
      if (key === "profile_pic" && !selectedFile) {
        continue;
      }
      formData.append(key, userData[key]);
    }
    for (const keyOptions in selectedOptions) {
      formData.append(keyOptions, selectedOptions[keyOptions]);
    }
    UserApiClient.patch(`api/v1/user/update/${user.id}/`, formData, true).then(res => {
      if (res.success) {
        Swal.fire({ title: '', text: 'Profile updated.', timer: 3000, icon: 'success' })
        dispatch(loginSuccess({ ...user, ...res.data }))
        window.location.reload();
      }
    })
  };

  useEffect(() => {
    setImageSrc((user.profile_pic.includes('http') ? '' : BaseApiUrl) + user.profile_pic);
  }, [user, userData])


  // Format the date to show only the date (no time)
  const formattedDate = date.toLocaleDateString(); // e.g., "11/25/2024"

  // Or if you want a specific format, you can pass options:
  const formattedDateCustom = date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  const email = user.email;
  const email_username = email.split('@')[0];


  const fetchSubData = async () => {
    await UserApiClient.get('api/v1/get_token/', {}, true).then(async res => {
      if (res.success) {
        setSubData(res.data);
      }
    })
  }

  const fetchInvoiceData = async () => {
    await UserApiClient.get('api/v1/user/invoices/', {}, true).then(async res => {
      if (res.success) {
        setInvoicData(res.data);
      }
    })
  }

  useEffect(() => {
    fetchSubData();
    fetchInvoiceData();
  }, [])

  return (
    <>
      <div className='mt-20 flex flex-col h-[1200px] font-josefin-sans '>
        <div className='bg-red-800 h-[45vh]'>
          <div className='flex items-center p-[30px]'>
            <img src={user.profile_pic?.includes('http') ? user.profile_pic : BaseApiUrl + user.profile_pic} alt="accountProfileImage" className='w-32 h-w-32 md:w-44 md:h-44 lg:w-[285px] lg:h-[285px] xl:w-72 xl:h-72 2xl:w-[300px] 2xl:h-[300px] rounded-full' />
            <div className='flex items-start flex-col ml-20'>
              <p className=' text-3xl mb-5 font-bold text-white text-center'>Profile Info</p>
              <p className='text-xl text-white font-bold flex justify-center items-center  capitalize'>{user.username ? user.username : email_username}</p>
              <p className='text-md text-white font-bold flex justify-center items-center mt-2 '>{user.email}</p>
              <p className='text-base text-white lg:text-xl font-bold text-left'>Joined At : {formattedDate}</p>
            </div>
          </div>

          {/* <div className='flex justify-center'>
            <div className='bg-white rounded-2xl lg:w-[800px] xl:w-[1200px] h-[345px] lg:h-[398px] xl:h-[438px] lg:mt-48 mt-32 z-10 relative'>
              <div className='px-3 md:px-10 py-5 xl:py-12'>
                <div className='flex justify-between w-full'>
                  
                  <p className=' lg:text-xl text-right font-bold w-[20%] xl:w-[38%]'>Edit Profile</p>
                </div>
                
                <div className='flex justify-center items-center gap-5 md:gap-20 mt-3 xl:mt-10'>
                  <p className=' lg:text-xl font-bold '>0 Your Generations</p>
                  <p className=' lg:text-xl font-bold '>0 Likes</p>
                  <p className=' lg:text-xl font-bold '>0 Followers</p>
                </div>
              </div>
              <div className='mt-4 xl:mt-0 flex border border-red-800 justify-between rounded-bl-xl rounded-br-xl'>
                <button className='bg-red-800 w-full md:text-2xl h-16 rounded-bl-xl text-center text-white'>
                  Public Generation
                </button>
                <button className='bg-white w-full md:text-2xl rounded-br-xl text-center text-red-800'>
                  Most Liked
                </button>
              </div>
            </div>
          </div> */}
          <div className="flex space-x-4 pb-2">
            <button
              onClick={() => handleTabClick(1)}
              className={`px-4 py-2 mx-2 w-[20%] font-semibold text-lg rounded-lg transition-colors duration-300 ${activeTab === 1 ? 'bg-[#000] text-white' : 'bg-gray-200 text-gray-600'
                }`}
            >
              Profile Setting
            </button>
            <button
              onClick={() => handleTabClick(2)}
              className={`px-4 py-2 mx-2 w-[20%] font-semibold text-lg rounded-lg transition-colors duration-300 ${activeTab === 2 ? 'bg-[#000] text-white' : 'bg-gray-200 text-gray-600'
                }`}
            >
              Subscription
            </button>
            <button
              onClick={() => handleTabClick(4)}
              className={`px-4 py-2 mx-2 w-[20%] font-semibold text-lg rounded-lg transition-colors duration-300 ${activeTab === 4 ? 'bg-[#000] text-white' : 'bg-gray-200 text-gray-600'
                }`}
            >
              Payment History
            </button>
          </div>
        </div>
        {/* <div className='bg-black h-[55vh]'>

                </div> */}

        {activeTab === 1 && (
          <div className="p-5 justify-center items-center bg-white mt-1">
            <div className='mt-10 mb-5'>
              <label className="text-zinc-500 font-semibold  text-[22px]">Upload Image</label>
              <img
                src={imageSrc}
                className="w-[100px] h-[100px] rounded-full object-cover"
                alt="User Avatar"
                onClick={handleImageClick}
              />
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                accept="image/*"
                name="profile_pic"
                onChange={handleFileChange}
              />
            </div>
            <div className='w-full flex justify-between font-josefin-sans'>
              <div className='w-[48%]'>

                {/* <input type="file" accept="image/*" className="mt-3 mb-6 w-full text-sm text-zinc-500 border border-zinc-300 rounded-lg py-3.5 px-3" value={userData.profile_pic} onChange={handleChange} name='profile_pic' /> */}
                <InputBox placholderName='First Name' title='First Name' textType='text' value={userData.first_name} onChange={handleChange} name='first_name' maxlength='255' />
                {/* <InputBox placholderName='Your E-mail' title='E-mail' textType='email' value={userData.email} onChange={handleChange} name='email' maxlength='255' readOnly /> */}
                <InputBox placholderName='Your Phone Number' title='Phone Number' textType='number' value={userData.number} onChange={handleChange} name='number' min={1000000000} max={9999999999} />
                <InputBox placholderName='Your State' title='State' textType='text' value={userData.state} onChange={handleChange} name='state' maxlength='255' />
                <InputBox placholderName='12456' title='Zip Code' textType='number' value={userData.zip_code} onChange={handleChange} name='zip_code' min={100000} max={999999} />
                {/* <label className='text-zinc-500 font-semibold text-[22px]'>Language</label>
                   <AddTags /> */}

              </div>
              <div className='w-[48%]'>
                <InputBox placholderName='Last Name' title='Last Name' textType='text' value={userData.last_name} onChange={handleChange} name='last_name' maxlength='255' />
                <InputBox placholderName='Your Address' title='Address' textType='text' value={userData.address} onChange={handleChange} name='address' maxlength='1000' />
                <div>
                  {dropdownData.map((menu, index) => (
                    <div key={index} className="mb-6">
                      <label className='text-zinc-500 font-semibold text-[22px]'>{menu.label}</label>
                      <div className="relative inline-block text-left w-full mt-3">
                        <div>
                          <button
                            type="button"
                            className="bg-white w-full text-gray-400 px-2 py-3 border border-zinc-300 rounded-lg"
                            onClick={() => toggleDropdown(index)}
                          >
                            <div className={`flex justify-between text-xl items-center px-5 ${selectedOptions[menu.label.toLowerCase()] ? 'text-black' : 'text-zinc-300'}`}>
                              {selectedOptions[menu.label.toLowerCase()] || `Select ${menu.label} here...`}
                              <svg
                                className="ml-2 h-5 w-5 inline-block"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M5.23 7.21a.75.75 0 011.06.02L10 10.94l3.71-3.71a.75.75 0 111.06 1.06l-4.25 4.25a.75.75 0 01-1.06 0L5.23 8.27a.75.75 0 01.02-1.06z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </div>
                          </button>
                        </div>

                        {openIndex === index && (
                          <div className="absolute w-full z-10 mt-1 bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5">
                            <div className="py-1 overflow-auto max-h-[250px]" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                              {menu.options.map((option, optionIndex) => (
                                <button
                                  key={optionIndex}
                                  className="block px-4 py-2 text-lg text-gray-700 hover:bg-gray-300 w-full text-start hover:text-gray-900"
                                  onClick={() => handleOptionClick(index, option)}
                                >
                                  {option.name}
                                </button>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className='flex justify-between'>
              <div className='w-[48%]'>
                <button className='rounded-lg w-full mt-6 bg-red-800 hover:bg-red-700 font-semibold text-white text-lg p-4' onClick={handleSubmit}>Save Changes</button>
              </div>
              <div className='w-[48%]'>
                <button className='rounded-lg w-full mt-6 bg-stone-300 hover:bg-stone-400 font-semibold text-black text-lg p-4' onClick={handleReset}>Reset</button>
              </div>
            </div>
          </div>
        )}
        {activeTab === 2 && (
          <div className="p-5 justify-center items-center bg-white mt-1">
            <h2 className='text-xl font-bold mt-8'>Subscription Details</h2>
            <div className="space-y-2">
              <div className="flex flex-wrap justify-between border rounded-lg p-3">
                <span className='font-bold text-gray-700'>Subscription:</span>
                <span className='text-gray-800'>{subData.subscription_config?.plan}</span>
              </div>
              <div className="flex flex-wrap justify-between border rounded-lg p-3">
                <span className='font-bold text-gray-700'>Token:</span>
                <span className='text-gray-800'>{subData.token}</span>
              </div>
              {subData.subscription_type != 'free' ? (
                <>
                  <div className="flex flex-wrap justify-between border rounded-lg p-3">
                    <span className='font-bold text-gray-700'>Status:</span>
                    <span className='text-gray-800'>{subData.plan_status}</span>
                  </div>
                  <div className="flex flex-wrap justify-between border rounded-lg p-3">
                    <span className='font-bold text-gray-700'>Membership:</span>
                    <span className='text-gray-800'>{subData.membership_id}</span>
                  </div>
                  <div className="flex flex-wrap justify-between border rounded-lg p-3">
                    <span className='font-bold text-gray-700'>Billing Email:</span>
                    <span className='text-gray-800'>{subData.billing_email}</span>
                  </div>
                  <div className="flex flex-wrap justify-between border rounded-lg p-3">
                    <span className='font-bold text-gray-700'>Amount Paid:</span>
                    <span className='text-gray-800'>{subData.amount}</span>
                  </div>
                  <div className="flex flex-wrap justify-between border rounded-lg p-3">
                    <span className='font-bold text-gray-700'>Currency:</span>
                    <span className='text-gray-800'>{subData.currency}</span>
                  </div>
                  <div className="flex flex-wrap justify-between border rounded-lg p-3">
                    <span className='font-bold text-gray-700'>Restriction Start:</span>
                    <span className='text-gray-800'>{subData.restriction_start}</span>
                  </div>
                  <div className="flex flex-wrap justify-between border rounded-lg p-3">
                    <span className='font-bold text-gray-700'>Restriction End:</span>
                    <span className='text-gray-800'>{subData.restriction_end}</span>
                  </div>
                  <div className="flex flex-wrap justify-between border rounded-lg p-3">
                    <span className='font-bold text-gray-700'>Renewal Date:</span>
                    <span className='text-gray-800'>{subData.renewal_date}</span>
                  </div>
                  <div className="flex flex-wrap justify-between border rounded-lg p-3">
                    <span className='font-bold text-gray-700'>Currency:</span>
                    <span className='text-gray-800'>{subData.currency}</span>
                  </div>
                  {subData.billing_address?.country && subData.billing_address?.name ?
                    <div className='!mt-10 space-y-2'>
                      <h2 className='text-xl font-bold'>Billing Address</h2>
                      <div className="flex flex-wrap justify-between border rounded-lg p-3">
                        <span className='font-bold text-gray-700'>City:</span>
                        <span className='text-gray-800'>{subData.billing_address.city}</span>
                      </div>

                      <div className="flex flex-wrap justify-between border rounded-lg p-3">
                        <span className='font-bold text-gray-700'>State:</span>
                        <span className='text-gray-800'>{subData.billing_address.state}
                        </span>
                      </div>
                      <div className="flex flex-wrap justify-between border rounded-lg p-3">
                        <span className='font-bold text-gray-700'>Postal Code:</span>
                        <span className='text-gray-800'>{subData.billing_address.postal_code}
                        </span>
                      </div>
                      <div className="flex flex-wrap justify-between border rounded-lg p-3">
                        <span className='font-bold text-gray-700'>Country:</span>
                        <span className='text-gray-800'>{subData.billing_address.country}
                        </span>
                      </div>
                    </div> :
                    <></>}
                </>
              ) : <>
              </>
              }
            </div>
          </div>
        )}
        {activeTab === 4 && (
          <div className="p-5 justify-center items-center bg-white mt-1">
            <div className="overflow-x-auto rounded-lg mt-10">

              <table className="w-full text-left text-gray-500 border border-stone-300">
                <thead className="text-xl text-black bg-stone-300">
                  <tr>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">Sr.</th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">Invoice ID</th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">Type</th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">Status</th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">Amount</th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">Created At</th>
                  </tr>
                </thead>
                <tbody>
                  {InvoicData.map((item, index) => (
                    <tr key={index} className="bg-white text-black text-xl font-normal border-b border-stone-300 capitalize">
                      <td className="px-6 py-4 whitespace-nowrap">{index + 1}.</td>
                      <td className="px-6 py-4 whitespace-nowrap">{item.invoice_id}</td>
                      <td className="px-6 py-4 whitespace-nowrap">{item.invoice_type}</td>
                      <td className="px-6 py-4 whitespace-nowrap">{item.invoice_status}</td>
                      <td className="px-6 py-4 whitespace-nowrap">{item.amount}/{item.currency}</td>
                      <td className="px-6 py-4 whitespace-nowrap">{item.created_at}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default AccountProfile