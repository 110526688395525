import React, { useEffect, useRef, useState } from 'react'
import InputBox from '../../../components/InputBox';
import AddTags from '../../../components/AddTags ';
import { useCountries } from "use-react-countries";
import timezoneOptions from "timezones-list";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { BaseApiUrl } from '../../../../utilities/enviroment';
import UserApiClient from '../../../../utilities/apiClients';
import { loginSuccess } from '../../../../redux/actions';
const languageList = require("language-list")().getData();

const Account = () => {
    const user = useSelector(state => state.user.user)
    const [userData, setuserData] = useState({
        'first_name': user.first_name,
        'last_name': user.last_name,
        'email': user.email,
        'number': user.number,
        'state': user.state,
        'address': user.address,
        'zip_code': user.zip_code
    });

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { countries } = useCountries();
    const [imageSrc, setImageSrc] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const fileInputRef = useRef(null);

    const dropdownData = [
        {
            label: 'Country',
            name: 'Select Your Country',
            options: countries
        }, {
            label: 'Timezone',
            name: 'Select Timezone',
            options: timezoneOptions
        },
    ]

    const [openIndex, setOpenIndex] = useState(null);
    const [selectedOptions, setSelectedOptions] = useState({
        "country": user.country, "timezone": user.timezone
    });

    const toggleDropdown = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    const handleOptionClick = (menuIndex, option) => {
        const menu = dropdownData[menuIndex]; // Get the current menu
        setSelectedOptions({
            ...selectedOptions,
            [menu.label.toLowerCase()]: option.name, // Use the label as the key (in lowercase)
        });
        setOpenIndex(null);
    };

    const handleImageClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImageSrc(reader.result);
            };
            reader.readAsDataURL(file);

            setuserData((prevState) => ({
                ...prevState,
                profile_pic: file,
            }));
            setSelectedFile(file);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setuserData({
            ...userData,
            [name]: value
        });
    };
    const handleReset = (e) => {
        setuserData(user);
        setSelectedOptions({
            "country": user.country, "timezone": user.timezone
        })
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!userData.first_name || !userData.last_name || !userData.number || !userData.state || !userData.address || !selectedOptions.country || !userData.zip_code || !selectedOptions.timezone) {
            Swal.fire({ title: '', text: 'Please fill all the fields', timer: 2000, icon: 'warning' })
            return;
        }
        const formData = new FormData();
        for (const key in userData) {
            if (key === "profile_pic" && !selectedFile) {
                continue;
            }
            formData.append(key, userData[key]);
        }
        for (const keyOptions in selectedOptions) {
            formData.append(keyOptions, selectedOptions[keyOptions]);
        }
        UserApiClient.patch(`api/v1/user/update/${user.id}/`, formData, true).then(res => {
            if (res.success) {
                Swal.fire({ title: '', text: 'Profile updated.', timer: 3000, icon: 'success' })
                dispatch(loginSuccess({ ...user, ...res.data }))
                window.location.reload();
            }
        })
    };

    useEffect(() => {
        setImageSrc((user.profile_pic.includes('http') ? '' : BaseApiUrl) + user.profile_pic);
    }, [user, userData])

    return (
        <>
            <div className='mt-10 mb-5'>
                <label className="text-zinc-500 font-semibold  text-[22px]">Upload Image</label>
                <img
                    src={imageSrc}
                    className="w-[100px] h-[100px] rounded-full object-cover"
                    alt="User Avatar"
                    onClick={handleImageClick}
                />
                <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    accept="image/*"
                    name="profile_pic"
                    onChange={handleFileChange}
                />
            </div>
            <div className='w-full bg-white flex justify-between font-josefin-sans'>
                <div className='w-[48%]'>

                    {/* <input type="file" accept="image/*" className="mt-3 mb-6 w-full text-sm text-zinc-500 border border-zinc-300 rounded-lg py-3.5 px-3" value={userData.profile_pic} onChange={handleChange} name='profile_pic' /> */}
                    <InputBox placholderName='First Name' title='First Name' textType='text' value={userData.first_name} onChange={handleChange} name='first_name' maxlength='255' />
                    {/* <InputBox placholderName='Your E-mail' title='E-mail' textType='email' value={userData.email} onChange={handleChange} name='email' maxlength='255' readOnly /> */}
                    <InputBox placholderName='Your Phone Number' title='Phone Number' textType='number' value={userData.number} onChange={handleChange} name='number' min={1000000000} max={9999999999} />
                    <InputBox placholderName='Your State' title='State' textType='text' value={userData.state} onChange={handleChange} name='state' maxlength='255' />
                    <InputBox placholderName='12456' title='Zip Code' textType='number' value={userData.zip_code} onChange={handleChange} name='zip_code' min={100000} max={999999} />
                    {/* <label className='text-zinc-500 font-semibold text-[22px]'>Language</label>
                    <AddTags /> */}

                </div>
                <div className='w-[48%]'>
                    <InputBox placholderName='Last Name' title='Last Name' textType='text' value={userData.last_name} onChange={handleChange} name='last_name' maxlength='255' />
                    <InputBox placholderName='Your Address' title='Address' textType='text' value={userData.address} onChange={handleChange} name='address' maxlength='1000' />
                    <div>
                        {dropdownData.map((menu, index) => (
                            <div key={index} className="mb-6">
                                <label className='text-zinc-500 font-semibold text-[22px]'>{menu.label}</label>
                                <div className="relative inline-block text-left w-full mt-3">
                                    <div>
                                        <button
                                            type="button"
                                            className="bg-white w-full text-gray-400 px-2 py-3 border border-zinc-300 rounded-lg"
                                            onClick={() => toggleDropdown(index)}
                                        >
                                            <div className={`flex justify-between text-xl items-center px-5 ${selectedOptions[menu.label.toLowerCase()] ? 'text-black' : 'text-zinc-300'}`}>
                                                {selectedOptions[menu.label.toLowerCase()] || `Select ${menu.label} here...`}
                                                <svg
                                                    className="ml-2 h-5 w-5 inline-block"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 20 20"
                                                    fill="currentColor"
                                                    aria-hidden="true"
                                                >
                                                    <path
                                                        fillRule="evenodd"
                                                        d="M5.23 7.21a.75.75 0 011.06.02L10 10.94l3.71-3.71a.75.75 0 111.06 1.06l-4.25 4.25a.75.75 0 01-1.06 0L5.23 8.27a.75.75 0 01.02-1.06z"
                                                        clipRule="evenodd"
                                                    />
                                                </svg>
                                            </div>
                                        </button>
                                    </div>

                                    {openIndex === index && (
                                        <div className="absolute w-full z-10 mt-2 bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5">
                                            <div className="py-1 overflow-auto max-h-[250px]" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                                                {menu.options.map((option, optionIndex) => (
                                                    <button
                                                        key={optionIndex}
                                                        className="block px-4 py-2 text-lg text-gray-700 hover:bg-gray-300 w-full text-start hover:text-gray-900"
                                                        onClick={() => handleOptionClick(index, option)}
                                                    >
                                                        {option.name}
                                                    </button>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className='flex justify-between'>
                <div className='w-[48%]'>
                    <button className='rounded-lg w-full mt-6 bg-red-800 hover:bg-red-700 font-semibold text-white text-lg p-4' onClick={handleSubmit}>Save Changes</button>
                </div>
                <div className='w-[48%]'>
                    <button className='rounded-lg w-full mt-6 bg-stone-300 hover:bg-stone-400 font-semibold text-black text-lg p-4' onClick={handleReset}>Reset</button>
                </div>
            </div>
        </>
    )
}

export default Account