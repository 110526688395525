import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { DeleteImg } from '../../../assets';
import UserApiClient from '../../../utilities/apiClients';


const Referralapproved = () => {
    const navigate = useNavigate();
    const [referrals, setReferrals] = useState([]);
    const [filteredReferrals, setFilteredReferrals] = useState([]);
    const [isPopupOpen, setIsPopupOpen] = useState(false); // State for controlling the popup
    const [selectedReferralId, setSelectedReferralId] = useState(null); // State to hold the selected referral ID

    const handleAddReferral = () => {
        const authToken = localStorage.getItem('authToken');

        if (!authToken) {
            console.error('No auth token found. Please log in.');
            return;
        }
        navigate('/admin/addReferral');
    };

    useEffect(() => {
        UserApiClient.get(`api/v1/affiliate/request/?req_affiliate=0`, true).then(async res=>{
            if (res.success) {
                setReferrals(res.data);
                setFilteredReferrals(res.data);
            }
        })
    }, []);

    const openPopup = (referralId) => {
        setSelectedReferralId(referralId); // Store the selected referral ID
        setIsPopupOpen(true); // Open the popup
    };

    const closePopup = () => {
        setIsPopupOpen(false); // Close the popup
        setSelectedReferralId(null); // Clear the selected referral ID
    };

    const handleDelete = async () => {
        try {
            const authToken = localStorage.getItem('authToken');
            if (!authToken) {
                console.error('No auth token found. Please log in.');
                return;
            }
            // Here you could perform the delete request if needed
            // await axios.delete(`${BASE_URL}delete_referral/${selectedReferralId}/`, {
            //     headers: {
            //         'Authorization': `Token ${authToken}`
            //     }
            // });

            // Remove the referral from the state after deletion
            setReferrals(referrals.filter(referral => referral.id !== selectedReferralId)); // Update the state after deletion
            closePopup(); // Close the popup after deletion
        } catch (error) {
            console.error('Error deleting referral:', error);
        }
    };

    const handleSearchChange = (e) => {
        const searchTerm = e.target.value.toLowerCase();
        const filtered = referrals.filter(referral =>
            referral.first_name.toLowerCase().includes(searchTerm) ||
            referral.last_name.toLowerCase().includes(searchTerm) ||
            referral.email.toLowerCase().includes(searchTerm) ||
            referral.referral_code.toLowerCase().includes(searchTerm)
        );
        setFilteredReferrals(filtered);
    };

    return (
        <>
            <div className='h-full bg-white px-7 pb-20 font-josefin-sans'>
                <div className="flex justify-between items-center">
                    <h2 className="text-2xl md:text-3xl font-semibold">Referral List</h2>
                    {/* <button
                        className="rounded-lg text-lg bg-red-800 hover:bg-red-700 text-white py-2 px-4 min-w-44"
                        onClick={handleAddReferral}
                    >
                        Add Referral
                    </button> */}
                </div>
                    <div className="mb-4 mt-10">
                        <input
                            type="text"
                            placeholder="Search Referral"
                            onChange={handleSearchChange}
                            className="border p-2 rounded w-full"
                        />
                    </div>
                <div className="overflow-x-auto rounded-lg mt-10">
                    <table className="w-full text-left text-gray-500 border border-stone-300">
                        <thead className="text-xl text-black bg-stone-300">
                            <tr>
                                <th scope="col" className="px-6 py-3">#ID</th>
                                <th scope="col" className="px-6 py-3">Email</th>
                                <th scope="col" className="px-6 py-3">referred By</th>
                                <th scope="col" className="px-6 py-3">Referral Code</th>
                                <th scope="col" className="px-6 py-3">Referral Commission</th>
                                <th scope="col" className="px-6 py-3">Token Commission</th>
                                {/* <th scope="col" className="px-6 py-3">Status</th> */}
                                <th scope="col" className="px-6 py-3 text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredReferrals.map((referral) => (
                                <tr key={referral.id} className="bg-white text-black text-xl font-normal border-b border-stone-300">
                                    <td className="px-6 py-4 whitespace-nowrap">#{referral.id}</td>
                                    <td className="px-6 py-4 whitespace-nowrap">{referral.email}</td>
                                    <td className="px-6 py-4 whitespace-nowrap">{referral.ref_by || 'Unreferred'}</td>
                                    <td className="px-6 py-4 whitespace-nowrap">{referral.affiliate_id || 'Not Generated'}</td>
                                    <td className="px-6 py-4 whitespace-nowrap">{referral.ref_commission}/{referral.ref_commission_raise}</td>
                                    <td className="px-6 py-4 whitespace-nowrap">{referral.token_commission}/{referral.token_commission_raise}</td>
                                    {/* <td className="px-6 py-4 whitespace-nowrap">
                                        <span className={`badge rounded-full ${referral.status === 'Approved' ? 'bg-green-500' : 'bg-yellow-500'} text-white py-1 px-3`}>
                                            {referral.status}
                                        </span>
                                    </td> */}
                                    <td className="px-6 py-4 flex gap-5 justify-center items-center">
                                        <img
                                            src={DeleteImg}
                                            alt="deleteImg"
                                            className='hover:cursor-pointer'
                                            onClick={() => openPopup(referral.id)}  // Trigger popup on click
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

export default Referralapproved;
