import React, { useState } from 'react';
import EasyTrackingBox from '../../components/EasyTrackingBox';
import { videoPlay } from '../../../assets';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Footer from '../../../frontend/components/Footer';

const BecomeAffiliate = () => {
    const [copySuccess, setCopySuccess] = useState('');
    const copyToClipboard = () => {
        const textToCopy = "https://www.eromantic.ai?ref=6638b4f04b073b3fadebe7d9";
        const textArea = document.createElement('textarea');
        textArea.value = textToCopy;
        document.body.appendChild(textArea);
        textArea.select();

        try {
            document.execCommand('copy');
            setCopySuccess('Copied!');
            setTimeout(() => {
                setCopySuccess('');
            }, 2000);
        } catch (err) {
            console.error('Failed to copy text', err);
        }

        document.body.removeChild(textArea);
    };

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const handleAffiliateSignup = () => {
        window.location.href = '/dashboard/affiliate-signup'; // Example: Redirect to the affiliate sign-up page
    };

    return (
        <>
            <div className="flex flex-col min-h-">
                <div className="flex-1 container mx-auto px-6 py-12">
                    <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold text-center text-white mb-8">Become an Affiliate Member</h1>
                    <p className="text-center text-lg md:text-xl lg:text-2xl text-white mb-12">
                        Join our affiliate program and start earning by promoting Eromantic-AI. Our program is designed to offer attractive commissions, dedicated support, and an easy way to grow your earnings.
                    </p>

                    {/* Affiliate Program Overview */}
                    <div className="mb-8 bg-white p-6 rounded-xl shadow-lg">
                        <h2 className=" text-xl md:text-2xl lg:text-3xl font-semibold text-black mb-2">Why Join Our Affiliate Program?</h2>
                        <p className="lg:text-xl md:text-lg text-base text-black mb-4">
                            As an affiliate, you earn a commission for every successful referral. Our program offers up to 50% commission on direct referrals, with additional earnings from sub-affiliate referrals. We provide support to help you maximize your success as an affiliate.
                        </p>
                    </div>

                    {/* Policy Summary */}
                    <div className="mb-8 bg-white p-6 rounded-xl shadow-lg">
                        <h2 className="text-xl md:text-2xl lg:text-3xl font-semibold text-black mb-2">Affiliate Program Policy</h2>
                        <p className="lg:text-xl md:text-lg text-black mb-4">
                            Our affiliate program is designed for transparency and fairness. Affiliates are expected to adhere to ethical promotion practices, including honest representation and avoiding any misleading promotions. Earnings are processed on demand once you reach a threshold of $150, and we offer support to answer any questions or concerns along the way.
                        </p>
                        <p className="lg:text-xl md:text-lg text-black mb-4">
                            For more details, please refer to our Affiliate Program Policy available in our Policies section.
                        </p>
                    </div>

                    {/* Benefits Section */}
                    <div className="mb-8 bg-white p-6 rounded-xl shadow-lg">
                        <h2 className="text-xl md:text-2xl lg:text-3xl font-semibold text-black mb-2">Benefits of Becoming an Affiliate</h2>
                        <ul className="list-disc list-inside lg:text-xl md:text-lg text-black mb-4">
                            <li>Earn up to 50% commission on direct referrals.</li>
                            <li>Additional earnings from sub-affiliate referrals.</li>
                            <li>Access to exclusive affiliate offers and promotions.</li>
                            <li>Dedicated support team to help you succeed.</li>
                            <li>Comprehensive tracking and reporting tools.</li>
                        </ul>
                    </div>

                    {/* CTA Button */}
                    <div className="text-center mt-12">
                        <button
                            onClick={handleAffiliateSignup}
                            className="bg-gradient-to-r from-[#ea3333] to-[#b30000] text-white px-8 py-4 rounded-full text-xl font-semibold shadow-lg hover:from-[#b30000] hover:to-[#ea3333] transition duration-200"
                        >
                            Become an Affiliate
                        </button>
                    </div>

                    {/* Contact Support */}
                    <div className="text-center mt-8">
                        <p className="text-lg font-medium text-white">
                            Have questions about the affiliate program? Contact our support team at 
                            <a href="https://support.eromantic.ai/hc/en-us" className="text-blue-500 ml-1">
                                support@eromantic.ai
                            </a>.
                        </p>
                    </div>
                </div>

                <Footer />
            </div>
        </>
    );
}

export default BecomeAffiliate;
