import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { loginImg, Logo } from '../../../assets';
import UserApiClient from '../../../utilities/apiClients';


const LoginAdmin = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [category, setCategory] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setCategory(option);
        setIsOpen(false);
    };

    const navigate = useNavigate();

    const handleLogin = async () => {
        const data = {
            username: email,
            password: password,
            category: category,
        }
        await UserApiClient.post(`api/login/`, data, false).then(async res => {
            if (res.success) {
                localStorage.setItem('authToken', res.data.access);
                navigate('/admin/dashboard');
            }
        });
    };

    return (
        <>
            <div className='w-full bg-white flex justify-between font-josefin-sans h-full'>
                <div className='w-[40%] hidden md:block'>
                    <img src={loginImg} alt="login" className='w-full object-cover h-[100vh]' />
                </div>
                <div className='px-5 relative pt-32 md:p-0 w-full md:w-[60%] h-[100vh] flex justify-center items-center'>
                <button
                            type="button"
                            className="btn absolute top-3 left-3 text-lg text-[#ff0022] cursor-pointer flex items-center"
                            onClick={() => navigate('/')}
                        >
                            <i className="fa-solid fa-circle-arrow-left mr-2"></i>Back
                        </button>
                    <div className='w-[400px] lg:w-[560px]'>
                        <img src={Logo} alt="Logo_Nav" className='m-auto' />
                        <p className='text-3xl font-bold text-center mt-5 mb-10'>ADMIN LOGIN</p>
                        <div className='bg-white px-2 py-3 border-[3px] border-zinc-300 rounded-lg flex justify-between items-center mb-2'>
                            <input
                                type="email"
                                className='ps-5 focus:outline-none w-full text-lg placeholder:text-zinc-300'
                                placeholder='Enter your Username/email address'
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div className="relative inline-block text-left w-[100%]">
                            <div>
                                <button
                                    type="button"
                                    className="bg-white w-[100%] text-lg text-gray-400 px-2 py-3 border-[3px] mt-[8px] border-zinc-300 rounded-lg"
                                    onClick={toggleDropdown}
                                >
                                    <div className={`flex justify-between text-lg items-center px-5 ${selectedOption ? 'text-black' : 'text-zinc-300'}`}>
                                        {selectedOption || 'Select Category'}
                                        <svg
                                            className="ml-2 h-5 w-5 inline-block"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                            aria-hidden="true"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M5.23 7.21a.75.75 0 011.06.02L10 10.94l3.71-3.71a.75.75 0 111.06 1.06l-4.25 4.25a.75.75 0 01-1.06 0L5.23 8.27a.75.75 0 01.02-1.06z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                    </div>
                                </button>
                            </div>

                            {isOpen && (
                                <div className="absolute w-full z-10 mt-2 bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5">
                                    <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                                        <button
                                            className="block px-4 text-base py-2 text-gray-700 hover:bg-gray-300 w-full text-start hover:text-gray-900"
                                            onClick={() => handleOptionClick('Admin')}
                                        >
                                            Admin
                                        </button>
                                        <button
                                            className="block px-4 py-2 text-base text-gray-700 hover:bg-gray-300 w-full text-start hover:text-gray-900"
                                            onClick={() => handleOptionClick('Staff')}
                                        >
                                            Staff
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className='bg-white px-2 py-3 mt-4 border-[3px] border-zinc-300 rounded-lg flex justify-between items-center '>
                            <input
                                type="password"
                                className='text-lg ps-5 focus:outline-none w-full placeholder:text-zinc-300'
                                placeholder='Enter your password'
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                        <p className='font-semibold mt-2'>Forgot your password? Reset it</p>
                        <button className='py-[10px] font-semibold text-lg w-full rounded-md bg-red-500 hover:bg-red-600 text-white mt-8' onClick={handleLogin}>Login</button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default LoginAdmin;

