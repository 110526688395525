import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PagenameSearchBox from '../components/PagenameSearchBox';
import { remaneUserProfile, tableUser1 } from '../../assets';
import UserApiClient from '../../utilities/apiClients';


const Usersdetail = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const [userDetails, setUserDetails] = useState({});
    const [commissionList, setCommissionList] = useState([]);
    const [withdrawalList, setWithdrawalList] = useState([]);
    const [subscriptionList, setSubscriptionList] = useState([]);
    const [activeTab, setActiveTab] = useState(1);

    // Handle tab click to change the active tab
    const handleTabClick = (tabNumber) => {
        setActiveTab(tabNumber);
    };

    // Function to handle navigation back to the user list
    const handleBlog = () => {
        navigate('/admin/allUser')
    }

    const fetchData = async () => {
        await UserApiClient.get(`api/v1/admin/user/${id}/`, {}, true).then(async res => {
            if (res.success) {
                setUserDetails(res.data)
            }
        })
    }

    const fetchCommissionData = async () => {
        await UserApiClient.get(`api/v1/admin/user/commissions/?user_id=${id}`, {}, true).then(async res => {
            if (res.success) {
                setCommissionList(res.data);
            }
        });
    }

    const fetchWithdrawalData = async () => {
        await UserApiClient.get(`api/v1/admin/user/withdrawals/?user_id=${id}`, {}, true).then(async res => {
            if (res.success) {
                setWithdrawalList(res.data);
            }
        });
    }

    const fetchSubscriptionData = async () => {
        await UserApiClient.get(`api/v1/admin/user/invoices/?user_id=${id}`, {}, true).then(async res => {
            if (res.success) {
                setSubscriptionList(res.data);
            }
        });
    }

    useEffect(() => {
        fetchData();
        fetchCommissionData();
        fetchWithdrawalData();
        fetchSubscriptionData()
    }, [])


    return (
        <>
            <div className='h-full  px-7 pb-20 font-josefin-sans'>
                <PagenameSearchBox title='User Profile' btnname='Back to user list' btnfunction={handleBlog} />

                {/* User Profile Section */}
                <div className='rounded-lg bg-red-800 w-full pb-16 mt-12'>
                    {/* <div className='flex justify-end pt-7 pr-7'>
                        <img src={remaneUserProfile} alt="rename" className='w-10 h-10' />
                    </div> */}
                    <div className='flex items-center p-5 xl:px-16 pb-8 gap-14 border-b border-white'>
                        {/* <div>
                            <img src={tableUser1} alt="user1" className='w-40 h-40 rounded-full' />
                        </div> */}
                        <div className='text-white'>
                            Email:<p className='text-2xl font-bold mb-2'> {userDetails.email}</p>
                            User Since:<p className='text-2xl font-bold mb-2'>  {userDetails.date_joined}</p>
                        </div>
                    </div>
                </div>
                <div className='rounded-lg bg-[#838383] w-full pb-16 mt-12'>
                    <div className="flex space-x-4">
                        <button
                            onClick={() => handleTabClick(1)}
                            className={`px-4 py-2 m-2 w-[20%] font-semibold text-lg rounded-lg transition-colors duration-300 ${activeTab === 1 ? 'bg-[#000] text-white' : 'bg-gray-200 text-gray-600'
                                }`}
                        >
                            Details
                        </button>
                        <button
                            onClick={() => handleTabClick(2)}
                            className={`px-4 py-2 m-2 w-[20%] font-semibold text-lg rounded-lg transition-colors duration-300 ${activeTab === 2 ? 'bg-[#000] text-white' : 'bg-gray-200 text-gray-600'
                                }`}
                        >
                            Withdrawals
                        </button>
                        <button
                            onClick={() => handleTabClick(3)}
                            className={`px-4 py-2 m-2 w-[20%] font-semibold text-lg rounded-lg transition-colors duration-300 ${activeTab === 3 ? 'bg-[#000] text-white' : 'bg-gray-200 text-gray-600'
                                }`}
                        >
                            Commissions
                        </button>
                        <button
                            onClick={() => handleTabClick(4)}
                            className={`px-4 py-2 m-2 w-[20%] font-semibold text-lg rounded-lg transition-colors duration-300 ${activeTab === 4 ? 'bg-[#000] text-white' : 'bg-gray-200 text-gray-600'
                                }`}
                        >
                            Invoices
                        </button>
                    </div>

                    {activeTab === 1 && (
                        <>
                            <div className="mt-5">
                                <div className="-100 p-6 pt-0 rounded-lg">
                                    {/* <h3 className="text-lg font-semibold text-black">User Information</h3> */}
                                    <div className="mt-0">
                                        <div className="p-6 max-w-4xl bg-white rounded-lg shadow-md mt-4">
                                            <h2 className="text-2xl font-semibold text-gray-800 mb-4">User Profile</h2>

                                            <div className="space-y-2">
                                                <div className="flex justify-between border rounded-lg p-3">
                                                    <span className="font-medium text-gray-600">Affiliate ID:</span>
                                                    <span className="text-gray-800">{userDetails.affiliate_id}</span>
                                                </div>
                                                <div className="flex justify-between border rounded-lg p-3">
                                                    <span className="font-medium text-gray-600">Balance:</span>
                                                    <span className="text-gray-800">${userDetails.balance}</span>
                                                </div>
                                                <div className="flex justify-between border rounded-lg p-3">
                                                    <span className="font-medium text-gray-600">Date Joined:</span>
                                                    <span className="text-gray-800">{new Date(userDetails.date_joined).toLocaleDateString()}</span>
                                                </div>
                                                <div className="flex justify-between border rounded-lg p-3">
                                                    <span className="font-medium text-gray-600">Email:</span>
                                                    <span className="text-gray-800">{userDetails.email}</span>
                                                </div>
                                                <div className="flex justify-between border rounded-lg p-3">
                                                    <span className="font-medium text-gray-600">ID:</span>
                                                    <span className="text-gray-800">{userDetails.id}</span>
                                                </div>
                                                <div className="flex justify-between border rounded-lg p-3">
                                                    <span className="font-medium text-gray-600">Active:</span>
                                                    <span className="text-gray-800">{userDetails.is_active ? 'Yes' : 'No'}</span>
                                                </div>
                                                <div className="flex justify-between border rounded-lg p-3">
                                                    <span className="font-medium text-gray-600">Affiliated:</span>
                                                    <span className="text-gray-800">{userDetails.is_affiliated ? 'Yes' : 'No'}</span>
                                                </div>
                                                <div className="flex justify-between border rounded-lg p-3">
                                                    <span className="font-medium text-gray-600">Requesting Affiliate:</span>
                                                    <span className="text-gray-800">{userDetails.is_requesting_affiliate ? 'Yes' : 'No'}</span>
                                                </div>
                                                <div className="flex justify-between border rounded-lg p-3">
                                                    <span className="font-medium text-gray-600">Subscription Type:</span>
                                                    <span className="text-gray-800">{userDetails.subscription_type}</span>
                                                </div>
                                                <div className="flex justify-between border rounded-lg p-3">
                                                    <span className="font-medium text-gray-600">Ref Commission:</span>
                                                    <span className="text-gray-800">${userDetails.ref_commission}</span>
                                                </div>
                                                <div className="flex justify-between border rounded-lg p-3">
                                                    <span className="font-medium text-gray-600">Ref Commission Raise:</span>
                                                    <span className="text-gray-800">${userDetails.ref_commission_raise}</span>
                                                </div>
                                                <div className="flex justify-between border rounded-lg p-3">
                                                    <span className="font-medium text-gray-600">Token Commission:</span>
                                                    <span className="text-gray-800">${userDetails.token_commission}</span>
                                                </div>
                                                <div className="flex justify-between border rounded-lg p-3">
                                                    <span className="font-medium text-gray-600">Token Commission Raise:</span>
                                                    <span className="text-gray-800">${userDetails.token_commission_raise}</span>
                                                </div>
                                                <div className="flex justify-between border rounded-lg p-3">
                                                    <span className="font-medium text-gray-600">User ID:</span>
                                                    <span className="text-gray-800">{userDetails.user_id}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </>
                    )}{activeTab === 2 && (
                        <>
                            <div className="mt-5">
                                <div className="-100 p-6 pt-0 rounded-lg">
                                    <h3 className="text-lg font-semibold text-black">Withdrawal List</h3>

                                    <div className="mt-4">
                                        <div className="overflow-x-auto rounded-lg mt-10">
                                            <table className="w-full text-left text-gray-500 border border-stone-300">
                                                <thead className="text-xl text-black bg-stone-300">
                                                    <tr>
                                                        <th scope="col" className="px-6 py-3">Sr.</th>
                                                        <th scope="col" className="px-6 py-3">Email</th>
                                                        <th scope="col" className="px-6 py-3">Amount</th>
                                                        <th scope="col" className="px-6 py-3">Created At</th>
                                                        <th scope="col" className="px-6 py-3">Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {withdrawalList.map((item, index) => (
                                                        <tr key={index} className="bg-white text-black text-xl font-normal border-b border-stone-300">
                                                            <td className="px-6 py-4 whitespace-nowrap">{index + 1}.</td>
                                                            <td className="px-6 py-4 whitespace-nowrap">{item.user}</td>
                                                            <td className="px-6 py-4 whitespace-nowrap">${item.amount}</td>
                                                            <td className="px-6 py-4 whitespace-nowrap">{item.created_at}</td>
                                                            <td className="px-6 py-4 whitespace-nowrap capitalize">{item.status}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                    {activeTab === 3 && (
                        <>
                            <div className="mt-5">
                                <div className="-100 p-6 pt-0 rounded-lg">
                                    <h3 className="text-lg font-semibold text-black">Commission List</h3>
                                    <div className="mt-4">
                                        <div className="overflow-x-auto rounded-lg mt-10">
                                            <table className="w-full text-left text-gray-500 border border-stone-300">
                                                <thead className="text-xl text-black bg-stone-300">
                                                    <tr>
                                                        <th scope="col" className="px-6 py-3">Sr.</th>
                                                        <th scope="col" className="px-6 py-3">Email</th>
                                                        <th scope="col" className="px-6 py-3">Amount</th>
                                                        <th scope="col" className="px-6 py-3">Affiliate Id</th>
                                                        <th scope="col" className="px-6 py-3">Commission Type</th>
                                                        <th scope="col" className="px-6 py-3">Created At</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {commissionList.map((item, index) => (
                                                        <tr key={index} className="bg-white text-black text-xl font-normal border-b border-stone-300">
                                                            <td className="px-6 py-4 whitespace-nowrap">{index + 1}.</td>
                                                            <td className="px-6 py-4 whitespace-nowrap">{item.user}</td>
                                                            <td className="px-6 py-4 whitespace-nowrap">${item.amount}</td>
                                                            <td className="px-6 py-4 whitespace-nowrap">{item.affiliate_id}</td>
                                                            <td className="px-6 py-4 whitespace-nowrap capitalize">{item.commission_type}</td>
                                                            <td className="px-6 py-4 whitespace-nowrap">{item.created_at}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                    {activeTab === 4 && (
                        <>
                            <div className="mt-5">
                                <div className="-100 p-6 pt-0 rounded-lg">
                                    <h3 className="text-lg font-semibold text-black">Invoice List</h3>
                                    <div className="mt-4">
                                        <div className="overflow-x-auto rounded-lg mt-10">
                                            <table className="w-full text-left text-gray-500 border border-stone-300">
                                                <thead className="text-xl text-black bg-stone-300">
                                                    <tr>
                                                        <th scope="col" className="px-6 py-3">Sr.</th>
                                                        <th scope="col" className="px-6 py-3">Invoice Id</th>
                                                        <th scope="col" className="px-6 py-3">Invoice Type</th>
                                                        <th scope="col" className="px-6 py-3">Email</th>
                                                        <th scope="col" className="px-6 py-3">Amount</th>
                                                        <th scope="col" className="px-6 py-3">Created At</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {subscriptionList.map((item, index) => (
                                                        <tr key={index} className="bg-white text-black text-xl font-normal border-b border-stone-300">
                                                            <td className="px-6 py-4 whitespace-nowrap">{index+1}.</td>
                                                            <td className="px-6 py-4 whitespace-nowrap">{item.invoice_id}</td>
                                                            <td className="px-6 py-4 whitespace-nowrap">{item.invoice_type}</td>
                                                            <td className="px-6 py-4 whitespace-nowrap">{item.user}</td>
                                                            <td className="px-6 py-4 whitespace-nowrap">${item.amount}</td>
                                                            <td className="px-6 py-4 whitespace-nowrap">{item.created_at}</td>
                                                        </tr>

                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default Usersdetail;
