import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Togal from './Togal';
import { DeleteImg, rename } from '../../assets';
import DeletePopup from './DeletePopup';
import UserApiClient from '../../utilities/apiClients';


const BlogListCard = ({ img, title, subtext, blogId, blog_publish }) => {
    const navigate = useNavigate();

    const handleBlog = async () => {

        await UserApiClient.get(`api/details_blog/${blogId}/`, {}, true).then(async res => {
            if (res.success) {
                navigate(`/admin/singleBlog/${blogId}`, { state: { blog: res.data } });
            }
        })
    };

    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const togglePopup = () => {
        setIsPopupOpen(!isPopupOpen);
    };

    const handleDelete = async () => {
        await UserApiClient.delete(`api/delete_blog/${blogId}/`, {}, true).then(async res => {
            if (res.success) {
                setIsPopupOpen(false);
                window.location.reload();
            }
        })
    };
    const handleStatusChange = async (event) => {
        const data = new FormData();
        data.append('blog_publish', event.target.checked);
        await UserApiClient.put(`api/update_blog_status/${blogId}/`, data, true).then(async res => {
            if (res.success) {
                // setBlogs(res.data)
            }
        })
    };

    return (
        <>
            <div className='border-2 border-zinc-300 rounded-lg w-[307px] font-josefin-sans'>
                <div className='flex justify-between p-3 items-center'>
                    <Togal handleChange={handleStatusChange} value={blog_publish} />
                    <div className='flex justify-center gap-3 items-center'>

                        <img src={rename} alt="editImg" className='hover:cursor-pointer' onClick={() => navigate(`/admin/EditBlog/${blogId}`)} />
                        <img src={DeleteImg} alt="deleteImg" className='hover:cursor-pointer' onClick={togglePopup} />
                    </div>
                </div>
                <div onClick={handleBlog} className='hover:cursor-pointer'>
                    <img src={img} alt="blogListCard" className='w-[307px] h-[173px]' />
                    <div className='text-left p-[14px]'>
                        <p className='text-black text-lg font-semibold'>{title}</p>
                        <p className='text-zinc-600 text-[12px] font-medium pt-[5px] pb-[12px] '>Create at 22/05/2024</p>
                        <p
                            className='text-zinc-600 text-[14px] font-normal leading-tight break-words'
                        >
                            <div
                                dangerouslySetInnerHTML={{ __html: subtext }}
                            />

                        </p>
                    </div>
                </div>
            </div>

            {isPopupOpen && (
                <DeletePopup togglePopup={togglePopup} handleDelete={handleDelete} />
            )}
        </>
    );
};

export default BlogListCard;
