import React from "react";
import Navbar from "../components/Navbar";
import { Link } from "react-router-dom";
import Header from "../components/Header";

const TermsOfService = () => {
  return (
    <>
      <div className="">
        <div className="container mx-auto">
          <div className="p-10">
            <p className="text-white text-center py-10 md:text-[50px] text-[20px] mt-[50px] ">
            Terms of Service 
            </p>
            <div>
              <h1 className="text-white text-[25px] mb-[-35px]"> Introduction</h1>
              <p className="text-white text-[18px] py-10">
                {" "}
                These terms of service constitute a binding agreement between you and Eromantic-AI, 1000 Brickell Ave, Ste 715 Miami, FL 33131. By accessing or using our services, you agree to be bound by these terms.
              </p>


              <h1 className="text-white text-[25px] mb-[-35px]"> Description of Service</h1>
              <p className="text-white text-[18px] py-10">
                {" "}
                Our platform is an AI Image Generation Platform that empowers users to create AI-generated content, specifically images. We provide users with the ability to generate a wide range of images using artificial intelligence technology. It is important to note that the content generated on our platform can be of an explicit or sexual nature, as determined by the users themselves. Our platform offers access to computing capabilities, without directly providing nor producing any content.
                 </p>

                 <h1 className="text-white text-[25px] mb-[-35px] "> 1. Eligibility</h1>
              <p className="text-white text-[18px] py-10">
                {" "}
                OYou must be at least 18 years old or the age of majority in your jurisdiction to use the Platform. By accessing and using the Platform, you represent and warrant that you meet these age requirements.
                 </p>


                 <h1 className="text-white text-[25px] mb-[-35px] "> 2. Prohibited Content</h1>
              <p className="text-white text-[18px] py-10">
                {" "}
                Users are strictly prohibited from generating or attempting to generate AI-generated images that display any form of rape, murder, non-consensual deepfakes (images portraying real people), scat, non-consensual sex, minors in any circumstances, violence, severe beatings, watersports, bestiality, zoophilia, necrophilia, prostitution, pedopornography, or that violates any card association standards. Any violation of this provision may result in the immediate termination of your account, content removal, and may also be subject to legal consequences. Please note that certain content cannot be generated in strict accordance with the regulations imposed by payment processors and banks, which we must adhere to in order to operate in compliance.
                 </p>


                 <h1 className="text-white text-[25px] mb-[-35px] "> 3. Account Creation</h1>
              <p className="text-white text-[18px] py-10">
                {" "}
                Users can join the Platform by signing up through their Google, Twitter, Reddit, or Discord accounts using our convenient one-click signup process. By signing up, you authorize us to access and use your provided information, such as your email (if available) and username, for the purpose of account creation and future access to the Platform.                 </p>


                 <h1 className="text-white text-[25px] mb-[-35px] "> 4. Limitation of Liability</h1>
                  <p className="text-white text-[18px] py-10">
                    {" "}
                    To the maximum extent permitted by law, we shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenue, whether incurred directly or indirectly, arising from your use of the Platform.
                 </p>



                 <h1 className="text-white text-[25px] mb-[-35px] ">5a. Content</h1>
                  <p className="text-white text-[18px] py-10">
                    {" "}
                    Kindly be advised that the quality of the content generated is predominantly contingent upon the inputs provided by the user (prompt), and as such, we cannot offer refunds for credits that have been utilized. Furthermore, it is important to note that we do not possess complete control over the AI output, as it is solely generated by artificial intelligence technology.
                 </p>


                 <h1 className="text-white text-[25px] mb-[-35px] ">5b. Ownership of Content</h1>
                  <p className="text-white text-[18px] py-10">
                    {" "}
                    You are granted permission to freely share and utilize any of your images generated by our platform for commercial purposes, provided that your utilization of the images is not intended to cause harm, defraud, or negatively impact third parties in any way.                 </p>


                 <h1 className="text-white text-[25px] mb-[-35px] ">6. Cancellation policy</h1>
                  <p className="text-white text-[18px] py-10">
                    {" "}
                    You have the freedom to cancel your Eromantic AI subscription anytime. You can cancel your subscription in the plans page at www.eromantic.ai/plans. Should you not manage, you should contact CCBill or Epoch to cancel your subscription.
                 </p>



                 <h1 className="text-white text-[25px] mb-[-35px] ">7. Affiliates</h1>
                  <p className="text-white text-[18px] py-10">
                    {" "}
                    Affiliates are provided with a compelling opportunity to earn a substantial 25% commission on revenue generated from user purchases within our platform. This commission is calculated based on the total revenue received, after deducting payment gateway fees. The payment gateway fees typically vary between 8% and 16%, contingent upon whether users completed their purchases using CCBill or Epoch payment methods. It is essential to bear in mind that affiliate commissions are not applicable to refunds or chargebacks. To elaborate further, you will receive a commission equivalent to 25% of the net revenue collected, which excludes transaction fees. It is pertinent to mention that cryptocurrency payments are not included in the affiliate program. CCBill payment gateway entails a fee of 10% plus an additional $0.22 for each transaction, while Epoch incurs an 8% fee (plus 2.5% to cover their payment and currency conversion fees, on average. Additionally, for security measures and to deter potential fraudulent activities, all payments will remain pending for a period of 7 days before being finalized and credited to affiliates' accounts. We reserve 10% of your earnings for a duration of 2 months as a precautionary measure to mitigate potential risks associated with chargebacks and refunds. To initiate the withdrawal of your funds, please send us an invoice specifying the service, such as 'affiliate marketing fees'. Ensure the invoice includes our company details. Payment can be issued via cryptocurrency, excluding transaction fees associated with the transfer. Please note that if your withdrawable earnings exceed 150$ and you do not submit an invoice to support@eromantic.ai within the end of the year, the accumulated funds will be forfeited and cannot be withdrawn.
                 </p>



                 <h1 className="text-white text-[25px] mb-[-35px] "> 8. Images uploads</h1>
                  <p className="text-white text-[18px] py-10">
                    {" "}
                    By utilizing this platform, users are granted the capability to upload images for the purpose of reproducing environments, contexts, or poses. However, it is imperative that you, as the user, undertake the responsibility of exclusively uploading images for which you possess the necessary rights. Furthermore, you expressly agree not to upload any images that are illegal or infringe upon the rights of others. In the event of any such illegal uploads, you hereby agree to indemnify us against any resulting claims or liabilities. Users cannot upload images to the platform unless they purchased their plan via Epoch or cryptocurrency.
                 </p>


                 <h1 className="text-white text-[25px] mb-[-35px] "> 9. Plans</h1>
                  <p className="text-white text-[18px] py-10">
                    {" "}
                    Plans constitute recurring subscriptions, unless payment is made using cryptocurrency. Credits never expire, but an active plan is required to use them. Our platform provides the following plans for purchase:

                      Bronze: Charged at $25/month plus up to (9%), if applicable. Includes 400 credits and up to 40 Restores.
                      Silver: Charged at $50/month plus up to (9%), if applicable. Includes 1000 credits and up to 100 Restores.
                      Gold: Charged at $125/month plus up to (9%), if applicable. Includes 2500 credits and 2500 Restores.
                 </p>


                 <h1 className="text-white text-[25px] mb-[-35px] ">10. Refund policy and liability</h1>
                  <p className="text-white text-[18px] py-10">
                    {" "}
                    Non-Liability for Delays or Performance Issues
                    We strive to provide a seamless and efficient AI image generation experience. However, due to various factors such as server load, maintenance, or technical issues, the performance of the image generation process may occasionally be delayed or slowed down. You acknowledge and agree that we shall not be liable for any delays or reduced performance during the image generation process.

                    Non-Liability for Damage in Circumstances Beyond Our Control
                    We take all reasonable precautions to maintain the security and integrity of our systems. However, in the event of any damage or loss occurring as a result of circumstances beyond our control, including but not limited to cyber-attacks, force majeure events, or any other unforeseen incidents, we shall not be held liable for such damages or losses.

                    No Refunds for Slow Image Generation:
                    The image generation process's speed and priority depend on the user's plan, and it is subject to the queue system. We cannot guarantee the specific time it takes to generate images for individual users based on their chosen plan. Therefore, we do not offer refunds if the image generation is slower than expected.

                    No Refunds for Unmet Expectations:
                    The images generated by the App are fully AI-generated and rely on the data provided and the AI model's capabilities. While we aim to provide satisfactory results, we cannot guarantee that the images will meet all user expectations or preferences. As such, we do not offer refunds based on the images not turning out as the user expects or wishes.

                    Refund Eligibility:
                    Refunds will only be considered under the following circumstances:

                    a. Applicable statutory consumer protection laws mandate a refund.
                    b. We fail to provide the service entirely, and the user is unable to use the service for its intended purpose.
                    c. Double billing or overcharging due to a technical error on our part.
                    d. The user has not consumed any credits provided to him and has not seen more than 10 generations in the discovery section.
                 </p>


                 <h1 className="text-white text-[25px] mb-[-35px] ">11. Restrictions</h1>
                  <p className="text-white text-[18px] py-10">
                    {" "}
                    In connection with the utilization of our Services, you are required to refrain from performing the following actions with regard to any material (including photographs, text, videos, etc.):

                    a. Uploading, disseminating, or otherwise transmitting content that is in violation of the law, poses harm, damages reputation, is defamatory, exhibits abusive behavior, is racially or ethnically offensive, involves harassment, communicates threats, intrudes upon personal privacy or the right to public image, disseminates false or misleading information, ignites controversy, or otherwise raises objections.
                    b. Engaging in activities that would incite, promote, or provide guidance for criminal offenses, infringe upon the rights of any party, result in legal accountability, or breach local, regional, national, or international legal statutes.
                    c. Displaying private or personal information pertaining to a third party without acquiring proper authorization from said third party.
                    d. Crafting content that could potentially exploit or cause harm to minors, either by exposing them to inappropriate subject matter, seeking personally identifiable data, or adopting any other approach.
                    e. Sharing information or content for which you lack the lawful right.
                    f. Generating content that, at our exclusive judgment, could be deemed objectionable, or that imposes limitations or barriers upon any individual's use or enjoyment of our Services, potentially exposing us or others to various forms of harm or legal responsibility.
                    d. Engaging in activities aimed at causing harm, disrupting, or attempting to compromise the security of our servers, including but not limited to spamming or any similar malicious actions.

                 </p>


                 <h1 className="text-white text-[25px] mb-[-35px] ">12. Face/Pose Replication</h1>
                  <p className="text-white text-[18px] py-10">
                    {" "}
                    The 'Replication' Feature ('Feature') available through our application allows users to create digital content by superimposing a face from one image onto another. This Feature is intended for use by adult content creators, photographers, and individuals who have obtained proper consent for the creation and distribution of deepfakes.

                    To access this feature, you must have paid for the premium plan using Cryptocurrency or Epoch, and provided either an ID document of the person depicted (model) in the image or proof that the image is AI-generated or not authentic.

                    Users of the Feature must adhere to all applicable laws, regulations, and ethical standards while using the Feature. The Feature is intended for lawful and ethical purposes only.

                    a. You expressly acknowledge that the creation of non-consensual, harmful, or malicious deepfakes is strictly prohibited. This includes generating content that may violate an individual's privacy, cause harm, defame, harass, or infringe upon intellectual property rights without proper authorization.
                    b. You may not use the Feature to create content that involves minors in any explicit, adult, or inappropriate context.
                    c. You may not use the Feature for any illegal, harmful, or malicious activities, including but not limited to generating content for the purpose of deception, fraud, or harassment.
                    By using the Feature, you warrant and represent that you either: a) are the person depicted in the generated deepfake, and you consent to its creation and distribution; or b) have obtained explicit and legally valid consent from the individual(s) depicted in the generated deepfake.

                    You are solely responsible for ensuring that you have the necessary rights and permissions to use the images and elements involved in the deepfake creation process.

                    If you encounter content generated using our Feature that you believe violates these Terms, infringes upon rights, or is otherwise inappropriate or harmful, please report it to us by contacting us at support@eromantic.ai.
                    </p>


                 <h1 className="text-white text-[25px] mb-[-35px] ">13. Model verification</h1>
                  <p className="text-white text-[18px] py-10">
                    {" "}
                    Model Verification is the process by which we ensure the legitimacy of your request to utilize the 'Replicate Face' feature and to establish your consent.

                    By submitting the Model Verification request, you affirm that:

                    - The information and data provided are accurate and not falsified.
                    - You provide consent for us to employ our servers in generating AI images that may emulate the facial features of the individual intended for replication. This individual corresponds to the person depicted in the identification and selfie consent images.
                    - You comprehend that making the content public may result in others visualizing the content.
                    - If the person requesting verification is not the same person portrayed in the image, you have obtained valid consent to request this model verification on our platform on their behalf.
                    - You provide consent to be depicted in the content.
                    - You provide consent for the public distribution of the content and to upload the content on our platform.
                    - If the content will be made public or available for downloading by other users, you provide consent to have the content downloaded.
                    - You acknowledge that you can contact us at any time to request the removal of your information.
                 </p>


                 <h1 className="text-white text-[25px] mb-[-35px] "> 14. Compliance policy</h1>
                  <p className="text-white text-[18px] py-10">
                    {" "}
                    Users can report any potentially problematic content they come across on our platform. We provide a convenient reporting mechanism to ensure easy reporting of such content. Review and Resolution: Once a reported complaint is received, our dedicated team will promptly review it within seven(7) business days. We take every reported complaint seriously and strive to resolve them in a fair and timely manner. Our investigations into reported complaints may result in the deletion of content that violates our terms of service. We take appropriate actions to address such violations, including termination of user accounts for repeated generation of illegal content or bypassing our filters.

                    Appeal Process:
                    1. Appeal Submission: To initiate an appeal, you must submit via email a written appeal to support@eromantic.ai explaining the reasons why you believe the decision made on your reported complaint was incorrect or unjustified. This appeal should clearly state your case and provide any supporting evidence or relevant information.
                    2. Appeal Review: Once we receive your appeal, our dedicated team will promptly review it within 7 business days. We will conduct a thorough reevaluation of the reported content and the initial decision made. Our goal is to ensure that all appeals are given fair consideration.
                    3. Appeal Outcome: Following the review of your appeal, we will communicate the outcome to you within 7 business days. There are three possible outcomes:
                    a. Upheld: If we determine that the initial decision made on your reported content was accurate and in compliance with our terms of service, the decision will be upheld, and the content will remain deleted or action taken against your account will be maintained.
                    b. Overturned: If we find that the initial decision was incorrect or unjustified, we will overturn the decision. The reported content will be restored if it was deleted, and any action taken against your account will be reversed.
                    c. Partial Overturn: In some cases, we may determine that the initial decision was partially incorrect or unjustified. In such situations, we will take appropriate measures to rectify the situation, which may include restoring some or all of the reported content or modifying the action taken against your account.
                    4. Final Decision: The decision made during the appeal process will be considered final. We strive to provide fair and accurate outcomes, but it's important to note that our decisions are based on our interpretation of our terms of service and platform guidelines.
                    5. Refills
                    You can request a limited number of credit restores on your generations. To be eligible for this, you should not have chosen more than one extension for that specific generation and the generation must note be expanded. The Basic plan does not offer any restores, the Pro plan provides up to 40 restores, the Platinum plan offers up to 100 restores, Diamond and Ruby provide up to 2500 restores.
                 </p>

                 <h1 className="text-white text-[25px] mb-[-35px] "> 15. Generation pricing</h1>
                  <p className="text-white text-[18px] py-10">
                    {" "}
                    To create images and videos, credits are necessary. Our pricing reflects the computational resources required for their generation. We reserve the right to adjust pricing at our discretion if we identify any discrepancies in the pricing structure. Basic AI model generations start at 0.75 credits, while HD model generations start at 1.25 credits. Additional features or settings may incur extra charges.
                 </p>


                 <h1 className="text-white text-[25px] mb-[-35px] "> 16. Intellectual property</h1>
                  <p className="text-white text-[18px] py-10">
                    {" "}
                    All logos, trademarks, trade names, service marks, and other distinctive brand features displayed on this website are the property of Eromantic-AI or its licensors. The design, layout, graphics, and other visual elements of the website are also protected by applicable intellectual property laws. You agree not to use, reproduce, modify, distribute, or display any of the aforementioned intellectual property without prior written consent from Eromantic-AI. Unauthorized use of these assets may result in legal action. By accessing or using this website, you acknowledge and agree that all intellectual property rights associated with it belong to Undresso Media Group SRL, and you shall not acquire any ownership or rights therein
                 </p>


                 <h1 className="text-white text-[25px] mb-[-35px] ">17. Termination of access</h1>
                  <p className="text-white text-[18px] py-10">
                    {" "}
                    We reserve the right to suspend or terminate your access to the Feature, the application, or any other services provided by us, if we determine, in our sole discretion, that you have violated these Terms of Service.
                 </p>






            </div>
            <div className=" max-w-full mt-2  border-t border-white">
              <div className="flex justify-between  gap-4 text-white pt-5 pb-5">
                <Link to="/blogs" className="text-[14px] lg:text-lg">
                  Blogs
                </Link>
                <Link to="/terms-of-service" className="text-[14px] lg:text-lg">
                  Terms of Service
                </Link>
                <Link to="/privacy-policy" className="text-[14px] lg:text-lg">
                  Privacy Policy
                </Link>
                <Link to="/cookie-policy" className="text-[14px] lg:text-lg">
                  Cookie Policy
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsOfService;
