import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Togal from '../../../components/Togal';
import { DeleteImg } from '../../../../assets';
import PagenameSearchBox from '../../../components/PagenameSearchBox';
import axios from 'axios';
import ConfirmationPopup from '../../../components/ConfirmationPopup'; // Import the ConfirmationPopup component

const BASE_URL = "https://api2.eromantic.ai/eromantic_api/";

const SubCategory = () => {
    const navigate = useNavigate();
    const [categories, setCategories] = useState([]);
    const [filteredCategories, setFilteredCategories] = useState([]); // State for filtered categories
    const [isPopupOpen, setIsPopupOpen] = useState(false); // State for controlling the popup
    const [selectedCategoryId, setSelectedCategoryId] = useState(null); // State to hold the selected category ID

    const handleAddCategory = () => {
        const authToken = localStorage.getItem('authToken');

        if (!authToken) {
            console.error('No auth token found. Please log in.');
            return;
        }
        navigate('/admin/addSubCategory');
    };

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const authToken = localStorage.getItem('authToken');

                if (!authToken) {
                    console.error('No auth token found. Please log in.');
                    return;
                }
                const response = await axios.get(`${BASE_URL}show_blog_categories_help/`, {
                    headers: {
                        'Authorization': `Token ${authToken}`  // or 'Bearer' for JWT tokens
                    }
                });
                setCategories(response.data);
                setFilteredCategories(response.data); // Initialize filtered categories
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };

        fetchCategories();
    }, []);

    const openPopup = (categoryId) => {
        setSelectedCategoryId(categoryId); // Store the selected category ID
        setIsPopupOpen(true); // Open the popup
    };

    const closePopup = () => {
        setIsPopupOpen(false); // Close the popup
        setSelectedCategoryId(null); // Clear the selected category ID
    };

    const handleDelete = async () => {
        try {
            const authToken = localStorage.getItem('authToken');
            if (!authToken) {
                console.error('No auth token found. Please log in.');
                return;
            }
            await axios.delete(`${BASE_URL}delete_category_help/${selectedCategoryId}/`, {
                headers: {
                    'Authorization': `Token ${authToken}`
                }
            });
            setCategories(categories.filter(category => category.id !== selectedCategoryId)); // Update the state after deletion
            closePopup(); // Close the popup after deletion
        } catch (error) {
            console.error('Error deleting category:', error);
        }
    };

    const handleStatusChange = async (event, category_id) => {
        console.log(event, category_id, "=======-----------")
        try {
            const data = new FormData();
            data.append('status', event.target.checked);
            const authToken = localStorage.getItem('authToken');
            await fetch(`https://api2.eromantic.ai/eromantic_api/update_category_status_help/${category_id}/`, {
                method: 'PUT',
                body: data,
                headers: {
                    'Authorization': `Token ${authToken}`,
                },
            });
        } catch (error) {
            console.error('Error updating category status:', error);
        }
    };

    const handleSearchChange = (e) => {
        const searchTerm = e.target.value.toLowerCase();
        const filtered = categories.filter(category => 
            category.title.toLowerCase().includes(searchTerm) ||
            category.description.toLowerCase().includes(searchTerm) ||
            (category.parent_category && category.parent_category.toLowerCase().includes(searchTerm))
        );
        setFilteredCategories(filtered);
    };

    return (
        <div className='h-full bg-white px-7 pb-20 font-josefin-sans'>
            <PagenameSearchBox 
                title='Category List' 
                placename='Search Category' 
                btnname='Add Category' 
                btnfunction={handleAddCategory} 
                onSearchChange={handleSearchChange} // Pass the search change handler
            />
            <div className="overflow-x-auto sm:rounded-lg mt-10">
                <table className="w-full text-left text-gray-500 border border-stone-300 table-auto sm:table-fixed">
                    <thead className="text-xl text-black bg-stone-300">
                        <tr>
                            <th scope="col" className="px-6 py-3">ID</th>
                            <th scope="col" className="px-6 py-3">Sub Category</th>
                            <th scope="col" className="px-6 py-3">Main Category</th>
                            <th scope="col" className="px-6 py-3">Description</th>
                            <th scope="col" className="px-6 py-3">Published</th>
                            <th scope="col" className="px-6 py-3">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredCategories.map((category) => (
                            <tr key={category.id} className="bg-white text-black text-xl font-normal border-b border-stone-300">
                                <td className="px-6 py-4 whitespace-nowrap">{category.id}</td>
                                <td className="px-6 py-4 whitespace-nowrap">{category.title}</td>
                                <td className="px-6 py-4 whitespace-nowrap">{category.parent_category}</td>
                                <td className="text-zinc-600 text-[14px] font-normal leading-tight break-words">{category.description}</td>
                                <td className="px-6 py-4">
                                    <Togal handleChange={(e) => handleStatusChange(e, category.id)} value={category.status} />
                                </td>
                                <td className="px-6 py-4 flex gap-5 justify-center items-center">
                                    <img
                                        src={DeleteImg}
                                        alt="deleteImg"
                                        className='hover:cursor-pointer'
                                        onClick={() => openPopup(category.id)}  // Trigger popup on click
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <ConfirmationPopup
                isOpen={isPopupOpen}
                onClose={closePopup}
                onConfirm={handleDelete}
            />
        </div>
    );
};

export default SubCategory;
