import React, { useEffect, useRef, useState } from "react";
import { IoClose, IoSettingsSharp } from "react-icons/io5";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import {
    bell,
    eheader,
    tokenss,
    Logo,
    userHeader,
    userProfileHeader,
} from "../../assets";
import { FaAngleDown, FaUserGear, FaUserLarge } from "react-icons/fa6";
import { FiAlignJustify } from "react-icons/fi";
import { IoCloseSharp } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/actions";
import UserApiClient from "../../utilities/apiClients";
import axios from "axios";
import { BaseApiUrl } from "../../utilities/enviroment";

const FrontendHeader = ({ toggleSidebar, isOpen, activeTab, setActiveTab }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [Userlogger, setUserlogger] = useState(null);
    const [active, setActive] = useState("join");
    const [usercheck, setUsercheck] = useState(true);
    const [Tokendata, setTokenData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    const user = useSelector((state) => state.user);
    const dispatch = useDispatch();

    const location = useLocation();
    const [newNotification, setNotifications] = useState([]);
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [isPopupVisibl, setPopupVisible] = useState(false);
    const [isPopupVisibletoken, setIsPopupVisibletoken] = useState(false);
    const popupRef = useRef(null);

    const handleClickOutside = (event) => {
        if (popupRef.current && !popupRef.current.contains(event.target)) {
            setIsPopupVisible(false);
            setPopupVisible(false);
            setIsPopupVisibletoken(false);
        }
    };

    const handleRequest = () => {
        setIsModalOpen(true);
        setIsPopupVisible(false);
        setPopupVisible(false);
        setIsPopupVisibletoken(false);
        setIsMenuOpen(!isMenuOpen);
        setIsDropdownOpen(!isDropdownOpen);
    };

    const navigate = useNavigate();

    const handleLogout = () => {
        dispatch(logout());
        localStorage.removeItem("token");
        localStorage.removeItem("u_id");
        localStorage.removeItem("hasVisited");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("login");
        navigate("/login");
    };
    const handleClickMenu = () => {
        const token = localStorage.getItem("token");
        const refreshToken = localStorage.getItem("refreshToken");
        const login = localStorage.getItem("login");

        // Check if all required items are present in localStorage
        if (token && refreshToken && login && user) {
            navigate("/");
        } else {
            navigate("/login"); // Otherwise, navigate to the home page
        }
    };
    // Function to handle bell click
    // const handleBellClick = () => {
    //     setPopupVisible(!isPopupVisible);
    // };

    useEffect(() => {
        setUserlogger(user.isAuthenticated);
        const popupVisibility =
            isPopupVisible || isPopupVisibl || isPopupVisibletoken;
        if (popupVisibility) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [
        isPopupVisible,
        isPopupVisibl,
        isPopupVisibletoken,
        user.isAuthenticated,
    ]);

    const navData = [
        // { name: 'DISCOVER', link: '/dashboard/discover' },
        // { name: 'GIRLS', link: '/dashboard/girls' },
        // { name: 'GENERATIONS', link: '/dashboard/generations' },
        // { name: 'PREMIUM', link: '/dashboard/creators' },
        // { name: 'GUIDE', link: '/joinhgh' },
    ];

    const userPagesdata = [
        {
            icon: <FaUserLarge size={15} />,
            name: "My Profile",
            path: "/dashboard/accountProfile",
        },
        {
            icon: <IoSettingsSharp size={15} />,
            name: "Gallery",
            path: "/dashboard/generations",
        },
        // { icon: <IoSettingsSharp size={20} />, name: 'Model Verification', path: '/dashboard/modelVerification' },
        // { icon: <IoSettingsSharp size={20} />, name: 'Modality', path: '/dashboard/modality' },
        // { icon: <IoSettingsSharp size={20} />, name: 'Referrals', path: '/dashboard/referrals' },
        // { icon: <IoSettingsSharp size={20} />, name: 'Support', path: '/dashboard/support' },
    ];

    const handleMenuToggle = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const handleDropdownToggle = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const authToken = localStorage.getItem("token");
                const response = await axios.get(
                    `https://api2.eromantic.ai/api/v1/get_token/`,
                    {
                        headers: {
                            Authorization: `Bearer ${authToken}`,
                        },
                    }
                );
                console.log("qwqwqwqwq", response.data);
                setTokenData(response.data);
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    const timeAgo = (timestamp) => {
        const now = new Date();
        const past = new Date(timestamp);
        const seconds = Math.floor((now - past) / 1000);
      
        const intervals = {
          year: 31536000,
          month: 2592000,
          week: 604800,
          day: 86400,
          hour: 3600,
          minute: 60,
          second: 1,
        };
      
        for (const [unit, value] of Object.entries(intervals)) {
          const count = Math.floor(seconds / value);
          if (count >= 1) {
            return `${count} ${unit}${count > 1 ? "s" : ""} ago`;
          }
        }
      
        return "Just now";
      };

    useEffect(() => {
        UserApiClient.get('api/v1/notification/', {}, false, true, false).then(async (res) => {
            if (res.success) {
                setNotifications(res.data);
            }
        })
    }, []);

    return (
        <>
            {/* <div className={`fixed border-b border-b-solid border-gray-300 shadow-md top-0 left-0 right-0 flex justify-between gap-5 px-2 xl:pe-[30px] py-4 items-center bg-white z-1000 ${!usercheck ? 'pointer-events-none opacity-100' : ''}`} style={{ zIndex: "1000", opacity: usercheck ? '' : '0.5' }}> */}
            <div
                className={`fixed shadow-md top-0 left-0 right-0 flex justify-between gap-5 px-2 xl:pe-[30px] py-4 items-center bg-white z-1000 ${!usercheck ? "pointer-events-none opacity-100" : ""
                    }`}
                style={{
                    zIndex: "1000",
                    opacity: usercheck ? "" : "0.5",
                    // background:
                    //     "linear-gradient(135deg, rgb(255 255 255 / 81%), rgb(161 145 145) 40%, rgb(109 109 109 / 80%) 70%) center center / cover",
                    background: "linear-gradient(135deg, rgb(255, 255, 255), rgb(161, 145, 145) 40%, rgb(109, 109, 109) 70%) center center / cover",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                }}
            >
                <div className="flex">
                    <button
                        onClick={toggleSidebar}
                        className="text-black bg-transparent border-none cursor-pointer ms-4 me-10"
                    >
                        {isOpen ? (
                            <IoCloseSharp className="text-3xl hover:shadow-[0px_10px_25px_rgba(255,0,0,0.6)] transition-shadow duration-300" />
                        ) : (
                            <FiAlignJustify className="text-3xl hover:shadow-[0px_10px_25px_rgba(255,0,0,0.6)] transition-shadow duration-300" />
                        )}
                    </button>
                    <div onClick={handleClickMenu} className="cursor-pointer">
                        <img src={Logo} alt="logo" className="w-56 md:w-56" />
                    </div>
                </div>
                {location.pathname === "/" ? (
                    <div className="formob">
                        <button
                            className={`inline-flex	items-center	px-4 py-2 text-[20px] ${activeTab === "Girlfriend"
                                    ? "text-[20px] text-[#99000b] border-b-4 border-[#99000b]"
                                    : "text-black-700 "
                                }`}
                            onClick={() => setActiveTab("Girlfriend")}
                        >
                            <i class="fa-solid fa-venus mr-2"></i>Girlfriend
                        </button>
                        <button
                            className={`inline-flex	items-center	px-4 py-2 text-[20px] ${activeTab === "Boyfriend"
                                    ? "text-[20px] text-[#99000b] border-b-4 border-[#99000b]"
                                    : "text-black-700 "
                                }`}
                            onClick={() => setActiveTab("Boyfriend")}
                        >
                            <i class="fa-solid fa-mars mr-2"></i>Boyfriend
                        </button>
                    </div>
                ) : (
                    ""
                )}

                {Userlogger ? (
                    <div className="flex items-center gap-8">
                        <div className="hidden lg:flex">
                            <nav>
                                <ul className="flex gap-5 xl:gap-10 font-semibold">
                                    {navData.map((item, index) => (
                                        <li
                                            key={index}
                                            className={`hover:cursor-pointer text-lg hover:text-gray-500 ${location.pathname === item.link ? "text-red-500" : ""
                                                }`}
                                        >
                                            <Link to={item.link}>{item.name}</Link>
                                        </li>
                                    ))}
                                </ul>
                            </nav>
                        </div>
                        <div className="">
                            <div className="fordesk">
                                <img
                                    onClick={() => setIsPopupVisible(true)}
                                    src={user.user.profile_pic?.includes('http') ? user.user.profile_pic : BaseApiUrl + user.user.profile_pic}
                                    alt="userHeader"
                                    className="hover:cursor-pointer w-[40px] h-[40px] rounded-full 
                                        hover:shadow-[0px_10px_25px_rgba(255,0,0,0.6)] transition-shadow duration-300"
                                />
                            </div>
                            <div className="formobile mx-2">
                                <button style={{ fontSize: "22px" }} onClick={handleLogout}>
                                    {" "}
                                    <i class="fa-solid fa-right-from-bracket w-25"></i>
                                </button>
                            </div>
                        </div>
                        <div className="lg:flex justify-between items-center gap-5 xl:gap-5 hidden">
                            <div className="-mr-3">
                                <img
                                    src={bell}
                                    className="hover:shadow-[0px_10px_25px_rgba(255,0,0,0.6)] transition-shadow duration-300 cursor-pointer"
                                    alt="bell"
                                    style={{borderRadius: "999px", width: "74%" }}
                                    onClick={() => setPopupVisible(true)}
                                />
                            </div>
                            <button
                                onClick={() => setIsPopupVisibletoken(true)}
                                className=" p-[2px] rounded-full flex gap-2 items-center justify-center hover:shadow-[0px_10px_25px_rgba(255,0,0,0.6)] transition-shadow duration-300"
                            >
                                <img src={tokenss} alt="token" className="w-10 rounded-full " />
                                {/* <p className='text-white font-bold text-2xl'>0</p> */}
                            </button>

                            {/* <div>
                                <NavLink
                                            to="/Aidreamchat"
                                            className={`border-2 py-2 px-3 border-[#9b9b9b] rounded-[50px] rounded-full transition-colors duration-300 ${active === 'login' ? 'bg-[#99000B] text-white' : 'hover:bg-[#99000B] hover:text-white'
                                                }`}
                                            // onClick={() => setActive('login')}
                                        >
                                            <i class="fa-solid fa-comments"></i>
                                        </NavLink>
                                    
                                </div> */}

                            <div>
                                <NavLink
                                    to="/priceplan"
                                    className={`hover:shadow-[0px_10px_25px_rgba(255,0,0,0.6)] transition-shadow duration-300 border-2 px-5 border-[#99000B] rounded-[50px] p-2 rounded-full transition-colors duration-300 ${active === "join"
                                            ? "bg-[#99000B] text-white"
                                            : "hover:bg-[#99000B] hover:text-white"
                                        }`}
                                    onClick={() => setActive("join")}
                                >
                                    {/* <i class="fa-solid fa-square-poll-vertical mr-2"></i> */}
                                    Pricing
                                </NavLink>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="flex items-center gap-8">
                        <div className="lg:flex">
                            <nav>
                                <ul className="flex gap-[20px]">
                                    <li className="text-black text-[21px]">
                                        <NavLink
                                            to="/login"
                                            className={`border-2 py-2 px-3 border-[#9b9b9b] rounded-[50px] rounded-full transition-colors duration-300 ${active === "login"
                                                    ? "bg-[#99000B] text-white"
                                                    : "hover:bg-[#99000B] hover:text-white"
                                                }`}
                                            onClick={() => setActive("login")}
                                        >
                                            <i class="fa-solid fa-arrow-right-to-bracket"></i>
                                        </NavLink>
                                    </li>
                                    <li className="text-black text-[21px] hidden sm:block">
                                        <NavLink
                                            to="/join"
                                            className={`border-2 px-5 border-[#99000B] rounded-[50px] p-2 rounded-full transition-colors duration-300 ${active === "join"
                                                    ? "bg-[#99000B] text-white"
                                                    : "hover:bg-[#99000B] hover:text-white"
                                                }`}
                                            onClick={() => setActive("join")}
                                        >
                                            <i class="fa-solid fa-gift mr-2"></i>
                                            Join for Free
                                        </NavLink>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                )}

                {/* <div className='lg:hidden hover:cursor-pointer'>
                    {isMenuOpen ? <IoClose size={28} onClick={handleMenuToggle} /> : <CgMenuRightAlt size={28} onClick={handleMenuToggle} />}
                </div> */}
            </div>

            {isMenuOpen && (
                <div className="bg-white lg:hidden pb-5 z-50  w-full flex justify-start gap-5">
                    <nav>
                        <ul className="flex flex-col ms-5 gap-4 font-semibold">
                            {navData.map((item, index) => (
                                <li
                                    key={index}
                                    className={`hover:cursor-pointer hover:text-gray-500 ${location.pathname === item.link ? "text-red-500" : ""
                                        }`}
                                >
                                    <Link to={item.link} onClick={handleMenuToggle}>
                                        {item.name}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                        <div className="ps-5 gap-3 flex flex-col py-3 ">
                            <button className="bg-red-800 w-fit hover:bg-red-700  py-2 px-4 rounded-full flex gap-2 items-center">
                                <img src={eheader} alt="eheader" />
                                <p className="text-white font-bold text-2xl">0</p>
                            </button>
                            <div>
                                <img src={bell} alt="bell" />
                            </div>
                            <div className="pb-4 mt-2">
                                <button
                                    onClick={handleLogout}
                                    className="w-32 py-2 hover:bg-red-600 bg-red-800 font-semibold text-white rounded-lg"
                                >
                                    Logout
                                </button>
                            </div>
                        </div>
                    </nav>
                    <div className="relative z-50 w-[240px] pr-2 md:px-5">
                        <div
                            onClick={handleDropdownToggle}
                            className="flex justify-between items-center hover:cursor-pointer"
                        >
                            <button className="flex justify-center items-center gap-2">
                                <FaUserGear size={25} />
                                <p className="font-semibold">User</p>
                            </button>
                            <FaAngleDown />
                        </div>
                        {isDropdownOpen && (
                            <div className="absolute mt-2 w-full bg-white">
                                <div className="font-semibold">
                                    {userPagesdata.map((item, index) => (
                                        <NavLink
                                            to={item.path}
                                            key={index}
                                            onClick={handleDropdownToggle}
                                        >
                                            <div className="flex justify-start gap-5 pt-2 items-center">
                                                {item.icon}
                                                <p
                                                    className={`hover:cursor-pointer hover:text-gray-500 ${location.pathname === item.path
                                                            ? "text-red-500"
                                                            : ""
                                                        }`}
                                                    onClick={handleMenuToggle}
                                                >
                                                    {item.name}
                                                </p>
                                            </div>
                                        </NavLink>
                                    ))}
                                    <div
                                        onClick={handleRequest}
                                        className="flex pt-2 justify-start gap-5 items-center hover:cursor-pointer"
                                    >
                                        <IoSettingsSharp size={20} />
                                        <p className="hover:cursor-pointer hover:text-gray-500">
                                            Request a feature
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}

            {/* Notification Popup */}
            {isPopupVisibl && (
  <div
    ref={popupRef}
    className="fixed z-[9999] top-16 right-8 bg-white shadow-lg rounded-2xl p-4 w-80 max-h-[450px] overflow-y-auto border border-gray-300"
  >
    {/* Header */}
    <div className="flex justify-between items-center border-b pb-3 mb-4">
      <h4 className="font-bold text-lg text-gray-800">Notifications</h4>
      <button
        className="text-gray-400 hover:text-gray-600 transition duration-200"
        onClick={() => setPopupVisible(false)}
      >
        &#x2715;
      </button>
    </div>

    {/* Notification List */}
    {newNotification.length > 0 ? (
      <ul className="space-y-4">
        {newNotification.map((notif, index) => (
          <li
            key={notif.id}
            className="flex items-center bg-gray-50 p-3 rounded-lg shadow-sm hover:bg-gray-100 transition duration-300"
          >
            {/* Avatar */}
            <div className="mr-3">
              <div className="w-10 h-10 flex items-center justify-center bg-gray-400 text-red-800 rounded-full">
                <span className="text-xl font-semibold">
                  {notif.text[0].toUpperCase()}
                </span>
              </div>
            </div>
            {/* Notification Content */}
            <div className="flex-1">
              <p className="text-gray-800 font-medium">{notif.text}</p>
              <span className="text-xs text-gray-500">
              {timeAgo(notif.created_at || "Just now")}
              </span>
            </div>
            {/* Action Button */}
    
          </li>
        ))}
      </ul>
    ) : (
      <div className="flex flex-col items-center justify-center text-gray-500 h-[200px]">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-10 w-10 mb-2 text-gray-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V4a2 2 0 10-4 0v1.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
          />
        </svg>
        <p>No new notifications</p>
      </div>
    )}
  </div>
)}


            {isPopupVisibletoken && (
                <>
                    <div className="fixed z-[9999] top-16 right-12 bg-white shadow-lg rounded-lg p-1 w-64">
                        {/* <div className="fixed top-16 right-12 z-[9999]  w-[40%] flex justify-end pr-16 text-black font-josefin-sans"> */}
                        <div
                            ref={popupRef}
                            className="bg-white rounded-lg w-62 mt-2 text-center"
                        >
                            {/* <div className='flex gap-5 py-5 justify-center items-center border-b-2 border-stone-300'>
                            <div>
                                <img src={userProfileHeader} alt="userProfileHeader" className='w-12 h-12 rounded-full' />
                            </div>
                            <div className='text-start'>
                                <p className='text-[17px] font-medium'>{Userlogger}</p>
                            </div>
                        </div> */}
                            <div className="px-4 py-2">
                                <NavLink onClick={() => setIsPopupVisible(false)}>
                                    <div
                                        className={`flex p-2 rounded-full justify-center gap-5 items-center ${Tokendata.subscription_type === "bronze_subscription"
                                                ? "bg-[#cd7f32]"
                                                : Tokendata.subscription_type === "silver_subscription"
                                                    ? "bg-[#c0c0c0]"
                                                    : Tokendata.subscription_type === "gold_subscription"
                                                        ? "bg-[#ffd700]"
                                                        : "bg-[#fa808c]" // Default color for 'free'
                                            }`}
                                    >
                                        <p className="text-sm font-bold">
                                            {Tokendata.subscription_type === "free"
                                                ? "free"
                                                : Tokendata.subscription_type === "bronze_subscription"
                                                    ? "Bronze User"
                                                    : Tokendata.subscription_type === "silver_subscription"
                                                        ? "Silver User"
                                                        : Tokendata.subscription_type === "gold_subscription"
                                                            ? "Gold User"
                                                            : ""}
                                        </p>
                                    </div>
                                </NavLink>
                                <NavLink onClick={() => setIsPopupVisible(false)}>
                                    <div className="flex pt-2 justify-between gap-5 items-center">
                                        <p className="text-sm font-bold">Your Token</p>
                                        <p className="text-sm font-bold">
                                            <i class="fa-brands fa-bitcoin"></i>
                                            {Tokendata.token == "null" ? "0" : (Math.round(Tokendata.token * 100) / 100).toFixed(2)}
                                        </p>
                                        {/* <p className="text-sm font-bold"><i class="fa-brands fa-bitcoin"></i>0</p> */}
                                    </div>
                                </NavLink>
                                {Tokendata.subscription_type === "free" ? (
                                    <div className="mt-1 ">
                                        <div className="flex justify-center items-center p-4 bg-red-100 border-l-4 border-red-500 rounded-lg shadow-md">
                                            {/* <i className="fa-solid fa-exclamation-triangle text-red-600 mr-3"></i> */}
                                            <span className="text-sm font-bold text-red-600">
                                                You don't have any subscription.{" "}
                                                <span className="text-blue-600 cursor-pointer hover:underline">
                                                    Buy now
                                                </span>{" "}
                                                to unlock exclusive features and enjoy more benefits!
                                            </span>
                                        </div>

                                        <button
                                            onClick={() => navigate("/priceplan")}
                                            className="mt-2 w-full p-2 hover:bg-red-600 bg-red-800 font-semibold text-white rounded-lg"
                                        >
                                            Subscribe Now
                                        </button>
                                    </div>
                                ) : (
                                    <>
                                        <NavLink onClick={() => setIsPopupVisible(false)}>
                                            <div className="flex p-2 justify-center gap-5 items-center">
                                                <p className="text-sm font-bold">Token Cost:</p>
                                            </div>
                                        </NavLink>
                                        <NavLink onClick={() => setIsPopupVisible(false)}>
                                            <div className="flex pt-3 justify-between gap-5 items-center">
                                                <p className="text-sm font-bold">
                                                    <i className="fa-solid fa-message mr-2"></i>Message
                                                </p>
                                                <p className="text-sm font-bold">
                                                    {Tokendata.subscription_type === "free"
                                                        ? ""
                                                        : Tokendata.subscription_type ===
                                                            "bronze_subscription" ||
                                                            Tokendata.subscription_type ===
                                                            "silver_subscription"
                                                            ? "0.1 Token"
                                                            : Tokendata.subscription_type ===
                                                                "gold_subscription"
                                                                ? "Unlimited"
                                                                : ""}
                                                </p>
                                            </div>
                                        </NavLink>

                                        <NavLink onClick={() => setIsPopupVisible(false)}>
                                            <div className="flex pt-3 justify-between gap-5 items-center">
                                                <p className="text-sm font-bold">
                                                    <i className="fa-regular fa-heart mr-2"></i>
                                                    GF/BF Creation
                                                </p>
                                                <p className="text-sm font-bold">
                                                    {Tokendata.subscription_type === "free" ? "" : "5T"}
                                                </p>
                                            </div>
                                        </NavLink>

                                        <NavLink onClick={() => setIsPopupVisible(false)}>
                                            <div className="flex pt-3 justify-between gap-5 items-center">
                                                <p className="text-sm font-bold">
                                                    <i className="fa-regular fa-image mr-2"></i>Image
                                                    Generation
                                                </p>
                                                <p className="text-sm font-bold">
                                                    {Tokendata.subscription_type === "free" ? "" : "1T"}
                                                </p>
                                            </div>
                                        </NavLink>

                                        {/* <NavLink onClick={() => setIsPopupVisible(false)}>
                      <div className="flex pt-3 justify-between gap-5 items-center">
                        <p className="text-sm font-bold">
                          <i className="fa-solid fa-video mr-2"></i>Video
                          Generation
                        </p>
                        <p className="text-sm font-bold">
                          {Tokendata.subscription_type === "free"
                            ? ""
                            : Tokendata.subscription_type ===
                                "bronze_subscription" ||
                              Tokendata.subscription_type ===
                                "silver_subscription"
                            ? "5T"
                            : Tokendata.subscription_type ===
                              "gold_subscription"
                            ? "Unlimited"
                            : ""}
                        </p>
                      </div>
                    </NavLink> */}

                                        <NavLink onClick={() => setIsPopupVisible(false)}>
                                            <div className="flex pt-3 justify-between gap-5 items-center">
                                                <p className="text-sm font-bold">
                                                    <i className="fa-solid fa-crop-simple mr-2"></i>
                                                    Upscale Image
                                                </p>
                                                <p className="text-sm font-bold">
                                                    {Tokendata.subscription_type === "free" ? "" : "1T"}
                                                </p>
                                            </div>
                                        </NavLink>

                                        <NavLink onClick={() => setIsPopupVisible(false)}>
                                            <div className="flex pt-3 justify-between gap-5 items-center">
                                                <p className="text-sm font-bold">
                                                    <i className="fa-solid fa-headset mr-2"></i>Voice Call
                                                </p>
                                                <p className="text-sm font-bold">
                                                    {Tokendata.subscription_type === "free"
                                                        ? ""
                                                        : "2T/Minute"}
                                                </p>
                                            </div>
                                        </NavLink>

                                        <NavLink onClick={() => setIsPopupVisible(false)}>
                                            <div className="flex pt-3 justify-between gap-5 items-center">
                                                <p className="text-sm font-bold">
                                                    <i className="fa-solid fa-volume-high mr-2"></i>Voice
                                                    Message
                                                </p>
                                                <p className="text-sm font-bold">
                                                    {Tokendata.subscription_type === "free" ? "" : "0.5T"}
                                                </p>
                                            </div>
                                        </NavLink>

                                        <NavLink onClick={() => setIsPopupVisible(false)}>
                                            <div className="flex pt-3 justify-between gap-5 items-center">
                                                <p className="text-sm font-bold">
                                                    <i className="fa-solid fa-gear mr-2"></i>Chat
                                                    Customization
                                                </p>
                                                <p className="text-sm font-bold">
                                                    {Tokendata.subscription_type === "free"
                                                        ? ""
                                                        : Tokendata.subscription_type ===
                                                            "bronze_subscription" ||
                                                            Tokendata.subscription_type ===
                                                            "silver_subscription"
                                                            ? "0.2T"
                                                            : Tokendata.subscription_type ===
                                                                "gold_subscription"
                                                                ? "0.2T"
                                                                : ""}
                                                </p>
                                            </div>
                                        </NavLink>

                                        {Tokendata.subscription_type === "gold_subscription" ? (
                                            <NavLink onClick={() => setIsPopupVisible(false)}>
                                                <div className="flex pt-3 justify-between gap-5 items-center">
                                                    <p className="text-sm font-bold">
                                                        <i className="fa-solid fa-pen-to-square mr-2"></i>
                                                        Message Edit
                                                    </p>
                                                    <p className="text-sm font-bold">
                                                        {Tokendata.subscription_type === "free"
                                                            ? ""
                                                            : Tokendata.subscription_type ===
                                                                "gold_subscription"
                                                                ? "0.1T"
                                                                : ""}
                                                    </p>
                                                </div>
                                            </NavLink>
                                        ) : (
                                            ""
                                        )}
                                    </>
                                )}
                                {/* <div onClick={handleRequest} className='flex pt-2 justify-start gap-5 items-center hover:cursor-pointer'>
                                <IoSettingsSharp size={20} />
                                <p className="text-xl font-bold">Request a feature</p>
                            </div> */}
                            </div>
                            {Tokendata.subscription_type === "free" ? (
                                ""
                            ) : (
                                <div className="px-3 mt-1 ">
                                    <button
                                        onClick={() => navigate("/priceplan?token=true")}
                                        className="my-1 w-full py-2 hover:bg-red-600 bg-red-800 font-semibold text-white rounded-lg"
                                    >
                                        Buy More Tokens
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </>
            )}

            {isPopupVisible && (
                <div className="fixed z-[9999] top-0 right-14 lg:top-[5rem] lg:right-[9.5rem] xl:right-[10.6rem] bg-white shadow-lg rounded-lg p-1 w-64">
                    <div className="absolute left-[45%] -top-4 w-0 h-0 border-l-8 border-l-transparent border-r-transparent border-r-8 border-b-[16px] border-b-white" ></div>
                    {/* <div className="fixed right-[9rem] 2xl:top-[7%] z-[9999] flex justify-end pr-16 text-black font-josefin-sans"> */}
                    <div ref={popupRef} className="bg-white rounded-lg mt-2 text-center">
                        <div className="flex gap-5 py-5 justify-center items-center border-y-2 border-stone-300">
                            <div>
                                <img
                                    src={user.user.profile_pic?.includes('http') ? user.user.profile_pic : BaseApiUrl + user.user.profile_pic}
                                    alt="userProfileHeader"
                                    className="w-12 h-12 rounded-full border border-red-600"
                                />
                            </div>
                            <div className="flex flex-col items-start">
                                <p>Welcome Back</p>
                                {user ? <p>@{user.user.email.split('@')[0]}</p> : <p>Dummy</p>}
                            </div>
                        </div>
                        <div className="flex flex-col justify-center ps-3">
                            {userPagesdata.map((item, index) => (
                                <NavLink
                                    key={index}
                                    to={item.path}
                                    onClick={() => setIsPopupVisible(false)}
                                >
                                    <div className="flex pt-2 justify-start gap-5 items-center">
                                        <span className="text-red-600" >{item.icon}</span>
                                        <p className="text-md font-bold">{item.name}</p>
                                    </div>
                                </NavLink>
                            ))}
                        </div>
                        {/* <div className="px-4">
              {
                                userPagesdata.map((item, index) => (
                                    <NavLink key={index} to={item.path} onClick={() => setIsPopupVisible(false)}>
                                        <div className='flex pt-2 justify-start gap-5 items-center'>
                                            {item.icon}
                                            <p className="text-md font-bold">{item.name}</p>
                                        </div>
                                    </NavLink>
                                ))
                            }
              <div onClick={handleRequest} className='flex pt-2 justify-start gap-5 items-center hover:cursor-pointer'>
                                <IoSettingsSharp size={20} />
                                <p className="text-xl font-bold">Request a feature</p>
                            </div>
            </div> */}
                        <div className="px-7 mt-1 ">
                            <button
                                onClick={handleLogout}
                                className="my-1 w-full py-2 hover:bg-red-600 bg-red-800 font-semibold text-white rounded-lg"
                            >
                                Logout
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {isModalOpen && (
                <div className="h-[810px] md:h-[965px] lg:h-[100vh] fixed inset-0 flex items-center justify-center bg-black font-josefin-sans bg-opacity-50 z-50">
                    <div className="bg-white rounded-lg w-[350px] md:w-[650px] lg:w-[900px] p-7 xl:px-16 xl:py-12 relative z-50">
                        <button
                            className="absolute top-3 right-3 text-lg font-bold z-50"
                            onClick={() => setIsModalOpen(false)}
                        >
                            <IoClose size={23} />
                        </button>
                        <h2 className="text-center text-3xl font-semibold mb-2">
                            Request a feature
                        </h2>
                        <p className="text-center text-stone-500 text-xl font-semibold mb-4">
                            Have a certain feature in mind or want us to improve certain
                            processes? Feel free to let us know down below.
                        </p>
                        <textarea
                            className="w-full text-lg lg:text-xl h-32 p-2 border text-black focus:outline-none border-black rounded-md"
                            placeholder="Type here..."
                        />
                        <div className="flex justify-center mt-4">
                            <button className="bg-red-800 hover:bg-red-700 w-72 text-2xl text-white font-semibold rounded-xl px-6 py-2">
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default FrontendHeader;
