import React, { useState, cloneElement } from 'react';
import DashboardHeader from '../components/DashboardHeader';
import Sidebar from '../../frontend/components/Sidebar';
import Bottombar from '../../frontend/components/Bottombar';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ScrolltoTop from '../../frontend/components/ScrolltoTop';

function LayoutFrontend({ children }) {

    const user = useSelector((state) => state.user.user);

    const [isOpen, setIsOpen] = useState(false);

    const [activeTab, setActiveTab] = useState('Girlfriend');

    const toggleSidebar = () => {
        setIsOpen(prev => !prev);
    };

    const handleActiveTab = (data) => {
        setActiveTab(data);
    };

    const location = useLocation();
    const isAidreamGFchat = location.pathname.startsWith('/AidreamGFchat');
    
    return (
        <div>
            <ScrolltoTop/>
            <header>
                <Sidebar isOpen={isOpen} setIsOpen={setIsOpen}  setActiveTab={handleActiveTab} />
            </header>
            <div className='backgroundStyle'>
                <div className="main-container h-[100vh] float-start w-full">
                    {/* Conditionally render the DashboardHeader */}
                    {!isAidreamGFchat && (
                        <DashboardHeader toggleSidebar={toggleSidebar} isOpen={isOpen} activeTab={activeTab} setActiveTab={handleActiveTab} />
                    )}
                    
                    <div className={`ml-[70px] h-full ${isAidreamGFchat ? 'mt-0' : 'mt-[90px]'}`}>
                        {React.Children.map(children, child =>
                            cloneElement(child, { activeTab, handleActiveTab })
                        )}
                    </div>
                    <Bottombar toggleSidebar={toggleSidebar} isOpen={isOpen} setActiveTab={handleActiveTab} />
                </div>
            </div>
        </div>
    );
}

export default LayoutFrontend;
