import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import InputBox from '../../../components/InputBox';
import { imgSelector } from '../../../../assets';
import PagenameSearchBox from '../../../components/PagenameSearchBox';
import UserApiClient from '../../../../utilities/apiClients';

const AddCategory = () => {
    const navigate = useNavigate();
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [parentCategory, setParentCategory] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        setSelectedImage(file);
    };

    const handleSubmit = async () => {
        const formData = new FormData();
        formData.append('title', title);
        formData.append('description', description);
        if (parentCategory) {
            formData.append('parent_category', parentCategory);
        }
        if (selectedImage) {
            formData.append('image', selectedImage);
        }
        await UserApiClient.post(`api/add_category/`, formData, true).then(async res => {
            if (res.success) {
                navigate('/admin/category');
            }
        })
};

    return (
        <>
            <div className='h-full bg-white px-7 pb-20 font-josefin-sans'>
                <PagenameSearchBox title='Category List' btnname='Back to Category List' btnfunction={() => navigate('/admin/category')} />
                <InputBox title='Category Title' placholderName='Category' value={title} onChange={(e) => setTitle(e.target.value)} />
                <InputBox title='Category Description' placholderName='Description' value={description} onChange={(e) => setDescription(e.target.value)} />

                <div className='pb-6 flex flex-col'>
                    <label className='text-zinc-500 pb-3 font-semibold text-[22px]'>Category Image</label>
                    <div className="flex items-center justify-center w-80">
                        <label
                            htmlFor="dropzone-file"
                            className="flex flex-col items-center justify-center w-full h-64 border border-zinc-300 rounded-lg cursor-pointer bg-white px-2 py-3"
                        >
                            <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                {selectedImage ? (
                                    <img
                                        src={URL.createObjectURL(selectedImage)}
                                        alt="Selected"
                                        className="w-full h-32 object-cover mb-4"
                                    />
                                ) : (
                                    <img src={imgSelector} alt="imgSelector" className='w-10 lg:w-20' />
                                )}
                                <input
                                    id="dropzone-file"
                                    type="file"
                                    className="hidden"
                                    accept='image/*'
                                    onChange={handleImageChange}
                                />
                                <p className="mt-6 text-base text-stone-400">
                                    {selectedImage ? 'Change Featured Image' : 'Add Featured Image'}
                                </p>
                            </div>
                        </label>
                    </div>
                </div>
                <button className='rounded-lg w-full mt-6 bg-red-800 hover:bg-red-700 text-lg font-semibold text-white p-4' onClick={handleSubmit}>Add Category</button>
            </div>
        </>
    );
}

export default AddCategory;
