export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT = 'LOGOUT';

// Action for logging in the user
export const loginSuccess = (user) => {
  return {
    type: LOGIN_SUCCESS,
    payload: user,
  };
};

// Action for logging out the user
export const logout = () => {
  localStorage.removeItem('authToken');
  localStorage.removeItem('token');
  return {
    type: LOGOUT,
  };
};
