import React, { useCallback, useEffect, useState } from 'react';
import { AiFillLike } from 'react-icons/ai';
import { FaHeart } from 'react-icons/fa6';
import axios from 'axios';
import { IoIosTime } from 'react-icons/io';
import { useNavigate } from "react-router-dom";
import {
    img1, img3, img4, img7, pg12, pg22, pg23, pg24, pg25, pg26, brown1, AURORA1, AURORA2, ELORA1, ELORA2, LISA1, LISA2, MYRA1, MYRA2, NOLA1, NOLA2, RHEA1, RHEA2, YESENIA1, YESENIA2, ZEMIRA1, ZEMIRA2, ZULEIKA1, ZULEIKA2, GIANINA1, GIANINA2, whitebg, Bodyicon,
    Bodyback, Relationshipicon, Location, age, brest, country, eyes, hair, occupation, manbody, personality, abrafo1, abrafo2, akshat1, akshat2, brian1, brian2,
    charles1, charles2, daniel1, daniel2, joseph1, joseph2, joshua1, joshua2, matthew1, matthew2, thomas1, thomas2, william1, william2, ashley1,
    ashley2, avni1, avni2, emma1, emma2, grace1, grace2, hannah1, hannah2, izumi1, izumi2, nailah1, nailah2, olivia1, olivia2, sarah1, sarah2,
    sophia1, sophia2, CONNOR1, CONNOR2, JACK1, JACK2, LUKE1, LUKE2, ETHAN1, ETHAN2, OZAN1, OZAN2, JUNG1, JUNG2, HORO1, HORO2, ANDREW1, ANDREW2, JACOB1, JACOB2,
    HENRY1, HENRY2,
} from '../../assets';
import Characters from "./LandingPage/Characters";
import OurcommunitySection from "./LandingPage/OurcommunitySection";
import Accordion from "./LandingPage/Accordion";
import Blogs from "./LandingPage/blogs";
import Footer from "../components/Footer";
import LandingPopup from "./LandingPopup";
import { useDispatch, useSelector } from 'react-redux';
import { loginSuccess, logout } from '../../redux/actions';
import { useRef } from 'react';
import UserApiClient from '../../utilities/apiClients';


const LandingPage = ({ activeTab, handleActiveTab }) => {
    // Static Data
    const [realisticGirlData, SetrealisticGirlData] = useState([])
    const [animeGirlData, SetanimeGirlData] = useState([])
    const [realisticBoyData, SetrealisticBoyData] = useState([])
    const [animeBoyData, SetanimeBoyData] = useState([])
    const [imgdata, setImgdata] = useState([]);
    const [imgdataanime, setImgdataanime] = useState([]);
    const [imgboydata, setImgboydata] = useState([]);
    const [imgboydataani, setImgboydataani] = useState([]);

    // Others
    const [selected, setSelected] = useState('Saved');
    const [Userlogger, setUserlogger] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modaldata, setModaldata] = useState('');
    const [modalImg, setModalImg] = useState("");
    const [liked, setLiked] = useState(false);
    const [activeTabani, setActiveTabani] = useState(1);
    const [LikesCount, setLikesCount] = useState(0);
    const [isVisible, setIsVisible] = useState(true);
    const timeRef = useRef(1800000); // 30 minutes in milliseconds


    // let LikesCount=0;
    const BASE_URL = "https://api2.eromantic.ai/assistant_api/";

    const openModal = async (assistant_id, image) => {
        if (!assistant_id) {
            console.error("assistant_id is required");
            return;
        }
        // let selectedImage = null;
        // if (imgdataanime.some(item => item.assistant_id === assistant_id)) {
        //     selectedImage = imgdataanime.find(item => item.assistant_id === assistant_id);
        // } else if (imgdata.some(item => item.assistant_id === assistant_id)) {
        //     selectedImage = imgdata.find(item => item.assistant_id === assistant_id);
        // } else if (imgboydata.some(item => item.assistant_id === assistant_id)) {
        //     selectedImage = imgboydata.find(item => item.assistant_id === assistant_id);
        // }
        // else if (imgboydataani.some(item => item.assistant_id === assistant_id)) {
        //     selectedImage = imgboydataani.find(item => item.assistant_id === assistant_id);
        // }
        if (image) {
            setModalImg(image);
        }

        await UserApiClient.get(`assistant_api/fetch_static_data/?assistant_id=${assistant_id}`, {}, false).then(async (res) => {
            if (res.success) {
                setModaldata(res.data)
                setLikesCount(res.data.likes);
                setIsModalOpen(true);
            }
        })
    };

    const closeModal = () => setIsModalOpen(false);
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const user = useSelector(state => state.user);

    // useEffect(() => {
    //     UserApiClient.get(`assistant_api/all_static_data/`, {}, false).then(async (res) => {
    //         if (res.success) {
    //             setImgdata(res.data?.static_data?.RealisticGirl || []);
    //             setImgdataanime(res.data?.static_data?.AnimeGirl || []);
    //             setImgboydata(res.data?.static_data?.RealisticBoy || []);
    //             setImgboydataani(res.data?.static_data?.AnimeBoy || []);
    //         }
    //     })
    // }, []);

    useEffect(() => {
        const token = localStorage.getItem('token');
        setUserlogger(token)
        loadStaticData();
    }, []);

    const loadMoreRecards = async (state) => {
        if (state == "Girlfriend_1") { setImgdata(realisticGirlData?.slice(0, imgdata.length + 10)); };
        if (state == "Girlfriend_2") { setImgdataanime(animeGirlData?.slice(0, imgdataanime.length + 10)); };
        if (state == "Boyfriend_1") { setImgboydata(realisticBoyData?.slice(0, imgboydata.length + 10)); };
        if (state == "Boyfriend_2") { setImgboydataani(animeBoyData?.slice(0, imgboydataani.length + 10)); };
        // loadStaticData(realisticGirlData.length + 10)
    }
    const loadStaticData = async () => {
        await UserApiClient.get(`assistant_api/all_static_data/`, {}, false).then(async (res) => {
            if (res.success) {
                // Fetch data and slice it to get the first 10 items
                SetrealisticGirlData(res.data?.static_data?.RealisticGirl || []);
                SetanimeGirlData(res.data?.static_data?.AnimeGirl || []);
                SetrealisticBoyData(res.data?.static_data?.RealisticBoy || []);
                SetanimeBoyData(res.data?.static_data?.AnimeBoy || []);
            }
        });
    };

    useEffect(() => {
        console.log(realisticGirlData.length, animeGirlData.length, realisticBoyData.length, animeBoyData.length, "================")
        setImgdata(realisticGirlData?.slice(0, user.isAuthenticated ? 10 : 9));
        setImgdataanime(animeGirlData?.slice(0, user.isAuthenticated ? 10 : 9));
        setImgboydata(realisticBoyData?.slice(0, user.isAuthenticated ? 10 : 9));
        setImgboydataani(animeBoyData?.slice(0, user.isAuthenticated ? 10 : 9));
    }, [realisticGirlData, animeGirlData, realisticBoyData, animeBoyData])

    const CreateDreamGF = () => {
        const token = localStorage.getItem('token');
        navigate(token ? "/CreateDreamGF" : "/login");
    };
    const Chatnow = (id) => {
        // StaticGenerate(id);
        const token = localStorage.getItem('token');
        navigate(token ? `/AidreamGFchats/${id}` : "/login");
    };


    const StaticGenerate = (id) => {
        const extractedPart = "asst_" + id.substring(id.lastIndexOf('/') + 1);
        const u_id = localStorage.getItem('u_id');
        const payload = { assistant_id: extractedPart, u_id: u_id };

        try {
            const authToken = localStorage.getItem('token');
            const response = axios.post(`${BASE_URL}static_chat/`, payload, {
                headers: {
                    'Authorization': `Bearer ${authToken}`
                }
            });

            if (response.status === 200 && response.data[0].name) {
                const responseName = response.data[0].name.toUpperCase();
                let matchedImage = null;

            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const CreateDreamBf = () => {
        const token = localStorage.getItem('token');
        navigate(token ? "/BoyFriend" : '/login');
    };
    const handleClosePopup = () => {
        // window.location.reload();
        setShowPopup(false);
    };

    const toggleLike = async (assistant_id) => {
        const u_id = localStorage.getItem('u_id');
        const payload = { assistant_id: assistant_id, u_id: u_id };

        try {
            const authToken = localStorage.getItem('token');
            const response = await axios.post(`${BASE_URL}like_static/`, payload, {
                headers: {
                    'Authorization': `Bearer ${authToken}`
                }
            });

            console.log("1111111111sssssss", response.data[0]);

            if (response.status === 200) {
                setLiked(!liked);
                console.log(response.data, "asasddasasd")

                setLikesCount(response.data.likes);

            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleTabClick = (tabIndex) => {
        setActiveTabani(tabIndex);
    };
    const handleTabClickboy = (tabIndex) => {
        setActiveTabani(tabIndex);
    };

    const itemsToDisplay = (modaldata.Butt_Size === '' || modaldata.Butt_Size === 'NA') &&
        (modaldata.Breast_Size === '' || modaldata.Breast_Size === 'NA')
        ? [
            { label: 'Relationship:', value: modaldata.Relationship, src: Relationshipicon },
            { label: 'Age:', value: modaldata.age, src: age },
            { label: 'Personality:', value: modaldata.Personality, src: personality },
            { label: 'Occupation:', value: modaldata.Occupation, src: occupation },
            { label: 'Hair Style and Color:', value: `${modaldata.hair_Style} ${modaldata.hair_color}`, src: hair },
            { label: 'Eyes:', value: modaldata.Eyes, src: eyes },
            { label: 'Ethnicity:', value: modaldata.Ethnicity, src: Location },
            { label: 'Region:', value: modaldata.region, src: country },
            { label: 'Body Type:', value: modaldata.Body_Type, src: manbody }
        ]
        : [
            { label: 'Relationship:', value: modaldata.Relationship, src: Relationshipicon },
            { label: 'Age:', value: modaldata.age, src: age },
            { label: 'Personality:', value: modaldata.Personality, src: personality },
            { label: 'Occupation:', value: modaldata.Occupation, src: occupation },
            { label: 'Hair Style and Color:', value: `${modaldata.hair_Style} ${modaldata.hair_color}`, src: hair },
            { label: 'Eyes:', value: modaldata.Eyes, src: eyes },
            { label: 'Ethnicity:', value: modaldata.Ethnicity, src: Location },
            { label: 'Region:', value: modaldata.region, src: country },
            { label: 'Body Type:', value: modaldata.Body_Type, src: Bodyicon },
            { label: 'Breast Size:', value: modaldata.Breast_Size, src: brest },
            { label: 'Butt Size:', value: modaldata.Butt_Size, src: Bodyback }
        ];

    useEffect(() => {
        const timerInterval = setInterval(() => {
            if (timeRef.current <= 0) {
                clearInterval(timerInterval); // Stop the timer when it reaches 0
                return;
            }

            // Update timeRef without triggering a re-render
            timeRef.current -= 1;

            // Format the time and update the state
            setFormattedTime(formatTime(timeRef.current));
        }, 1); // Update every millisecond

        // Clean up the interval when the component is unmounted or the timer ends
        return () => clearInterval(timerInterval);
    }, []);


    // Function to format the time in mm:ss:ms format
    const formatTime = (time) => {
        const minutes = Math.floor(time / 60000); // Extract minutes
        const seconds = Math.floor((time % 60000) / 1000); // Extract seconds
        const milliseconds = Math.floor((time % 1000) / 10); // Extract milliseconds

        return `${String(minutes).padStart(2, '0')} : ${String(seconds).padStart(2, '0')} : ${String(milliseconds).padStart(2, '0')}`;
    };

    const [formattedTime, setFormattedTime] = useState(formatTime(timeRef.current));


    return (
        <>
            <div className='px-2 xl:px-10 pb-5 text-white font-josefin-sans'>
              
                {isVisible || Userlogger && (
                    <div className="bg-gradient-to-r from-red-600 h-20 px-10 to-white w-full flex  justify-between items-center relative rounded-xl">
                        {/* Close button */}
                        <button
                            onClick={() => setIsVisible(false)}
                            className="absolute top-2 right-2 text-black text-2xl"
                        >
                            ×
                        </button>

                        <div className="text-xl lg:text-2xl">First Subscription</div>
                        <div className="border-2 px-4 border-[#99000B] rounded-full p-1 md:p-2 transition-colors duration-300 bg-[#99000B] text-white">
                            Up to 70% OFF
                        </div>
                        <div className="border-2 w-[130px] px-5 border-[#807D7D] rounded-full p-2 transition-colors duration-300 bg-[#807D7D] text-white">
                            {/* {formatTime(timeLeft)} */}
                            {formattedTime}
                            <div className='flex justify-between'>

                                <p className='text-xs '>Min</p>
                                <p className='text-xs '>Sec</p>
                                <p className='text-xs  '>Ms</p>
                            </div>
                        </div>
                    </div>
                )}

                {
                    activeTab === "Girlfriend" ? (
                        <>
                            <div className="carousel-container">
                                <div className='flex items-center mt-[3rem] lg:mt-5 mb-10 w-full relative carousel-item'>
                                    <img src="/assets/img/Rectangledream.png" alt="Icon" className="w-full h-auto" />
                                    <div className='absolute right-0'>
                                        <div className='grid justify-center p-4 mr-4 sm:mr-6 lg:mr-10'>
                                            <div className='text-xl sm:text-1xl lg:text-3xl xl:text-5xl font-medium text-center'>
                                                Create your own Virtual <span className='text-red-500'> AI Girlfriend</span>
                                            </div>
                                            <div className='text-center mt-1 lg:mt-10'>
                                                <button className="bg-red-800 text-white font-medium rounded-2xl p-2 sm:p-4 lg:px-6 py-1" onClick={CreateDreamGF}>
                                                    Create Your Dream Virtual AI  Girlfriend
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- Add more carousel items here --> */}
                                <div className='flex items-center mt-[7rem] lg:mt-5 mb-10 w-full relative carousel-item justify-center'>
                                    <img src="/assets/img/Rectangledream.png" alt="Icon" className="w-full h-auto" />
                                    <div className='absolute text-center'>
                                        <div className='grid justify-center p-4 mr-4 sm:mr-6 lg:mr-10'>
                                            <div className='text-xl sm:text-xl lg:text-2xl xl:text-5xl font-medium text-center'>
                                                Create your own Virtual <span className='text-red-500'> AI Girlfriend</span>
                                            </div>
                                            <div className='text-center mt-1 lg:mt-10'>
                                                <button className="bg-red-800 text-white font-medium rounded-2xl p-2 sm:p-4 lg:px-10 lg:py-3" onClick={CreateDreamGF}>
                                                    Create Your Dream Virtual AI Girlfriend
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='flex items-center mt-[2rem] lg:mt-5 mb-10 w-full relative justify-center'>
                                <img src="/assets/img/Rectangledream.png" alt="Icon" className="w-full h-auto rounded-xl" />
                                <div className='absolute text-center'>
                                    <div className='grid justify-center p-4 mr-4 sm:mr-6 lg:mr-10'>
                                        <div className='custombuttonsize sm:text-xl lg:text-2xl xl:text-5xl font-medium text-center'>
                                            Create your own Virtual <span className='text-red-500'> AI Girlfriend</span>
                                        </div>
                                        <div className='text-center mt-1 lg:mt-10'>
                                            <button className="custombuttonsize bg-red-800 text-white font-medium rounded-2xl p-2 sm:p-4 lg:px-6 py-1" onClick={CreateDreamGF}>
                                                Create Your Dream Virtual AI Girlfriend
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='text-xl lg:text-2xl lg:my-4 mt-0 font-bold lg:py-4 py-0 text-black text-center'>Meet our Best <span className='text-[#991b1b]'>AI GirlFriends</span></div>

                            <div className="flex justify-center space-x-4">
                                <button
                                    onClick={() => handleTabClick(1)}
                                    className={`px-4 py-2 w-[25%] font-semibold text-lg rounded-tl-lg rounded-bl-lg transition-colors duration-300 ${activeTabani === 1 ? 'bg-[#991b1b] text-white' : 'bg-gray-200 text-gray-600'
                                        }`}
                                >
                                    Realistic
                                </button>
                                <button
                                    onClick={() => handleTabClick(2)}
                                    style={{ margin: "0" }}
                                    className={`px-4 py-2 w-[25%] font-semibold text-lg rounded-tr-lg rounded-br-lg transition-colors duration-300 ${activeTabani === 2 ? 'bg-[#991b1b] text-white' : 'bg-gray-200 text-gray-600'
                                        }`}
                                >
                                    Anime
                                </button>
                            </div>

                            <div className="mt-4">
                                {activeTabani === 1 && (
                                    <div className='grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4'>
                                        {imgdata && imgdata.map((item, index) => (
                                            <div
                                                key={index}
                                                className={`relative ${index === 0 ? 'rounded-md border-2 border-red-600 shadow-md shadow-red-800 ' : ''}`} // Highlight first item
                                                onMouseEnter={() => setHoveredIndex(index)}
                                                onMouseLeave={() => setHoveredIndex(null)}
                                            >
                                                <div className='overflow-hidden'>
                                                    <img
                                                        src={hoveredIndex === index ? item.character.img_url2 : item.character.img_url}
                                                        alt={`Image of ${item.character.name}`}
                                                        className={`h-80 w-full xl:h-[500px] rounded-xl object-cover transition-transform duration-700 ease-in-out ${hoveredIndex === index ? 'scale-105' : ''}`}
                                                    />
                                                </div>
                                                <div onClick={() => openModal(item.assistant, item.character.img_url)} className='absolute inset-0 flex flex-col justify-between opacity-100 transition-opacity duration-700'>
                                                    <div className='flex justify-end pt-4 rounded-lg'>
                                                        <button className="text-white font-medium rounded-2xl px-3 py-2 hover:animate-shake">
                                                            <img src="/assets/img/Group-icon.png" onClick={() => Chatnow(item.assistant.replaceAll("asst_", ""))} alt="Icon" className="w-12 h-12" />
                                                        </button>
                                                    </div>
                                                    <div className='absolute bottom-0 left-0 right-0 bg-black bg-opacity-70 text-white p-2 rounded-b-lg'>
                                                        <div className='flex justify-between flex-wrap py-1'>
                                                            <span className="text-lg lg:text-lg font-medium">{item.character.name}</span>
                                                            <span className="text-lg lg:text-lg font-medium">{item.character.age}</span>
                                                        </div>
                                                        <p className='text-sm lg:text-base'>{(item.character.summary).slice(0, 60)}...</p>
                                                    </div>
                                                </div>

                                                {/* Display "Lindi Creation" only for the first item */}
                                                {index === 0 && (
                                                    <div onClick={() => window.open('https://www.instagram.com/lmonies/?hl=en', '_blank')} className="absolute top-0 left-0 bg-[#ef444496] text-white font-bold p-1 m-2 rounded-lg">
                                                        <i class="fa-brands fa-instagram mr-[2px]"></i> AI Avatar
                                                    </div>
                                                )}
                                            </div>
                                        ))}

                                        {
                                            Userlogger ?
                                                <></>
                                                // <div className='relative' onMouseEnter={() => setHoveredIndex(10)}
                                                //     onMouseLeave={() => setHoveredIndex(null)}
                                                // >
                                                //     <img src={hoveredIndex === 10 ? GIANINA2 : GIANINA1} alt="img1" className='h-80 w-full xl:h-[500px] rounded-xl object-cover' />
                                                //     <div className='absolute inset-0 flex flex-col justify-between opacity-100 transition-opacity duration-700'>
                                                //         <div className='flex justify-end pt-4'>
                                                //             <button className="text-white font-medium rounded-2xl px-3 py-2 hover:animate-shake">
                                                //                 <img onClick={() => openModal('asst_N9awtinfHzE6V63hmUR8TUOg')} src="/assets/img/Group-icon.png" alt="Icon" className="w-16 h-15" />
                                                //             </button>
                                                //         </div>
                                                //         <div className='absolute bottom-0 left-0 right-0 bg-black bg-opacity-70 text-white p-2 rounded-b-lg'>
                                                //             <div className='flex justify-between flex-wrap py-1'>
                                                //                 <span className="text-lg lg:text-lg font-medium">GIANINA</span>
                                                //                 <span className="text-lg lg:text-lg font-medium">40 Years</span>
                                                //             </div>
                                                //             <p className='text-sm lg:text-base'>{`I'm a data scientist with a passion for puns and a love for the outdoors. Looking for someone to rock climb with and laugh at my jokes...`.slice(0, 60)}...</p>
                                                //         </div>
                                                //     </div>
                                                // </div>
                                                :
                                                <div className='relative bg-[#990013] rounded-xl'>
                                                    {/* <img src={img1} alt="img1" className='h-80 w-full xl:h-[500px] rounded-xl object-cover' /> */}
                                                    <div className=' inset-0 flex flex-col duration-700 py-5 px-3 sm:p-5'>
                                                        <div className='flex justify-center p-0 sm:p-4'>
                                                            <i class="fa-solid fa-gift fa-4x"></i>
                                                        </div>
                                                        <div className='bg-opacity-70 my-3 sm:my-10 text-white p-2 rounded-b-lg'>
                                                            <div className='flex flex-col gap-4 sm:gap-10 text-center py-1'>
                                                                <span className="text-sm sm:text-lg lg:text-lg font-medium">Register to view more</span>
                                                                <span className="text-sm sm:text-lg lg:text-lg font-medium">It's FREE To Join
                                                                </span>
                                                                {/* <span className='text-sm sm:text-lg lg:text-lg font-medium'>
                                                                    No cards/payments required<br />
                                                                    for the Joining.
                                                                </span> */}
                                                            </div>
                                                        </div>
                                                        <div className='absolute bottom-0 left-0 right-0 text-center bg-opacity-70 text-black p-2 rounded-b-lg'>
                                                            <button className="bg-white font-semibold [w-75%] text-[#990013] rounded-2xl p-4 " onClick={CreateDreamBf}>
                                                                Register To Join
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                        }
                                    </div>
                                )}
                                {activeTabani === 2 && (
                                    <div className='grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4'>
                                        {imgdataanime.map((item, index) => (
                                            <div
                                                key={index}
                                                className='relative'
                                                onMouseEnter={() => setHoveredIndex(index)}
                                                onMouseLeave={() => setHoveredIndex(null)}
                                            >
                                                <div className='overflow-hidden'>
                                                    <img
                                                        src={hoveredIndex === index ? item.character.img_url2 : item.character.img_url}
                                                        alt={`Image of ${item.character.name}`}
                                                        className={`h-80 w-full xl:h-[500px] rounded-xl object-cover transition-transform duration-700 ease-in-out ${hoveredIndex === index ? 'scale-105' : ''}`}
                                                    />
                                                </div>
                                                <div onClick={() => openModal(item.assistant, item.character.img_url2)} className='absolute inset-0 flex flex-col justify-between opacity-100 transition-opacity duration-700'>
                                                    <div className='flex justify-end pt-4'>
                                                        <button className="text-white font-medium rounded-2xl px-3 py-2 hover:animate-shake">
                                                            <img src="/assets/img/Group-icon.png" onClick={() => Chatnow(item.assistant.replaceAll("asst_", ""))} alt="Icon" className="w-12 h-12" />
                                                        </button>
                                                    </div>
                                                    <div className='absolute bottom-0 left-0 right-0 bg-black bg-opacity-70 text-white p-2 rounded-b-lg'>
                                                        <div className='flex justify-between flex-wrap py-1'>
                                                            <span className="text-lg lg:text-lg font-medium">{item.character.name}</span>
                                                            <span className="text-lg lg:text-lg font-medium">{item.character.age}</span>
                                                        </div>
                                                        <p className='text-sm lg:text-base'>{(item.character.summary).slice(0, 60)}...</p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        {
                                            Userlogger ?
                                                ""
                                                // <div className='relative'>
                                                //     <img src={sophia1} alt="img1" className='h-80 w-full xl:h-[500px] rounded-xl object-cover' />
                                                //     <div className='absolute inset-0 flex flex-col justify-between opacity-100 transition-opacity duration-700'>
                                                //         <div className='flex justify-end pt-4'>
                                                //             <button className="text-white font-medium rounded-2xl px-3 py-2 hover:animate-shake">
                                                //                 <img onClick={() => openModal('asst_lJmsOx1UCLjAyh44EXyXiogb')} src="/assets/img/Group-icon.png" alt="Icon" className="w-16 h-15" />
                                                //             </button>
                                                //         </div>
                                                //         <div className='absolute bottom-0 left-0 right-0 bg-black bg-opacity-70 text-white p-2 rounded-b-lg'>
                                                //             <div className='flex justify-between flex-wrap py-1'>
                                                //                 <span className="text-lg lg:text-lg font-medium">GIANINA</span>
                                                //                 <span className="text-lg lg:text-lg font-medium">40 Years</span>
                                                //             </div>
                                                //             <p className='text-sm lg:text-base'>{`I'm a data scientist with a passion for puns and a love for the outdoors. Looking for someone to rock climb with and laugh at my jokes...`.slice(0, 60)}...</p>
                                                //         </div>
                                                //     </div>
                                                // </div>
                                                :
                                                <div className='relative bg-[#990013] rounded-xl'>
                                                    {/* <img src={img1} alt="img1" className='h-80 w-full xl:h-[500px] rounded-xl object-cover' /> */}
                                                    <div className=' inset-0 flex flex-col duration-700 py-5 px-3 sm:p-5'>
                                                        <div className='flex justify-center p-0 sm:p-4'>
                                                            <i class="fa-solid fa-gift fa-4x"></i>
                                                        </div>
                                                        <div className='bg-opacity-70 my-3 sm:my-10 text-white p-2 rounded-b-lg'>
                                                            <div className='flex flex-col gap-4 sm:gap-10 text-center py-1'>
                                                                <span className="text-sm sm:text-lg lg:text-lg font-medium">Register to view more</span>
                                                                <span className="text-sm sm:text-lg lg:text-lg font-medium">It's FREE To Join
                                                                </span>
                                                                {/* <span className='text-sm sm:text-lg lg:text-lg font-medium'>
                                                                    No cards/payments required<br />
                                                                    for the Joining.
                                                                </span> */}
                                                            </div>
                                                        </div>
                                                        <div className='absolute bottom-0 left-0 right-0 text-center bg-opacity-70 text-black p-2 rounded-b-lg'>
                                                            <button className="bg-white [w-75%] text-[#990013] font-semibold rounded-2xl p-4" onClick={CreateDreamBf}>
                                                                Register To Join
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                        }
                                    </div>
                                )}
                            </div>


                            {/* <div className='py-20'>
                                <h2 className="text-4xl font-extrabold text-center mb-10 text-gray-800">
                                    How to Make Your Virtual AI Girlfriend with Eromantic AI
                                </h2>
                                <p className="text-lg text-center mb-[5rem] text-gray-600">
                                    It’s super easy to create your dream Girlfriend. Follow these simple steps:
                                </p>
                                <Characters />
                            </div> */}
                        </>
                    ) : (
                        <>
                            <div className='flex items-center mt-[7rem] lg:mt-5 mb-10 w-full relative'>
                                <img src="/assets/img/boydream.png" alt="Icon" className="w-full h-auto rounded-xl" />
                                <div className='absolute right-0 '>
                                    <div className='grid justify-center sm:p-4 p-0 mr-0 sm:mr-4 lg:mr-10'>
                                        <div className='md:text-xl lg:text-3xl xl:text-5xl font-medium text-center custombuttonsize'>
                                            Create your own Virtual <span className='text-red-500'> AI Boyfriend</span>
                                        </div>
                                        <div className='text-center mt-0 lg:mt-10'>
                                            <button className="bg-red-800 text-white font-medium rounded-2xl p-2 sm:p-4 lg:p-5 custombuttonsize md:text-xl lg:2xl" onClick={CreateDreamBf}>
                                                Create Your Dream Virtual AI Boyfriend
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* 
                            <div className='flex items-center mt-5 mb-10 w-full relative'>
                                <img src="/assets/img/boydream.png" alt="Icon" className="w-full rounded-xl h-auto" />
                                <div className='absolute right-0'>
                                    <div className='grid justify-center p-4 mt-[70px] mr-[100px]'>
                                        <div className='text-4xl lg:text-5xl font-medium'>Create your own <span className='text-red-500'>Dream Boyfriend</span></div>
                                        <div className='text-center mt-10'>
                                            <button className="bg-red-800 text-white font-medium rounded-2xl p-4" onClick={CreateDreamBf}>
                                                Create Your Dream Boyfriend
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <div className='text-xl lg:text-2xl lg:my-4 mt-0 font-bold lg:py-4 py-0 text-black text-center'>Meet our Best <span className='text-[#991b1b]'>AI BoyFriends</span></div>
                            <div className="flex justify-center space-x-4">
                                <button
                                    onClick={() => handleTabClickboy(1)}
                                    className={`px-4 py-2 w-[25%] font-semibold text-lg rounded-tl-lg rounded-bl-lg transition-colors duration-300 ${activeTabani === 1 ? 'bg-[#991b1b] text-white' : 'bg-gray-200 text-gray-600'
                                        }`}
                                >
                                    Realistic
                                </button>
                                <button
                                    onClick={() => handleTabClickboy(2)}
                                    style={{ margin: "0" }}
                                    className={`px-4 py-2 w-[25%] font-semibold text-lg rounded-tr-lg rounded-br-lg transition-colors duration-300 ${activeTabani === 2 ? 'bg-[#991b1b] text-white' : 'bg-gray-200 text-gray-600'
                                        }`}
                                >
                                    Anime
                                </button>
                            </div>
                            <div className="mt-4">
                                {activeTabani === 1 && (
                                    <div className='grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4'>
                                        {imgboydata.map((item, index) => (
                                            <div
                                                key={index}
                                                className='relative'
                                                onMouseEnter={() => setHoveredIndex(index)}
                                                onMouseLeave={() => setHoveredIndex(null)}
                                            >
                                                <div className=' overflow-hidden'>
                                                    <img
                                                        src={hoveredIndex === index ? item.character.img_url2 : item.character.img_url}
                                                        alt={`Image of ${item.character.name}`}
                                                        className={`h-80 w-full xl:h-[500px] rounded-xl object-cover transition-transform duration-700 ease-in-out ${hoveredIndex === index ? 'scale-105' : ''}`}
                                                    />
                                                </div>
                                                <div onClick={() => openModal(item.assistant, item.character.img_url2)} className='absolute inset-0 flex flex-col justify-between opacity-100 transition-opacity duration-700'>
                                                    <div className='flex justify-end pt-4'>
                                                        <button className="text-white font-medium rounded-2xl px-3 py-2 hover:animate-shake">
                                                            <img onClick={() => Chatnow(item.assistant.replaceAll("asst_", ""))} src="/assets/img/Group-icon.png" alt="Icon" className="w-12 h-12" />
                                                        </button>
                                                    </div>
                                                    <div className='absolute bottom-0 left-0 right-0 bg-black bg-opacity-70 text-white p-2 rounded-b-lg'>
                                                        <div className='flex justify-between flex-wrap py-1'>
                                                            <span className="text-lg lg:text-lg font-medium">{item.character.name}</span>
                                                            <span className="text-lg lg:text-lg font-medium">{item.character.age}</span>
                                                        </div>
                                                        <p className='text-sm lg:text-base'>{(item.character.summary).slice(0, 60)}...</p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        {
                                            Userlogger ?
                                                ""
                                                :
                                                <div className='relative bg-[#990013] rounded-xl'>
                                                    {/* <img src={img1} alt="img1" className='h-80 w-full xl:h-[500px] rounded-xl object-cover' /> */}
                                                    <div className=' inset-0 flex flex-col duration-700 py-5 px-3 sm:p-5'>
                                                        <div className='flex justify-center p-0 sm:p-4'>
                                                            <i class="fa-solid fa-gift fa-4x"></i>
                                                        </div>
                                                        <div className='bg-opacity-70 my-3 sm:my-10 text-white p-2 rounded-b-lg'>
                                                            <div className='flex flex-col gap-4 sm:gap-10 text-center py-1'>
                                                                <span className="text-sm sm:text-lg lg:text-lg font-medium">Register to view more</span>
                                                                <span className="text-sm sm:text-lg lg:text-lg font-medium">It's FREE To Join
                                                                </span>
                                                                {/* <span className='text-sm sm:text-lg lg:text-lg font-medium'>
                                                                    No cards/payments required<br />
                                                                    for the Joining.
                                                                </span> */}
                                                            </div>
                                                        </div>
                                                        <div className='absolute bottom-0 left-0 right-0 text-center bg-opacity-70 text-black p-2 rounded-b-lg'>
                                                            <button className="bg-white [w-75%] text-[#990013] font-semibold rounded-2xl p-4" onClick={CreateDreamBf}>
                                                                Register To Join
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                        }
                                    </div>
                                )}
                                {activeTabani === 2 && (
                                    <div className='grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4'>
                                        {imgboydataani.map((item, index) => (
                                            <div
                                                key={index}
                                                className='relative'
                                                onMouseEnter={() => setHoveredIndex(index)}
                                                onMouseLeave={() => setHoveredIndex(null)}
                                            >
                                                <div className='overflow-hidden'>
                                                    <img
                                                        src={hoveredIndex === index ? item.character.img_url2 : item.character.img_url}
                                                        alt={`Image of ${item.character.name}`}
                                                        className={`h-80 w-full xl:h-[500px] rounded-xl object-cover transition-transform duration-700 ease-in-out ${hoveredIndex === index ? 'scale-105' : ''}`}
                                                    />
                                                </div>
                                                <div onClick={() => openModal(item.assistant, item.img_url2)} className='absolute inset-0 flex flex-col justify-between opacity-100 transition-opacity duration-700'>
                                                    <div className='flex justify-end pt-4'>
                                                        <button className="text-white font-medium rounded-2xl px-3 py-2 hover:animate-shake">
                                                            <img onClick={() => Chatnow(item.assistant.replaceAll("asst_", ""))} src="/assets/img/Group-icon.png" alt="Icon" className="w-12 h-12" />
                                                        </button>
                                                    </div>
                                                    <div className='absolute bottom-0 left-0 right-0 bg-black bg-opacity-70 text-white p-2 rounded-b-lg'>
                                                        <div className='flex justify-between flex-wrap py-1'>
                                                            <span className="text-lg lg:text-lg font-medium">{item.character.name}</span>
                                                            <span className="text-lg lg:text-lg font-medium">{item.character.age}</span>
                                                        </div>
                                                        <p className='text-sm lg:text-base'>{(item.character.summary).slice(0, 60)}...</p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        {
                                            Userlogger ?
                                                ""
                                                :
                                                <div className='relative bg-[#990013] rounded-xl'>
                                                    {/* <img src={img1} alt="img1" className='h-80 w-full xl:h-[500px] rounded-xl object-cover' /> */}
                                                    <div className=' inset-0 flex flex-col duration-700 py-5 px-3 sm:p-5'>
                                                        <div className='flex justify-center p-0 sm:p-4'>
                                                            <i class="fa-solid fa-gift fa-4x"></i>
                                                        </div>
                                                        <div className='bg-opacity-70 my-3 sm:my-10 text-white p-2 rounded-b-lg'>
                                                            <div className='flex flex-col gap-4 sm:gap-10 text-center py-1'>
                                                                <span className="text-sm sm:text-lg lg:text-lg font-medium">Register to view more</span>
                                                                <span className="text-sm sm:text-lg lg:text-lg font-medium">It's FREE To Join
                                                                </span>
                                                                {/* <span className='text-sm sm:text-lg lg:text-lg font-medium'>
                                                                    No cards/payments required<br />
                                                                    for the Joining.
                                                                </span> */}
                                                            </div>
                                                        </div>
                                                        <div className='absolute bottom-0 left-0 right-0 text-center bg-opacity-70 text-black p-2 rounded-b-lg'>
                                                            <button className="bg-white [w-75%] text-[#990013] font-semibold rounded-2xl p-4" onClick={CreateDreamBf}>
                                                                Register To Join
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                        }
                                    </div>
                                )}
                            </div>
                        </>
                    )
                }
                {
                    Userlogger ?
                        <div className='text-center mt-4 bg-opacity-70 text-black p-2 rounded-b-lg'>
                            
                            <button className="bg-red-800 [w-75%] text-white font-medium rounded-2xl py-2 px-4" onClick={() => loadMoreRecards(`${activeTab}_${activeTabani}`)}
                                style={{ display: `${activeTab}_${activeTabani}` == "Girlfriend_1" && realisticGirlData.length == imgdata.length ? 'none' :
                                `${activeTab}_${activeTabani}` == "Girlfriend_2" && animeGirlData.length == imgdataanime.length ? 'none' :
                                    `${activeTab}_${activeTabani}` == "Boyfriend_1" && realisticBoyData.length == imgboydata.length ? 'none' :
                                        `${activeTab}_${activeTabani}` == "Boyfriend_2" && animeBoyData.length == imgboydataani.length ? 'none' : ""}}
                            >
                                <i class="fa-solid fa-angles-down mr-1"></i> Load More
                            </button>
                        </div>
                        :
                        ""
                }
                <div className='py-20'>
                    <h2 className="text-4xl font-extrabold text-center mb-10 text-gray-800">
                        How to Make Your Virtual AI {activeTab} with Eromantic AI
                    </h2>
                    <p className="text-lg text-center mb-[5rem] text-gray-600">
                        It’s super easy to create your dream {activeTab}. Follow these simple steps:
                    </p>
                    <Characters />
                </div>
                <Blogs />
                <Accordion />
                <Footer />
            </div>
            {showPopup && <LandingPopup onClose={handleClosePopup} />}

            {isModalOpen && (
                <div className="fixed inset-0 flex items-center justify-center">
                    <div
                        className="bg-white p-8 rounded w-[85%] md:w-[80%] mt-10 xxs:h-[70%] md:h-[80%] flex flex-col items-center justify-center"
                        style={{
                            // backgroundImage: `url(${modalImg})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            // filter: 'contrast(0.5)',
                        }}
                    >
                        <div className="relative -top-[3%] left-[50%]">
                            <button
                                onClick={closeModal}
                                className="text-black border-none  py-2 rounded"
                            >
                                <i className="fa-regular fa-2xl fa-circle-xmark"></i>
                            </button>
                        </div>
                        <div
                            className="flex flex-col gap-5 sm:gap-16 sm:flex-row overflow-scroll"
                            style={{ scrollbarWidth: "none" }}
                        >
                            <div>
                                <img
                                    src={modalImg}
                                    alt="Generated Image"
                                    className="w-full h-[300px] sm:h-[330px] md:h-[400px] lg:w-[400px] xl:w-[500px] lg:h-[500px] xl:h-[600px] 2xl:w-[570px] 2xl:h-[621px] rounded-xl"
                                    style={{ objectFit: "cover" }}
                                />
                            </div>
                            <div className="flex flex-col gap-8">
                                <div className="flex justify-between">
                                    <h2 className="text-[#b30016d9] text-lg sm:text-xl font-bold md:text-2xl lg:text-4xl whitespace-nowrap">
                                        {modaldata.name}
                                    </h2>
                                    <h2 className="text-[#b30016d9] text-lg sm:text-xl font-bold md:text-2xl lg:text-4xl whitespace-nowrap">
                                        <span
                                            onClick={() => toggleLike(modaldata.assistant_id)}
                                            className="ms-5"
                                        >
                                            {liked ? (
                                                <>
                                                    <i className="fa-solid fa-sm fa-heart"></i>{" "}
                                                    <span className="text-lg">{LikesCount} Liked</span>
                                                </>
                                            ) : (
                                                <>
                                                    <i className="fa-regular fa-sm fa-heart"></i>{" "}
                                                    <span className="text-lg">{LikesCount} Like</span>
                                                </>
                                            )}
                                        </span>
                                    </h2>
                                </div>
                                <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-3 mb-3">
                                    {itemsToDisplay.map(({ label, value, src }, index) => (
                                        <div key={index} className="flex  items-start gap-2">
                                            <img
                                                src={src}
                                                className="icon w-6 md:w-10 mt-1 "
                                                alt="icon"
                                            />

                                            <div className="flex flex-col">
                                                <span className="text-muted font-bold text-base mo:text-lg md:text-xl  ">
                                                    {label}
                                                </span>
                                                <span className="uppercase text-[12px] mo:text-base md:text-lg">
                                                    {value}
                                                </span>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                                <div className="flex items-start">
                                    <span className="icon mt-1 mr-2 icon-summary"></span>
                                    <div className="flex flex-col">
                                        <span className="font-semibold text-muted text-base mo:text-lg md:text-xl lg:text-2xl ">
                                            About:
                                        </span>
                                        <span className="text-[12px] mo:text-base md:text-lg lg:text-xl">
                                            {modaldata.Summary}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <button
                            className="bg-red-800 text-white font-medium rounded-2xl p-2 sm:p-4 lg:p-4 w-[35%] lg:w-[25%] mt-4"
                            onClick={() =>
                                Chatnow(modaldata.assistant_id.replaceAll("asst_", ""))
                            }
                        >
                            Chat Now
                        </button>
                    </div>
                </div>
            )}

        </>
    );
};

export default LandingPage;
