import React from 'react';

const TemplateThree = () => {
    // Placeholder functions for edit and close modal actions
    const handleEditTemplate = () => {
        // Handle template editing logic
        console.log('Edit template');
    };

    return (
        <div className="bg-white">
            {/* Header Section */}
            <header className="flex justify-between items-center px-10 py-5 bg-white text-black">
                <h1 className="text-2xl font-bold">EROMANTIC.AI</h1>
                <nav>
                    <ul className="flex gap-6">
                        <li className="hover:text-red-600 cursor-pointer">Home</li>
                        <li className="hover:text-red-600 cursor-pointer">Features</li>
                        <li className="hover:text-red-600 cursor-pointer">FAQ</li>
                    </ul>
                </nav>
            </header>

            {/* Hero Section */}
            <section className="bg-gradient-to-r from-red-700 to-black py-20 px-10 flex justify-between flex-wrap items-center">
                <div className="max-w-md">
                    <h2 className="text-4xl font-bold mb-5">Find Your AI Girlfriend</h2>
                    <p className="text-gray-300 mb-5">
                        If you want a beautiful AI girlfriend who will always be there for
                        you, try the AI options at Eromantic.ai today!
                    </p>
                    <button className="bg-red-600 px-5 py-2 rounded-lg text-white hover:bg-red-700">
                        Try for Free
                    </button>
                </div>
                <div className="flex gap-4">
                    <img
                        src="image1.jpg"
                        alt="AI Girlfriend 1"
                        className="w-72 h-96 rounded-lg"
                    />
                    <img
                        src="image2.jpg"
                        alt="AI Girlfriend 2"
                        className="w-72 h-96 rounded-lg"
                    />
                </div>
            </section>

            {/* AI Girlfriends Section */}
            <section className="bg-black py-20">
                <h3 className="text-3xl font-bold text-center mb-10">
                    Best Eromantic.ai's AI Girlfriends
                </h3>
                <div className="grid grid-cols-2 md:grid-cols-4 gap-6 px-10">
                    {[...Array(8)].map((_, index) => (
                        <div
                            key={index}
                            className="relative bg-black border border-gray-800 rounded-lg p-4"
                        >
                            <img
                                src={`girlfriend${index + 1}.jpg`}
                                alt={`Girlfriend ${index + 1}`}
                                className="w-full h-60 object-cover rounded-lg mb-4"
                            />
                            <h4 className="text-lg font-bold">Catalina</h4>
                            <p className="text-sm text-gray-400">29 years</p>
                            <p className="text-xs text-gray-500">
                                Weather report anchor in Vienna. Loves attention.
                            </p>
                            <button className="absolute top-2 right-2 bg-red-600 p-2 rounded-full text-white">
                                ❤
                            </button>
                        </div>
                    ))}
                </div>
            </section>

            {/* AI Video Generator Section */}
            <section className="bg-red-600 py-20 text-black px-10 flex justify-between items-center">
                <div className="max-w-md">
                    <h3 className="text-3xl font-bold mb-5">AI Video Generator</h3>
                    <p>
                        Our cutting-edge tool redefines the creative process, allowing you
                        to effortlessly craft personalized videos that mirror your imagination.
                    </p>
                </div>
                <div>
                    <video
                        src="video.mp4"
                        controls
                        className="w-96 h-60 object-cover rounded-lg"
                    ></video>
                </div>
            </section>

            {/* FAQ Section */}
            <section className="bg-black text-white py-20 px-10">
                <h3 className="text-3xl font-bold text-center mb-10">
                    Frequently Asked Questions
                </h3>
                <div className="space-y-4 max-w-4xl mx-auto">
                    {[...Array(4)].map((_, index) => (
                        <div key={index} className="bg-gray-800 p-4 rounded-lg">
                            <h4 className="text-lg font-bold flex justify-between">
                                <span>What is EromanticAI?</span>
                                <span className="text-red-600">+</span>
                            </h4>
                            <p className="text-sm text-gray-300 mt-2">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                Curabitur vel risus eget nisi.
                            </p>
                        </div>
                    ))}
                </div>
            </section>

            {/* Footer */}
            <footer className="bg-black text-gray-400 py-10 text-center">
                <p>
                    &copy; 2024 EROMANTIC.AI. All Rights Reserved -{" "}
                    <span className="text-white underline cursor-pointer">
                        Sitemap
                    </span>
                </p>
                <div className="flex justify-center gap-4 mt-4">
                    <span className="cursor-pointer">Terms of Service</span>
                    <span className="cursor-pointer">Our Policy</span>
                    <span className="cursor-pointer">Cookie Policy</span>
                </div>
            </footer>
        </div>
    );
};

export default TemplateThree;
