import React, { useState } from 'react';
import { IoClose } from 'react-icons/io5';
import { DeleteImg, eye } from '../../assets';
import PagenameSearchBox from '../components/PagenameSearchBox';

const Support = () => {
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [supportRequests, setSupportRequests] = useState([
        { id: 1, question: 'How do I reset my password?', response: '', status: false },
        { id: 2, question: 'Can I change my email address?', response: 'Yes, you can update your email in settings.', status: true },
        { id: 3, question: 'How do I contact support?', response: '', status: false },
        { id: 4, question: 'What is the refund policy?', response: '', status: false }
    ]);
    const [filteredRequests, setFilteredRequests] = useState(supportRequests);
    const [popupQuestion, setPopupQuestion] = useState('');
    const [popupAnswer, setPopupAnswer] = useState('');
    const [responseText, setResponseText] = useState('');

    const handleAddRequest = () => {
        // Placeholder for Add Request functionality
        alert('Add Support Request functionality');
    };

    const togglePopup = (question = '', answer = '') => {
        setIsPopupOpen(!isPopupOpen);
        setPopupQuestion(question);
        setPopupAnswer(answer);
    };

    const handleClose = () => {
        setIsPopupOpen(false);
        setPopupQuestion('');
        setPopupAnswer('');
    };

    const handleDelete = (requestId) => {
        setSupportRequests((prevRequests) => prevRequests.filter((request) => request.id !== requestId));
    };

    const handleReply = (requestId) => {
        // Simulate replying to the support request
        setSupportRequests((prevRequests) =>
            prevRequests.map((request) =>
                request.id === requestId ? { ...request, response: responseText, status: true } : request
            )
        );
        setResponseText(''); // Clear the response input
        handleClose(); // Close the popup after replying
    };

    const handleSearchChange = (e) => {
        const searchTerm = e.target.value.toLowerCase();
        const filtered = supportRequests.filter((request) =>
            request.question.toLowerCase().includes(searchTerm) || request.response.toLowerCase().includes(searchTerm)
        );
        setFilteredRequests(filtered);
    };

    return (
        <>
            <div className="h-full bg-white px-7 pb-20 font-josefin-sans">
                <PagenameSearchBox
                    title="Support Requests"
                    placename="Search Requests"
                    btnname="Add Request"
                    btnfunction={handleAddRequest}
                    onSearchChange={handleSearchChange}
                />
                <div className="overflow-x-auto mt-10 sm:rounded-lg">
                    <table className="w-full text-left text-gray-500 border border-stone-300 table-auto sm:table-fixed">
                        <thead className="text-xl text-black bg-stone-300">
                            <tr>
                                <th scope="col" className="px-2 2xl:px-6 py-3 w-[33%]">
                                    Question
                                </th>
                                <th scope="col" className="px-2 2xl:px-6 py-3 w-[33%]">
                                    Response
                                </th>
                                <th scope="col" className="px-2 2xl:px-6 py-3">
                                    Status
                                </th>
                                <th scope="col" className="px-2 2xl:px-6 py-3 text-center">
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredRequests.map((request) => (
                                <tr
                                    key={request.id}
                                    className="bg-white text-xl font-normal text-black border-b border-stone-300"
                                >
                                    <td className="px-2 2xl:px-6 py-4 whitespace-normal break-words">
                                        {request.question}
                                    </td>
                                    <td className="px-2 2xl:px-6 py-4 whitespace-normal break-words">
                                        {request.response || 'No response yet'}
                                    </td>
                                    <td className="px-2 2xl:px-6 py-4">
                                        <span className={`px-2 py-1 rounded-full ${request.status ? 'bg-green-500' : 'bg-red-500'} text-white`}>
                                            {request.status ? 'Resolved' : 'Pending'}
                                        </span>
                                    </td>
                                    <td className="px-2 2xl:px-6 py-4 flex gap-5 justify-center items-center">
                                        <img src={eye} alt="eye" className="hover:cursor-pointer" onClick={() => togglePopup(request.question, request.response)} />
                                        <img src={DeleteImg} alt="delete" className="hover:cursor-pointer" onClick={() => handleDelete(request.id)} />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

            {isPopupOpen && (
              <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60 z-10">
              <div className="bg-white pt-5 pb-10 px-10 rounded-lg text-left shadow-lg w-full sm:w-[400px] md:w-[500px] lg:w-[600px] xl:w-[700px] max-w-full">
                  <IoClose
                      size={29}
                      onClick={handleClose}
                      className="absolute top-3 right-3 hover:cursor-pointer"
                  />
                  <p className="mb-4 font-semibold text-2xl">Question</p>
                  <p className="mb-4 font-semibold text-lg">{popupQuestion}</p>
                  <p className="mb-4 font-semibold text-2xl">Response</p>
                  <p className="mb-4 font-semibold text-lg">{popupAnswer || 'No response yet'}</p>
                  
                  {/* Show textarea and button only when no answer is given */}
                  {popupAnswer === '' && (
                      <div>
                          <textarea
                              value={responseText}
                              onChange={(e) => setResponseText(e.target.value)}
                              placeholder="Write your response"
                              className="w-full p-3 border rounded-md"
                          />
                          <button
                              onClick={() => handleReply(popupQuestion.id)} // Simulate sending reply to the correct question
                              className="mt-3 bg-blue-500 text-white px-4 py-2 rounded"
                          >
                              Submit Reply
                          </button>
                      </div>
                  )}
              </div>
          </div>
          
            )}
        </>
    );
};

export default Support;
