import React from 'react';

const TemplateOne = () => {
    // Static data for the template
    const url = 'https://eromantic.ai';
    const heroTitle = 'Find Your AI Girlfriend';
    const heroDescription = 'If you want a beautiful AI girlfriend who will always be there for you, try the AI options at Eromantic.ai today!';
    const buttonText = 'Try for Free';
    const imageUrls = ['image1.jpg', 'image2.jpg'];
    const customPoseText = 'CUSTOM POSE';
    const inPaintText = 'IN PAINT';
    const girlfriendNames = Array(8).fill('Catalina');
    const videoUrl = 'video.mp4';
    const faqQuestions = Array(4).fill('What is EromanticAI?');

    return (
        <div className="">
           

            {/* Hero Section */}
            <section className="bg-gradient-to-r from-red-700 to-black py-20 px-10 flex justify-between items-center flex-wrap">
                <div className="max-w-md">
                    <h1 className="text-4xl font-bold mb-5 text-white">{heroTitle}</h1>
                    <p className="text-gray-300 mb-5">{heroDescription}</p>
                    <button className="bg-red-600 px-5 py-2 rounded-lg text-white hover:bg-red-700 w-full">
                        {buttonText}
                    </button>
                </div>
                <div className="flex gap-4">
                    {imageUrls.map((imgUrl, index) => (
                        <img
                            key={index}
                            src={imgUrl}
                            alt={`Image ${index + 1}`}
                            className="w-72 h-96 mb-4 bg-gray-200 border border-gray-300 rounded-lg"
                        />
                    ))}
                </div>
            </section>

            {/* Custom Pose & In Paint Section */}
            <section className=" py-20 px-10 items-center">
                <h3 className="text-3xl font-bold text-center mb-10">
                    Effortlessly Craft Your Fantasy:
                    <br />
                    <span className="text-xl font-semibold">High-Quality AI-Generated Content at Your Fingertips</span>
                </h3>
                <div className="text-black py-10">
                    <div className="flex flex-col lg:flex-row items-center justify-between px-10 gap-10">
                        <div>
                            <img
                                src={imageUrls[0]}
                                alt="Custom Pose"
                                className="w-72 h-96 mb-4 bg-gray-200 border border-gray-300 rounded-lg"
                            />
                        </div>
                        <div className="flex flex-col items-start text-center">
                            <h4 className="text-2xl font-bold mb-4">{customPoseText}</h4>
                            <p className="text-black mb-4">
                                Eromantic AI 'Custom Pose' AI: Upload, generate, and enjoy seamless character pose transfers.
                            </p>
                            <button className="px-6 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700">
                                Try Now
                            </button>
                        </div>
                    </div>

                    <div className="flex flex-col lg:flex-row-reverse items-center justify-between px-10 gap-10 mt-10">
                        <div>
                            <img
                                src={imageUrls[1]}
                                alt="In Paint"
                                className="w-72 h-96 mb-4 bg-gray-200 border border-gray-300 rounded-lg"
                            />
                        </div>
                        <div className="flex flex-col items-start text-center">
                            <h4 className="text-2xl font-bold mb-4">{inPaintText}</h4>
                            <p className="text-black mb-4">
                                Refine your image with Eromantic AI's 'In Paint' feature. Click 'Edit,' select, and watch the magic happen!
                            </p>
                            <button className="px-6 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700">
                                Try Now
                            </button>
                        </div>
                    </div>
                </div>
            </section>

            {/* AI Girlfriends Section */}
            <section className="py-20">
                <h3 className="text-white text-3xl font-bold text-center mb-10">
                    Best Eromantic.ai's AI Girlfriends
                </h3>
                <div className="grid grid-cols-2 md:grid-cols-4 gap-6 px-10">
                    {girlfriendNames.map((name, index) => (
                        <div
                            key={index}
                            className="relative bg-black border border-gray-800 rounded-lg p-4"
                        >
                            <h4 className="text-lg font-bold text-white">{name}</h4>
                            <img
                                src={`girlfriend${index + 1}.jpg`}
                                alt={`Girlfriend ${index + 1}`}
                                className="w-full h-60 object-cover rounded-lg mb-4"
                            />
                            <p className="text-sm text-gray-400">29 years</p>
                            <p className="text-xs text-gray-500">
                                Weather report anchor in Vienna. Loves attention.
                            </p>
                            <button className="absolute top-2 right-2 bg-red-600 p-2 rounded-full text-white">
                                ❤
                            </button>
                        </div>
                    ))}
                </div>
            </section>

            {/* AI Video Generator Section */}
            <section className="bg-red-600 py-20 text-black px-10 flex justify-between items-center">
                <div className="max-w-md">
                    <h3 className="text-3xl font-bold mb-5">AI Video Generator</h3>
                    <p>
                        Our cutting-edge tool redefines the creative process, allowing you
                        to effortlessly craft personalized videos that mirror your imagination.
                    </p>
                </div>
                <div>
                    <video
                        src={videoUrl}
                        controls
                        className="w-96 h-60 object-cover rounded-lg"
                    ></video>
                </div>
            </section>

            {/* Content Section */}
            <section className="py-20 text-black text-center">
                <h3 className="text-3xl font-bold mb-10">Try Our Free AI Girlfriend Chat</h3>
                <div className="px-10">
                    <div className="bg-white text-black rounded-lg p-6">
                        <p className="text-gray-700">
                            If you're looking for the perfect digital companion for naughty, flirty fun, you've found it. Candy.ai has combined advanced machine learning and AI features to create the best girlfriend AI experience you'll find. Between erotic texting, realistic nudes, and an adaptive, human personality, she'll seem like the girl of your dreams. Candy.ai can effortlessly adapt to your preferences and become what you've always wanted. Maybe you're into assertive, older women, perhaps you'd prefer a college girl, or you could want an anime girlfriend AI. At Candy.ai, these personalities are just a few of the ready-made options waiting for you.But maybe you don't want a ready-made AI girlfriend, and you'd prefer a custom one. The process couldn't be easier than with Candy.ai. Just go to the Create page, choose temperament and physical traits, then select generate. From here, you can start chatting with your girlfriend AI while she learns about you, your preferences, and how to satisfy you. This is all for the sake of Candy.ai's mission to provide safe, exciting, and intimate experiences for anyone who's looking. We back this up with high-grade data security, and a commitment to keeping your chats private for your eyes only.
                        </p>
                    </div>
                </div>
            </section>

            {/* FAQ Section */}
            <section className=" text-black py-20 px-10">
                <h3 className="text-3xl font-bold text-center mb-10">
                    Frequently Asked Questions
                </h3>
                <div className="space-y-4 max-w-4xl mx-auto">
                    {faqQuestions.map((question, index) => (
                        <div key={index} className="bg-gray-800 p-4 rounded-lg">
                            <h4 className="text-lg font-bold text-white">{question}</h4>
                            <p className="text-sm text-gray-300 mt-2">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur vel risus eget nisi.
                            </p>
                        </div>
                    ))}
                </div>
            </section>

            {/* Footer */}
            <footer className="bg-gray-900 py-8 px-10 text-center text-white">
                <p>&copy; 2024 Eromantic.ai. All rights reserved.</p>
            </footer>
        </div>
    );
};

export default TemplateOne;
