import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Togal from '../../../components/Togal';
import { DeleteImg } from '../../../../assets';
import PagenameSearchBox from '../../../components/PagenameSearchBox';
import ConfirmationPopup from '../../../components/ConfirmationPopup';
import UserApiClient from '../../../../utilities/apiClients';


const Category = () => {
    const navigate = useNavigate();
    const [categories, setCategories] = useState([]);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [selectedCategoryId, setSelectedCategoryId] = useState(null);
    const [searchQuery, setSearchQuery] = useState(''); // State for search query

    const handleAddCategory = () => {
        const authToken = localStorage.getItem('authToken');

        if (!authToken) {
            console.error('No auth token found. Please log in.');
            return;
        }
        navigate('/admin/addCategory');
    };

    useEffect(() => {
        const fetchCategories = async () => {
            await UserApiClient.get(`api/list_category/`, {}, true).then(async res => {
                if (res.success) {
                    setCategories(res.data);
                }
            })
        };

        fetchCategories();
    }, []);

    const openPopup = (categoryId) => {
        setSelectedCategoryId(categoryId);
        setIsPopupOpen(true);
    };

    const closePopup = () => {
        setIsPopupOpen(false);
        setSelectedCategoryId(null);
    };

    const handleDelete = async () => {
        await UserApiClient.delete(`api/delete_category/${selectedCategoryId}/`, {}, true).then(async res => {
            if (res.success) {
                setCategories(categories.filter(category => category.id !== selectedCategoryId));
                closePopup();
            }
        })
    };

    const handleStatusChange = async (event, category_id) => {
        const data = new FormData();
        data.append('status', event.target.checked);
        await UserApiClient.put(`api/update_category_status/${category_id}/`, data, true).then(async res => {
            if (res.success) {
                setCategories(categories.filter(category => category.id !== selectedCategoryId));
                closePopup();
            }
        })
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value); // Update search query
    };

    // Filter categories based on search query
    const filteredCategories = categories.filter(category => 
        category.title.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div className='h-full bg-white px-7 pb-20 font-josefin-sans'>
            <PagenameSearchBox
                title='Category List'
                placename='Search Category'
                btnname='Add Category'
                btnfunction={handleAddCategory}
                onSearchChange={handleSearchChange} // Pass the search change handler
            />
            <div className="overflow-x-auto sm:rounded-lg mt-10">
                <table className="w-full text-left text-gray-500 border border-stone-300 table-auto sm:table-fixed">
                    <thead className="text-xl text-black bg-stone-300">
                        <tr>
                            <th scope="col" className="px-6 py-3">ID</th>
                            <th scope="col" className="px-6 py-3">Name</th>
                            <th scope="col" className="px-6 py-3">Description</th>
                            <th scope="col" className="px-6 py-3">Published</th>
                            <th scope="col" className="px-6 py-3">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredCategories.map((category) => (
                            <tr key={category.id} className="bg-white text-black text-xl font-normal border-b border-stone-300">
                                <td className="px-6 py-4 whitespace-nowrap">{category.id}</td>
                                <td className="px-6 py-4 whitespace-nowrap">{category.title}</td>
                                <td className="text-zinc-600 text-[14px] font-normal leading-tight break-words">{category.description}</td>
                                <td className="px-6 py-4"><Togal handleChange={(e) => handleStatusChange(e, category.id)} value={category.status} /></td>
                                <td className="px-6 py-4 flex gap-5 justify-center items-center">
                                    <img
                                        src={DeleteImg}
                                        alt="deleteImg"
                                        className='hover:cursor-pointer'
                                        onClick={() => openPopup(category.id)} // Trigger popup on click
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <ConfirmationPopup
                isOpen={isPopupOpen}
                onClose={closePopup}
                onConfirm={handleDelete}
            />
        </div>
    );
};

export default Category;
