import React, { useState, useEffect } from "react";
// import { FixedSizeGrid as Grid } from 'react-window';
import axios from "axios";
import ImgDetailOnhoverGenration from "../../components/ImgDetailOnhoverGenration";
import { AiFillLike } from "react-icons/ai";
import { FaHeart } from "react-icons/fa6";
import { GiRoundStar } from "react-icons/gi";
import { IoIosTime } from "react-icons/io";
import { IoSearchOutline } from "react-icons/io5";
import { filter } from "../../../assets";
import { filterOn } from "../../../assets";
import { useNavigate } from "react-router-dom";
import Footer from "../../../frontend/components/Footer";
import UserApiClient from "../../../utilities/apiClients";

const Discover = () => {
  const [imageData, setImageData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [imageDataLen, setImageDataLen] = useState(0);
  const [showMoreBtn, setShowMoreBtn] = useState(true);
  const navigate = useNavigate();

  const Showcasedata = [
    { name: "Showcased", icon: <FaHeart size={20} color="#616161" /> },
    { name: "Most Liked", icon: <GiRoundStar size={20} color="#616161" /> },
    { name: "Followings", icon: <AiFillLike size={20} color="#616161" /> },
    { name: "Newest", icon: <IoIosTime size={20} color="#616161" /> },
  ];
  const afterModelData = [
    { name: "AFTER SEX" },
    { name: "AFTER SEX" },
    { name: "AFTER SEX" },
    { name: "AFTER SEX" },
    { name: "AFTER SEX" },
    { name: "AFTER SEX" },
    { name: "AFTER SEX" },
    { name: "AFTER SEX" },
    { name: "AFTER SEX" },
    { name: "AFTER SEX" },
  ];

  const handleFilter = () => {
    setOpen(!open);
  };

  const fetchImageData = async (limit) => {
    setLoading(true);
    await UserApiClient.get(
      "api/v1/get_allPublic_image/?limit=" + limit || 10,
      {},
      false
    ).then(async (res) => {
      if (res.success) {

        setImageData(res.data);
        if (res.data.length === imageDataLen) {
          setShowMoreBtn(false);
        }
        setImageDataLen(res.data.length);
        setLoading(false);
      } else {
        setError(error.res.data.detail);
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    fetchImageData(10);
  }, []);

  // useEffect(() => {
  //   fetchImageData();
  // }, [page]); // Fetch images whenever the page changes

  // const handleScroll = () => {
  //   if (
  //     window.innerHeight + document.documentElement.scrollTop >=
  //       document.documentElement.offsetHeight - 100 &&
  //     !loading &&
  //     hasMore
  //   ) {
  //     setPage((prevPage) => prevPage + 1); // Load the next page
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll);
  //   return () => window.removeEventListener("scroll", handleScroll); // Cleanup listener
  // }, [loading, hasMore]);

  // useEffect(() => {
  //     const fetchImageData = async () => {
  //         setLoading(true);
  //         const logger = localStorage.getItem('login');
  //         try {
  //             const response = await axios.get('https://api2.eromantic.ai/api/v1/get_allPublic_image/');
  //             console.log(response.data, 'Fetched Image Data');
  //             setImageData(response.data); // Set fetched image data to state
  //             setUserDetails(logger);
  //             setLoading(false);
  //         } catch (error) {
  //             setError('Failed to fetch data');
  //             setLoading(false);
  //         }
  //     };
  //     fetchImageData();
  // }, []);

  const handleImageView = (imgId) => {
    navigate(`/dashboard/ImageView/${imgId}`);
  };

  if (loading) return <p>Loading...</p>;
  // if (error) return <p>{error}</p>;

  // Define the number of columns for the grid (5 columns per row)
  const columnCount = 5;
  const columnWidth = Math.floor(window.innerWidth / columnCount); // Adjust column width dynamically
  const rowCount = Math.ceil(imageData.length / columnCount);

  return (
    <>
      <div className="mt-20 lg:px-10 2xl:px-20 px-2 pb-20 pt-10 text-black font-josefin-sans">
        <div className="flex justify-start lg:justify-center xl:justify-between flex-wrap items-center">
          <div className="m-2 px-3 py-2 w-[450px] text-black flex items-center gap-5 bg-white rounded-full">
            <IoSearchOutline color="black" size={23} />
            <input
              type="text"
              placeholder="Search anything"
              className="bg-white rounded-full w-full focus:outline-none text-xl"
            />
          </div>
          <div className="flex justify-center lg:justify-start items-center flex-wrap">
            {Showcasedata.map((item, index) => (
              <div
                key={index}
                className="w-60 md:w-48 m-2 justify-center flex items-center gap-2 px-4 py-2 border-2 bg-[#6161614A] hover:cursor-pointer hover:bg-[#FF00133D] border-zinc-600 text-black text-lg rounded-full hover:border-red-700 hover:text-red-500"
              >
                {item.icon}
                <p className="text-center">{item.name}</p>
              </div>
            ))}
          </div>
          {/* <div className='flex gap-5 items-center m-2 hover:cursor-pointer' onClick={handleFilter} >
                        <p className={`font-semibold text-lg ${open ? 'text-red-500' : 'text-black'}`}>Filters</p>
                        <img src={open ? filterOn : filter} alt="filter" />
                    </div> */}
        </div>
        {open && (
          <div>
            <div className="flex flex-wrap items-center justify-center space-x-4 px-4 py-2 border-2 border-zinc-600 bg-[#6161614A] text-black rounded-lg mt-5">
              <div className="border-r-2 border-zinc-600 flex justify-center">
                <button className="px-4 py-2 text-black rounded-lg hover:text-zinc-600 focus:outline-none">
                  NORMAL
                </button>
                <button className="px-4 py-2 text-black rounded-lg hover:text-zinc-600 focus:outline-none">
                  EXPENDED
                </button>
                <button className="px-4 py-2 text-black rounded-lg hover:text-zinc-600 focus:outline-none">
                  UPSCALED
                </button>
              </div>
              <div className="border-r-2 border-zinc-600 flex justify-center">
                <button className="px-4 py-2 text-black rounded-lg hover:text-zinc-600 focus:outline-none">
                  IMAGE
                </button>
                <button className="px-4 py-2 text-black rounded-lg hover:text-zinc-600 focus:outline-none">
                  VIDEO
                </button>
              </div>
              <div className="border-r-2 border-zinc-600 flex justify-center">
                <button className="px-4 py-2 text-black rounded-lg hover:text-zinc-600 focus:outline-none">
                  REALISTIC
                </button>
                <button className="px-4 py-2 text-black rounded-lg hover:text-zinc-600 focus:outline-none">
                  ANIME
                </button>
              </div>
              <button className="px-4 py-2 text-black rounded-lg hover:text-zinc-600 focus:outline-none">
                NSFW
              </button>
            </div>

            <div className="border-2 px-11 py-8 border-zinc-600 bg-[#6161614A] rounded-xl w-full h-[465px] overflow-y-auto scrollbar-thumb-rose-500 mt-5">
              <div className="flex flex-wrap justify-center gap-7 ">
                {afterModelData.map((item1, index) => {
                  return (
                    <div
                      key={index}
                      className="bg-[#ECECEC] w-[268px] h-[172px] rounded-2xl mt-3"
                    >
                      <div className="aftersexBg opacity-60 w-[268px] h-[172px] rounded-2xl flex justify-center items-center">
                        <p className="text-xl">{item1.name}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}

        {/* Grid layout for images with 5 columns */}

        {/* <div className='flex justify-center  items-center flex-wrap'>
                {imageData.map((item, index) => (
                    <div className="relative hover:cursor-pointer" onClick={() => handleImageView(item.generation_id)}>
                        <img
                            alt={item.generation_prompt} // You can dynamically set this to describe the image
                            loading="lazy"
                            width="300"
                            height="300"
                            decoding="async"
                            data-nimg="1"
                            className="rounded-3xl m-4 "
                            srcSet={`https://api2.eromantic.ai/media/output/${item.image_file_name}?w=384&q=90 1x, https://api2.eromantic.ai/media/output/${item.image_file_name}?w=640&q=90 2x`}
                            src={`https://api2.eromantic.ai/media/output/${item.image_file_name}?w=640&q=90`}

                            style={{ color: 'transparent' }}
                        />

                        <ImgDetailOnhoverGenration username={item.user_name} prompt={item.generation_prompt} model={item.Aimodel} extensions={item.extensions} />
                    </div>

                ))}
            </div> */}
        {/* <div className="grid grid-cols-2 gap-4 md:grid-cols-4">
                {imageData.map((item, index) => (
                    <div key={index} className="grid gap-4">
                    <div>
                        <img
                        className="h-auto max-w-full rounded-lg object-cover object-center"
                        src={`https://api2.eromantic.ai/media/output/${item.image_file_name}`}
                        alt={item.alt}
                        style={{ width: item.width || 'auto', height: item.height || 'auto' }}
                        />
                    </div>
                    </div>
                ))}
                </div> */}
        <div className="text-3xl text-center mt-5 animated-text">
          Discover All Public Generation{" "}
        </div>

        {/* <div className="p-5 md:p-10">
                    <div className="columns-1 gap-1 lg:gap-1 sm:columns-2 lg:columns-3 xl:columns-4 [&>img:not(:first-child)]:mt-5 lg:[&>img:not(:first-child)]:mt-2">
                        {imageData.map((item, index) => {
                            const baseUrl = `${item.image_file_name}?auto=format&fit=crop`;
                            return (
                                <div
                                    key={index}
                                    onClick={() => handleImageView(item.generation_id)}
                                    className="relative group"
                                >
                                    <img
                                        className="h-auto w-full rounded-2xl object-cover object-center p-2 transition-transform duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg cursor-pointer"
                                        src={`${baseUrl}`}
                                        alt={`gallery-photo-${index}`}
                                    />
                                    <div className="absolute bottom-2 left-2 right-2 bg-black bg-opacity-70 opacity-0 group-hover:opacity-100 transition-opacity duration-300 p-4 text-white text-center border-1 border-gray-400 rounded-[10px]">
                                    <p className="text-sm sm:text-md md:text-lg">{item.generation_prompt}</p>
                                    <h2 className='text-red-800 text-xl'>@{item.user_name}</h2>
                                    </div>
                                </div>
                            );
                        })}

                    </div>
                </div> */}

        <div className="p-5 md:p-10">
          <div className="columns-1 gap-1 lg:gap-1 sm:columns-2 lg:columns-3 xl:columns-4 [&>img:not(:first-child)]:mt-5 lg:[&>img:not(:first-child)]:mt-2">
            {imageData.map((item, index) => {
              // Check if image_file_name exists and has at least one item
              if (!item.image_file_name || item.image_file_name.length === 0) {
                return null; // Skip rendering this item if no images are present
              }

              return item.image_file_name.map((imageUrl, imgIndex) => {
                const baseUrl = `${imageUrl}?auto=format&fit=crop`;
                return (
                  <div
                    className="relative group"
                    key={`${index}-${imgIndex}`}
                    onClick={() => handleImageView(item.generation_id)}
                  >
                    <img
                      className="h-auto w-full rounded-2xl object-cover object-center p-2 transition-transform duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg cursor-pointer"
                      src={`${baseUrl}`}
                      alt={`gallery-photo-${imgIndex}`}
                    />
                    <div className="absolute bottom-2 left-2 right-2 bg-black bg-opacity-70 opacity-0 group-hover:opacity-100 transition-opacity duration-300 p-4 text-white text-center border-1 border-gray-400 rounded-[10px]">
                    <p className="text-sm sm:text-md md:text-lg">
                      {item.generation_prompt.split(' ').slice(0, 40).join(' ')}{item.generation_prompt.split(' ').length > 40 ? '...' : ''}
                    </p>
                      <div className="flex justify-between items-center">
                        <h2 className="text-red-800 text-xl text-wrap">
                          @{item.user_name}
                        </h2>

                        {item.upscale ? (
                          <div className="flex items-center space-x-2 px-2 py-1 bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 text-white rounded-full text-xs font-semibold hover:from-indigo-600 hover:via-purple-600 hover:to-pink-600 transition-all duration-300 ease-in-out shadow-md hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-indigo-300">
                            {/* Icon indicating upscale */}
                            <i class="fa-solid text-yellow-500 fa-star"></i>
                            <p className="ml-1">Upscaled</p>
                          </div>
                        ) : (
                          ""
                          // <button className="flex items-center space-x-2 px-2 py-1 bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 text-white rounded-full text-xs font-semibold hover:from-indigo-600 hover:via-purple-600 hover:to-pink-600 transition-all duration-300 ease-in-out shadow-md hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-indigo-300">
                          //   <span>Upscale Image</span>
                          // </button>
                        )}
                      </div>
                    </div>
                  </div>
                );
              });
            })}
          </div>
        </div>
        {showMoreBtn ? (
          <div className="flex items-center w-full justify-center">
            <button
              className="bg-red-800 [w-75%] text-white font-medium rounded-2xl py-2 px-4"
              onClick={() => {
                fetchImageData(imageData.length + 10);
              }}
            >
              <i class="fa-solid fa-angles-down mr-1"></i> Load More
            </button>
          </div>
        ) : (
          ""
        )}
      </div>
      <Footer />
    </>
  );
};

export default Discover;
