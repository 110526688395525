import React, { useState, useEffect } from "react";
import axios from "axios"; // Add axios import
import {
  img1,
  img10,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
} from "../../assets";
import { useNavigate } from "react-router-dom";
import ImgDetailOnhoverGenration from "./ImgDetailOnhoverGenration";
import { Fragment } from "react";
import UserApiClient from "../../utilities/apiClients";

const GenerationImagesBox = () => {
  const navigate = useNavigate();

  // State management for loading, error, and image data
  const [loading, setLoading] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [error, setError] = useState("");
  const [imageData, setImageData] = useState([]);
  const [imageDataLen, setImageDataLen] = useState(0);
  const [showMoreBtn, setShowMoreBtn] = useState(true);

  const handleImageView = (imgId) => {
    navigate(`/dashboard/ImageView/${imgId}`);
  };

  
  const upscale = (generation_id) => {
    console.log("aaaaaaa",generation_id)
    setLoading(true);
     UserApiClient.post(
      "api/v1/image/upscale/" ,{generation_id}, false ).then(async (res) => {
      if (res.success) {
        setLoading(false);
      } else {
        setError(error);
        setLoading(false);
      }
    });
  };

  const fetchImageData = async (limit) => {
    setLoading(true);
    await UserApiClient.get(
      "api/v1/get_allGenerated_image/?limit=" + limit || 10,
      {},
      false
    ).then(async (res) => {
      if (res.success) {
        setImageData(res.data);
        if (res.data.length === imageDataLen) {
          setShowMoreBtn(false);
        }
        setImageDataLen(res.data.length);
        setLoading(false);
      } else {
        setError(error.res.data.detail);
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    fetchImageData(10);
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error)
    return (
      <div className="w-full grid grid-cols-1 gap-3 justify-center mt-5 lg:mt-14">
        {" "}
        {/* Using CSS Grid for gallery view */}
        <div className="text-center relative text-red-500 text-2xl font-bold">
          {error}
        </div>
      </div>
    );
  console.log(userDetails, "ususususu");
  const getRandomWidth = () =>
    Math.floor(Math.random() * (380 - 360 + 80)) + 360;
  const getRandomHeight = () =>
    Math.floor(Math.random() * (524 - 244 + 120)) + 244;

  return (
    <>
      {/* <div className="p-5 md:p-10">
                <div className="columns-1 gap-1 lg:gap-1 sm:columns-2 lg:columns-3 xl:columns-4 [&>img:not(:first-child)]:mt-5 lg:[&>img:not(:first-child)]:mt-2">
                {imageData.map((item, index) => {
                        const baseUrl = `${item.image_file_name}?auto=format&fit=crop`;
                        return (
                            <div  className="relative group" key={index} onClick={() => handleImageView(item.generation_id)}>
                                <img
                                    className="h-auto w-full rounded-2xl object-cover object-center p-2 transition-transform duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg cursor-pointer"

                                    src={`${baseUrl}`}
                                    alt={`gallery-photo-${index}`}
                                />
                                    <div className="absolute bottom-2 left-2 right-2 bg-black bg-opacity-70 opacity-0 group-hover:opacity-100 transition-opacity duration-300 p-4 text-white text-center border-1 border-gray-400 rounded-[10px]">
                                    <p className="text-sm sm:text-md md:text-lg">{item.generation_prompt}</p>
                                    <h2 className='text-red-800 text-xl text-wrap'>@{item.user}</h2>
                                    </div>
                            </div>
                        );
                    })}
                </div>
            </div> */}
      <div className="p-5 md:p-10">
        <div className="columns-1 gap-1 lg:gap-1 sm:columns-2 lg:columns-3 xl:columns-4 [&>img:not(:first-child)]:mt-5 lg:[&>img:not(:first-child)]:mt-2">
          {imageData.map((item, index) => {
            // Check if image_file_name exists and has at least one item
            if (!item.image_file_name || item.image_file_name.length === 0) {
              return null; // Skip rendering this item if no images are present
            }

            return item.image_file_name.map((imageUrl, imgIndex) => {
              const baseUrl = `${imageUrl}?auto=format&fit=crop`;
              return (
                <div
                  className="relative group"
                  key={`${index}-${imgIndex}`}
                >
                  <img
                    className="h-auto w-full rounded-2xl object-cover object-center p-2 transition-transform duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg cursor-pointer"
                    src={`${baseUrl}`}
                    onClick={() => handleImageView(item.generation_id)}
                    alt={`gallery-photo-${imgIndex}`}
                  />
                  <div className="absolute bottom-2 left-2 right-2 bg-black bg-opacity-70 opacity-0 group-hover:opacity-100 transition-opacity duration-300 p-4 text-white text-center border-1 border-gray-400 rounded-[10px]">
                    <p className="text-sm sm:text-md md:text-lg">
                      {item.generation_prompt}
                    </p>
                    <div className="flex justify-between items-center">
                      <h2 className="text-red-800 text-xl text-wrap">
                        @{item.user_name}
                      </h2>

                      {item.upscale ? (
                        <div className="flex items-center space-x-2 px-2 py-1 bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 text-white rounded-full text-xs font-semibold hover:from-indigo-600 hover:via-purple-600 hover:to-pink-600 transition-all duration-300 ease-in-out shadow-md hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-indigo-300">
                          {/* Icon indicating upscale */}
                          <i class="fa-solid text-yellow-500 fa-star"></i>
                          <p className="ml-1">Upscaled</p>
                        </div>
                      ) : (
                        <button className="flex items-center space-x-2 px-2 py-1 bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 text-white rounded-full text-xs font-semibold hover:from-indigo-600 hover:via-purple-600 hover:to-pink-600 transition-all duration-300 ease-in-out shadow-md hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-indigo-300"
                        onClick={()=>upscale(item.generation_id)}
                          >
                          <span>Upscale Image</span>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              );
            });
          })}
        </div>
        {showMoreBtn ? (
          <div className="flex items-center w-full justify-center">
            <button
              className="bg-red-800 [w-75%] text-white font-medium rounded-2xl py-2 px-4"
              onClick={() => {
                fetchImageData(imageData.length + 10);
              }}
            >
              <i class="fa-solid fa-angles-down mr-1"></i> Load More
            </button>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default GenerationImagesBox;
