import React, { useRef, useState } from 'react'
import InputBox from '../../../components/InputBox'
import Swal from 'sweetalert2';
import UserApiClient from '../../../../utilities/apiClients';

const Security = () => {
    const [data, setData] = useState({});
    const [error, setError] = useState('');
    const resetBtn = useRef();

    const validatePassword = (password) => {
        const minLength = /.{8,}/; // Minimum 8 characters
        const hasLowercase = /[a-z]/; // At least one lowercase character
        const hasUpperCase = /[A-Z]/; // At least one uppercase character
        const hasSpecialChar = /[0-9!@#$%^&*(),.?":{}|<>]/; // At least one number, symbol, or whitespace character

        if (!minLength.test(password)) {
            return 'Password must be at least 8 characters long.';
        }
        if (!hasLowercase.test(password)) {
            return 'Password must contain at least one lowercase character.';
        }
        if (!hasUpperCase.test(password)) {
            return 'Password must contain at least one Uppercase character.';
        }
        if (!hasSpecialChar.test(password)) {
            return 'Password must contain at least one number, symbol, or whitespace character.';
        }
        return '';
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        if (name == "new_password") {
            const validationError = validatePassword(value);
            setError(validationError);
        }
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        if (data.new_password != data.confirm_password) {
            Swal.fire('New and Confirm password should be same.', '', 'warning', 3000)
            return;
        }

        if (error.length > 0) return;

        await UserApiClient.put(`api/v1/user/change-password/`, { ...data }, true).then(async res => {
            if (res.success) {
                Swal.fire(res.data.message, '', 'success', 3000)
                resetBtn.current?.click();
            }
        })
    }

    return (
        <>
            <form onSubmit={handleFormSubmit}>
                <div className='font-josefin-sans'>
                    <p className='text-2xl font-semibold mt-10'>Change Password</p>
                    <div className='w-full flex justify-between mt-7'>
                        <div className='w-[48%]'>
                            <InputBox placholderName='Current Password' title='Current Password' textType='password' type="text" id="old_password" name='old_password' onChange={handleChange} required />
                            <InputBox placholderName='New Password' title='New Password' textType='password' id="new_password" name='new_password' onChange={handleChange} required />
                            {error && <div style={{ color: 'red', marginBottom: '20px' }}>{error}</div>}
                            <p className='text-xl font-semibold text-stone-500 pb-2 xl:pb-3'>Password Requirements:</p>
                            <ul>
                                <li className='ms-5 xl:ms-14 pt-1 xl:py-2 list-disc text-base xl:text-lg font-semibold text-stone-500 ' >Minimum 8 characters long - the more, the better</li>
                                <li className='ms-5 xl:ms-14 pt-1 xl:py-2 list-disc text-base xl:text-lg font-semibold text-stone-500 ' >At least one lowercase character</li>
                                <li className='ms-5 xl:ms-14 pt-1 xl:py-2 list-disc text-base xl:text-lg font-semibold text-stone-500 ' >At least one number, symbol, or whitespace character</li>
                            </ul>
                        </div>
                        <div className='w-[48%] pt-[7.7rem]'>
                            <InputBox placholderName='Confirm New Password' title='Confirm New Password' textType='password' id="confirm_password" name='confirm_password' onChange={handleChange} required />
                        </div>
                    </div>
                </div>
                <div className='flex justify-between'>
                    <div className='w-[48%]'>
                        <button type='submit' className='rounded-lg w-full mt-6 bg-red-800 hover:bg-red-700 font-semibold text-white text-lg p-4'>Save Changes</button>
                    </div>
                    <div className='w-[48%]'>
                        <button type='reset' ref={resetBtn} className='rounded-lg w-full mt-6 bg-stone-300 hover:bg-stone-400 font-semibold text-black text-lg p-4'>Reset</button>
                    </div>
                </div>
            </form>
        </>
    )
}

export default Security
