import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Footer from '../../../frontend/components/Footer';
import UserApiClient from '../../../utilities/apiClients';
import { useSelector } from 'react-redux';
import { AppDomain } from '../../../utilities/enviroment';

const AffiliateSignupPage = () => {
    const user = useSelector(state=>state.user.user);
    const [referralLink, setReferralLink] = useState('');
    const [affiliateId, setAffiliateId] = useState(''); // New state to store affiliate ID
    const [formData, setFormData] = useState({
        ref_website: '',
        promotion_plan: '',
        ref_source: '',
    });
    const [errors, setErrors] = useState({});
    const [submitted, setSubmitted] = useState(false);
    const [isAffiliated, setIsAffiliated] = useState(false);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formData.ref_website) newErrors.ref_website = "Website or social profile is required";
        if (!formData.promotion_plan) newErrors.promotion_plan = "about you and your Promotion Plan is required";
        return newErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }
        await UserApiClient.put(`api/v1/affiliate/request/${user.id}/`, {...formData, "req_affiliate": true}, true).then(async res=>{
            if (res.success) {
                setSubmitted(res.data.req_affiliate);
            }
        })
    };

    const fetchData = async () => {
        await UserApiClient.get(`api/v1/affiliate/request/${user.id}/`, {}, true).then(async res=>{
            if (res.success) {
                setIsAffiliated(res.data.is_affiliated);
                setSubmitted(res.data.is_requesting_affiliate);
                setReferralLink(`${AppDomain}join/referral?affiliate_id=${res.data.affiliate_id}`)
                setAffiliateId(res.data.affiliate_id)
            }
        })
    }
    useEffect(()=>{
        if (!submitted) {
            fetchData();
        }
    }, [submitted])

    return (
        <>
            <div className="container mx-auto px-6 py-12 text-white rounded-lg shadow-lg">
                {/* <h1 className="text-4xl font-extrabold text-center mb-8">Affiliate Program Signup</h1>
                <p className="text-center text-lg mb-12">
                    Join our affiliate program and start earning by promoting our platform. Please fill out the form below to apply.
                </p> */}
                <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold text-center text-white my-8">Become an Affiliate Member</h1>
                <p className="text-center text-lg md:text-xl lg:text-2xl text-white mb-12">
                    Join our affiliate program and start earning by promoting Eromantic-AI. Our program is designed to offer attractive commissions, dedicated support, and an easy way to grow your earnings.
                </p>

                {!submitted ? (
                    <form onSubmit={handleSubmit} className="max-w-lg mx-auto bg-white shadow-md rounded-lg p-8 transform transition-all hover:scale-105 duration-300">
                        <div className="mb-6">
                            <label htmlFor="website" className="block text-gray-700 font-semibold mb-2">Website/Social Profile</label>
                            <input
                                type="text"
                                id="website"
                                name="ref_website"
                                value={formData.ref_website}
                                onChange={handleChange}
                                className={`w-full px-4 py-2 text-black border rounded-md focus:outline-none focus:ring-2 focus:ring-red-500 ${errors.ref_website ? 'border-red-500' : 'border-gray-300'}`}
                                placeholder="Link to your website or social profile"
                            />
                            {errors.ref_website && <p className="text-red-500 text-sm mt-1">{errors.ref_website}</p>}
                        </div>

                        {/* New Textarea Field */}
                        <div className="mb-6">
                            <label htmlFor="promotionPlan" className="block text-gray-700 font-semibold mb-2">Please tell us about you and your Promotion Plan:</label>
                            <textarea
                                id="promotionPlan"
                                name="promotion_plan"
                                row="5"
                                value={formData.promotion_plan}
                                onChange={handleChange}
                                style={{ scrollbarWidth: "thin" }}
                                className={`w-full px-4 py-2 text-black border rounded-md focus:outline-none focus:ring-2 focus:ring-red-500 ${errors.ref_website ? 'border-red-500' : 'border-gray-300'}`}
                                placeholder="Describe your audience (size, age, gender, interests, etc.) and the promotion methods you'll be using."
                            />
                            {errors.promotion_plan && <p className="text-red-500 text-sm mt-1">{errors.promotion_plan}</p>}
                        </div>

                        <div className="mb-6">
                            <label htmlFor="referralSource" className="block text-gray-700 font-semibold mb-2">How did you hear about us?</label>
                            <input
                                type="text"
                                id="referralSource"
                                name="ref_source"
                                value={formData.ref_source}
                                onChange={handleChange}
                                className="w-full px-4 py-2 text-black border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500"
                                placeholder="Optional"
                            />
                        </div>

                        {errors.form && <p className="text-red-500 text-sm mt-4">{errors.form}</p>}

                        <button
                            type="submit"
                            className="w-full bg-gradient-to-r from-[#ea3333d1] to-[#e64a77] text-white py-3 rounded-md text-lg font-semibold hover:bg-purple-700 transition duration-200 transform hover:scale-105"
                        >
                            Submit Application
                        </button>
                    </form>
                ) : (
                    <div className="text-center mt-8 bg-white shadow-lg p-8 rounded-lg">
                        <h2 className="text-2xl font-semibold mb-4 text-indigo-600">Thank You for Joining Our Affiliate Program!</h2>
                        {isAffiliated ? (
                            <>
                                <p className="text-lg mb-4 text-black">Your Affiliate ID:</p>
                                <p className="text-lg font-semibold text-blue-500 break-all">{affiliateId}</p>
                                <p className="text-lg mt-4 text-black">Share your referral link to start earning:</p>
                                <p className="text-lg font-semibold text-blue-500 break-all">{referralLink}</p>
                            </>
                        ): (
                            <>
                                <p className="text-lg mb-4 text-black">Your request has been submitted. Please await approval from the administrator.</p>
                                <p className="text-lg mb-4 text-black">You will have your affiliate link after approval.</p>
                            </>
                        )}
                    </div>
                )}


                {/* Affiliate Program Overview */}
                <div className="mb-8 mt-10 bg-white p-6 rounded-xl shadow-lg">
                    <h2 className="text-xl md:text-2xl lg:text-3xl font-semibold text-black mb-2">Why Join Our Affiliate Program?</h2>
                    <p className="lg:text-xl md:text-lg text-base text-black mb-4">
                        As an affiliate, you earn a commission for every successful referral. Our program offers up to 50% commission on direct referrals, with additional earnings from sub-affiliate referrals. We provide support to help you maximize your success as an affiliate.
                    </p>
                </div>

                {/* Policy Summary */}
                <div className="mb-8 bg-white p-6 rounded-xl shadow-lg">
                    <h2 className="text-xl md:text-2xl lg:text-3xl font-semibold text-black mb-2">Affiliate Program Policy</h2>
                    <p className="lg:text-xl md:text-lg text-base text-black mb-4">
                        Our affiliate program is designed for transparency and fairness. Affiliates are expected to adhere to ethical promotion practices, including honest representation and avoiding any misleading promotions. Earnings are processed on demand once you reach a threshold of $150, and we offer support to answer any questions or concerns along the way.
                    </p>
                    <p className="lg:text-xl md:text-lg text-base text-black mb-4">
                        For more details, please refer to our Affiliate Program Policy available in our Policies section.
                    </p>
                </div>

                {/* Benefits Section */}
                <div className="mb-8 bg-white p-6 rounded-xl shadow-lg">
                    <h2 className="text-xl md:text-2xl lg:text-3xl font-semibold text-black mb-2">Benefits of Becoming an Affiliate</h2>
                    <ul className="list-disc list-inside lg:text-xl md:text-lg text-base text-black mb-4">
                        <li>Earn up to 50% commission on direct referrals.</li>
                        <li>Additional earnings from sub-affiliate referrals.</li>
                        <li>Access to exclusive affiliate offers and promotions.</li>
                        <li>Dedicated support team to help you succeed.</li>
                        <li>Comprehensive tracking and reporting tools.</li>
                    </ul>
                </div>
                <div className="text-center mt-8">
                    <p className="text-lg">
                        For questions or assistance, contact our support team at
                        <a href="https://support.eromantic.ai/hc/en-us" className="text-red-500 ml-1">support@eromantic.ai</a>.
                    </p>
                </div>
            </div>

            <Footer />
        </>
    );
};

export default AffiliateSignupPage;
