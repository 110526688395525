import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { FaHeart } from "react-icons/fa6";
import { GiRoundStar } from "react-icons/gi";
import { AiFillLike } from "react-icons/ai";
import { IoIosTime } from "react-icons/io";
import Footer from "../../frontend/components/Footer";
import UserApiClient from "../../utilities/apiClients";

const DiscoverImagesBox = () => {
  const [imageData, setImageData] = useState([]); // Ensure this is initialized as an empty array
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [userDetails, setUserDetails] = useState("");
  const navigate = useNavigate();

  const handleFilter = () => {
    setOpen(!open);
  };

  useEffect(() => {
    UserApiClient.get(
      "api/v1/get_allPublic_image/?limit=10",
      {},
      true,
      true,
      undefined
    ).then(async (res) => {
      if (res.data) {
        setImageData(res.data); // Set fetched image data to state
        console.log("Response",res.data)
      } else {
        setError("");
      }
    });
  }, []);

  const handleImageView = (imgId) => {
    navigate(`/dashboard/ImageView/${imgId}`);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  // Ensure imageData is an array before calling .reverse()
  const imagesToShow = Array.isArray(imageData)
    ? imageData.reverse().slice(0, 15)
    : []; // Get the latest 15 images

  return (
    <>
      {/* <div className="p-5 md:p-10">
                <div className="columns-1 gap-1 lg:gap-1 sm:columns-2 lg:columns-3 xl:columns-4 [&>img:not(:first-child)]:mt-5 lg:[&>img:not(:first-child)]:mt-2">
                    {imagesToShow.map((item, index) => {
                        const baseUrl = `${item.image_file_name}?auto=format&fit=crop`;
                        return (
                            <div
                                key={index}
                                onClick={() => handleImageView(item.generation_id)}
                                className="relative group"
                            >
                                <img
                                    className="h-auto max-w-full rounded-2xl object-cover object-center p-2 transition-transform duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg cursor-pointer"
                                    src={baseUrl}
                                    alt={`gallery-photo-${index}`}
                                />
                                <div className="absolute bottom-2 left-2 right-2 bg-black bg-opacity-70 opacity-0 group-hover:opacity-100 transition-opacity duration-300 p-4 text-white text-center border-1 border-gray-400 rounded-[10px]">
                                    <p className="text-sm sm:text-md md:text-lg">{item.generation_prompt}</p>
                                    <h2 className='text-red-800 text-xl'>@{item.user_name}</h2>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div> */}

        <p className="text-2xl lg:text-4xl font-medium mt-1 lg:mt-10 text-center">
          You Might Also Enjoy
        </p>
      <div className="p-5 md:p-10">
        <div className="columns-1 gap-1 lg:gap-1 sm:columns-2 lg:columns-3 xl:columns-4 [&>img:not(:first-child)]:mt-5 lg:[&>img:not(:first-child)]:mt-2">
          {imagesToShow.map((item, index) => {
            // Check if image_file_name exists and has at least one item
            if (!item.image_file_name || item.image_file_name.length === 0) {
              return null; // Skip rendering this item if no images are present
            }

            return item.image_file_name.map((imageUrl, imgIndex) => {
              const baseUrl = `${imageUrl}?auto=format&fit=crop`;
              return (
                <div
                  className="relative group"
                  key={`${index}-${imgIndex}`}
                  onClick={() => handleImageView(item.generation_id)}
                >
                  <img
                    className="h-auto w-full rounded-2xl object-cover object-center p-2 transition-transform duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg cursor-pointer"
                    src={`${baseUrl}`}
                    alt={`gallery-photo-${imgIndex}`}
                  />
                  <div className="absolute bottom-2 left-2 right-2 bg-black bg-opacity-70 opacity-0 group-hover:opacity-100 transition-opacity duration-300 p-4 text-white text-center border-1 border-gray-400 rounded-[10px]">
                    <p className="text-sm sm:text-md md:text-lg">
                      {item.generation_prompt}
                    </p>
                    <h2 className="text-red-800 text-xl text-wrap">
                      @{item.user_name}
                    </h2>
                  </div>
                </div>
              );
            });
          })}
        </div>
      </div>
      {/* Optional: Footer component can be added here if required */}
      <Footer />
    </>
  );
};

export default DiscoverImagesBox;
