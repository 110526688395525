import React, { useState } from "react";

const TemplateOne = () => {
  const [image1, setImage1] = useState(null);
  const [image2, setImage2] = useState(null);

  // Handle file input change
  const handleImageChange = (e, setImage) => {
    const file = e.target.files[0];
    if (file) {
      setImage(URL.createObjectURL(file));
    }
  };

  return (
    <div className="p-8 space-y-6">
      {/* Section 1 */}
      <div className="space-y-4">
        <h2 className="text-xl font-semibold">Section 1</h2>
        <div className="flex flex-col space-y-2">
          <label htmlFor="heading1" className="font-medium">
            Heading
          </label>
          <input
            id="heading1"
            type="text"
            className="p-2 border border-gray-300 rounded"
            placeholder="Enter heading"
          />
        </div>
        <div className="flex flex-col space-y-2">
          <label htmlFor="content1" className="font-medium">
            Content
          </label>
          <textarea
            id="content1"
            rows="4"
            className="p-2 border border-gray-300 rounded"
            placeholder="Enter content"
          />
        </div>
        <div className="flex flex-col space-y-2">
          <label htmlFor="image1" className="font-medium">
            Upload Image 1
          </label>
          <input
            id="image1"
            type="file"
            accept="image/*"
            className="p-2 border border-gray-300 rounded"
            onChange={(e) => handleImageChange(e, setImage1)}
          />
          {image1 && (
            <img
              src={image1}
              alt="Preview"
              className="mt-2 w-32 h-32 object-cover"
            />
          )}
        </div>
        <div className="flex flex-col space-y-2">
          <label htmlFor="image2" className="font-medium">
            Upload Image 2
          </label>
          <input
            id="image2"
            type="file"
            accept="image/*"
            className="p-2 border border-gray-300 rounded"
            onChange={(e) => handleImageChange(e, setImage2)}
          />
          {image2 && (
            <img
              src={image2}
              alt="Preview"
              className="mt-2 w-32 h-32 object-cover"
            />
          )}
        </div>
      </div>

      {/* Section 2 */}
      <div className="space-y-4">
        <h2 className="text-xl font-semibold">Section 2</h2>
        <div className="flex flex-col space-y-2">
          <label htmlFor="image3" className="font-medium">
            Upload Image
          </label>
          <input
            id="image3"
            type="file"
            accept="image/*"
            className="p-2 border border-gray-300 rounded"
          />
        </div>
        <div className="flex flex-col space-y-2">
          <label htmlFor="heading2" className="font-medium">
            Heading
          </label>
          <input
            id="heading2"
            type="text"
            className="p-2 border border-gray-300 rounded"
            placeholder="Enter heading"
          />
        </div>
        <div className="flex flex-col space-y-2">
          <label htmlFor="content2" className="font-medium">
            Content
          </label>
          <textarea
            id="content2"
            rows="4"
            className="p-2 border border-gray-300 rounded"
            placeholder="Enter content"
          />
        </div>
      </div>

      {/* Section 3 */}
      <div className="space-y-4">
        <h2 className="text-xl font-semibold">Section 3</h2>
        <div className="flex flex-col space-y-2">
          <label htmlFor="heading3" className="font-medium">
            Heading
          </label>
          <input
            id="heading3"
            type="text"
            className="p-2 border border-gray-300 rounded"
            placeholder="Enter heading"
          />
        </div>
        <div className="flex flex-col space-y-2">
          <label htmlFor="content3" className="font-medium">
            Content
          </label>
          <textarea
            id="content3"
            rows="4"
            className="p-2 border border-gray-300 rounded"
            placeholder="Enter content"
          />
        </div>
        <div className="flex flex-col space-y-2">
          <label htmlFor="image4" className="font-medium">
            Upload Image
          </label>
          <input
            id="image4"
            type="file"
            accept="image/*"
            className="p-2 border border-gray-300 rounded"
          />
        </div>
      </div>

      {/* Section 4 */}
<div className="space-y-4">
  <h2 className="text-xl font-semibold">Section 4</h2>

  {/* Step 1 */}
  <div className="flex flex-col space-y-2">
    <label htmlFor="step1" className="font-medium">Step 1 Number</label>
    <input
      id="step1"
      type="text"
      className="p-2 border border-gray-300 rounded"
      placeholder="Enter step number (e.g. 1)"
    />
  </div>
  <div className="flex flex-col space-y-2">
    <label htmlFor="heading1" className="font-medium">Step 1 Heading</label>
    <input
      id="heading1"
      type="text"
      className="p-2 border border-gray-300 rounded"
      placeholder="Enter heading (e.g. Get Your AI Girlfriend)"
    />
  </div>
  <div className="flex flex-col space-y-2">
    <label htmlFor="content1" className="font-medium">Step 1 Content</label>
    <textarea
      id="content1"
      rows="4"
      className="p-2 border border-gray-300 rounded"
      placeholder="Enter content for step 1"
    />
  </div>

  {/* Step 2 */}
  <div className="flex flex-col space-y-2">
    <label htmlFor="step2" className="font-medium">Step 2 Number</label>
    <input
      id="step2"
      type="text"
      className="p-2 border border-gray-300 rounded"
      placeholder="Enter step number (e.g. 2)"
    />
  </div>
  <div className="flex flex-col space-y-2">
    <label htmlFor="heading2" className="font-medium">Step 2 Heading</label>
    <input
      id="heading2"
      type="text"
      className="p-2 border border-gray-300 rounded"
      placeholder="Enter heading (e.g. Customize Your Girlfriend)"
    />
  </div>
  <div className="flex flex-col space-y-2">
    <label htmlFor="content2" className="font-medium">Step 2 Content</label>
    <textarea
      id="content2"
      rows="4"
      className="p-2 border border-gray-300 rounded"
      placeholder="Enter content for step 2"
    />
  </div>

  {/* Step 3 */}
  <div className="flex flex-col space-y-2">
    <label htmlFor="step3" className="font-medium">Step 3 Number</label>
    <input
      id="step3"
      type="text"
      className="p-2 border border-gray-300 rounded"
      placeholder="Enter step number (e.g. 3)"
    />
  </div>
  <div className="flex flex-col space-y-2">
    <label htmlFor="heading3" className="font-medium">Step 3 Heading</label>
    <input
      id="heading3"
      type="text"
      className="p-2 border border-gray-300 rounded"
      placeholder="Enter heading (e.g. Start Chatting)"
    />
  </div>
  <div className="flex flex-col space-y-2">
    <label htmlFor="content3" className="font-medium">Step 3 Content</label>
    <textarea
      id="content3"
      rows="4"
      className="p-2 border border-gray-300 rounded"
      placeholder="Enter content for step 3"
    />
  </div>
</div>


      {/* Section 5 */}
      <div className="space-y-4">
        <h2 className="text-xl font-semibold">Section 5</h2>
        <div className="flex flex-col space-y-2">
          <label htmlFor="heading5" className="font-medium">
            Heading
          </label>
          <input
            id="heading5"
            type="text"
            className="p-2 border border-gray-300 rounded"
            placeholder="Enter heading"
          />
        </div>
        <div className="flex flex-col space-y-2">
          <label htmlFor="content5" className="font-medium">
            Content
          </label>
          <textarea
            id="content5"
            rows="4"
            className="p-2 border border-gray-300 rounded"
            placeholder="Enter content"
          />
        </div>
      </div>
    </div>
  );
};

export default TemplateOne;
