import { useState, useEffect } from "react";
import axios from 'axios';
import { RiCompassDiscoverLine, RiAiGenerate } from "react-icons/ri";
import { TbGenderDemigirl, TbPremiumRights, TbArrowGuide, TbGenderDemiboy } from "react-icons/tb";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import UserApiClient from "../../utilities/apiClients";

export const Sidebar = ({ isOpen, setActiveTab,setIsOpen }) => {
    const userData = useSelector(state => state.user);
    const user = userData.user;
    const [active, setActive] = useState(1);
    const [Userlogger, setUserlogger] = useState(null);
    const [usercheck, setUsercheck] = useState(null);
    const [errors, setErrors] = useState({});
    const [isAffiliated, setIsAffiliated] = useState(false);
    const goto = (index) => setActive(index);
    const navigate = useNavigate();

    useEffect(() => {
        setUsercheck(localStorage.getItem('hasVisited'));
        setUserlogger(localStorage.getItem('token') && userData.isAuthenticated);
    }, []); // Add dependency array to avoid infinite loop

    const switchToBoyFriend = () => {
        setActiveTab('Boyfriend');
        navigate("/");
    };
    const switchToGirlFriend = () => {
        setActiveTab('Girlfriend');
        navigate("/");
    };
    const closeSidebar = () => {
        setIsOpen(false);  // Close the sidebar when called
    };

useEffect(() => {
        if (userData.isAuthenticated && Userlogger) {
            setIsAffiliated(user.is_affiliated);
        }
        
    }, [userData, Userlogger]);

    const location = useLocation();
const isAidreamGFchat = location.pathname.startsWith('/AidreamGFchat');


    const navItems = [
        // ...(isAidreamGFchat ? [
        //     { label: "Home", icon: <i class="fa-solid fa-house"></i>, link: "/" }
        // ] : []),
        { label: "Home", icon: <i class="fa-solid fa-house"></i>, link: "/" },
        // { label: "AI GIRLFRIEND", icon: <i className="fa-solid fa-venus"></i>, onClick: switchToGirlFriend },
        // { label: "AI Creation", icon: <i class="fa-solid fa-venus-mars"></i>, onClick: switchToGirlFriend },
        { label: "‎ Create AI Companion", icon: <i class="fa-solid fa-child-dress"></i>, link: Userlogger ? "/CreateDreamGF" : "/login" },
        // {
        //     label: "‎ Create AI GIRLFRIEND", 
        //     icon: <i className="fa-solid fa-child-dress"></i>, 
        //     link: Userlogger ? "/CreateDreamGF" : "/login", 
        //     backgroundColor: '#FFB6C1',
        //     borderRight: '2px solid #FF69B4', 
        //     borderTopRightRadius: '20px', 
        //     borderBottomRightRadius: '20px' 
        // },
        // {
        //     label: "‎ Create AI BOYFRIEND", 
        //     icon: <i className="fa-solid fa-person"></i>, 
        //     link: Userlogger ? "/BoyFriend" : "/login", 
        //     backgroundColor: '#ADD8E6',
        //     borderRight: '2px solid #1E90FF',
        //     borderTopRightRadius: '20px', 
        //     borderBottomRightRadius: '20px' 
        // },
        { label: "Generate", icon: <i className="fa-solid fa-heart-circle-plus"></i>, link: Userlogger ? "/dashboard":"/login" },
        { label: "Chat", icon: <i className="fa-solid fa-comments"></i>, link: Userlogger ? "/AidreamGFchat" : "/login" },
        { label: "My Creations", icon: <i class="fa-solid fa-shield-heart"></i>, link: Userlogger ? "/dashboard/mycreation" : "/login" },
        { label: "Discover", icon: <i className="fa-solid fa-compass"></i>, link: Userlogger ? "/dashboard/discover" : "/login" },
        { label: "My Gallery", icon: <i className="fa-solid fa-file-circle-plus"></i>, link: Userlogger ? "/dashboard/generations" : "/login" },
        { label: "Pricing", icon: <i className="fa-solid fa-square-poll-vertical"></i>, link: Userlogger ? "/priceplan" : "/login" },
        {
            label: "Affiliates",
            icon: <i className="fa-solid fa-user-plus"></i>,
            link: isAffiliated
                ? (Userlogger ? "/dashboard/referrals" : "/login")
                : (Userlogger ? "/dashboard/affiliate-signup" : "/login")
        },
        { label: "Support", icon: <i className="fa-solid fa-headset"></i>, link: "https://support.eromantic.ai/hc/en-us", isExternal: true },

    ];

    return (
        <>
            <div className="">
                <aside style={{ background: 'linear-gradient(135deg, rgb(255 255 255 / 81%), rgb(161 145 145) 40%, rgb(109 109 109 / 80%) 70%) center center / cover',top:isAidreamGFchat?"0px":"80px" }} className={`sidebar ${isOpen ? 'sidewidth' : ''}`}>
                {/* <aside style={{ background: 'linear-gradient(135deg, rgb(255 255 255 / 81%), rgb(161 145 145) 40%, rgb(109 109 109 / 80%) 70%) center center / cover', ...(isAidreamGFchat ? { top: '0' } : {}) }} className={`sidebar ${isOpen ? 'sidewidth' : ''}`}> */}
                    <div className="inner">
                        <nav className="menu mt-3">
                            {navItems.map((item, index) => (
                                <div key={item.label} style={{ textDecoration: 'none' }}>
                                    <button
                                        className={active === index ? "active" : ""}
                                        onClick={() => {
                                            goto(index);
                                            if (item.onClick) {
                                                item.onClick();
                                            } else if (item.isExternal) {
                                                window.open(item.link, "_blank"); // Opens the link in a new tab
                                            } else {
                                                navigate(item.link); // Navigates for internal routes
                                            }
                                            closeSidebar()
                                        }}
                                        style={{
                                            backgroundColor: item.backgroundColor ? item.backgroundColor : '',
                                            borderRight: item.borderRight ? item.borderRight : '',
                                            borderTopRightRadius: item.borderTopRightRadius ? item.borderTopRightRadius : '', // Apply top-right border radius
                                            borderBottomRightRadius: item.borderBottomRightRadius ? item.borderBottomRightRadius : '', // Apply bottom-right border radius
                                            paddingRight: item.borderRight ? '10px' : '' // Add padding to avoid overlapping with the border
                                        }}
                                    >
                                        <span className="text-[16px] xl:text-[24px] text-black">{item.icon}</span>
                                        <p className="text-[12px] xl:text-[16px] text-black">{item.label}</p>
                                    </button>

                                </div>
                            ))}
                        </nav>
                    </div>
                </aside>
            </div>
            <div className="">
                <aside className={`sidebarmob ${isOpen ? 'sidewidthmob' : ''}`}>
                    <div className="inner">
                        <nav className="menu mt-3">
                            {navItems.map((item, index) => (
                                <div key={item.label} style={{ textDecoration: 'none' }}>
                                    <button
                                        className={active === index ? "active" : ""}
                                        onClick={() => {
                                            goto(index);
                                            if (item.onClick) {
                                                item.onClick();
                                            } else if (item.isExternal) {
                                                window.open(item.link, "_blank"); // Opens the link in a new tab
                                            } else {
                                                navigate(item.link); // Navigates for internal routes
                                            }
                                            closeSidebar()
                                        }}
                                        style={{
                                            backgroundColor: item.backgroundColor ? item.backgroundColor : '',
                                            borderRight: item.borderRight ? item.borderRight : '',
                                            borderTopRightRadius: item.borderTopRightRadius ? item.borderTopRightRadius : '', // Apply top-right border radius
                                            borderBottomRightRadius: item.borderBottomRightRadius ? item.borderBottomRightRadius : '', // Apply bottom-right border radius
                                            paddingRight: item.borderRight ? '10px' : '' // Add padding to avoid overlapping with the border
                                        }}
                                    >
                                        <span className="text-[16px] xl:text-[24px] text-black">{item.icon}</span>
                                        <p className="text-[12px] xl:text-[16px] text-black">{item.label}</p>
                                    </button>

                                </div>
                            ))}
                        </nav>
                    </div>
                </aside>
            </div>
        </>
    );
};

export default Sidebar;
