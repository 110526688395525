import React, { useState, useEffect } from 'react';
import BlogListCardHelp from '../../../components/BlogListCardHelp';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import PagenameSearchBox from '../../../components/PagenameSearchBox';

const BASE_URL = "https://api2.eromantic.ai/eromantic_api/";

const HelpBlog = () => {
    const navigate = useNavigate();
    const [blogs, setBlogs] = useState([]);
    const [filteredBlogs, setFilteredBlogs] = useState([]); // State for filtered blogs
    const [searchQuery, setSearchQuery] = useState(''); // State for search query

    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                const authToken = localStorage.getItem('authToken');
                const response = await axios.get(`${BASE_URL}show_blog_post_help/`, {
                    headers: {
                        'Authorization': `Token ${authToken}`  // or 'Bearer' for JWT tokens
                    }
                });
                setBlogs(response.data);
                setFilteredBlogs(response.data); // Initialize filtered blogs
            } catch (error) {
                console.error('Error fetching blogs:', error);
            }
        };

        fetchBlogs();
    }, []);

    useEffect(() => {
        // Filter blogs based on search query
        const results = blogs.filter(blog =>
            blog.title.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredBlogs(results);
    }, [searchQuery, blogs]);

    const handleBlog = () => {
        const authToken = localStorage.getItem('authToken');
        if (!authToken) {
            console.error('No auth token found. Please log in.');
            return;
        }
        navigate('/admin/createHelpBlog');
    };

    return (
        <div className='h-full bg-white px-7 pb-20 font-josefin-sans'>
            <PagenameSearchBox 
                title='Blog Help List' 
                placename='Search help Blog' 
                btnname='CREATE HELP BLOG' 
                btnfunction={handleBlog} 
                onSearchChange={e => setSearchQuery(e.target.value)} // Set search query
            />
            <div className='flex flex-wrap justify-start gap-8 pt-8'>
                {filteredBlogs.map((blog) => (
                    <BlogListCardHelp
                        key={blog.blog_id}
                        blogId={blog.blog_id}
                        img={blog.image}  // Assuming your API response has the image URL
                        title={blog.title}
                        subtext={''}
                        blog_publish={blog.blog_publish}
                    />
                ))}
            </div>
        </div>
    );
};

export default HelpBlog;
