// src/redux/store.js
import { createStore, combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session'; // for sessionStorage
import userReducer from './reducers';

// Set up persist config
const persistConfig = {
    key: 'root',
    storage: storageSession,  // using sessionStorage
  };

// Combine reducers (you can add more reducers here if needed)
const rootReducer = combineReducers({
  user: userReducer,
});

// Apply persistReducer to the rootReducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create the Redux store with persistedReducer
const store = createStore(persistedReducer);

// Create a persistor instance
const persistor = persistStore(store);

export { store, persistor };
