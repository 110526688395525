import React from "react";
import { Logo } from "../../assets";
import { useNavigate } from 'react-router-dom/dist';

const LandingPopup = ({ onClose }) => {
    const navigate = useNavigate();

    const handleBackgroundClick = (e) => {
        // Prevent click events from bubbling up to the background
        e.stopPropagation();
        window.location.href = "https://www.google.com";
    };

    const handleLogin = () => {
        localStorage.setItem("hasVisited", "true");
        onClose();
    };

    return (
        <div
            className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 font-josefin-sans z-[1100]"
        // onClick={handleBackgroundClick} 
        >
            <div
                className="bg-white p-0 rounded-lg shadow-lg w-12/12 w-11/12 lg:w-11/12 xl:w-4/6 2xl:w-3/6"
                onClick={(e) => e.stopPropagation()} // Stop clicks from propagating to the background
            >
                <div className='pb-4 flex justify-center items-center'>
                    <img src={Logo} alt="logo" className='w-56 md:w-72' />
                </div>

                <hr className="border-[1.2px] w-full  border-[#b1b0b0]" />

                <div className="mx-9 text-[14px] lg:text-[18px]">
                    <p className="text-[#FF0013] py-4 "><span className="font-bold">Warning: </span>This site is intended for adults only and contains AI-generated adult imagery.</p>
                    <p className="text-[#222222] my-4">
                        By entering this website, you confirm that you are at least 18 years old. By using the site, you agree to our Terms of Service. Our Privacy Policy outlines how we collect and use your data. We use cookies for basic analytics and spam detection.
                    </p>
                    <p className="text-[#222222] mt-10">Any content generated on this website that resembles real people is purely coincidental.</p>
                    <div className="flex justify-center my-4 gap-5">
                        <button
                            onClick={handleLogin}
                            type="button"
                            className="text-white bg-[#FF0013] hover:bg-[#ff0013] rounded-[55px] text-[18px] xl:text-[20px] px-3 xl:px-5 text-center w-[150px] xl:w-[180px] h-[40px] xl:h-[48px] xl:font-normal mt-4 lg:mt-6 xl:mt-12"
                        >
                            I Confirm
                        </button>
                        <button
                            onClick={handleBackgroundClick}
                            type="button"
                            className="hover:text-white text-[#FF0013] hover:bg-[#FF0013] border border-[#FF0013] rounded-[55px] text-[18px] xl:text-[20px] px-3 xl:px-5 text-center xl:w-[240px] h-[40px] xl:h-[48px] xl:font-normal mt-4 lg:mt-6 xl:mt-12"
                        >
                            Leave The Website
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LandingPopup;
