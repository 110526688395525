import React from 'react'
import { useNavigate } from 'react-router-dom'
import { DeleteImg, DolarWhite, eye, fileWhite, invoiceWhite, rename, usreWhite } from '../../../assets'
import PagenameSearchBox from '../../components/PagenameSearchBox'

const Invoice = () => {

    const navigate = useNavigate()

    const handleBlog = () => {
        navigate('/admin/createInvoice')
    }

    return (
        <>
            <div className='h-[100vh] bg-white px-7 pb-20 font-josefin-sans'>
                <div className='bg-red-800 py-6 flex justify-center flex-wrap md:gap-5 lg:gap-3 xl:gap-5 2xl:gap-10 rounded-lg'>
                    <div className='flex justify-between items-start border-r text-white border-white md:w-[180px] lg:w-[150px] xl:w-60 2xl:w-72 md:px-5 lg:px-3 xl:px-5 2xl:px-10'>
                        <div>
                            <p className='text-[36px] font-semibold'>24</p>
                            <p className='text-2xl'>Users</p>
                        </div>
                        <img src={usreWhite} alt="user" className='w-6 h-6' />
                    </div>
                    <div className='flex justify-between items-start text-white border-white md:w-[180px] lg:w-[150px] xl:w-60 2xl:w-72 md:px-5 lg:px-3 xl:px-5 2xl:px-10 border-r md:border-r-0 lg:border-r'>
                        <div>
                            <p className='text-[36px] font-semibold'>24</p>
                            <p className='text-2xl'>Users</p>
                        </div>
                        <img src={invoiceWhite} alt="user" className='w-6 h-6' />
                    </div>
                    <div className='flex justify-between items-start border-r text-white border-white md:w-[180px] lg:w-[150px] xl:w-60 2xl:w-72 md:px-5 lg:px-3 xl:px-5 2xl:px-10'>
                        <div>
                            <p className='text-[36px] font-semibold'>24</p>
                            <p className='text-2xl'>Users</p>
                        </div>
                        <img src={DolarWhite} alt="user" className='w-5 h-6' />
                    </div>
                    <div className='flex justify-between items-start text-white md:w-[180px] lg:w-[150px] xl:w-60 2xl:w-72 md:px-5 lg:px-3 xl:px-5 2xl:px-10'>
                        <div>
                            <p className='text-[36px] font-semibold'>24</p>
                            <p className='text-2xl'>Users</p>
                        </div>
                        <img src={fileWhite} alt="user" className='w-6 h-6' />
                    </div>
                </div>

                <PagenameSearchBox title='User Invoice' placename='Search Invoice' btnname='Create Invoice' btnfunction={handleBlog} />

                <div className="relative overflow-x-auto sm:rounded-lg mt-10">
                    <table className="w-full text-left border border-stone-300">
                        <thead className="text-xl text-black  bg-stone-300">
                            <tr>
                                <th scope="col" className=" px-6 py-3">
                                    #ID
                                </th>
                                <th scope="col" className=" px-6 py-3">
                                    Name
                                </th>
                                <th scope="col" className=" px-6 py-3">
                                    Cost
                                </th>
                                <th scope="col" className=" px-6 py-3">
                                    Plan
                                </th>
                                <th scope="col" className=" px-6 py-3">
                                    Issue Date
                                </th>
                                <th scope="col" className=" px-6 py-3 ">
                                    Download
                                </th>
                                <th scope="col" className=" px-6 py-3 text-center">
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="bg-white font-normal text-black text-xl border-b border-stone-300">
                                <td className="px-6 py-4 whitespace-nowrap ">#ET001</td>
                                <td className="px-6 py-4 whitespace-nowrap ">gagan.pyt@gmail.com</td>
                                <td className="px-6 py-4 whitespace-nowrap ">$20</td>
                                <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                                    <div className='rounded-full border text-base w-20 py-1 border-[#FFD700] bg-[#FFD7004A] text-[#FFD700]'>
                                        <p className='text-center'>Gold</p>
                                    </div>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap ">28 Mar 2020</td>
                                <td className="px-6 py-4">-</td>
                                <td className="px-6 py-4">
                                    <div className='flex gap-5 justify-center items-center'>
                                        <img src={eye} alt="rename" className='hover:cursor-pointer' />
                                        <img src={rename} alt="rename" className='hover:cursor-pointer' />
                                        <img src={DeleteImg} alt="deteteImg" className='hover:cursor-pointer' />
                                    </div>
                                </td>
                            </tr>
                            <tr className="bg-white font-normal text-black text-xl border-b border-stone-300">
                                <td className="px-6 py-4 whitespace-nowrap ">#ET002</td>
                                <td className="px-6 py-4 whitespace-nowrap ">jatav.creative777@gmail.com</td>
                                <td className="px-6 py-4 whitespace-nowrap ">$60</td>
                                <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                                    <div className='rounded-full border text-base w-20 py-1 border-[#CD7F32] bg-[#CD7F324A] text-[#CD7F32]'>
                                        <p className='text-center'>Bronze</p>
                                    </div>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap ">28 Mar 2020</td>
                                <td className="px-6 py-4">-</td>
                                <td className="px-6 py-4">
                                    <div className='flex gap-5 justify-center items-center'>
                                        <img src={eye} alt="rename" className='hover:cursor-pointer' />
                                        <img src={rename} alt="rename" className='hover:cursor-pointer' />
                                        <img src={DeleteImg} alt="deteteImg" className='hover:cursor-pointer' />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default Invoice