import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import UserApiClient from '../../../utilities/apiClients';

const BASE_URL = 'https://api.eromantic.ai/api/'; // Replace with your actual API URL

function Blogs() {
    const [blogs, setBlogs] = useState([]);
    const carouselRef = useRef(null);  // Reference to the carousel container
    const navigate = useNavigate();


    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                const response = await axios.get('https://api.eromantic.ai/api/show_blog_post_public/?limit=4');
                setBlogs(response.data);
            } catch (error) {
                console.error('Error fetching blogs:', error);
            }
        };
    
        fetchBlogs();
    }, []); 
    // useEffect(() => {
    //     const fetchBlogs = async () => {

    //         await UserApiClient.get('api/show_blog_post_public/?limit=4', {}, false).then(async (res) => {
    //             if (res.success) {
    //                 setBlogs(res.data);
    //             }
    //         })
    //     };

    //     fetchBlogs();
    // }, []);

    function stripHtml(html) {
        const div = document.createElement('div');
        div.innerHTML = html;
        return div.textContent || div.innerText || '';
    }

    // Slide the carousel to the left
    const slideLeft = () => {
        if (carouselRef.current) {
            carouselRef.current.scrollBy({
                left: -carouselRef.current.offsetWidth / 2, // Adjust scroll amount (half the container width)
                behavior: 'smooth',
            });
        }
    };

    // Slide the carousel to the right
    const slideRight = () => {
        if (carouselRef.current) {
            carouselRef.current.scrollBy({
                left: carouselRef.current.offsetWidth / 2, // Adjust scroll amount (half the container width)
                behavior: 'smooth',
            });
        }
    };

    return (
        <div>
            <section className="pb-4">
                <div>
                    <h2 className="font-manrope text-red-600 text-3xl sm:text-4xl font-bold text-gray-900 text-center mb-2">Our latest blog</h2>
                    <h4 className="font-manrope text-xl sm:text-2xl font-bold text-gray-900 text-center mb-2">FROM OUR AI GIRLFRIEND BLOG</h4>
                    <h4 className="font-manrope text-lg sm:text-xl font-bold text-gray-900 text-center mb-16">
                        Curious about what an AI girlfriend is? In our blog, you can find all the necessary information about the topic AI girlfriend.
                    </h4>

                    {/* Custom Carousel */}
                    <div className="relative">
                        <div className="overflow-hidden">
                            <div
                                ref={carouselRef}  // Using ref to target the carousel
                                className="flex transition-all duration-500"
                                style={{ scrollBehavior: 'smooth' }}
                            >
                                {blogs.length > 0 ? (
                                    blogs.map(blog => (
                                        <div
                                            key={blog.blog_id}
                                            className="flex-none w-full sm:w-1/2 lg:w-1/3 xl:w-1/4 px-4"
                                        >
                                            <div className="group w-full border border-gray-300 rounded-2xl">
                                                <div className="flex items-center">
                                                    <img
                                                        src={blog.image} // Ensure this property exists and contains the correct image URL
                                                        alt={blog.title}
                                                        className="h-72 object-cover rounded-t-2xl w-full object-cover"
                                                    />
                                                </div>
                                                <div className="p-4 lg:p-6 transition-all duration-300 rounded-b-2xl group-hover:bg-gray-50">
                                                    <span className="text-[#99000b] font-medium mb-3 block">{blog.formattedDate}</span>
                                                    <div className="overflow-hidden">
                                                        <h4 className="xl:h-[4rem] lg:h-[5rem] text-xl text-gray-900 font-medium leading-8 mb-5">
                                                            {blog.title?.length > 80 ? stripHtml(blog.title).slice(0, 80) + '...' : stripHtml(blog.title)}
                                                        </h4>
                                                    </div>

                                                    {/* <p className="text-gray-500 leading-6 mb-10">
                                                        {blog.description?.length > 100 ? stripHtml(blog.description).slice(0, 100) + '...' : stripHtml(blog.description)}
                                                    </p> */}
                                                    <a
                                                        href={`/singleBlog/${blog.slug}`}
                                                        className="cursor-pointer text-lg text-[#99000b] font-semibold"
                                                    >
                                                        Read more..
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <p>No blogs available at the moment.</p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {blogs.length > 0 ? (
                <div className='text-center py-10'>
                    <a class="border-2 px-5 border-[#99000B] rounded-[50px] p-2 rounded-full transition-colors duration-300 bg-[#99000B] text-white" href="/blogs">Read More Blogs</a>
                </div>
            )
                :
                ""
            }
        </div>
    );
}

export default Blogs;
