import React, { useState, useEffect } from "react";
import { Navigate, useLocation, useParams } from "react-router-dom"; // Import useParams from react-router
import { img1 } from "../../../assets";
import { VscHeartFilled } from "react-icons/vsc";
import DiscoverImagesBox from "../../components/DiscoverImagesBox";
import axios from "axios";
import "../../../App.css";
import UserApiClient from "../../../utilities/apiClients";

const promptDataWithoutdis = [
  { name: "PROMPT" },
  //   { name: "FACE PROMPT" },
  //   { name: "EXCLUDE" },
  //   { name: "EXTENSIONS" },
  //   { name: "OTHER DETAILS" },
];

const ImageView = () => {
  const [selectedName, setSelectedName] = useState("PROMPT");
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(false); // Separate loading for image generation
  const [error, setError] = useState(null);
  const [drive, setDrive] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [GeneratedImage, setGeneratedImage] = useState(null);
  const [putResponsedata, setPutResponsedata] = useState(null);
  const [generateduser, setGeneratedUser] = useState("UNKNOWN");
  const [promptData, setPromptData] = useState(promptDataWithoutdis); // Initialize with default data
  const [currentIndex, setCurrentIndex] = useState(0);

  const { id } = useParams();
  const selectedDescription = promptData.find(
    (item) => item.name === selectedName
  )?.description;

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const dataString = queryParams.get("data");

  const dataArray = dataString ? dataString.split(",") : [];

  let url = id.trim();
  // if (url.toLowerCase().includes('_creations')) {
  //     const parts = url.split('_');
  // const assistent_id = "asst_"+parts[parts.length - 1];  // The last part of the URL

  // console.log('Extracted value:', assistent_id);
  // const payload = {
  //     Aimodel: data.Aimodel ?? "1",
  //     Aititle: data.Aititle,
  //     generation_prompt: data.generation_prompt,
  //     steps: data.steps || "medium",
  //     make_ai_listen: data.make_ai_listen || "creative",
  //     magic: data.magic || "none",
  //     aspect_ratio: data.aspect_ratio || "fourth",
  //     variations: data.variations || "",
  //     make_private: data.make_private || false,
  //     user_name: data.user_name || "Anonymous",
  //     generation_id: data.generation_id || `ER_GenImg_${Date.now()}`,
  //     generation_slug: data.generation_slug || `ER_${Date.now()}_slug`,
  //     extensions: data.extensions || "",
  //     face_prompt: data.face_prompt || "",
  //     other: data.other || "",
  //     nationality: data.nationality,
  // }
  //     UserApiClient.post(`assistant_api/bot/image_generate/`, {"assistant_id":assistent_id}, false).then(async (res) => {
  //         if (res.success) {

  //         }})
  // } else {

  // }

  useEffect(() => {
    setGeneratedImage(dataArray);

    const fetchImageData = async () => {
      try {
        const response = await axios.get(
          `https://api2.eromantic.ai/api/v1/get_generated_image/${id}/`
        );
        const updatedPromptData = promptDataWithoutdis.map((item) => {
          setDrive(response.data.generation_prompt);
          if (response.data.user_name !== null) {
            setGeneratedUser(response.data);
          }
          setPutResponsedata(response.data);

          if (item.name === "PROMPT") {
            return { ...item, description: response.data.generation_prompt };
            //   } else if (item.name === "EXCLUDE") {
            //     return { ...item, description: response.data.variations };
            //   } else if (item.name === "FACE PROMPT") {
            //     return { ...item, description: response.data.face_prompt };
          } else if (item.name === "EXTENSIONS") {
            return { ...item, description: response.data.extensions };
          } else if (item.name === "OTHER DETAILS") {
            return { ...item, description: response.data.other };
          }
          return item;
        });
        setPromptData(updatedPromptData);
        setLoading(false);
        if (
          response.data.image_file_name &&
          response.data.image_file_name.length > 0
        ) {
          setGeneratedImage(response.data.image_file_name);
          setLoading2(false);
        } else {
          generateImage(response.data);
        }
      } catch (error) {
        setError("Failed to fetch data");
        console.log(error, "error");
        setLoading(false);
      }
    };

    // Only call fetchImageData once when the component mounts
    if (id) {
      fetchImageData();
    }

    // Scroll to top when the page loads
    window.scrollTo(0, 0);
  }, [id]);

  const generateImage = async (data) => {
    setLoading2(true); // Show loader for generating image
    try {
      const payload = {
        Aimodel: data.Aimodel ?? "1", // Default value if Aimodel is null
        Aititle: data.Aititle,
        generation_prompt: data.generation_prompt,
        // image_file_name: data.image_file_name,
        steps: data.steps || "medium",
        make_ai_listen: data.make_ai_listen || "creative",
        magic: data.magic || "none",
        aspect_ratio: data.aspect_ratio || "fourth",
        variations: data.variations || "",
        upscale: data.upscale || false,
        make_private: data.make_private || false,
        user_name: data.user_name || "Anonymous",
        generation_id: data.generation_id || `ER_GenImg_${Date.now()}`,
        generation_slug: data.generation_slug || `ER_${Date.now()}_slug`,
        extensions: data.extensions || "",
        face_prompt: data.face_prompt || "",
        other: data.other || "",
        nationality: data.nationality,
      };
      const authToken = localStorage.getItem("token");
      const response = await axios.post(
        "https://api2.eromantic.ai/api/v1/LoadImage/",
        payload,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      // Handle the response
      setGeneratedImage(response.data.images);
      setLoading2(false);
    } catch (error) {
      console.error("Error generating image:", error);
      setError("Failed to generate image. Please try again.");
      setLoading2(false);
    }
  };

  const downloadImage = async () => {
    if (GeneratedImage) {
      setLoading(true); // Start loading
      setError(null); // Reset any previous error
      try {
        const response = await fetch(GeneratedImage);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);

        // Show a preview of the image in an <img> element (optional)
        const imagePreview = document.createElement("img");
        imagePreview.src = url;
        imagePreview.alt = "Generated Image Preview";
        document.body.appendChild(imagePreview);

        // Automatically download the image
        const link = document.createElement("a");
        link.href = url;
        link.download = `generated-image_${Date.now()}.png`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error("Error downloading image:", error);
        setError("Failed to download image. Please try again.");
      } finally {
        setLoading(false); // Stop loading
      }
    } else {
      setError("No image available for download");
    }
  };

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const nextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % GeneratedImage.length);
  };

  const prevImage = () => {
    setCurrentIndex(
      (prevIndex) =>
        (prevIndex - 1 + GeneratedImage.length) % GeneratedImage.length
    );
  };

  const handleThumbnailClick = (index) => {
    setCurrentIndex(index); // Update the currentIndex to the clicked thumbnail's index
  };

  return (
    <>
      <div className="2xl:px-20 lg:px-10 px-2 mt-5 pb-20 pt-10 text-white font-josefin-sans">
        <div className="flex flex-wrap justify-center gap-7">
          <div className="flex flex-col gap-3">
            <div className="flex items-center gap-3">
              <p className="lg:text-3xl font-medium text-black">Created by </p>
              <p className="lg:text-2xl font-light text-yellow-500">
                @{generateduser.user_name}
              </p>
            </div>
            {loading2 ? (
              <div className="flex items-center justify-center w-[350px] h-[400px] lg:w-[400px] xl:w-[500px] lg:h-[500px] xl:h-[600px] 2xl:w-[570px] 2xl:h-[621px]">
                <div className="loader"></div>
              </div>
            ) : (
              <div className="image-container">
                {Array.isArray(GeneratedImage) &&
                GeneratedImage.length === 1 ? (
                  // If only 1 image is present, render a single image
                  <img
                    src={GeneratedImage[0]}
                    style={{ objectFit: "cover" }}
                    onClick={toggleModal}
                    alt="Generated Image"
                    className="w-[350px] h-[400px] lg:w-[400px] xl:w-[500px] lg:h-[500px] xl:h-[600px] 2xl:w-[570px] 2xl:h-[621px] rounded-xl"
                  />
                ) : Array.isArray(GeneratedImage) &&
                  GeneratedImage.length > 1 ? (
                  // If multiple images are present, render them in a slider
                  <div className="relative">
                    <button
                      onClick={prevImage}
                      className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-black text-white p-2 rounded-full"
                    >
                      &#10094;
                    </button>

                    <img
                      src={GeneratedImage[currentIndex]}
                      alt={`Generated Image ${currentIndex + 1}`}
                      className="w-[350px] h-[400px] lg:w-[400px] xl:w-[500px] lg:h-[500px] xl:h-[600px] 2xl:w-[570px] 2xl:h-[621px] rounded-xl"
                      style={{ objectFit: "cover" }}
                      onClick={toggleModal}
                    />

                    <button
                      onClick={nextImage}
                      className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-black text-white p-2 rounded-full"
                    >
                      &#10095;
                    </button>

                    {/* Thumbnails Gallery */}
                    <div className="mt-5 w-full flex justify-center overflow-x-auto gap-4">
                      {Array.isArray(GeneratedImage) &&
                        GeneratedImage.length > 1 &&
                        GeneratedImage.map((image, index) => (
                          <div
                            key={index}
                            onClick={() => handleThumbnailClick(index)}
                            className={`cursor-pointer ${
                              currentIndex === index
                                ? "border-2 border-red-600"
                                : ""
                            }`}
                          >
                            <img
                              src={image}
                              alt={`Thumbnail ${index + 1}`}
                              className="w-[80px] h-[100px] object-cover rounded-lg"
                            />
                          </div>
                        ))}
                    </div>
                  </div>
                ) : (
                  // Fallback for when no images are available
                  <div>No images available</div>
                )}
              </div>
            )}
            {error && <div className="error-message">{error}</div>}{" "}
            {/* Display error */}
            {isOpen && (
              <div
                className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 pointer-events-auto"
                style={{ zIndex: "9999" }}
              >
                <div className="bg-white p-4 rounded-lg">
                  <img
                    src={GeneratedImage[currentIndex]}
                    alt="Enlarged"
                    className="w-full h-auto max-w-2xl max-h-full rounded-lg"
                  />
                  <button
                    className="mt-4 p-2 bg-blue-500 text-white rounded"
                    onClick={toggleModal}
                  >
                    Close
                  </button>
                </div>
              </div>
            )}
          </div>

          <div className="flex flex-col gap-3">
            <div className="flex justify-between">
              <div className="flex items-center gap-3">
                {/* <VscHeartFilled color="#696969" className="lg:text-2xl" />
                <p className="w-7 lg:w-14 lg:text-2xl font-medium text-stone-500 text-left">
                  8
                </p> */}
                <div
                  className="flex"
                  style={{ cursor: "pointer" }}
                  onClick={downloadImage}
                >
                  {loading && <div className="loader"></div>}
                  <p className="lg:text-2xl font-medium hover:text-red-600">
                    <i className="fa-solid fa-download"></i> DOWNLOAD
                  </p>
                  {error && <div className="error-message">{error}</div>}{" "}
                  {/* Error message */}
                </div>
              </div>
              <div className="flex flex-col gap-3">
                <button
                  className="bg-red-800 [w-75%] text-white font-medium rounded-2xl py-2 px-4"
                  onClick={() => Navigate("/dashboard")}
                >
                  Generate New
                </button>
              </div>
            </div>
            {/* <div className="md:w-[700px] lg:w-[900px] lg:h-[500px] xl:w-[800px] xl:h-[600px] 2xl:w-[966px] 2xl:h-[621px] border-2 border-zinc-600 bg-[#36432e4a] rounded-xl py-3 px-5"> */}
            {/* <div className="flex flex-wrap justify-start gap-2 md:gap-4 lg:gap-7 w-full text-[#42372e]">
                {promptData.map((item) => (
                  <p
                    key={item.name}
                    className={`cursor-pointer ${
                      selectedName === item.name ? "text-red-600 overline" : ""
                    } md:text-xl font-semibold`}
                    onClick={() => setSelectedName(item.name)}
                  >
                    {item.name}
                  </p>
                ))}
              </div>
              <div className="mt-5 text-[#352039] md:text-[23px] font-normal">
                {selectedDescription
                  ? selectedDescription
                  : "No description available"}
              </div> */}

            <div class="md:w-[700px] lg:w-[900px] xl:w-[800px] 2xl:w-[966px] border-2 border-zinc-600 rounded-xl py-6 px-8 shadow-lg hover:shadow-2xl transition-shadow duration-300">
              <div class="bg-gradient-to-r from-[#b59797] via-[#b59797] to-[#6d6d6d] p-6 rounded-xl shadow-md mb-6 transform transition-all hover:scale-105 hover:bg-gradient-to-r hover:from-[#6d6d6d] hover:via-[#b59797] hover:to-[#b59797] duration-300 fade-in">
                <div class="text-2xl font-semibold text-white mb-4">Prompt</div>
                {/* <div class="text-lg text-white mb-2">{promptData.name}</div> */}
                <div class="text-md text-zinc-300">
                  {selectedDescription
                    ? selectedDescription
                    : "No description available"}
                </div>
              </div>

              <div class="bg-gradient-to-r from-[#b59797] via-[#b59797] to-[#6d6d6d] p-6 rounded-xl shadow-md mb-6 transform transition-all hover:scale-105 hover:bg-gradient-to-r hover:from-[#6d6d6d] hover:via-[#b59797] hover:to-[#b59797] duration-300 fade-in">
                <div class="text-2xl ul font-semibold text-white mb-4">
                  Upscale Image
                </div>
                {/* <div class="text-lg text-white mb-2">Your content for Section 2</div> */}
                <div class="text-md text-zinc-300">
                  Section 2 description goes here. You can add dynamic content
                  based on your needs.
                </div>
              </div>

              {/* <div class="bg-gradient-to-r from-[#b59797] via-[#b59797] to-[#6d6d6d] p-6 rounded-xl shadow-md mb-6 transform transition-all hover:scale-105 hover:bg-gradient-to-r hover:from-[#6d6d6d] hover:via-[#b59797] hover:to-[#b59797] duration-300 fade-in delay-200">
                <div class="text-2xl font-semibold text-white mb-4">
                  Section 3
                </div>
                <div class="text-md text-zinc-300">
                  Section 3 description goes here. Use this area to provide more
                  details or additional content.
                </div>
              </div>

              <div class="bg-gradient-to-r from-[#b59797] via-[#b59797] to-[#6d6d6d] p-6 rounded-xl shadow-md mb-6 transform transition-all hover:scale-105 hover:bg-gradient-to-r hover:from-[#6d6d6d] hover:via-[#b59797] hover:to-[#b59797] duration-300 fade-in delay-400">
                <div class="text-2xl font-semibold text-white mb-4">
                  Section 4
                </div>
                <div class="text-lg text-white mb-2">
                  Your content for Section 4
                </div>
                <div class="text-md text-zinc-300">
                  Section 4 description goes here. Here, you can include further
                  information or details that enhance the user experience.
                </div>
              </div> */}
            </div>
          </div>
        </div>

        <div className="border-t border-zinc-600 my-9"></div>

        <div className="flex items-center gap-6">
          <div className="bg-red-700 w-5 h-5 rounded-full"></div>
          <p className="text-lg lg:text-2xl font-light text-yellow-500">
            {/* @{generateduser} */}
          </p>
          <p className="text-xl text-stone-600 font-medium">
            {generateduser.created_at
              ? generateduser.created_at.split("T")[0]
              : "Date not available"}
          </p>
        </div>

        {/* <p className='text-lg lg:text-2xl font-medium mt-1 lg:mt-6'>{drive}</p>
                <p className='text-3xl lg:text-4xl font-medium mt-4 lg:mt-[75px]'>Comment</p>
                <div className='bg-zinc-300 rounded-xl mt-1 lg:mt-2 px-5 py-3 text-black text-lg lg:text-2xl font-light w-full h-48'>
                    <input type="text" className='focus:outline-none bg-zinc-300 w-full' placeholder='Leave a comment' />
                </div>
                <button className='bg-red-800 w-40 lg:w-80 text-center py-2 rounded-full text-lg lg:text-2xl mt-3 lg:mt-4'>SUBMIT</button>

                <div className='border-t border-zinc-600 my-4 lg:my-9'></div> */}

        <DiscoverImagesBox />
      </div>
    </>
  );
};

export default ImageView;
